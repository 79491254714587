import { useContext, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/helper';
import {
  HUBS_OVERVIEW_TABLE_COLUMNS,
  SINGLE_CARDS_INFOS
} from 'constants/hub-overview';
import { OpsControl } from 'contexts/ops-control.context';
import {
  PENDING_TRANSIT_DATE_FORMAT,
  TOGGLE_BUTTON_INITAL_VALUE,
  TOGGLE_BUTTONS_LIST
} from 'constants/pending-transit';
import {
  exportDispatchingOpsRoom,
  getDispatchingOpsRoom
} from 'services/ops-control';
import { getPaginationCount } from 'utils/helpers';
import { downloadAsXlsx } from 'utils/download';

import BRToggleButton from 'components/BRToggleButton/BRToggleButton';
import PendingTransitFilter from '../PendingTransit/PendingTransitFilter/PendingTransitFilter';
import SingleInfoCard from '../SingleCardInfo/SingleCardInfo';
import { notify } from 'components/Notify/Notify';
import BRTable from 'components/BRTable/BRTable';
import HubView from './HubView/HubView';

import { ReactComponent as HubsIcon } from 'assets/bosta-icons/hubs.svg';

import './HubsOverview.less';

const HubsOverview = ({ intl }) => {
  const refreshTable = useRef();
  const { allHubs } = useContext(OpsControl);
  const [loading, setLoading] = useState(false);
  const [currentHub, setCurrentHub] = useState();
  const [toggleButtonValue, setToggleButtonValue] = useState(
    TOGGLE_BUTTON_INITAL_VALUE
  );
  const [selectedDate, setSelectedDate] = useState();
  const [HubDetails, setHubDetails] = useState();
  const [cardData, setCardData] = useState({
    toBeDispatched: 0,
    pending: 0,
    addedToRoute: 0,
    dispatched: 0,
    late: 0,
    missed: 0
  });

  function getCounts(el, toggleButton) {
    const counts = {
      toBeDispatched: 0,
      pending: 0,
      addedToRoute: 0,
      dispatched: 0,
      late: 0,
      missed: 0
    };

    switch (toggleButton) {
      case TOGGLE_BUTTONS_LIST[1].value:
        counts.toBeDispatched = el?.to_be_dispatched_forward_count;
        counts.pending = el?.pending_not_received_forward_count;
        counts.addedToRoute = el?.added_to_route_forward_count;
        counts.dispatched = el?.dispatched_forward_count;
        counts.late = el?.late_forward_count;
        counts.missed = el?.missed_forward_count;
        break;

      case TOGGLE_BUTTONS_LIST[2].value:
        counts.toBeDispatched = el?.to_be_dispatched_return_count;
        counts.pending = el?.pending_not_received_return_count;
        counts.addedToRoute = el?.added_to_route_return_count;
        counts.dispatched = el?.dispatched_return_count;
        counts.late = el?.late_return_count;
        counts.missed = el?.missed_return_count;
        break;

      default:
        counts.toBeDispatched =
          el?.to_be_dispatched_forward_count +
          el?.to_be_dispatched_return_count;
        counts.pending =
          el?.pending_not_received_forward_count +
          el?.pending_not_received_return_count;
        counts.addedToRoute =
          el?.added_to_route_forward_count + el?.added_to_route_return_count;
        counts.dispatched =
          el?.dispatched_forward_count + el?.dispatched_return_count;
        counts.late = el?.late_forward_count + el?.late_return_count;
        counts.missed = el?.missed_forward_count + el?.missed_return_count;
        break;
    }

    return counts;
  }

  const formatTableData = (data, toggleButton) => {
    let toBeDispatchedSum = 0,
      pendingSum = 0,
      addedToRouteSum = 0,
      dispatchedSum = 0,
      lateSum = 0,
      missedSum = 0;

    const formattedData = data?.map((el) => {
      const {
        toBeDispatched,
        dispatched,
        late,
        missed,
        addedToRoute,
        pending
      } = getCounts(el, toggleButton);

      toBeDispatchedSum += toBeDispatched;
      pendingSum += pending;
      addedToRouteSum += addedToRoute;
      dispatchedSum += dispatched;
      lateSum += late;
      missedSum += missed;
      return {
        hub: (
          <div
            onClick={() => {
              setHubDetails(el);
            }}
            className="hub-deliveries__hubname-column"
          >
            {el?.warehouse_name}
          </div>
        ),
        to_be_dispatched: toBeDispatched,
        dispatched: dispatched,
        late: late,
        missed: missed
      };
    });

    setCardData({
      toBeDispatched: toBeDispatchedSum,
      pending: pendingSum,
      addedToRoute: addedToRouteSum,
      dispatched: dispatchedSum,
      late: lateSum,
      missed: missedSum
    });
    return formattedData;
  };

  const fetchTableData = async ({ page, limit, filterValues }) => {
    try {
      setLoading(true);
      const payload = {
        date: filterValues?.selectedDate
          ? filterValues?.selectedDate?.format(PENDING_TRANSIT_DATE_FORMAT)
          : dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(filterValues?.currentHub && {
          hubId: filterValues?.currentHub
        })
      };
      console.log(currentHub, filterValues);
      const res = await getDispatchingOpsRoom(payload);
      const result = res.data?.slice((page - 1) * limit, limit);
      setLoading(false);
      return {
        list: formatTableData(
          res?.data,
          filterValues?.toggleButton || toggleButtonValue
        ),
        total:
          res?.data.length === limit
            ? limit
            : getPaginationCount({
                page,
                result: result,
                pageSize: limit
              })
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const exportTableData = async () => {
    try {
      const payload = {
        date: selectedDate
          ? selectedDate.format(PENDING_TRANSIT_DATE_FORMAT)
          : dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(currentHub && { hubId: currentHub }),
        ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[1].value && {
          forwardDeliveriesOnly: true
        }),
        ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[2].value && {
          forwardDeliveriesOnly: false
        })
      };

      const data = await exportDispatchingOpsRoom(payload);
      downloadAsXlsx(
        data,
        `dispatching_report_${selectedDate || dayjs()}.xlsx`
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const changeToggleButton = (value) => {
    setToggleButtonValue(value);
    refreshTable.current({
      filterValues: {
        toggleButton: value,
        selectedDate: selectedDate,
        currentHub: currentHub
      }
    });
  };

  const handleHubChange = (hubValue) => {
    refreshTable.current({
      filterValues: {
        toggleButton: toggleButtonValue,
        selectedDate: selectedDate,
        currentHub: hubValue
      }
    });
    setCurrentHub(hubValue);
  };

  const changeDateValue = (dateValue) => {
    setSelectedDate(dateValue);
    refreshTable.current({
      filterValues: {
        toggleButton: toggleButtonValue,
        selectedDate: dateValue,
        currentHub: currentHub
      }
    });
  };

  const clearSelectedDate = () => {
    setSelectedDate();
  };

  return (
    <div>
      {!HubDetails ? (
        <>
          <p className="display-xs pending-transit__title">
            {intl.formatMessage({ id: 'ops_control.pending_transit.header' })}
          </p>

          <div className="pending-transit__header">
            <BRToggleButton
              loading={loading}
              buttonsList={TOGGLE_BUTTONS_LIST}
              setValue={setToggleButtonValue}
              value={toggleButtonValue}
              changeToggleButton={changeToggleButton}
            />
            <PendingTransitFilter
              loading={loading}
              intl={intl}
              allHubs={allHubs}
              currentHub={currentHub}
              selectedDate={selectedDate}
              setCurrentHub={setCurrentHub}
              setSelectedDate={setSelectedDate}
              handleHubChange={handleHubChange}
              changeDateValue={changeDateValue}
            />
          </div>
          <div className="pending-transit__cards-container hubsOverview-container">
            {SINGLE_CARDS_INFOS(cardData).map(({ data, title }) => {
              return (
                <SingleInfoCard loading={loading} data={data} title={title} />
              );
            })}
          </div>
          <div className="pending-transit__hubs-table-container">
            <BRTable
              exportListFileFunction={exportTableData}
              shareMethods={acceptMethods}
              listFunction={fetchTableData}
              pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
              columns={HUBS_OVERVIEW_TABLE_COLUMNS}
              hideFilterButton
              title={
                <div className="pending-transit__hubs-table-title">
                  <HubsIcon />
                  {intl.formatMessage({
                    id: 'ops_control.pending_transit.hubs_table.title'
                  })}
                </div>
              }
            />
          </div>
        </>
      ) : (
        <HubView
          allHubs={allHubs}
          hubDetails={HubDetails}
          setHubDetails={setHubDetails}
          clearSelectedDate={clearSelectedDate}
          intl={intl}
          setCurrentHub={setCurrentHub}
        />
      )}
    </div>
  );
};

export default injectIntl(HubsOverview);
