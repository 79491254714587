import { fmt } from 'IntlWrapper/IntlWrapper';
import { ALL_COUNTRIES } from 'common/countries/constants/countries';
import { currentUserCountry } from 'common/countries/countries-mapping';
import { handleOnShowAllTnClick } from 'utils/money-debrief';

export const moneyDebriefReportColumns = [
  {
    dataIndex: 'debriefID',
    key: 'debriefID',
    title: fmt({ id: 'hub_management.money_debrief_report.columns.debrief_id' })
  },
  {
    dataIndex: 'debriefDate',
    key: 'debriefDate',
    title: fmt({
      id: 'hub_management.money_debrief_report.columns.debrief_date'
    })
  },
  {
    dataIndex: 'star',
    key: 'star',
    title: fmt({ id: 'hub_management.money_debrief_report.columns.star' })
  },
  {
    dataIndex: 'receiptType',
    key: 'receiptType',
    title: fmt({
      id: 'hub_management.money_debrief_report.columns.receip_type'
    })
  },
  {
    dataIndex: 'trackingNumber',
    key: 'trackingNumber',
    title: fmt({
      id: 'hub_management.money_debrief_report.columns.tn'
    }),
    render: (tn, { additionalInfo }) =>
      additionalInfo?.trackingNumbers?.length ? (
        <span
          className="br-money-debrief-report__show-all-tn-text"
          onClick={() => handleOnShowAllTnClick(additionalInfo.trackingNumbers)}
        >
          Show all
        </span>
      ) : (
        tn
      )
  },
  {
    dataIndex: 'receiptNo',
    key: 'receiptNo',
    title: fmt({
      id: 'hub_management.money_debrief_report.columns.receipt_number'
    })
  },
  ...(currentUserCountry.codeName === ALL_COUNTRIES[0].codeName
    ? [
        {
          dataIndex: 'fawryId',
          key: 'fawryId',
          title: fmt({
            id: 'hub_management.money_debrief_report.columns.fawry_id'
          })
        }
      ]
    : []),
  {
    dataIndex: 'amount',
    key: 'amount',
    title: fmt({ id: 'hub_management.money_debrief_report.columns.amount' })
  },
  {
    dataIndex: 'receiptDate',
    key: 'receiptDate',
    title: fmt({
      id: 'hub_management.money_debrief_report.columns.receipt_date'
    })
  }
];

export const hubTransferReportColumns = [
  { dataIndex: 'containerID', title: 'Container ID' },
  { dataIndex: 'originHub', title: 'Origin Hub' },
  { dataIndex: 'destinationHub', title: 'Destination Hub' },
  { dataIndex: 'starId', title: 'Star Id' },
  {
    dataIndex: 'numberOfPackagesTransferred',
    title: 'Packages Transferred',
    align: 'right'
  },
  {
    dataIndex: 'numberOfPackagesReceived',
    title: 'Packages Received',
    align: 'right'
  },
  { dataIndex: 'transferDate', title: 'Transfer Date' },
  { dataIndex: 'transferState', title: 'Transfer State' }
];

export const transferStates = ['Created', 'In progress', 'Completed'];

export const ALL_HUBS = 'All_Hubs';
