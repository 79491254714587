import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { getProblematicJobsStatistics } from 'services/fulfillment-problematic-jobs';
import { PROBLEMATIC_JOBS_STATE_IDS } from 'constants/fulfillment-problematic-jobs';
import { SllrWrapper } from 'contexts/sllr.context';

import FxFStatistics from 'components/NewFulfillment/CommonComponents/FxFStatistics/FxFStatistics';
import FxFFilters from 'components/NewFulfillment/CommonComponents/FxFFilters/FxFFilters';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import FxFDateFilter from 'components/NewFulfillment/CommonComponents/FxFDateFilter/FxFDateFilter';
import ProblematicJobsTable from 'components/NewFulfillment/FulfillmentProblematicJobs/ProblematicJobsList/components/ProblematicJobsTable/ProblematicJobsTable';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as CreatedIcon } from 'assets/bosta-icons/Created.svg';
import { ReactComponent as CompletedIcon } from 'assets/bosta-icons/CompletedIcon.svg';

const ProblematicJobsList = ({ intl }) => {
  const { isLoading, setIsLoading } = useContext(SllrWrapper);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const [tableFilters, setTableFilters] = useState({
    state: null
  });
  const [isFirstFetchDone, setIsFirstFetchDone] = useState(false);
  const [statisticsFilters, setStatisticsFilters] = useState({});

  const { ON_HOLD, PUTAWAY } = PROBLEMATIC_JOBS_STATE_IDS;

  const getStatisticsListToRender = ({ total, onHoldCount, putAwayCount }) => [
    {
      key: 'total',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.total'
      }),
      content: total,
      stateIdFilterValue: null
    },
    {
      key: 'created',
      title: intl.formatMessage({
        id: 'fulfillment_problematic_jobs.state_name.created'
      }),
      content: onHoldCount,
      icon: <CreatedIcon />,
      stateIdFilterValue: ON_HOLD
    },
    {
      key: 'completed',
      title: intl.formatMessage({
        id: 'fulfillment_problematic_jobs.state_name.completed'
      }),
      content: putAwayCount,
      icon: <CompletedIcon />,
      stateIdFilterValue: PUTAWAY
    }
  ];

  const updateFilters = (filter) => {
    if (isFirstFetchDone) {
      setTableFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  const updateStatisticsFilters = (filter) => {
    if (isFirstFetchDone) {
      setStatisticsFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'fulfillment_problematic_jobs.title'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div>
            <div className="br-fulfillment-return-list__date-filter-section">
              <FxFDateFilter
                updateFilters={updateFilters}
                updateStatisticsFilters={updateStatisticsFilters}
              />
            </div>
            <FxFStatistics
              updateFilters={updateFilters}
              poFilters={tableFilters}
              fetchData={getProblematicJobsStatistics}
              getStatisticsListToRender={getStatisticsListToRender}
              statisticsFilters={statisticsFilters}
            />
            <FxFFilters updateFilters={updateFilters} hideDateFilter />
            <ProblematicJobsTable
              tableFilters={tableFilters}
              isFirstFetchDone={isFirstFetchDone}
              setIsFirstFetchDone={setIsFirstFetchDone}
            />
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(withRouter(ProblematicJobsList));
