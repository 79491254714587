import { fmt } from 'IntlWrapper/IntlWrapper';

export const TOGGLE_BUTTONS_LIST = [
  {
    title: fmt({ id: 'ops_control.pending_transit.toggle_bottons.all' }),
    value: 'All'
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.toggle_bottons.forward' }),
    value: 'Forward'
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.toggle_bottons.return' }),
    value: 'Return'
  }
];

export const HUBS_TABLE_COLUMNS = [
  {
    title: fmt({ id: 'ops_control.pending_transit.hubs_table.hub' }),
    dataIndex: 'hub'
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.hubs_table.pending_from' }),
    dataIndex: 'pending_from'
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.hubs_table.pending_to' }),
    dataIndex: 'pending_to'
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.hubs_table.total_pending' }),
    dataIndex: 'total_pending'
  },
  {
    title: fmt({
      id: 'ops_control.pending_transit.hubs_table.pending_from_plus'
    }),
    dataIndex: 'pending_from_plus'
  },
  {
    title: fmt({
      id: 'ops_control.pending_transit.hubs_table.pending_to_plus'
    }),
    dataIndex: 'pending_to_plus'
  },
  {
    title: fmt({
      id: 'ops_control.pending_transit.hubs_table.total_pending_plus'
    }),
    dataIndex: 'total_pending_plus'
  },
  {
    title: fmt({ id: 'ops_control.pending_transit.hubs_table.max_order_age' }),
    dataIndex: 'max_order_age'
  }
];

export const TOTAL_OUTGOING_PENDING_FORWARD_DELIVERIES = (hubData) => {
  const {
    outgoing_forward_deliveries_not_exceeded_duration,
    outgoing_forward_deliveries_exceeded_duration
  } = hubData || {};
  return (
    outgoing_forward_deliveries_not_exceeded_duration +
      outgoing_forward_deliveries_exceeded_duration || 0
  );
};

export const TOTAL_INCOMING_PENDING_FORWARD_DELIVERIES = (hubData) => {
  const {
    incoming_forward_deliveries_not_exceeded_duration,
    incoming_forward_deliveries_exceeded_duration
  } = hubData || {};
  return (
    incoming_forward_deliveries_not_exceeded_duration +
      incoming_forward_deliveries_exceeded_duration || 0
  );
};

export const TOTAL_INCOMING_PENDING_RETURN_DELIVERIES = (hubData) => {
  const {
    incoming_return_deliveries_not_exceeded_duration,
    incoming_return_deliveries_exceeded_duration
  } = hubData || {};

  return (
    incoming_return_deliveries_not_exceeded_duration +
      incoming_return_deliveries_exceeded_duration || 0
  );
};

export const TOTAL_OUTGOING_PENDING_RETURN_DELIVERIES = (hubData) => {
  const {
    outgoing_return_deliveries_not_exceeded_duration,
    outgoing_return_deliveries_exceeded_duration
  } = hubData || {};

  return (
    outgoing_return_deliveries_not_exceeded_duration +
      outgoing_return_deliveries_exceeded_duration || 0
  );
};

export const TOTAL_PENDING_IN_TRANSIT = (hubData, toggleButtonValue) => {
  const transfferedFromSelectedHub =
    toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
      ? TOTAL_OUTGOING_PENDING_FORWARD_DELIVERIES(hubData)
      : toggleButtonValue === TOGGLE_BUTTONS_LIST[2].title
      ? TOTAL_OUTGOING_PENDING_RETURN_DELIVERIES(hubData)
      : TOTAL_OUTGOING_PENDING_FORWARD_DELIVERIES(hubData) +
        TOTAL_OUTGOING_PENDING_RETURN_DELIVERIES(hubData);

  const trasfferedToSelectedHub =
    toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
      ? TOTAL_INCOMING_PENDING_FORWARD_DELIVERIES(hubData)
      : toggleButtonValue === TOGGLE_BUTTONS_LIST[2].title
      ? TOTAL_INCOMING_PENDING_RETURN_DELIVERIES(hubData)
      : TOTAL_INCOMING_PENDING_FORWARD_DELIVERIES(hubData) +
        TOTAL_INCOMING_PENDING_RETURN_DELIVERIES(hubData);
  return [
    {
      title: fmt({ id: 'ops_control.hub_deliveries.transferred_from_hub' }),
      data: transfferedFromSelectedHub || 0
    },
    {
      title: fmt({ id: 'ops_control.hub_deliveries.transferred_to_hub' }),
      data: trasfferedToSelectedHub || 0
    }
  ];
};

export const TOTAL_PENDING_IN_TRANSIT_PLUS = (hubData, toggleButtonValue) => {
  const {
    outgoing_forward_deliveries_exceeded_duration,
    outgoing_return_deliveries_exceeded_duration,
    incoming_forward_deliveries_exceeded_duration,
    incoming_return_deliveries_exceeded_duration
  } = hubData || {};

  const transferredFromHubPlus =
    toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
      ? outgoing_forward_deliveries_exceeded_duration
      : toggleButtonValue === TOGGLE_BUTTONS_LIST[2].title
      ? outgoing_return_deliveries_exceeded_duration
      : outgoing_forward_deliveries_exceeded_duration +
        outgoing_return_deliveries_exceeded_duration;

  const transferredToHubPlus =
    toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
      ? incoming_forward_deliveries_exceeded_duration
      : toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
      ? incoming_return_deliveries_exceeded_duration
      : incoming_forward_deliveries_exceeded_duration +
        incoming_return_deliveries_exceeded_duration;
  return [
    {
      title: fmt({
        id: 'ops_control.hub_deliveries.transferred_from_hub'
      }),
      data: transferredFromHubPlus || 0
    },
    {
      title: fmt({
        id: 'ops_control.hub_deliveries.transferred_to_hub'
      }),
      data: transferredToHubPlus || 0
    }
  ];
};

export const SUM_PENDING_IN_TRANSIT = (hubData, toggleButtonValue) => {
  return toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
    ? TOTAL_INCOMING_PENDING_FORWARD_DELIVERIES(hubData) +
        TOTAL_OUTGOING_PENDING_FORWARD_DELIVERIES(hubData)
    : toggleButtonValue === TOGGLE_BUTTONS_LIST[2].title
    ? TOTAL_INCOMING_PENDING_RETURN_DELIVERIES(hubData) +
      TOTAL_OUTGOING_PENDING_RETURN_DELIVERIES(hubData)
    : TOTAL_INCOMING_PENDING_FORWARD_DELIVERIES(hubData) +
      TOTAL_OUTGOING_PENDING_FORWARD_DELIVERIES(hubData) +
      TOTAL_INCOMING_PENDING_RETURN_DELIVERIES(hubData) +
      TOTAL_OUTGOING_PENDING_RETURN_DELIVERIES(hubData);
};

export const SUM_PENDING_IN_TRANSIT_PLUS = (hubData, toggleButtonValue) => {
  const {
    outgoing_forward_deliveries_exceeded_duration,
    outgoing_return_deliveries_exceeded_duration,
    incoming_forward_deliveries_exceeded_duration,
    incoming_return_deliveries_exceeded_duration
  } = hubData || {};

  const forwardData =
    outgoing_forward_deliveries_exceeded_duration +
    incoming_forward_deliveries_exceeded_duration;

  const returnData =
    outgoing_return_deliveries_exceeded_duration +
    incoming_return_deliveries_exceeded_duration;
  return toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title
    ? forwardData
    : toggleButtonValue === TOGGLE_BUTTONS_LIST[2]
    ? returnData
    : forwardData + returnData || 0;
};

export const HUB_DELIVERIES_COLUMNS = [
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.tracking_number'
    }),
    dataIndex: 'tracking_number'
  },
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.transferred_from'
    }),
    dataIndex: 'trasferred_from'
  },
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.transferred_to'
    }),
    dataIndex: 'transferred_to'
  },
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.seal_number'
    }),
    dataIndex: 'seal_number'
  },
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.trasnfer_aging'
    }),
    dataIndex: 'transfer_aging'
  },
  {
    title: fmt({
      id: 'ops_control.hub_deliveries.deliveries_table.e2e_aging'
    }),
    dataIndex: 'e2e_aging'
  }
];

export const HUB_DELIVERIES_FILTERS = (
  hubs,
  transferredTo,
  transferredFrom
) => [
  {
    label: 'Transferred To',
    key: 'transferredTo',
    type: 'dropdown',
    menu: hubs,
    disabled: transferredFrom
  },
  {
    label: 'Transferred From',
    key: 'transferredFrom',
    type: 'dropdown',
    menu: hubs,
    disabled: transferredTo
  }
];

export const TOGGLE_BUTTON_INITAL_VALUE = 'All';

export const PENDING_TRANSIT_DATE_FORMAT = 'YYYY-MM-DD';
