import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import dayjs from 'dayjs';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';

import {
  getReturnOrders,
  exportReturnOrders
} from 'services/fulfillment-return-orders';
import { downloadAsXlsx } from 'utils';
import { formatDateTime } from 'utils/fulfillment';
import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/fulfillment';
import {
  FULFILLMENT_OUTBOUND_RETURN_ORDER_MAIN_PATH,
  RETURN_ORDER_TYPES_NAMES,
  RETURN_ORDERS_SEARCH_OPTIONS,
  RETURN_ORDERS_SEARCH_OPTIONS_IDS,
  getReturnOrderRenderedTag,
  shouldShowIssues
} from 'constants/fulfillment-return-orders';
import { ORDER_SORT } from 'constants/helper';
import { getSkuForScan } from 'constants/fulfillment-sales-orders';
import { SllrWrapper } from 'contexts/sllr.context';

import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as LostIcon } from 'assets/bosta-icons/help.svg';
import { ReactComponent as DamagedIcon } from 'assets/bosta-icons/Cup.svg';

const ReturnOrdersTable = ({
  intl,
  returnOrdersFilters,
  isFirstFetchDone,
  setIsFirstFetchDone
}) => {
  const { setIsLoading } = useContext(SllrWrapper);
  const [returnOrdersCount, setReturnOrdersCount] = useState(0);
  const [selectedSearchOption, setSelectedSearchOption] = useState(
    RETURN_ORDERS_SEARCH_OPTIONS_IDS.SKU.KEY
  );
  const [sharedPayload, setSharedPayload] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const refreshTable = useRef();

  const renderIcon = ({ icon, className = '', tooltipName }) => (
    <Tooltip
      title={intl.formatMessage({
        id: `fulfillment_returns.return_orders_table.${tooltipName}`
      })}
    >
      <Icon component={icon} className={`circled-icon ${className}`} />
    </Tooltip>
  );

  const columns = [
    {
      title: intl.formatMessage({
        id: 'fulfillment_returns.return_orders_table.columns.tracking_number'
      }),
      render: ({
        trackingNumber,
        id,
        containsLost,
        containsDamaged,
        state = {}
      }) => (
        <div className="br-fulfillment-returns-table__tracking-number-col">
          <Link
            target="_blank"
            to={`${FULFILLMENT_OUTBOUND_RETURN_ORDER_MAIN_PATH}/${id}`}
          >
            {trackingNumber}
          </Link>
          {shouldShowIssues(state.stateId) && (
            <>
              {containsLost &&
                renderIcon({
                  tooltipName: 'contains_lost',
                  className: 'br-lost-icon',
                  icon: LostIcon
                })}
              {containsDamaged &&
                renderIcon({
                  tooltipName: 'contains_damaged',
                  className: 'br-damaged-icon',
                  icon: DamagedIcon
                })}
            </>
          )}
        </div>
      )
    },
    {
      dataIndex: 'businessName',
      title: intl.formatMessage({
        id: 'fulfillment_returns.return_orders_table.columns.business_name'
      })
    },
    {
      dataIndex: 'skuCount',
      title: intl.formatMessage({
        id: 'fulfillment_returns.return_orders_table.columns.no_of_sku'
      }),
      sorter: true
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_returns.return_orders_table.columns.qty'
      }),
      render: ({ qualityCheckedQuantity, totalQuantity }) =>
        qualityCheckedQuantity ?? totalQuantity
    },
    {
      dataIndex: 'type',
      title: intl.formatMessage({
        id: 'fulfillment_returns.return_orders_table.columns.type'
      }),
      render: ({ typeId }) => RETURN_ORDER_TYPES_NAMES[typeId],
      sorter: true
    },
    {
      dataIndex: 'state',
      title: intl.formatMessage({
        id: 'fulfillment_returns.return_orders_table.columns.status'
      }),
      render: getReturnOrderRenderedTag,
      sorter: true
    },
    {
      dataIndex: 'updatedAt',
      title: intl.formatMessage({
        id: 'fulfillment_returns.return_orders_table.columns.updated_at'
      }),
      render: (updatedAt) => {
        const { formattedDate, formattedTime } = formatDateTime(updatedAt);

        return `${formattedDate}, ${formattedTime}`;
      },
      sorter: true
    }
  ];

  const handleGetReturnOrders = async ({
    page,
    limit,
    searchInputText,
    sortField,
    sortOrder
  }) => {
    setIsLoading(true);
    let value = searchInputText?.trim();
    if (selectedSearchOption === RETURN_ORDERS_SEARCH_OPTIONS_IDS.SKU.KEY) {
      value = getSkuForScan(value);
    }
    const { returnOrderType, issueType, ...restFilters } = returnOrdersFilters;
    setSearchValue(value);
    const payload = omitBy(
      {
        page,
        limit,
        ...restFilters,
        type: returnOrderType,
        ...(value && {
          [RETURN_ORDERS_SEARCH_OPTIONS_IDS[selectedSearchOption].API_KEY]:
            value
        }),
        ...(sortField && {
          sort: `${sortOrder === ORDER_SORT.DESCEND ? '-' : ''}${sortField}`
        }),
        ...(issueType && {
          [issueType]: true
        }),
        linesType: 'all'
      },
      isNull
    );

    setSharedPayload(payload);
    setIsFirstFetchDone(true);

    try {
      const {
        data: { returnOrders = [], count = 0 }
      } = await getReturnOrders(payload);
      setReturnOrdersCount(count);
      setIsLoading(false);

      return {
        list: returnOrders,
        total: count
      };
    } catch (error) {
      notify(error.message);
      setIsLoading(false);
    }
  };

  const handleExportReturnOrders = async () => {
    setIsLoading(true);
    try {
      const data = await exportReturnOrders(sharedPayload);
      const fileName = `Return_Orders_${dayjs().format('DD_MM_YYYY')}`;
      downloadAsXlsx(data, fileName);
      notify(
        intl.formatMessage({
          id: 'common.file_downloaded_successfully'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = () => refreshMethod({ pageNumber: 1 });
  };

  const updateTable = () => {
    refreshTable.current();
  };

  useEffect(() => {
    if (isFirstFetchDone && refreshTable?.current) {
      updateTable();
    }
  }, [returnOrdersFilters]);

  return (
    <BRTable
      className="br-fulfillment-table"
      title={intl.formatMessage(
        {
          id: 'fulfillment_returns.return_orders_table.title'
        },
        {
          count: returnOrdersCount
        }
      )}
      columns={columns}
      listFunction={handleGetReturnOrders}
      shareMethods={acceptMethods}
      searchPlaceholder={intl.formatMessage(
        {
          id: 'fulfillment_returns.return_orders_table.search_placeholder'
        },
        {
          searchBy: RETURN_ORDERS_SEARCH_OPTIONS_IDS[selectedSearchOption].LABEL
        }
      )}
      exportListFileFunction={handleExportReturnOrders}
      showFilter={false}
      showSearch
      searchOptions={RETURN_ORDERS_SEARCH_OPTIONS}
      selectedSearchOption={selectedSearchOption}
      setSelectedSearchOption={setSelectedSearchOption}
      pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
      emptySearchContent={{
        title: intl.formatMessage({ id: 'common.no_results_found' }),
        hideAction: !searchValue
      }}
    />
  );
};

export default injectIntl(ReturnOrdersTable);
