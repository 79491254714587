import { injectIntl } from 'react-intl';
import { Modal } from 'antd';

import { getCurrency } from 'common/countries/countries-mapping';
import { MAX_ITEM_COD_VALUE, MAX_ITEM_VALUE } from 'constants/shipments';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';

import './ItemValue.less';

const GoodsTermsModal = ({ intl, close, ...props }) => {
  const currency = getCurrency().type;
  return (
    <Modal
      open
      className="br-item-value__modal"
      title={
        <div className="br-item-value__modal__header">
          <p>
            {intl.formatMessage({
              id: 'shipments.new_order.terms_and_conditions'
            })}
          </p>
          <CloseIcon onClick={close} />
        </div>
      }
      footer={null}
      onCancel={close}
      {...props}
    >
      <div className="br-item-value__modal-content">
        <div>
          <p className="heading">
            {intl.formatMessage({
              id: 'shipments.new_order.item_value_terms_modal.title_1'
            })}
          </p>
          <p>
            {intl.formatMessage(
              {
                id: 'shipments.new_order.item_value_terms_modal.subtitle_1'
              },
              { max: MAX_ITEM_VALUE, currency }
            )}
          </p>
        </div>

        <div>
          <p className="heading">
            {intl.formatMessage({
              id: 'shipments.new_order.item_value_terms_modal.title_2'
            })}
          </p>
          <p>
            {intl.formatMessage(
              {
                id: 'shipments.new_order.item_value_terms_modal.subtitle_2'
              },
              {
                maxCod: MAX_ITEM_COD_VALUE,
                currency
              }
            )}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(GoodsTermsModal);
