import dayjs from 'dayjs';

export const sumOfTotalReceived = (sortingFacilityData) => {
  const {
    fresh_deliveries_received_count,
    transferred_deliveries_received_count,
    rto_deliveries_received_count
  } = sortingFacilityData || {};
  return (
    fresh_deliveries_received_count +
      transferred_deliveries_received_count +
      rto_deliveries_received_count || 0
  );
};

export const allPendingSum = (sortingFacilityData) => {
  const {
    pending_transfer_deliveries_count,
    ready_to_dispatch_deliveries_count
  } = sortingFacilityData || {};
  return (
    pending_transfer_deliveries_count + ready_to_dispatch_deliveries_count || 0
  );
};

export const getInitialDate = () => {
  // TEMPORARY FIX, will get this value from backend later
  const SF_CUTOFF_TIME = 5;

  return dayjs().tz().hour() < SF_CUTOFF_TIME
    ? dayjs().tz().subtract(1, 'day')
    : dayjs().tz();
};
