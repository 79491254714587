import { formatDateTime } from 'utils/fulfillment';

export const renderDate = (date) => {
  if (!date) return <span className="text-gray">-</span>;
  const { formattedDate, formattedTime } = formatDateTime(date);

  return (
    <>
      <div>{formattedDate}</div>
      <div className="text-gray">{formattedTime}</div>
    </>
  );
};
