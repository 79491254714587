import { useEffect, useRef, useState } from 'react';
import { Form, Collapse, Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import Icon from '@ant-design/icons';

import { formatOptions } from 'utils';
import {
  cleanEmptyString,
  isUserAuthorized,
  trimObjectStrings
} from 'utils/helpers';
import { fetchCities, fetchZones, getFmCodes } from 'services/cities';
import { fetchStars } from 'services/live-ops-priorities';
import { DATE_FORMAT } from 'constants/date-picker';
import {
  PICKUPS_DATE_RANGE_FILTERS_KEYS,
  PICKUPS_MULTIPLE_VALUES_FILTERS,
  PICKUP_EXCEPTION_VALIDATION,
  PICKUP_TYPES_OPTIONS,
  RepetitionTypes,
  TypeAssignHubs,
  stateTypes
} from 'constants/pickups';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import BRButton from 'components/BRButton/BRButton';
import SearchDateRangePicker from 'containers/Deliveries/DeliveriesSearch/components/SearchDateRangePicker/SearchDateRangePicker';
import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';
import SearchCheckbox from 'containers/Deliveries/DeliveriesSearch/components/SearchCheckbox/SearchCheckbox';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as DropDownIcon } from 'assets/bosta-icons/Dropdown.svg';

import './PickupsSearch.less';

const PickupsSearch = ({ handleOnSubmit, hubs }) => {
  const [cities, setCities] = useState([]);
  const [stars, setStars] = useState([]);
  const [areas, setAreas] = useState([]);
  const [fmCodes, setFmCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef();

  const intl = useIntl();

  const [form] = Form.useForm();
  const cityId = Form.useWatch('cityId', form);

  useEffect(async () => {
    setIsLoading(true);
    await Promise.all([getCities(), fetchFmCodes(), getStars()]);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (cityId) {
      getAreas();
    } else {
      setAreas([]);
    }
  }, [cityId]);

  const getCities = async () => {
    try {
      const data = await fetchCities();
      setCities(data);
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchFmCodes = async () => {
    try {
      const data = (await getFmCodes()) || [];
      const formattedFMCodes = data.map((fmCode) => ({
        label: fmCode,
        value: fmCode
      }));
      setFmCodes(formattedFMCodes);
    } catch (error) {
      notify(error.message);
    }
  };

  const getStars = async () => {
    try {
      const { message } = await fetchStars();

      setStars(
        formatOptions(
          '_id',
          'profile.firstName,profile.lastName',
          message?.stars || []
        )
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const getAreas = async () => {
    setIsLoading(true);
    try {
      const data = await fetchZones(cityId);
      if (data?.length) {
        setAreas(data);
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const onFinish = (values) => {
    PICKUPS_MULTIPLE_VALUES_FILTERS.forEach((key) => {
      if (values[key]) {
        values[key] = values[key].join(',');
      }
    });

    const cleanedValues = cleanEmptyString(values);

    Object.keys(cleanedValues).forEach((key) => {
      if (PICKUPS_DATE_RANGE_FILTERS_KEYS.includes(key)) {
        const dateStartKey = `${key}Start`;
        const dateEndKey = `${key}End`;

        cleanedValues[dateStartKey] = dayjs(values[key][0]).format(DATE_FORMAT);

        cleanedValues[dateEndKey] = dayjs(values[key][1]).format(DATE_FORMAT);

        delete cleanedValues[key];
      }
    });

    handleOnSubmit(trimObjectStrings(cleanedValues));
  };

  const handleResetFilters = () => {
    formRef.current.resetFields();
    handleOnSubmit({});
  };

  return (
    <Collapse
      className="br-filters-search__container"
      ghost
      defaultActiveKey={['0']}
      expandIcon={({ isActive }) => (
        <Icon
          component={DropDownIcon}
          rotate={isActive ? 0 : -90}
          className="ant-icon-xl"
        />
      )}
    >
      <Collapse.Panel
        header={intl.formatMessage({
          id: 'common.search'
        })}
      >
        <Form onFinish={onFinish} ref={formRef} form={form}>
          <div className="br-filters-search__row">
            <Form.Item
              name="pickupRequestId"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.pickup_request_id'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'pickups.pickup_request.search.pickup_request_id'
                })}
              />
            </Form.Item>
            {isUserAuthorized(aclMatrix.BUSINESSES, [
              ACL_MATRIX.BUSINESSES_LIST
            ]) && (
              <Form.Item
                name="businessId"
                label={intl.formatMessage({
                  id: 'pickups.pickup_request.search.business'
                })}
              >
                <BusinessSelector
                  allowClear
                  name="businessId"
                  placeholder={intl.formatMessage({
                    id: 'common.select'
                  })}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            )}

            <Form.Item
              name="state"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.state'
              })}
            >
              <Select
                allowClear
                mode="multiple"
                showArrow
                options={stateTypes}
                placeholder={intl.formatMessage({ id: 'common.select' })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
          </div>
          <div className="br-filters-search__row">
            <Form.Item
              name="starId"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.star'
              })}
            >
              <Select
                allowClear
                showSearch
                options={stars}
                optionFilterProp="label"
                placeholder={intl.formatMessage({
                  id: 'common.select'
                })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <Form.Item
              name="cityId"
              label={intl.formatMessage({
                id: 'businesses.search_filters.city'
              })}
            >
              <Select
                allowClear
                showSearch
                options={cities}
                optionFilterProp="name"
                fieldNames={{ label: 'name', value: '_id' }}
                placeholder={intl.formatMessage({
                  id: 'common.select'
                })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <Form.Item
              name="areaId"
              label={intl.formatMessage({
                id: 'location_form_labels.area'
              })}
            >
              <Select
                allowClear
                showSearch
                options={areas}
                optionFilterProp="name"
                fieldNames={{ label: 'name', value: '_id' }}
                placeholder={intl.formatMessage({
                  id: 'common.select'
                })}
                disabled={!cityId || isLoading}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
          </div>
          <div className="br-filters-search__row">
            <Form.Item
              name="hubId"
              label={intl.formatMessage({
                id: 'stars.create_edit_star.form_labels.hub'
              })}
            >
              <Select
                allowClear
                showSearch
                showArrow
                mode="multiple"
                options={hubs}
                optionFilterProp="name"
                fieldNames={{ label: 'name', value: '_id' }}
                placeholder={intl.formatMessage({
                  id: 'common.select'
                })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <SearchDateRangePicker
              name="scheduledAt"
              label={intl.formatMessage({
                id: 'deliveries.search_filters.scheduledAt'
              })}
            />
            <SearchDateRangePicker
              name="createdAt"
              label={intl.formatMessage({
                id: 'deliveries.search_filters.createdAt'
              })}
            />
          </div>
          <div className="br-filters-search__row">
            <SearchDateRangePicker
              name="pickedUpAt"
              label={intl.formatMessage({
                id: 'deliveries.search_filters.pickedUpAt'
              })}
            />
            <Form.Item
              name="type"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.pickup_request_type'
              })}
            >
              <Select
                allowClear
                options={PICKUP_TYPES_OPTIONS}
                placeholder={intl.formatMessage({ id: 'common.select' })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <Form.Item
              name="repeatedType"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.repetition_type'
              })}
            >
              <Select
                allowClear
                options={RepetitionTypes}
                placeholder={intl.formatMessage({ id: 'common.select' })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
          </div>
          <div className="br-filters-search__row">
            <Form.Item
              name="isHubAutoAssigned"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.assign_type'
              })}
            >
              <Select
                allowClear
                options={TypeAssignHubs}
                placeholder={intl.formatMessage({ id: 'common.select' })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <SearchCheckbox
              name="starInitiatedAction"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.created_by_star'
              })}
            />
            <SearchCheckbox
              name="isBusinessFirstPickup"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.first_business_pickup'
              })}
            />
          </div>
          <div className="br-filters-search__row">
            <SearchDateRangePicker
              name="attemptDate"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.table_columns.attemptDate'
              })}
            />
            <SearchDateRangePicker
              name="assignedAt"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.table_columns.assignDate'
              })}
            />
            <Form.Item
              name="fmCodes"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.fm_code'
              })}
            >
              <Select
                allowClear
                showSearch
                showArrow
                mode="multiple"
                options={fmCodes}
                placeholder={intl.formatMessage({ id: 'common.select' })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
          </div>
          <div className="br-filters-search__row">
            <Form.Item
              name="whatsAppFakeException"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.exception_validation'
              })}
            >
              <Select
                options={PICKUP_EXCEPTION_VALIDATION}
                allowClear
                placeholder={intl.formatMessage({ id: 'common.select' })}
              />
            </Form.Item>
          </div>
          <div className="br-businesses-search__actions">
            <div>
              <BRButton
                type="primary"
                htmlType="submit"
                label={intl.formatMessage({ id: 'common.search' })}
              />
              <BRButton
                label={intl.formatMessage({ id: 'common.clear' })}
                onClick={handleResetFilters}
              />
            </div>
          </div>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default PickupsSearch;
