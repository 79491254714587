import { notify } from 'components/Notify/Notify';
import { LOG_ACTIONS } from 'constants/packaging';
import { useEffect, useState } from 'react';
import { getMaterialLogs } from 'services/packaging';
import dayjs from 'dayjs';
import { getCurrency } from 'common/countries/countries-mapping';
import { fmt } from 'IntlWrapper/IntlWrapper';
import { isSaudi } from 'constants/helper';

export const formatKeyName = (title) => {
  return title
    ?.toLowerCase()
    ?.replace(' - ', '_')
    ?.replace(/ /g, '_')
    ?.replace(/-/g, '_')
    ?.replace(/\./g, '_');
};

export const formatMaterialItems = (materialsArray = []) => {
  const formattedMaterials = [];
  materialsArray.forEach((item) => {
    formattedMaterials.push({
      material: {
        name: item.title,
        size: item.size ? `Size ${item.size}` : item.pack ? item.pack : '',
        description: item.description ? item.description : ''
      },
      quantity: item.total_balance,
      price: item.price,
      status: !!item.avalible,
      id: item.id,
      free: !!item.free
    });
  });
  return formattedMaterials;
};

const formatTakenBy = (log) => {
  let userRole = log.user_role?.toLowerCase()?.replaceAll('_', ' ');
  userRole = userRole?.split(' ');
  if (userRole?.length > 1) {
    userRole = userRole.map(
      (name) => name.charAt(0).toUpperCase() + name.slice(1)
    );
    userRole = userRole.join(' ');
  } else {
    userRole = userRole
      ? userRole[0].charAt(0).toUpperCase() + userRole[0].slice(1)
      : '';
  }
  return `${userRole} ${log.user_name}`;
};

const formatLogs = (logsArray) => {
  let formattedLogs = [];
  logsArray.forEach((log) => {
    let logObject = {
      takenBy: formatTakenBy(log),
      logDate: dayjs(log.createdAt).format('ddd, D MMM, h:mm a'),
      description: log.description,
      tierName: log?.tier_name
    };
    switch (log.action) {
      case LOG_ACTIONS.availability:
        formattedLogs.push({
          ...logObject,
          logTitle: fmt({
            id: 'packaging.manage_materials.logs.status_action'
          }),
          logInfo: fmt(
            {
              id: 'packaging.manage_materials.logs.status_log_text'
            },
            {
              status: fmt({
                id: `business_details.api_integration.states.${
                  log.new_value === '1' ? 'enabled' : 'disabled'
                }`
              }),
              itemName: log.item_name
            }
          )
        });
        return;
      case LOG_ACTIONS.balance:
        formattedLogs.push({
          ...logObject,
          logTitle: fmt({
            id: 'packaging.manage_materials.logs.quantity_action'
          }),
          logInfo: fmt(
            {
              id: 'packaging.manage_materials.logs.quantity_log_text'
            },
            {
              oldValue: log.old_value,
              itemName: log.item_name,
              newValue: log.new_value
            }
          )
        });
        return;
      case LOG_ACTIONS.price:
        formattedLogs.push({
          ...logObject,
          logTitle: fmt({
            id: 'packaging.manage_materials.logs.price_action'
          }),
          logInfo: fmt(
            {
              id: 'packaging.manage_materials.logs.price_log_text'
            },
            {
              oldValue: `${log.old_value} ${getCurrency().type}`,
              itemName: log.item_name,
              newValue: `${log.new_value} ${getCurrency().type}`
            }
          )
        });
        return;
    }
  });
  return formattedLogs;
};
let PAGE_SIZE = 5;
export const useMaterialLogs = (isPackagingPage = false) => {
  const [logs, setLogs] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    getLogs();
  }, []);

  const getLogs = async (customPageNumber = 0, isRefresh = false) => {
    setIsLoading(true);
    try {
      const { logsList, totalLogs } = await getMaterialLogs({
        pageNumber: customPageNumber,
        pageSize: PAGE_SIZE,
        payload: !isSaudi() && {
          actionTypes: isPackagingPage
            ? ['Update Availability', 'Update Balance']
            : ['Update Price']
        }
      });
      setLogs((prev) => {
        const formattedLogs = formatLogs(logsList);
        if (isRefresh) {
          return [...formattedLogs];
        }
        return [...prev, ...formattedLogs];
      });
      setShowLoadMore(totalLogs > (customPageNumber + 1) * PAGE_SIZE);
      customPageNumber !== pageNumber && setPageNumber(customPageNumber);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };
  const loadMoreLogs = () => {
    getLogs(pageNumber + 1);
  };

  const refreshLogs = async () => {
    await getLogs(0, true);
  };

  return {
    logs,
    showLoadMore,
    isLoading,
    loadMoreLogs,
    haveManyLogs: logs.length > 1,
    refreshLogs
  };
};

export const formatAreaZone = (address) => {
  const { zone, district } = address;
  let formatedArea = '';

  if (!zone?.name && !district) {
    return fmt({
      id: `common.empty_field`
    });
  } else if (typeof district === 'string') {
    if (zone && (zone?.name === district || !district)) {
      formatedArea = `${zone?.name}`;
    } else {
      formatedArea = `${zone?.name} - ${district}`;
    }
  } else {
    formatedArea =
      zone && zone?.name !== district?.name
        ? district
          ? `${zone?.name} -${district?.name || ''}`
          : `${zone?.name}`
        : `${district?.name}`;
  }
  return formatedArea;
};
