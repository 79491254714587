import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Radio, Form, Input, Tooltip, Table } from 'antd';
import { injectIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router';
import dayjs from 'dayjs';

import {
  createSeal,
  deleteTrackingNumberFromSeal,
  getSeal,
  scanDeliveries,
  scanShelves,
  updateSeal
} from 'services/hubs';
import { printAirwaybill, updateOrder } from 'services/shipments';
import { receivePackagesAtHub } from 'actions/DeliveriesActions';

import {
  DELIVERIES_SCAN_TYPES,
  FIRST_MILE_HUB,
  HUB_TRANSFER_SCANNING_TYPE,
  HUB_TRANSFER_SEAL_LIMIT,
  INTERNATIONAL_HUB,
  LAST_MILE_HUB,
  RECEIVED_AT_WAREHOUSE_CODE,
  REVERSE_HUB,
  SEAL_SCAN_TYPE,
  SORTING_HUB,
  SORTING_STATION_HUB
} from 'constants/hubs';
import { UPDATED_DELIVERY_DETAILS } from 'constants/action-center';
import {
  ASSIGN_TO_HUB_UPDATE_TYPE,
  DELIVERY_STATES_CODES,
  PUDO_PROVIDER,
  UNCOVERED_STATE_CODE
} from 'constants/shipments';
import {
  DEFAULT_SCAN_TYPE,
  DEFAULT_WEIGHT,
  NORMAL_PACKAGE_TYPE
} from 'constants/pickups';

import Sounds from 'common/Sounds';
import { openModal } from 'utils/modal';
import { downloadAsPdf } from 'utils/download';
import { HubsContext } from 'contexts/hub.context';
import {
  searchDeliveries,
  setDeliveriesOnShelf,
  updateManyDeliveries
} from 'services/deliveries';
import {
  ASSIGN_MANY_TO_HUB_TYPE,
  BOSTA_BUSINESS_ID,
  BOSTA_MATERIAL
} from 'constants/Deliveries';
import { isForwardOrder, isReturnOrder } from 'utils/deliveries';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import HubSelectors from '../HubSelectors/HubSelectors';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import DeliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import BRButton from 'components/BRButton/BRButton';
import TransferToHubErrorModal from 'containers/Hub/TransferToHub/components/TransferToHubErrorModal/TransferToHubErrorModal';
import BRSwappingTables from 'components/BRSwappingTables/BRSwappingTables';
import BRDropdownModal from 'components/BRDropdownModal/BRDropdownModal';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';

import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Tooltip.svg';
import { ReactComponent as Delete } from 'assets/bosta-icons/Delete.svg';
import { ReactComponent as AlertIcon } from 'assets/bosta-icons/alert-triangle.svg';
import { ReactComponent as CircleCheck } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as Printer } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as CloseIcon } from 'assets/imgRevamp/closeIconThin.svg';

import './ScanSealsAndBins.less';
import { getBostaMaterialBusinessCountryCode } from 'common/countries/countries-mapping';

const ScanSealsAndBins = ({ intl, location, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scannedDeliveries, setScannedDeliveries] = useState([]);
  const [editModeScannedDeliveries, setEditModeScannedDeliveries] = useState(
    []
  );
  const [sealNumber, setSealNumber] = useState(null);
  const [scanningEnabled, setScanningEnabled] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [sourceHubId, setSourceHubId] = useState(null);
  const [sourceHubType, setSourceHubType] = useState(null);
  const [destinationHubType, setDestinationHubType] = useState(null);
  const [destinationHubId, setDestinationHubId] = useState(null);
  const [destinationHubsOptions, setDestinationHubsOptions] = useState([]);
  const [oldSealNumber, setOldSealNumber] = useState(null);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerProps, setErrorBannerProps] = useState({});
  const [editingChange, setEditingChange] = useState(false);
  const [scanningType, setScanningType] = useState(SEAL_SCAN_TYPE.NORMAL);
  const [bin, setBin] = useState(null);
  const [wrongScannedDeliveries, setWrongScannedDeliveries] = useState([]);
  const [sourceHubData, setSourceHubData] = useState({});
  const [destinationHubData, setDestinationHubData] = useState({});
  const [
    sortingStationAllowedTransferHubs,
    setSortingStationAllowedTransferHubs
  ] = useState([]);

  const formRef = useRef('');
  const trackingNumberInputRef = useRef('');
  const sealNumberInputRef = useRef('');
  const tableRef = useRef('');

  const { allHubs, scopedHubs } = useContext(HubsContext);

  const { beep, warning } = Sounds;

  const queryParams = new URLSearchParams(location.search);

  const editSeal = queryParams.get('editSeal');
  const sourceHub = queryParams.get('sourceHubId');
  const sealType = queryParams.get('sealType');
  const editDestinationHubId = queryParams.get('destinationHub');

  const acceptMethods = (refreshMethod) => {
    tableRef.current = refreshMethod;
  };

  const refreshTable = () => {
    tableRef.current();
  };

  const clearTrackingNumberField = () => {
    formRef.current.setFieldsValue({ trackingNumber: '' });
  };

  const clearSealNumberField = () => {
    formRef.current.setFieldsValue({ seal: '' });
  };

  useEffect(() => {
    if (editSeal) {
      setScanningEnabled(true);
      setSourceHubId(sourceHub);
      setScanningType(sealType);
      editDestinationHubId && setDestinationHubId(editDestinationHubId);

      if (sealType === SEAL_SCAN_TYPE.BINS) {
        scanShelf(editSeal, sourceHub);
        formRef.current.setFieldsValue({ bin: editSeal });
      } else {
        setIsEditing(true);
        scanSeal(editSeal, sourceHub);
        formRef.current.setFieldsValue({ seal: editSeal });
      }
      history.replace({ search: '' });
    }
  }, [location]);

  useEffect(() => {
    if (scanningEnabled) {
      sealNumberInputRef.current.focus();
    }
  }, [scanningEnabled]);

  useEffect(() => {
    refreshTable();
  }, [scannedDeliveries]);

  useEffect(() => {
    if (destinationHubId) {
      const destinationHub = allHubs.find(
        (hub) => hub._id === destinationHubId
      );

      if (destinationHub?.vendor) {
        setScanningType(SEAL_SCAN_TYPE.BINS);
      }
    }
  }, [destinationHubId]);

  useEffect(() => {
    if (editingChange) {
      formRef.current.validateFields();
    }
  }, [editingChange]);

  const hasPendingOrderUpdateActions = (delivery) => {
    return !!delivery?.actionItems.filter(
      (item) =>
        item.action === UPDATED_DELIVERY_DETAILS &&
        !item.isApplied &&
        (item?.actionDetails?.dropOffAddress || item?.actionDetails?.codAmount)
    ).length;
  };

  const orderCodUpdated = (delivery) => {
    return delivery?.actionItems.find(
      (item) =>
        item.action === UPDATED_DELIVERY_DETAILS &&
        !item.isApplied &&
        item?.actionDetails?.codAmount
    );
  };

  const changeAssignedHub = async ({
    deliveryId,
    warehouseId,
    oldHubName,
    trackingNumber
  }) => {
    const newHubName = allHubs.find((hub) => hub._id === warehouseId).name;
    setIsLoading(true);
    try {
      const payload = {
        warehouseId,
        updateType: ASSIGN_TO_HUB_UPDATE_TYPE
      };

      await updateOrder({
        id: deliveryId,
        payload
      });

      setErrorBannerProps({
        type: 'success',
        icon: <CircleCheck />,
        subText: intl.formatMessage(
          {
            id: 'hubs.hub_transfer.transfer_seals.assign_hub_change_success'
          },
          {
            oldHub: oldHubName,
            newHub: newHubName
          }
        ),
        buttonText: intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.print_awb'
        }),
        buttonProps: {
          type: 'basic',
          prefixIcon: <Printer />,
          onClick: () => handlePrintAwb({ deliveryId, trackingNumber })
        }
      });
      trackingNumberInputRef.current.focus();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handlePrintAwb = async ({ deliveryId, trackingNumber }) => {
    setIsLoading(true);
    try {
      const { data } = await printAirwaybill(deliveryId);
      downloadAsPdf(data, trackingNumber);
      setShowErrorBanner(false);
      trackingNumberInputRef.current.focus();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleChangeAssignedHub = ({
    deliveryId,
    trackingNumber,
    oldHubName
  }) => {
    openModal(BRDropdownModal, {
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.change_assigned_hub'
      }),
      dropdownData: allHubs.reduce((acc, hub) => {
        hub.type !== SORTING_HUB &&
          acc.push({ value: hub._id, label: hub.name });
        return acc;
      }, []),
      confirmBtnText: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.confirm_changes'
      }),
      onConfirm: (warehouseId) =>
        changeAssignedHub({
          deliveryId,
          warehouseId,
          oldHubName,
          trackingNumber
        })
    });
  };

  const removeTrackingNumberFromSeal = async (delivery) => {
    setIsLoading(true);

    const { sealNumber, ...restDelivery } = delivery;
    try {
      const { message } = await deleteTrackingNumberFromSeal(
        sealNumber,
        restDelivery.trackingNumber
      );
      notify(message, 'success');
      startDeliveryValidation(restDelivery);
    } catch (e) {
      notify(e.message);
    }

    setIsLoading(false);
  };

  const validateDelivery = (delivery) => {
    if (delivery?.externalScanned) {
      return true;
    }

    if (!destinationHubId) {
      notify(
        intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.scanning_errors.select_destination_hub'
        })
      );
      return false;
    }

    if (!RECEIVED_AT_WAREHOUSE_CODE.includes(delivery.state.code)) {
      const deliveryState = DeliveryStateFormatter.getStateName(delivery);

      notify(
        intl.formatMessage(
          {
            id: 'hubs.hub_transfer.transfer_seals.scanning_errors.final_state'
          },
          {
            deliveryState
          }
        ),
        'error'
      );
      if (
        !wrongScannedDeliveries.find(
          ({ trackingNumber }) => trackingNumber === delivery.trackingNumber
        )
      ) {
        setWrongScannedDeliveries((prev) => [
          ...prev,
          {
            trackingNumber: delivery.trackingNumber,
            deliveryState
          }
        ]);
      }
      return false;
    }

    if (
      destinationHubType === INTERNATIONAL_HUB &&
      !delivery.internationalTrackingNumber
    ) {
      notify(
        intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.scanning_errors.wrong_international_scan'
        })
      );
      return false;
    }

    if (delivery.sealNumber) {
      openModal(BRConfirmationModal, {
        title: intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.tracking_number_on_seal_modal.title'
        }),
        message: intl.formatMessage(
          {
            id: 'hubs.hub_transfer.transfer_seals.tracking_number_on_seal_modal.message'
          },
          {
            trackingNumber: delivery.trackingNumber,
            sealNumber: delivery.sealNumber
          }
        ),
        onConfirm: () => {
          removeTrackingNumberFromSeal(delivery);
        },
        onCancel: () => {
          clearTrackingNumberField();
        }
      });
      return false;
    }

    if (delivery.warehouseTransit) {
      notify(
        intl.formatMessage(
          {
            id: 'hubs.hub_transfer.transfer_seals.scanning_errors.already_intransit'
          },
          { trackingNumber: delivery.trackingNumber }
        ),
        'error'
      );
      return false;
    }

    if (hasPendingOrderUpdateActions(delivery)) {
      openModal(TransferToHubErrorModal, {
        delivery,
        title: intl.formatMessage({
          id: `action_center.order_details.${
            orderCodUpdated(delivery)
              ? 'order_cod_updated_transfer'
              : 'address_updated'
          }`
        }),
        onSuccess: () => scanDelivery(delivery.trackingNumber)
      });
      return false;
    }

    if (
      destinationHubId !== delivery?.assignedHub?._id &&
      delivery?.assignedHub?.forcedAssignment
    ) {
      notify(
        intl.formatMessage(
          {
            id: 'hubs.hub_transfer.wrong_destnation_hub_error'
          },
          { hubname: delivery?.assignedHub?.name }
        )
      );
      return false;
    }

    if (delivery?.state?.receivedAtWarehouse?.warehouse?._id !== sourceHubId) {
      openModal(BRConfirmationModal, {
        title: intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.not_at_warehouse_validation.title'
        }),
        message: intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.not_at_warehouse_validation.message'
        }),
        confirmButtonText: intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.not_at_warehouse_validation.confirm'
        }),
        cancelButtonText: intl.formatMessage({
          id: 'common.cancel'
        }),
        onConfirm: () => {
          receiveDelivery(delivery);
        }
      });
      return false;
    }

    const isDifferentLastMileHub =
      delivery?.assignedHub?._id !== destinationHubId &&
      destinationHubType === LAST_MILE_HUB;

    const isInvalidTransferToSortingStation =
      !sortingStationAllowedTransferHubs.includes(delivery?.assignedHub?._id) &&
      destinationHubType === SORTING_STATION_HUB;

    const isTransferringToWrongLastMileHub =
      sourceHubType === SORTING_HUB &&
      delivery?.assignedHub &&
      isForwardOrder(delivery) &&
      (isDifferentLastMileHub || isInvalidTransferToSortingStation);

    if (isTransferringToWrongLastMileHub) {
      notify(
        intl.formatMessage(
          {
            id: 'hubs.hub_transfer.transfer_seals.scanning_errors.wrong_assigned_hub'
          },
          {
            trackingNumber: delivery.trackingNumber,
            hubName: delivery?.assignedHub?.name || 'N/A'
          }
        )
      );
      return false;
    }

    if (
      isForwardOrder(delivery) &&
      destinationHubType === REVERSE_HUB &&
      ![BOSTA_BUSINESS_ID, getBostaMaterialBusinessCountryCode()].includes(
        delivery.sender._id
      ) &&
      sourceHubData?.isCentralHub
    ) {
      notify(
        intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.scanning_errors.forward_order_error'
        })
      );
      return false;
    }

    if (
      isReturnOrder(delivery) &&
      destinationHubType === SORTING_HUB &&
      sourceHubData?.isCentralHub
    ) {
      notify(
        intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.scanning_errors.return_order_error'
        })
      );
      return false;
    }

    if (
      isReturnOrder(delivery) &&
      sourceHubType === SORTING_HUB &&
      destinationHubData?.isCentralHub
    ) {
      notify(
        intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.scanning_errors.return_order_error'
        })
      );
      return false;
    }

    if (sourceHubType !== LAST_MILE_HUB) {
      if (delivery.state.code === UNCOVERED_STATE_CODE) {
        notify(
          intl.formatMessage({
            id: 'hubs.hub_transfer.transfer_seals.scanning_errors.uncovered'
          }),
          'error'
        );
        return false;
      }
    }
    return true;
  };

  const startDeliveryValidation = (delivery) => {
    if (delivery && validateDelivery(delivery)) {
      delivery.scanTime = dayjs.utc().format();
      if (isEditing) {
        setEditingChange(true);
      }

      isEditing && scanningType !== SEAL_SCAN_TYPE.BULKY
        ? setEditModeScannedDeliveries((prevState) => [...prevState, delivery])
        : setScannedDeliveries((prevState) => [...prevState, delivery]);

      clearTrackingNumberField();
      beep();
    } else {
      clearTrackingNumberField();
      warning();
    }
  };

  const receiveDelivery = async (delivery) => {
    const _id = delivery._id;
    const pricingPackageSize =
      delivery.pricingPackageSize?.name || NORMAL_PACKAGE_TYPE;
    const scanType = delivery.firstHub?.scanType || DEFAULT_SCAN_TYPE;
    const packageWeight = delivery.specs?.weight || DEFAULT_WEIGHT;

    try {
      await receivePackagesAtHub({
        deliveries: [
          {
            _id,
            pricingPackageSize,
            scanType,
            packageWeight
          }
        ]
      });
      notify(
        intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.not_at_warehouse_validation.success'
        }),
        'success'
      );
      scanDelivery(delivery.trackingNumber);
    } catch (error) {
      notify(error.message);
    }
  };

  const scanDelivery = debounce(async (trackingNumber) => {
    if (showErrorBanner) {
      setShowErrorBanner(false);
    }

    if (trackingNumber.length >= 6) {
      const alreadyExist = scannedDeliveries.findIndex(
        (delivery) => delivery.trackingNumber === trackingNumber
      );

      const alreadyExistInEdit = editModeScannedDeliveries.findIndex(
        (delivery) => delivery.trackingNumber === trackingNumber
      );

      if (alreadyExist > -1 || alreadyExistInEdit > -1) {
        notify(
          intl.formatMessage(
            {
              id: 'hubs.hub_transfer.transfer_seals.scanning_errors.already_scanned'
            },
            { trackingNumber }
          ),
          'error'
        );
        warning();
        clearTrackingNumberField();
        return;
      }

      if (
        scannedDeliveries?.length + editModeScannedDeliveries?.length ===
          HUB_TRANSFER_SEAL_LIMIT &&
        scanningType === SEAL_SCAN_TYPE.NORMAL
      ) {
        notify(
          intl.formatMessage(
            {
              id: 'hubs.hub_transfer.transfer_seals.scanning_errors.seal_limit'
            },
            {
              limit: HUB_TRANSFER_SEAL_LIMIT
            }
          ),
          'error'
        );
        warning();
        clearTrackingNumberField();
        return;
      }

      setIsLoading(true);
      try {
        const payload = {
          searchType: DELIVERIES_SCAN_TYPES.TRANSFER_TO_HUB_SCAN,
          trackingNumbers: [trackingNumber]
        };
        const { data } = await scanDeliveries(payload);

        startDeliveryValidation(data?.[0]);
      } catch (error) {
        notify(error.message);
        warning();
      }

      setIsLoading(false);
    }
  }, 500);

  const scanSeal = debounce(async (sealNumber, editSourceHubId) => {
    const value = sealNumber?.trim();

    if (showErrorBanner) {
      setShowErrorBanner(false);
    }

    if (value) {
      setIsLoading(true);
      try {
        const payload = {
          sourceHubId: sourceHubId || editSourceHubId,
          destinationHubId
        };
        const { data } = await getSeal({
          sealNumber: value.trim(),
          scanType: scanningType,
          payload
        });

        if (data?.trackingNumbers && !isEditing) {
          const {
            destinationHubId,
            deliveries = [],
            type,
            trackingNumbers = []
          } = data;
          const startSealEdit = () => {
            setIsEditing(true);
            setOldSealNumber(value);
            setDestinationHubId(destinationHubId);
            setScannedDeliveries(
              deliveries.map((delivery) => ({
                ...delivery,
                scanTime: trackingNumbers.find(
                  (del) => del?.trackingNumber === delivery?.trackingNumber
                )?.scanTime
              }))
            );
            setScanningType(type);
            type === SEAL_SCAN_TYPE.BULKY && setSealNumber(value);
            trackingNumberInputRef.current.focus();
          };
          if (!editSeal && type !== SEAL_SCAN_TYPE.BULKY) {
            openModal(BRConfirmationModal, {
              title: intl.formatMessage({
                id: 'hubs.hub_transfer.transfer_seals.edit_seal_modal.title'
              }),
              message: intl.formatMessage(
                {
                  id: 'hubs.hub_transfer.transfer_seals.edit_seal_modal.message'
                },
                {
                  sealNumber: data?.sealNumber
                }
              ),
              onConfirm: () => {
                startSealEdit();
              },
              onCancel: () => {
                clearSealNumberField();
              }
            });
          } else {
            startSealEdit();
          }
        } else if (data?.trackingNumbers && isEditing) {
          notify(
            intl.formatMessage({
              id: 'hubs.hub_transfer.transfer_seals.scanning_errors.seal_already_used'
            })
          );
          warning();
          clearSealNumberField();
        } else if (!data && !destinationHubId) {
          notify(
            intl.formatMessage({
              id: 'hubs.hub_transfer.transfer_seals.scanning_errors.select_destination_hub'
            })
          );
          warning();
          clearSealNumberField();
        } else {
          setSealNumber(value);
          trackingNumberInputRef.current.focus();
        }
      } catch (error) {
        notify(error.message);
        clearSealNumberField();
      }
      setIsLoading(false);
    }
  }, 500);

  const scanShelf = debounce(async (binName, editSourceHubId) => {
    const value = binName?.trim();

    if (value) {
      try {
        const payload = {
          shelfName: value
        };
        await scanShelves({ hubId: sourceHubId || editSourceHubId, payload });
        setBin(value);
        beep();
        trackingNumberInputRef.current.focus();
        getBinDeliveries({ shelfNames: value, editSourceHubId });
      } catch (error) {
        notify(error.message);
      }
    }
  }, 500);

  const getBinDeliveries = async ({ shelfNames, editSourceHubId }) => {
    setIsLoading(true);
    try {
      const payload = {
        hubs: [sourceHubId || editSourceHubId],
        shelfNames,
        stateCodes: [DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE.toString()]
      };
      const { deliveries } = await searchDeliveries(payload);
      setScannedDeliveries(deliveries);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleStartScanning = useCallback(() => {
    setScanningEnabled(true);
  }, []);

  const handleCloseSealClick = async () => {
    setIsLoading(true);
    try {
      const payload = {
        sealNumber,
        sourceHubId,
        destinationHubId,
        type: scanningType,
        trackingNumbers: scannedDeliveries.map(
          ({ trackingNumber, scanTime }) => ({
            trackingNumber,
            scanTime
          })
        )
      };

      if (scannedDeliveries.length && !sealNumber) {
        notify(
          intl.formatMessage({
            id: 'hubs.hub_transfer.transfer_seals.scanning_errors.scan_seal_first'
          })
        );
        warning();
        setIsLoading(false);
        return;
      }

      if (payload.trackingNumbers?.length && sourceHubId && destinationHubId) {
        await createSeal(payload);
      }

      handleReset({ disableScanning: true });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleBinsConfirmClick = async () => {
    if (!bin) {
      return handleReset({ disableScanning: true });
    }
    setIsLoading(true);
    try {
      const payload = {
        deliveryIds: scannedDeliveries
          .map((delivery) => delivery._id)
          .join(','),
        hubId: sourceHubId,
        shelfValue: bin,
        unSetShelf: true
      };
      await setDeliveriesOnShelf(payload);

      if (destinationHubType === INTERNATIONAL_HUB) {
        await updateManyDeliveries({
          updateType: ASSIGN_MANY_TO_HUB_TYPE,
          deliveries: scannedDeliveries.map(({ _id }) => ({ _id })),
          warehouseId: destinationHubId
        });
      }

      handleReset({ disableScanning: true });
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleReset = ({ disableScanning } = {}) => {
    formRef.current.resetFields();
    setScannedDeliveries([]);
    setWrongScannedDeliveries([]);
    setEditModeScannedDeliveries([]);
    setSealNumber(null);
    setBin(null);

    disableScanning && setScanningEnabled(false);
  };

  const handleSealUpdate = async () => {
    setIsLoading(true);

    const newScannedDeliveries = editModeScannedDeliveries.map(
      ({ trackingNumber, scanTime }) => ({
        trackingNumber,
        scanTime
      })
    );
    const editedDeliveries = scannedDeliveries.map(
      ({ trackingNumber, scanTime }) => ({
        trackingNumber,
        scanTime
      })
    );
    try {
      const payload = {
        type: scanningType,
        sealNumber,
        oldSealNumber,
        sourceHubId,
        destinationHubId,
        trackingNumbers: [...newScannedDeliveries, ...editedDeliveries]
      };

      if (
        !sealNumber ||
        (sealNumber === oldSealNumber && scanningType !== SEAL_SCAN_TYPE.BULKY)
      ) {
        setErrorBannerProps({
          type: 'danger',
          icon: <AlertIcon />,
          subText: intl.formatMessage({
            id: 'hubs.hub_transfer.transfer_seals.scanning_errors.scan_new_seal'
          })
        });
        setShowErrorBanner(true);
        clearSealNumberField();
        sealNumberInputRef.current.focus();
        setIsLoading(false);
        warning();
        return;
      }

      await updateSeal(payload);

      formRef.current.resetFields();
      setScannedDeliveries([]);
      setEditModeScannedDeliveries([]);
      setScanningEnabled(false);
      setEditingChange(false);
      setIsEditing(false);
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const handleDeleteScannedDelivery = (deliveryId) => {
    const filteredDeliveries = scannedDeliveries.filter(
      (delivery) => delivery._id !== deliveryId
    );

    if (isEditing) {
      setEditingChange(true);
    }

    setScannedDeliveries(filteredDeliveries);
  };

  const handleDeleteEditScannedDelivery = (deliveryId) => {
    const filteredDeliveries = editModeScannedDeliveries.filter(
      (delivery) => delivery._id !== deliveryId
    );

    setEditingChange(true);

    setEditModeScannedDeliveries(filteredDeliveries);
  };

  const handleCloseWrongScanTable = () => {
    setWrongScannedDeliveries([]);
  };

  const handleConfirmClick = () => {
    if (isEditing) {
      handleSealUpdate();
    } else if (scanningType === SEAL_SCAN_TYPE.BINS) {
      handleBinsConfirmClick();
    } else {
      handleCloseSealClick();
    }
    handleCloseWrongScanTable();
  };

  const handleScanningTypeChange = (value) => {
    handleReset();
    setScanningType(value);
  };

  const formatData = () =>
    scannedDeliveries.map((delivery) => {
      const {
        _id,
        trackingNumber,
        sender,
        type,
        pricingPackageSize,
        externalScanned
      } = delivery;

      if (externalScanned) {
        return {
          _id,
          key: _id,
          trackingNumber,
          actions: (
            <Delete
              className="br-hub-transfer-delete__action"
              onClick={() => handleDeleteScannedDelivery(_id)}
            />
          )
        };
      }

      return {
        _id,
        key: _id,
        trackingNumber,
        business: sender?.name,
        state: DeliveryStateFormatter.getStateName(delivery),
        type,
        size: pricingPackageSize?.name,
        actions: (
          <Delete
            className="br-hub-transfer-delete__action"
            onClick={() => handleDeleteScannedDelivery(_id)}
          />
        )
      };
    });

  const formatEditData = ({ data, isLeftTable }) =>
    data.map((delivery) => {
      const { _id, trackingNumber, sender, type, externalScanned } = delivery;

      if (externalScanned) {
        return {
          _id,
          key: _id,
          trackingNumber,
          actions: (
            <Delete
              className="br-hub-transfer-delete__action"
              onClick={() =>
                isLeftTable
                  ? handleDeleteEditScannedDelivery(_id)
                  : handleDeleteScannedDelivery(_id)
              }
            />
          )
        };
      }

      return {
        _id,
        key: _id,
        trackingNumber,
        business: sender.name,
        type,
        actions: (
          <Delete
            className="br-hub-transfer-delete__action"
            onClick={() =>
              isLeftTable
                ? handleDeleteEditScannedDelivery(_id)
                : handleDeleteScannedDelivery(_id)
            }
          />
        )
      };
    });

  const columns = [
    {
      dataIndex: 'trackingNumber',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.tracking_number'
      })
    },
    {
      dataIndex: 'size',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.size'
      })
    },
    {
      dataIndex: 'type',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.type'
      })
    },
    {
      dataIndex: 'state',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.state'
      })
    },
    {
      dataIndex: 'business',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.business'
      })
    },
    {
      dataIndex: 'actions',
      title: '',
      align: 'center'
    }
  ];

  const editingTableColumns = [
    {
      dataIndex: 'trackingNumber',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.tracking_number'
      })
    },
    {
      dataIndex: 'type',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.type'
      })
    },
    {
      dataIndex: 'business',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.business'
      })
    },
    {
      dataIndex: 'actions',
      title: '',
      align: 'center'
    }
  ];

  const wrongScanColumns = [
    {
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.tracking_number'
      }),
      dataIndex: 'trackingNumber',
      width: 130,
      key: 'trackingNumber'
    },
    {
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.transfer_seals.table_columns.state'
      }),
      dataIndex: 'deliveryState',
      key: 'deliveryState'
    }
  ];

  const getScannedDeliveries = () => {
    return {
      list: formatData()
    };
  };

  const sealValidationValidator = (value) => {
    if (
      editingChange &&
      value === oldSealNumber &&
      scanningType === SEAL_SCAN_TYPE.NORMAL
    ) {
      return Promise.reject(
        intl.formatMessage({
          id: 'hubs.hub_transfer.transfer_seals.scanning_errors.scan_new_seal_validation'
        })
      );
    }
    return Promise.resolve();
  };

  const sealValidationRule = () => ({
    validator: (_, value) => sealValidationValidator(value)
  });

  return (
    <LoadingWrapper loading={isLoading}>
      <HubSelectors
        allHubs={allHubs}
        scopedHubs={scopedHubs}
        setIsLoading={setIsLoading}
        scanningEnabled={scanningEnabled}
        handleStartScanning={handleStartScanning}
        sourceHubId={sourceHubId}
        destinationHubId={destinationHubId}
        setSourceHubId={setSourceHubId}
        setDestinationHubId={setDestinationHubId}
        setSourceHubType={setSourceHubType}
        setDestinationHubType={setDestinationHubType}
        setShowErrorBanner={setShowErrorBanner}
        showErrorBanner={showErrorBanner}
        errorBannerProps={errorBannerProps}
        destinationHubsOptions={destinationHubsOptions}
        setDestinationHubsOptions={setDestinationHubsOptions}
        setSourceHubData={setSourceHubData}
        setDestinationHubData={setDestinationHubData}
        setSortingStationAllowedTransferHubs={
          setSortingStationAllowedTransferHubs
        }
      />
      <div className="br-hub-transfer-scanning">
        <div className="br-hub-transfer-scanning__container">
          <div className="br-hub-transfer-scanning__type">
            <Form.Item
              label={intl.formatMessage({
                id: 'hubs.hub_transfer.transfer_seals.scanning.scanning_type'
              })}
            >
              <Radio.Group
                value={scanningType}
                onChange={(e) => handleScanningTypeChange(e.target.value)}
              >
                {HUB_TRANSFER_SCANNING_TYPE({
                  destinationHubId
                }).map(({ label, value, disabled = false }) => (
                  <Radio.Button value={value} disabled={disabled}>
                    {label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="br-hub-transfer__scanning display-flex">
            <Form ref={formRef}>
              {scanningType === SEAL_SCAN_TYPE.BINS ? (
                <Form.Item
                  name="bin"
                  label={intl.formatMessage({
                    id: 'hubs.hub_transfer.transfer_seals.scanning.scan_bin'
                  })}
                >
                  <Input
                    ref={sealNumberInputRef}
                    autoComplete="off"
                    placeholder={intl.formatMessage({
                      id: 'hubs.hub_transfer.transfer_seals.scanning.scan_bin'
                    })}
                    onChange={(e) => scanShelf(e.target.value)}
                    disabled={!scanningEnabled || !destinationHubId}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="seal"
                  rules={[sealValidationRule()]}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'hubs.hub_transfer.transfer_seals.scanning.scanning_seal'
                      })}
                      <Tooltip
                        title={intl.formatMessage({
                          id: 'hubs.hub_transfer.transfer_seals.scanning.scanning_seal_tooltip'
                        })}
                      >
                        <TooltipIcon className="br-hub-transfer-scanning-seal__tooltip-icon" />
                      </Tooltip>
                    </>
                  }
                >
                  <Input
                    ref={sealNumberInputRef}
                    autoComplete="off"
                    placeholder={intl.formatMessage({
                      id: 'hubs.hub_transfer.transfer_seals.scanning.scanning_seal_placeholder'
                    })}
                    onChange={(e) => scanSeal(e.target.value)}
                    disabled={!scanningEnabled}
                  />
                </Form.Item>
              )}
              <span>
                {intl.formatMessage({
                  id: 'hubs.hub_transfer.transfer_seals.scanning.and'
                })}
              </span>
              <Form.Item
                name="trackingNumber"
                label={intl.formatMessage({
                  id: 'hubs.hub_transfer.transfer_seals.scanning.tracking_number'
                })}
              >
                <Input
                  ref={trackingNumberInputRef}
                  autoComplete="off"
                  placeholder={intl.formatMessage({
                    id: 'hubs.hub_transfer.transfer_seals.scanning.tracking_number_placeholder'
                  })}
                  onChange={(e) => scanDelivery(e.target.value?.trim())}
                  disabled={
                    !scanningEnabled ||
                    (scanningType === SEAL_SCAN_TYPE.BINS && !bin)
                  }
                />
              </Form.Item>
            </Form>
            {scanningEnabled && (
              <div className="br-hub-transfer-scanning__actions display-flex">
                {scanningType !== SEAL_SCAN_TYPE.BINS && (
                  <div className="br-hub-transfer-scanning__count body-medium">
                    {(scannedDeliveries?.length || 0) +
                      (editModeScannedDeliveries?.length || 0)}{' '}
                    {scanningType === SEAL_SCAN_TYPE.NORMAL && (
                      <span>/ {HUB_TRANSFER_SEAL_LIMIT}</span>
                    )}
                  </div>
                )}
                {((isEditing && editingChange) || !isEditing) && (
                  <BRButton
                    type="destructive-primary"
                    label={intl.formatMessage({
                      id: `hubs.hub_transfer.transfer_seals.scanning.${
                        scanningType === SEAL_SCAN_TYPE.BINS
                          ? 'confirm_bin'
                          : 'close_seal'
                      }`
                    })}
                    onClick={handleConfirmClick}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {!!wrongScannedDeliveries.length && (
          <Table
            title={() => (
              <div className="br-hub-transfer-scanning__wrong-scanned-table__header">
                <span>
                  {intl.formatMessage({
                    id: 'hubs.hub_transfer.transfer_seals.wrong_scan_table_title'
                  })}
                </span>
                <CloseIcon
                  className="br-close-icon"
                  onClick={handleCloseWrongScanTable}
                />
              </div>
            )}
            className="br-hub-transfer-scanning__wrong-scanned-table"
            columns={wrongScanColumns}
            dataSource={wrongScannedDeliveries}
            pagination={false}
            size="small"
            scroll={{ y: 120 }}
          />
        )}
      </div>
      <div className="br-hub-transfer-table__container">
        {isEditing ? (
          <BRSwappingTables
            leftTableProps={{
              columns: editingTableColumns,
              preTableHeaderContent: (
                <div className="br-hub-transfer-table__title heading">
                  {intl.formatMessage(
                    {
                      id: 'hubs.hub_transfer.transfer_seals.table.edit_title'
                    },
                    {
                      count: editModeScannedDeliveries?.length || 0
                    }
                  )}
                </div>
              )
            }}
            rightTableProps={{
              columns: editingTableColumns,
              preTableHeaderContent: (
                <div className="br-hub-transfer-table__title heading">
                  {intl.formatMessage(
                    {
                      id: 'hubs.hub_transfer.transfer_seals.table.title'
                    },
                    {
                      count: scannedDeliveries?.length || 0
                    }
                  )}
                </div>
              )
            }}
            leftTableDataSource={formatEditData({
              data: editModeScannedDeliveries,
              isLeftTable: true
            })}
            rightTableDataSource={formatEditData({ data: scannedDeliveries })}
          />
        ) : (
          <BRTable
            preTableHeaderContent={
              <div className="br-hub-transfer-table__title heading">
                {intl.formatMessage(
                  {
                    id: 'hubs.hub_transfer.transfer_seals.table.title'
                  },
                  {
                    count: scannedDeliveries?.length || 0
                  }
                )}
              </div>
            }
            columns={columns}
            showFilter={false}
            listFunction={getScannedDeliveries}
            emptySearchContent={{
              title: intl.formatMessage({
                id: 'hubs.hub_transfer.transfer_seals.scanning.empty_table_title'
              }),
              search: false
            }}
            pagination={false}
            shareMethods={acceptMethods}
          />
        )}
      </div>
    </LoadingWrapper>
  );
};

export default withRouter(injectIntl(ScanSealsAndBins));
