import { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import {
  exportFulfillmentProducts,
  getFulfillmentProductsTree
} from 'services/products';
import { downloadAsXlsx } from 'utils';
import { ORDER_SORT } from 'constants/helper';
import { formatPrice, getSKU } from 'utils/fulfillment';
import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/fulfillment';
import {
  PRODUCTS_SEARCH_OPTIONS,
  PRODUCTS_SEARCH_OPTIONS_IDS
} from 'constants/fulfillment-products';
import { getSkuForScan } from 'constants/fulfillment-sales-orders';
import { SllrWrapper } from 'contexts/sllr.context';

import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import ProductImage from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/ProductImage/ProductImage';

import { ReactComponent as DropdownIcon } from 'assets/bosta-icons/Dropdown.svg';

import './ProductsTable.less';

const ProductsTable = ({
  intl,
  getBusinessName,
  selectedBusinessId,
  tableRef = {}
}) => {
  const { setIsLoading } = useContext(SllrWrapper);
  const [productsCount, setProductsCount] = useState(0);
  const [sharedPayload, setSharedPayload] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [selectedSearchOption, setSelectedSearchOption] = useState(
    PRODUCTS_SEARCH_OPTIONS_IDS.SKU.KEY
  );
  const renderVariantsTooltip = (variantsLength) => {
    const variants = intl.formatMessage(
      {
        id: `fulfillment_products.products_table.${
          variantsLength === 1 ? 'one_variant' : 'variants'
        }`
      },
      {
        count: variantsLength
      }
    );
    return (
      <Tooltip
        title={intl.formatMessage(
          {
            id: 'fulfillment_products.products_table.variants_tooltip'
          },
          {
            count: variants
          }
        )}
        placement="bottom"
      >
        <span className="br-variants-tooltip-title">{variants}</span>
      </Tooltip>
    );
  };

  const columns = [
    {
      render: ({ imageSrc }) => <ProductImage src={imageSrc} />,
      width: 80
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_products.products_table.columns.product_name'
      }),
      sorter: true,
      ellipsis: true,
      width: '24%',
      render: ({ children, name, nameAr }) => (
        <span>
          {name || nameAr}
          {children && renderVariantsTooltip(children.length)}
        </span>
      ),
      field: 'name'
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_products.products_table.columns.sku'
      }),
      render: ({ children, ...rest }) => (
        <span className="text-gray">
          {children
            ? intl.formatMessage({
                id: 'fulfillment_products.products_table.master'
              })
            : getSKU(rest)}
        </span>
      ),
      width: 104
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_products.products_table.columns.reference_id'
      }),
      render: ({ referenceId, children }) =>
        children
          ? intl.formatMessage(
              {
                id: 'fulfillment_products.products_table.variants'
              },
              {
                count: children.length
              }
            )
          : referenceId || <span className="text-gray">-</span>,
      width: 150
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_products.products_table.columns.price'
      }),
      render: ({ maxPrice, children }) => {
        return children ? (
          <span className="text-gray">
            {intl.formatMessage({
              id: 'fulfillment_products.products_table.varies'
            })}
          </span>
        ) : (
          formatPrice(maxPrice)
        );
      },
      sorter: true,
      field: 'maxPrice'
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_products.products_table.columns.total_on_hand'
      }),
      render: ({ children, totalOnhand }) => !children && totalOnhand
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_products.products_table.columns.total_forecasted'
      }),
      render: ({ children, totalForcasted }) => !children && totalForcasted
    },
    {
      dataIndex: 'businessId',
      title: intl.formatMessage({
        id: 'fulfillment_products.products_table.columns.business'
      }),
      render: getBusinessName,
      ellipsis: true
    }
  ];

  const formatProducts = (products) =>
    products.map(({ productsVariances = [], ...rest }) => ({
      ...rest,
      maxPrice: rest.defaultPrice,
      imageSrc: rest.defaultImage,
      key: rest.bostaSku,
      ...(productsVariances.length && {
        children: productsVariances.map((props) => ({
          ...props,
          businessId: rest.businessId,
          name: `${rest.name || rest.nameAr} - ${props.optionsString}`,
          maxPrice: props.variantPrice,
          imageSrc: props.variantDefaultImage
        }))
      })
    }));

  const resetTable = () => {
    setProductsCount(0);
    setSharedPayload({});
    setSearchValue('');

    return {
      list: [],
      total: 0
    };
  };

  const handleGetProducts = async ({
    page,
    limit,
    searchInputText,
    sortField,
    sortOrder
  }) => {
    setIsLoading(true);
    let value = searchInputText?.trim();
    if (selectedSearchOption === PRODUCTS_SEARCH_OPTIONS_IDS.SKU.KEY) {
      value = getSkuForScan(value);
    }
    setSearchValue(value);

    const payload = {
      page,
      limit,
      businessId: selectedBusinessId,
      ...(value && {
        [PRODUCTS_SEARCH_OPTIONS_IDS[selectedSearchOption].API_KEY]: value
      }),
      ...(sortField && {
        sortedBy: `${sortOrder === ORDER_SORT.DESCEND ? '-' : ''}${sortField}`
      })
    };
    setSharedPayload(payload);

    try {
      const {
        data: { products = [], count = 0 }
      } = await getFulfillmentProductsTree(payload);
      setProductsCount(count);
      setIsLoading(false);

      return {
        list: formatProducts(products),
        total: count
      };
    } catch (error) {
      notify(error.message);
      setIsLoading(false);
    }
  };

  const handleExportBusinesses = async () => {
    if (selectedBusinessId) {
      setIsLoading(true);

      try {
        const data = await exportFulfillmentProducts(sharedPayload);
        const fileName = `Fulfillment_Products_${dayjs().format('DD_MM_YYYY')}`;
        downloadAsXlsx(data, fileName);
        notify(
          intl.formatMessage({
            id: 'common.file_downloaded_successfully'
          }),
          'success'
        );
      } catch (error) {
        notify(error.message);
      }
      setIsLoading(false);
    }
  };

  const acceptMethods = (refreshMethod) => {
    tableRef.current = () => refreshMethod({ pageNumber: 1 });
  };

  const updateTable = () => {
    tableRef.current();
  };

  useEffect(() => {
    updateTable();
  }, [selectedBusinessId]);

  return (
    <BRTable
      className="br-fulfillment-table br-fulfillment-products-table"
      title={intl.formatMessage(
        {
          id: 'fulfillment_products.products_table.title'
        },
        {
          count: productsCount
        }
      )}
      columns={columns}
      listFunction={selectedBusinessId ? handleGetProducts : resetTable}
      shareMethods={acceptMethods}
      searchPlaceholder={intl.formatMessage(
        {
          id: 'fulfillment_products.products_table.search_placeholder'
        },
        {
          searchBy: PRODUCTS_SEARCH_OPTIONS_IDS[selectedSearchOption].LABEL
        }
      )}
      exportListFileFunction={handleExportBusinesses}
      showFilter={false}
      showSearch
      pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
      searchOptions={PRODUCTS_SEARCH_OPTIONS}
      selectedSearchOption={selectedSearchOption}
      setSelectedSearchOption={setSelectedSearchOption}
      emptySearchContent={{
        title: intl.formatMessage({ id: 'common.no_results_found' }),
        hideAction: !searchValue
      }}
      expandIcon={({ expandable, expanded, onExpand, record }) =>
        expandable && (
          <DropdownIcon
            className={classNames('collapse-expand-icon', {
              expanded
            })}
            onClick={(e) => onExpand(record, e)}
          />
        )
      }
    />
  );
};

export default injectIntl(ProductsTable);
