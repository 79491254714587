import { Select } from 'antd';
const HubSelection = ({
  allHubs,
  currentHub,
  intl,
  loading,
  handleHubChange
}) => {
  const hubsOptions = allHubs?.map((hub) => {
    return { value: hub._id, label: hub.name };
  });
  return (
    <Select
      disabled={loading}
      value={currentHub}
      onChange={handleHubChange}
      allowClear
      showSearch
      showArrow
      options={hubsOptions}
      optionFilterProp="label"
      placeholder={intl.formatMessage({
        id: 'ops_control.hub_selector_placeholder'
      })}
    />
  );
};
export default HubSelection;
