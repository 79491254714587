import { useState, useEffect } from 'react';
import { InputNumber } from 'antd';

import { getSKU } from 'utils/fulfillment';

const QuantityInput = ({
  initialValue,
  productSku,
  setProducts,
  quantityKey = 'quantity',
  ...rest
}) => {
  const [value, setValue] = useState(1);

  const handleChange = (val) => {
    if (val && !isNaN(val)) {
      setValue(val);
      setProducts((prev) => {
        const index = prev.findIndex(
          (product) => getSKU(product) === productSku
        );
        if (index !== -1) {
          prev[index] = {
            ...prev[index],
            [quantityKey]: val
          };
        }
        return [...prev];
      });
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <InputNumber min={1} value={value} onChange={handleChange} {...rest} />
  );
};

export default QuantityInput;
