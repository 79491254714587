import React, { useRef, useState } from 'react';
import { Skeleton, Tooltip } from 'antd';

import { mediaHook } from 'customHooks';
import { IS_SAUDI } from 'constants/helper';
import { charLimit, FREE_ITEMS_ARRAY } from 'constants/packaging';
import { shortenString } from 'utils';

import { ReactComponent as LeftArrow } from 'assets/bosta-icons/Chevron-left.svg';
import { ReactComponent as RightArrow } from 'assets/bosta-icons/Chevron-right.svg';

const PackageItemsRow = ({
  title,
  freeItems = true,
  data = [],
  mobileScreenSizes: { isExtraLargeMobileScreen },
  isLoading
}) => {
  const divRef = useRef();
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(false);

  const handleScrollDiv = (value) => {
    const addedValue = divRef.current.scrollLeft + value;
    const finishValue =
      divRef.current.scrollWidth - addedValue <= divRef.current.clientWidth;
    const startValue = addedValue === 0;
    if (addedValue > 0 && isLeftArrowDisabled) {
      setIsLeftArrowDisabled(false);
    }
    if ((value < 0 || !finishValue) && isRightArrowDisabled) {
      setIsRightArrowDisabled(false);
    }

    if (finishValue) {
      setIsRightArrowDisabled(true);
    }
    if (startValue) {
      setIsLeftArrowDisabled(true);
    }
    divRef.current.scrollLeft = addedValue;
  };

  const renderSkeleton = () => {
    return (
      <div className="br-packaging-balance__items-row">
        {FREE_ITEMS_ARRAY.map((info) => (
          <div key={info.key}>
            <div>
              <Skeleton.Avatar active={true} shape="square" size="large" />

              <div>
                <span className="body">
                  <Skeleton title={{ width: 150 }} paragraph={false} active />
                </span>
                <span className="caption">
                  <Skeleton title={{ width: 100 }} paragraph={false} active />
                </span>
              </div>
            </div>
            <div>
              <span className="sidebar-parent">
                <Skeleton title={{ width: 20 }} paragraph={false} active />
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const itemDescription = ({
    title,
    descriptionEg,
    descriptionKsa,
    sizeEg,
    sizeKsa
  }) => {
    const tooltipText = IS_SAUDI
      ? descriptionKsa || sizeKsa
      : descriptionEg || sizeEg;

    const displayText = shortenString(tooltipText, charLimit);

    return (
      <div>
        <span className="body">{title}</span>

        <Tooltip
          overlayClassName="br-packaging-balance__tooltips"
          title={tooltipText}
        >
          <span className="caption">{displayText}</span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className="br-packaging-balance__items-container">
      <div className="br-packaging-balance__items-actions-container">
        <span>{title}</span>
        {(isExtraLargeMobileScreen || !freeItems) && (
          <div>
            <LeftArrow
              className={isLeftArrowDisabled && 'disabled'}
              onClick={() =>
                isLeftArrowDisabled ? null : handleScrollDiv(-200)
              }
            />
            <RightArrow
              onClick={() =>
                isRightArrowDisabled ? null : handleScrollDiv(200)
              }
              className={isRightArrowDisabled && 'disabled'}
            />
          </div>
        )}
      </div>
      {isLoading || !data.length ? (
        renderSkeleton()
      ) : (
        <div
          className="br-packaging-balance__items-row"
          ref={divRef}
          onScroll={() => handleScrollDiv(0)}
        >
          {data?.map(
            (info) =>
              !info?.hide && (
                <div key={info.key}>
                  <div>
                    <img src={info.icon} alt="" width={61} height={54} />
                    {itemDescription(info)}
                  </div>
                  <div>
                    <span className="sidebar-parent">{info.count || 0}</span>
                  </div>
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default mediaHook(PackageItemsRow);
