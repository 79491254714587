import { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import {
  FIRST_MILE_HUB,
  LAST_MILE_HUB,
  SORTING_HUB,
  SORTING_STATION_HUB
} from 'constants/hubs';
import { DELIVERY_STATES_CODES, PUDO_HUB_TYPE } from 'constants/shipments';
import { getSortingFacility } from 'services/hubs';
import { exportXlsx, searchDeliveries } from 'services/deliveries';
import { getAllowedTransferHubsIds, getCurrentUserHubInfo } from 'utils/hubs';
import {
  isAdminGroupUser,
  isDefaultBostaCourier,
  isUserAuthorized
} from 'utils/helpers';
import { downloadAsXlsx } from 'utils/download';
import {
  HUB_LEADER,
  SUPER_ADMIN,
  VENDOR_MANAGER,
  WAREHOUSE_CLERK
} from 'common/aclMatrix';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import Banner from 'components/Banner/Banner';

import './HubSelectors.less';

const HubSelectors = ({
  intl,
  allHubs,
  scopedHubs,
  setIsLoading,
  handleStartScanning,
  scanningEnabled,
  sourceHubId,
  setSourceHubId,
  destinationHubId,
  setDestinationHubId,
  setSourceHubType = () => {},
  setDestinationHubType = () => {},
  setShowErrorBanner,
  showErrorBanner,
  errorBannerProps,
  dispatchingTab,
  sealsSummary,
  destinationHubsOptions,
  setDestinationHubsOptions,
  setSourceHubData = () => {},
  setDestinationHubData = () => {},
  setSortingStationAllowedTransferHubs = () => {}
}) => {
  const [sortingFacility, setSortingFacility] = useState([]);
  const [destinationHubDeliveries, setDestinationHubDeliveries] = useState({});

  useEffect(() => {
    if (!isDefaultBostaCourier([VENDOR_MANAGER, HUB_LEADER, WAREHOUSE_CLERK])) {
      getSortingFacilityHubs();
    }
  }, []);

  useEffect(() => {
    const userHubInfo = getCurrentUserHubInfo();

    if (isAdminGroupUser()) {
      scopedHubs.length === 1 && setSourceHubId(scopedHubs[0]._id);
    } else if (userHubInfo?._id) {
      setSourceHubId(userHubInfo._id);
    }
  }, [scopedHubs]);

  useEffect(() => {
    if (sourceHubId && allHubs.length) {
      getDestinationHubOptions();
      const sourceHub = allHubs.find((hub) => hub._id === sourceHubId);
      setSourceHubType(sourceHub.type);
      setSourceHubData(sourceHub);
    }
  }, [sourceHubId, allHubs, sortingFacility]);

  useEffect(() => {
    if (destinationHubId) {
      const currentDestinationHub = destinationHubsOptions.find(
        (hub) => hub._id === destinationHubId
      );
      setDestinationHubType(currentDestinationHub?.type);
      setDestinationHubData(currentDestinationHub);
      if (currentDestinationHub?.type === SORTING_STATION_HUB) {
        const allowedTransfersHubIds = getAllowedTransferHubsIds({
          destinationHub: currentDestinationHub,
          sourceHubId,
          allHubs
        });

        setSortingStationAllowedTransferHubs(allowedTransfersHubIds);
      } else {
        setSortingStationAllowedTransferHubs([]);
      }
    }
  }, [destinationHubId]);

  const getSortingFacilityHubs = async () => {
    try {
      const { warehouses } = await getSortingFacility();
      setSortingFacility(warehouses);
    } catch (error) {
      notify(error.message);
    }
  };

  const getDestinationHubOptions = () => {
    const { allowedTransfersHubIds = [] } =
      allHubs.find((hub) => hub._id === sourceHubId) || {};

    if (!isDefaultBostaCourier([VENDOR_MANAGER, HUB_LEADER, WAREHOUSE_CLERK])) {
      setDestinationHubsOptions(sortingFacility);
    } else {
      const allowedDestinationHubs = allowedTransfersHubIds.length
        ? allHubs.filter(
            (hub) =>
              allowedTransfersHubIds.includes(hub._id) &&
              hub._id !== sourceHubId
          )
        : allHubs;
      setDestinationHubsOptions(allowedDestinationHubs);
    }
  };

  const getDestinationHubDeliveries = async () => {
    setIsLoading(true);
    try {
      const payload = {
        hubs: [sourceHubId],
        assignedHub: destinationHubId,
        stateCodes: [DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE.toString()]
      };

      const data = await searchDeliveries(payload);
      setDestinationHubDeliveries(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleSourceHubChange = (value) => {
    setDestinationHubId(null);
    setSourceHubId(value);
  };

  const handleStartScanningClick = () => {
    if (destinationHubId) {
      getDestinationHubDeliveries();
    }
    handleStartScanning();
  };

  const handleViewPackageClick = async () => {
    setIsLoading(true);
    try {
      const payload = {
        hubs: [sourceHubId],
        assignedHub: destinationHubId,
        stateCodes: [DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE.toString()]
      };

      const { data, msg } = await exportXlsx(payload);

      if (data) {
        downloadAsXlsx(
          data,
          `Export_[${dayjs(new Date()).format('DD-MM-YYYY')}].xlsx`
        );
      } else {
        notify(msg, 'success');
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      {showErrorBanner && (
        <Banner
          className="br-hub-transfer-error__banner"
          dismissIcon
          bannerFixed
          isAntIcon
          onDismissIconClick={() => setShowErrorBanner(false)}
          {...errorBannerProps}
        />
      )}
      <div className="br-hub-and-star-selectors__container">
        <div className="br-hub-and-star-selectors display-flex">
          <Form.Item
            label={intl.formatMessage({
              id: 'hubs.hub_transfer.transfer_seals.hub_star_selectors.source_hub'
            })}
          >
            <Select
              disabled={
                (!isAdminGroupUser() && !isUserAuthorized(SUPER_ADMIN)) ||
                scanningEnabled
              }
              value={sourceHubId}
              onChange={handleSourceHubChange}
              showSearch
              optionFilterProp="children"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {scopedHubs.map(
                (hub) =>
                  hub.type !== PUDO_HUB_TYPE && (
                    <Select.Option value={hub._id} key={hub._id}>
                      {hub.name}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'hubs.hub_transfer.transfer_seals.hub_star_selectors.destination_hub'
            })}
          >
            <Select
              disabled={!sourceHubId || scanningEnabled}
              value={{ value: destinationHubId }}
              labelInValue
              onChange={({ value }) => setDestinationHubId(value)}
              showSearch
              optionFilterProp="children"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {destinationHubsOptions.map((hub) => (
                <Select.Option value={hub._id} key={hub._id}>
                  {hub.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!scanningEnabled && !dispatchingTab && (
            <BRButton
              type="primary"
              label="Start Scanning"
              disabled={!sourceHubId}
              onClick={handleStartScanningClick}
            />
          )}
        </div>
        <div className="br-hub-and-star-selectors-deliveries__banner">
          {!!destinationHubDeliveries?.count && scanningEnabled && (
            <Banner
              subText={intl.formatMessage(
                {
                  id: 'hubs.hub_transfer.transfer_seals.hub_star_selectors.deliveries_count_banner'
                },
                {
                  count: (
                    <span className="br-hub-and-star-selectors-deliveries__count body-medium">
                      {destinationHubDeliveries?.count}
                    </span>
                  ),
                  hubName: destinationHubsOptions.find(
                    (hub) => hub._id === destinationHubId
                  )?.name
                }
              )}
              buttonText={intl.formatMessage({
                id: 'hubs.hub_transfer.transfer_seals.hub_star_selectors.view_packages'
              })}
              buttonProps={{
                onClick: handleViewPackageClick
              }}
            />
          )}
          {!!sealsSummary?.sealsCount &&
            !!sealsSummary?.packagesCount &&
            !destinationHubId && (
              <div className="br-hub-and-star-selectors-seals__summary">
                <div className="br-hub-transfer__summary">
                  {intl.formatMessage(
                    { id: 'hubs.hub_transfer.dispatch_seals.seals_count' },
                    {
                      count: (
                        <span className="body-medium">
                          {sealsSummary.sealsCount}
                        </span>
                      )
                    }
                  )}
                </div>
                <div className="br-hub-transfer__summary">
                  {intl.formatMessage(
                    { id: 'hubs.hub_transfer.dispatch_seals.bins_count' },
                    {
                      count: (
                        <span className="body-medium">
                          {sealsSummary.binsCount}
                        </span>
                      )
                    }
                  )}
                </div>
                <div className="br-hub-transfer__summary">
                  {intl.formatMessage(
                    { id: 'hubs.hub_transfer.dispatch_seals.packages_count' },
                    {
                      count: (
                        <span className="body-medium">
                          {sealsSummary.packagesCount}
                        </span>
                      )
                    }
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default injectIntl(HubSelectors);
