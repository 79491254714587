import React, { useContext } from 'react';
import {
  Table,
  Input,
  Button,
  Form,
  InputNumber,
  DatePicker,
  Select
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { collectStarMoney } from 'services/hubs';
import { getDebriefStarsDepositTypes } from 'common/countries/countries-mapping';

import { notify } from 'components/Notify/Notify';

import './CODTab.less';

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  OnSuccessValidation,
  type,
  ...restProps
}) => {
  const form = useContext(EditableContext);
  const save = async (e) => {
    try {
      const values = await form.validateFields();
      OnSuccessValidation({
        ...record,
        ...values
      });
    } catch (errInfo) {}
  };
  let childNode = children;

  let formControl;
  switch (type) {
    case 'number':
      formControl = (
        <InputNumber
          style={{ width: 180 }}
          onPressEnter={save}
          onBlur={save}
          onChange={save}
          disabled={record?.isDisabled}
        />
      );
      break;
    case 'select':
      formControl = (
        <Select onChange={save} disabled={record?.isDisabled}>
          {getDebriefStarsDepositTypes().map((el) => (
            <Select.Option value={el} key={el}>
              {el}
            </Select.Option>
          ))}
        </Select>
      );
      break;
    case 'date':
      formControl = (
        <DatePicker
          onChange={save}
          format="YYYY-MM-DD"
          disabled={record?.isDisabled}
        />
      );
      break;
    default:
      formControl = (
        <Input
          onPressEnter={save}
          onBlur={save}
          disabled={record?.isDisabled}
        />
      );
  }

  if (editable) {
    childNode = (
      <Form.Item
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`
          }
        ]}
        initialValue={record[dataIndex]}
        value={record[dataIndex]}
      >
        {formControl}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
class CODTab extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Deposit Type',
        dataIndex: 'depositType',
        width: '25%',
        type: 'select',
        editable: true
      },
      {
        title: 'Receipt No.',
        dataIndex: 'receiptNo',
        width: '20%',
        editable: true
      },
      {
        title: 'Receipt Date',
        dataIndex: 'receiptDate',
        width: '20%',
        type: 'date',
        editable: true
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: '20%',
        type: 'number',
        editable: true
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        render: (text, record) => {
          return JSON.parse(localStorage.getItem('userAutoCodCollection'))
            ?.autoCODCollection ? (
            <></>
          ) : (
            <Button
              type="primary"
              onClick={() => this.handleDelete(record.key)}
              icon={<DeleteOutlined title="Delete" />}
            >
              Delete
            </Button>
          );
        }
      }
    ];
    this.state = {
      dataSource:
        this.props?.codRecords && this.disableConfirmCashCollectionButton()
          ? this.formatCODRecords(this.props.codRecords)
          : [],
      count: 0,
      completedRowsData: []
    };
  }

  formatCODRecords = (codArray) => {
    const formattedCodArray = [];
    codArray?.map((record) => {
      let newObject = {
        key: record?.createdAt,
        depositType: record.src,
        receiptNo: record.receiptNo,
        receiptDate: dayjs(record?.receiptDate || record?.createdAt),
        amount: Math.abs(record?.amount),
        isDisabled: true
      };
      formattedCodArray.push(newObject);
    });
    return formattedCodArray;
  };

  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key)
    });
  };

  handleAddRowToTable = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      depositType: '',
      receiptNo: '',
      receiptDate: dayjs(),
      amount: ''
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1
    });
  };

  OnSuccessValidation = (row) => {
    const { dataSource } = this.state;
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      dataSource: newData,
      completedRowsData: newData
    });
  };

  OnConfirmCashCollection = async () => {
    const { completedRowsData } = this.state;
    const { starId, isDataLoading, getUpdatedData } = this.props;
    try {
      isDataLoading(true);
      if (!starId) {
        notify('Please choose a star.');
      } else {
        const cashMoneyArray = completedRowsData.map(
          ({ depositType, amount, receiptNo, receiptDate }) => ({
            depositType,
            amount,
            receiptNo,
            receiptDate
          })
        );
        const { result } = await collectStarMoney({
          cashMoneyArray,
          starId
        });
        getUpdatedData();
        this.setState({ dataSource: [], count: 0 });
        notify(result, 'success');
      }
    } catch (error) {
      notify(error.message);
    }
    isDataLoading(false);
  };

  disableConfirmCashCollectionButton = () => {
    const countryObject = JSON.parse(
      localStorage.getItem('userAutoCodCollection')
    );
    return countryObject ? countryObject.autoCODCollection : false;
  };

  render() {
    const { dataSource, completedRowsData } = this.state;
    const { codRecords } = this.props;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type,
          OnSuccessValidation: this.OnSuccessValidation
        })
      };
    });

    return (
      <div>
        <Table
          components={components}
          className="br-cod-tab"
          dataSource={
            this.disableConfirmCashCollectionButton()
              ? this.formatCODRecords(codRecords)
              : dataSource
          }
          columns={columns}
          footer={() => (
            <div className="overflow-auto">
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={this.handleAddRowToTable}
                disabled={this.disableConfirmCashCollectionButton()}
              />
              <Button
                type="primary"
                className="mt-3"
                disabled={
                  !completedRowsData.length ||
                  this.disableConfirmCashCollectionButton()
                }
                onClick={this.OnConfirmCashCollection}
              >
                Confirm Cash Collection
              </Button>
            </div>
          )}
        />
      </div>
    );
  }
}

export default CODTab;
