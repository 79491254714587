import get from 'lodash/get';

import {
  ALL_COUNTRIES,
  KUWAIT_COUNTRY_DATA,
  OMAN_COUNTRY_DATA,
  QATAR_COUNTRY_DATA
} from 'common/countries/constants/countries';
import { isReturnToFulfillmentAvailable } from 'common/countries/countries-mapping';
import { exceptionsMapping } from 'constants/hubs';
import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import { openModal } from 'utils/modal';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import BRButton from 'components/BRButton/BRButton';
import BRPreviewImage from 'components/BRPreviewImage/BRPreviewImage';

import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as Clock } from 'assets/bosta-icons/Clock.svg';
import { ReactComponent as Inventory } from 'assets/bosta-icons/Inventory.svg';
import { ReactComponent as Rocket } from 'assets/bosta-icons/rocket.svg';
import { ReactComponent as Check } from 'assets/bosta-icons/Check.svg';
import { ReactComponent as Box } from 'assets/bosta-icons/box.svg';
import { ReactComponent as InTransitIcon } from 'assets/bosta-icons/In Transit.svg';
import { ReactComponent as BostaBoxIcon } from 'assets/icons/BostaBox.svg';

export const INTERNATIONAL_DELIVERIES_COLUMNS = ({ isTransferPage } = {}) => [
  ...(isTransferPage
    ? [
        {
          dataIndex: 'delete',
          fixed: 'left',
          width: 60
        }
      ]
    : []),
  {
    title: fmt({ id: 'business_details.business_info.business_name' }),
    dataIndex: 'businessName',
    fixed: 'left',
    width: 200
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.search.tracking_number_label'
    }),
    dataIndex: 'trackingNumberButton',
    width: 120
  },
  {
    title: fmt({ id: 'international_orders.pickups.state' }),
    dataIndex: 'state',
    width: 160
  },
  {
    title: fmt({ id: 'pickups.pickup_details.pickup_info.address' }),
    dataIndex: 'pickupAddress',
    width: 180
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.dropoff_address'
    }),
    dataIndex: 'dropOffAddress',
    width: 180
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.customer_details'
    }),
    dataIndex: 'customerDetails',
    width: 160
  },
  {
    title: fmt({
      id: 'international_orders.pickups.search.createdAt'
    }),
    dataIndex: 'createdAt',
    width: 180
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.picked_up_at'
    }),
    dataIndex: 'pickedUpAt',
    width: 180
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.in_transit_at'
    }),
    dataIndex: 'intransitAt',
    width: 180
  },
  {
    title: fmt({
      id: 'international_orders.pickups.search.pickedUpAt'
    }),
    dataIndex: 'completedAt',
    width: 180
  },
  ...(!isTransferPage
    ? [
        {
          dataIndex: 'actions',
          width: 60
        }
      ]
    : [])
];

export const INTERNATIONAL_DELIVERIES_E2E_COLUMNS = [
  {
    title: fmt({ id: 'business_details.business_info.business_name' }),
    dataIndex: 'businessName',
    fixed: 'left',
    width: 200
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.international_tn'
    }),
    dataIndex: 'trackingNumberButton',
    width: 150
  },
  {
    title: fmt({ id: 'international_orders.deliveries.table_columns.from_to' }),
    dataIndex: 'fromTo',
    width: 120
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.destination_tn'
    }),
    dataIndex: 'destinationDomesticOrder',
    width: 180
  },
  {
    title: fmt({ id: 'international_orders.pickups.state' }),
    dataIndex: 'state',
    width: 250
  },
  {
    title: fmt({ id: 'international_orders.deliveries.table_columns.weight' }),
    dataIndex: ['specs', 'weight'],
    render: (weight) =>
      weight ? (
        <span className="br-international-deliveries__weight">
          {fmt(
            { id: 'international_orders.deliveries.delivery_details.weight' },
            {
              weight
            }
          )}
        </span>
      ) : (
        '-'
      ),
    width: 120
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.customs_vat'
    }),
    dataIndex: 'customVAT',
    width: 120
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.customer_details'
    }),
    dataIndex: 'customerDetails',
    width: 180
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.table_columns.updated_at'
    }),
    dataIndex: 'date',
    width: 180
  },
  {
    dataIndex: 'actions',
    width: 60
  }
];

export const INTERNATIONAL_DELIVERY_STATUS_CODES = {
  CREATED: 10,
  IN_PROGRESS: 21,
  RECEIVED_AT_WAREHOUSE: 24,
  RECEIVED_AT_WAREHOUSE_DESTINATION: 24.1,
  TRANSFERRED: 26,
  IN_TRANSIT: 30,
  IN_TRANSIT_DESTINATION: 30.1,
  OUT_FOR_DELIVERY: 41,
  COMPLETED: 45,
  RETURNED: 46,
  EXCEPTION: 47,
  CANCELED: 48,
  DELIVERED: 60
};

export const INTERNATIONAL_DELIVERY_AWAITING_YOUR_ACTION_CODE = 200;

export const INTERNATIONAL_DELIVERY_TAG_COLORS = {
  [INTERNATIONAL_DELIVERY_STATUS_CODES.CREATED]: 'yellow',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.IN_PROGRESS]: 'purple',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.RECEIVED_AT_WAREHOUSE]: 'teal',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT]: 'blue',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.TRANSFERRED]: 'blue',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.OUT_FOR_DELIVERY]: 'blue',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.COMPLETED]: 'green',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.DELIVERED]: 'green',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.RETURNED]: 'green',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.CANCELED]: 'gray',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.EXCEPTION]: 'yellow',
  [INTERNATIONAL_DELIVERY_AWAITING_YOUR_ACTION_CODE]: 'yellow'
};

export const INTERNATIONAL_DELIVERY_STATES_LABEL = {
  [INTERNATIONAL_DELIVERY_STATUS_CODES.CREATED]: fmt({
    id: 'international_orders.deliveries.states.created'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.IN_PROGRESS]: fmt({
    id: 'international_orders.deliveries.states.picked_up'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.RECEIVED_AT_WAREHOUSE]: fmt({
    id: 'international_orders.deliveries.states.received_at_warehouse'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT]: fmt({
    id: 'international_orders.deliveries.states.in_transit'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.TRANSFERRED]: fmt({
    id: 'international_orders.deliveries.states.transferred'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.COMPLETED]: fmt({
    id: 'international_orders.deliveries.states.completed'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.CANCELED]: fmt({
    id: 'international_orders.deliveries.states.canceled'
  })
};

export const INTERNATIONAL_DELIVERY_E2E_STATES_LABEL = {
  [INTERNATIONAL_DELIVERY_STATUS_CODES.CREATED]: fmt({
    id: 'international_orders.deliveries.states.created'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.IN_PROGRESS]: fmt({
    id: 'international_orders.deliveries.states.picked_up_from_business'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.RECEIVED_AT_WAREHOUSE]: fmt({
    id: 'international_orders.deliveries.states.received_at_warehouse_origin'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.RECEIVED_AT_WAREHOUSE_DESTINATION]: fmt({
    id: 'international_orders.deliveries.states.received_at_warehouse_destination'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT]: fmt({
    id: 'international_orders.deliveries.states.air_transit'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT_DESTINATION]: fmt({
    id: 'international_orders.deliveries.states.in_transit_destination'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.TRANSFERRED]: fmt({
    id: 'international_orders.deliveries.states.ready_for_air_transit'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.OUT_FOR_DELIVERY]: fmt({
    id: 'international_orders.deliveries.states.out_for_delivery'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.EXCEPTION]: fmt({
    id: 'international_orders.deliveries.states.exception'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.COMPLETED]: fmt({
    id: 'international_orders.deliveries.states.custom_cleared'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.RETURNED]: fmt({
    id: 'international_orders.deliveries.states.returned'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.DELIVERED]: fmt({
    id: 'international_orders.deliveries.states.delivered'
  }),
  [INTERNATIONAL_DELIVERY_STATUS_CODES.CANCELED]: fmt({
    id: 'international_orders.deliveries.states.canceled'
  }),
  [INTERNATIONAL_DELIVERY_AWAITING_YOUR_ACTION_CODE]: fmt({
    id: 'international_orders.deliveries.states.awaiting_for_action'
  })
};

export const INTERNATIONAL_DELIVERY_DATE_FORMAT = 'DD MMM YYYY, h:mm A';

export const INTERNATIONAL_DELIVERY_MULTI_SEARCH_KEYS = [
  'trackingNumber',
  'receiverPhone'
];

export const INTERNATIONAL_DELIVERY_UPDATE_TYPES = {
  TRANSFER: 'TRANSFER',
  CLEARED_FROM_CUSTOMS: 'CLEARED_FROM_CUSTOMS',
  RECEIVE_AT_WAREHOUSE: 'RECEIVE_AT_WAREHOUSE'
};

export const INTERNATIONAL_TRANSFER_DEFAULT_HUBS = {
  SOURCE_HUB: 'Reverse Hub',
  DESTINATION_HUB: 'Riyadh Hub “Thabit”'
};

export const WEIGHT_FIELD_KEY = 'weight';
export const CUSTOMS_VAT_VALUE = 'customsVatValueWithCurrency';
export const CUSTOM_VAT_TYPE = 'customVAT.type';
export const ADDRESS_FIELD_KEY = 'address';
export const RETURN_OPTION_KEY = 'returnOption';

export const INTERNATIONAL_DELIVERY_DETAILS_FIELDS = ({ isE2E } = {}) => [
  {
    title: fmt({
      id: 'international_orders.deliveries.delivery_details.sections.delivery_details'
    }),
    fields: [
      {
        title: fmt({
          id: 'business_details.business_info.type'
        }),
        dataIndex: 'type'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.weight'
        }),
        dataIndex: 'weight',
        isEditable: true
      },
      {
        title: fmt({ id: 'common.cod' }),
        dataIndex: 'cod'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.invoice_value'
        }),
        dataIndex: 'invoiceAmount'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.vat'
        }),
        dataIndex: 'customVAT.type',
        access: !!isE2E,
        isEditable: isE2E
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.customs_vat'
        }),
        dataIndex: 'customsVatValueWithCurrency',
        isEditable: isE2E,
        access: !!isE2E
      },
      {
        title: fmt({ id: 'action_center.order_details.package_type' }),
        dataIndex: 'specs.packageType',
        access: !isE2E
      },
      {
        title: fmt({ id: `shipments.new_order.form_labels.order_reference` }),
        dataIndex: 'businessReference'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.doemstic_tracking_number'
        }),
        dataIndex: 'destinationDomesticOrder',
        access: isE2E
      },
      {
        title: fmt({ id: 'shipments.new_order.customer_details.order_notes' }),
        dataIndex: 'notes',
        fullWidth: true
      }
    ]
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.delivery_details.sections.flight_details'
    }),
    access: isE2E,
    className: 'br-international-delivery-details__flight-section',
    icon: <BostaBoxIcon />,
    fields: [
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.flight_no'
        }),
        dataIndex: 'flightDetails.flightNumber'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.flight_date'
        }),
        dataIndex: 'flightDetails.flightDate'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.master_airway_bill'
        }),
        dataIndex: 'flightDetails.masterAWB'
      }
    ]
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.delivery_details.sections.customer_details'
    }),
    fields: [
      {
        title: fmt({
          id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.reciever_name`
        }),
        dataIndex: 'receiver.fullName'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.work_address'
        }),
        dataIndex: 'isWorkAddress'
      },
      {
        title: fmt({
          id: `orders.shipment_details.shipment_details_summary.table_cells.customer_info_table.phone_number`
        }),
        dataIndex: 'receiver.phone'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.second_phone'
        }),
        dataIndex: 'receiver.secondPhone'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.address'
        }),
        dataIndex: 'address',
        isEditable: isE2E
      }
    ]
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.delivery_details.sections.pickup_details'
    }),
    fields: [
      {
        title: fmt({
          id: `pickups.create_edit_pickup.form_labels.pickup_date`
        }),
        dataIndex: 'collectedFromBusiness',
        fullWidth: true
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.pickup_location_name'
        }),
        dataIndex: 'pickupAddress.businessLocationName',
        fullWidth: true
      },
      {
        title: fmt({ id: 'pickups.pickup_details.pickup_info.address' }),
        dataIndex: 'formattedPickupAddress',
        fullWidth: true
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.courier_name'
        }),
        dataIndex: 'collectedFromBusinessBy.name'
      },
      {
        title: fmt({
          id: 'international_orders.deliveries.delivery_details.fields.courier_phone'
        }),
        dataIndex: 'collectedFromBusinessBy.phone'
      },
      {
        title: fmt({ id: 'shipments.new_order.form_labels.return_location' }),
        dataIndex: RETURN_OPTION_KEY,
        fullWidth: true,
        isEditable: isE2E,
        access: isE2E
      }
    ]
  },
  {
    title: fmt({
      id: 'international_orders.deliveries.delivery_details.sections.pricing_details'
    }),
    access: isE2E,
    fields: [
      {
        title: fmt({
          id: `international_orders.deliveries.delivery_details.fields.pricing_tier`
        }),
        dataIndex: 'pricing.tier.name'
      },
      {
        title: fmt({
          id: `international_orders.deliveries.delivery_details.fields.pricing_with_vat`
        }),
        dataIndex: 'pricing.priceAfterVat'
      },
      {
        title: fmt({
          id: `international_orders.deliveries.delivery_details.fields.pricing_without_vat`
        }),
        dataIndex: 'pricing.priceBeforeVat'
      },
      {
        title: fmt({
          id: `international_orders.deliveries.delivery_details.fields.package_weight`
        }),
        dataIndex: 'weight'
      },
      {
        title: fmt({
          id: `international_orders.deliveries.delivery_details.fields.pickup_country`
        }),
        dataIndex: 'pickupAddress.country.name'
      },
      {
        title: fmt({
          id: `international_orders.deliveries.delivery_details.fields.dropoff_country`
        }),
        dataIndex: 'dropOffAddress.country.name'
      }
    ]
  }
];

export const INTERNATIONAL_DELIVERIES_FILTERS = (counts) => [
  {
    value: '',
    label: fmt({ id: 'common.all' }),
    count: counts.all,
    color: 'teal'
  },
  {
    value: INTERNATIONAL_DELIVERY_STATUS_CODES.CREATED,
    label: fmt({
      id: 'international_orders.deliveries.states.created'
    }),
    count: counts.created,
    color: 'yellow',
    icon: <Box />,
    fill: false
  },
  {
    value: INTERNATIONAL_DELIVERY_STATUS_CODES.IN_PROGRESS,
    label: fmt({
      id: 'international_orders.deliveries.states.picked_up'
    }),
    count: counts.pickedUp,
    color: 'purple',
    icon: <Clock />,
    fill: false
  },
  {
    value: INTERNATIONAL_DELIVERY_STATUS_CODES.RECEIVED_AT_WAREHOUSE,
    label: fmt({
      id: 'international_orders.deliveries.states.received_at_warehouse'
    }),
    count: counts.receivedAtWarehouse,
    color: 'teal',
    icon: <Inventory />
  },
  {
    value: INTERNATIONAL_DELIVERY_STATUS_CODES.TRANSFERRED,
    label: fmt({
      id: 'international_orders.deliveries.states.transferred'
    }),
    count: counts.transferred,
    color: 'blue',
    icon: <InTransitIcon />
  },
  {
    value: INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT,
    label: fmt({
      id: 'international_orders.deliveries.states.in_transit'
    }),
    count: counts.inTransit,
    color: 'blue',
    icon: <Rocket />,
    fill: false
  },
  {
    value: INTERNATIONAL_DELIVERY_STATUS_CODES.COMPLETED,
    label: fmt({
      id: 'international_orders.deliveries.states.completed'
    }),
    count: counts.completed,
    color: 'green',
    icon: <Check />
  },
  {
    value: INTERNATIONAL_DELIVERY_STATUS_CODES.CANCELED,
    label: fmt({
      id: 'international_orders.deliveries.states.canceled'
    }),
    count: counts.canceled,
    color: 'gray',
    icon: <Close />
  }
];

export const DELIVERY_COUNTS_MAPPING = {
  0: 'all',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.CREATED]: 'created',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.IN_PROGRESS]: 'pickedUp',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.RECEIVED_AT_WAREHOUSE]:
    'receivedAtWarehouse',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.IN_TRANSIT]: 'inTransit',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.TRANSFERRED]: 'transferred',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.COMPLETED]: 'completed',
  [INTERNATIONAL_DELIVERY_STATUS_CODES.CANCELED]: 'canceled'
};

export const INTERNATIONAL_DELIVERY_UPDATE_SUCCESS_MESSAGE = (count) => ({
  [INTERNATIONAL_DELIVERY_UPDATE_TYPES.TRANSFER]: fmt(
    { id: 'international_orders.transfer.table.transfer_success' },
    {
      count
    }
  ),
  [INTERNATIONAL_DELIVERY_UPDATE_TYPES.RECEIVE_AT_WAREHOUSE]: fmt(
    { id: 'international_orders.transfer.table.receive_success' },
    {
      count
    }
  )
});

export const INTERNATIONAL_DELIVERY_ACTIONABLE_CODES = {
  [INTERNATIONAL_DELIVERY_UPDATE_TYPES.TRANSFER]: [
    INTERNATIONAL_DELIVERY_STATUS_CODES.RECEIVED_AT_WAREHOUSE
  ],
  [INTERNATIONAL_DELIVERY_UPDATE_TYPES.RECEIVE_AT_WAREHOUSE]: [
    INTERNATIONAL_DELIVERY_STATUS_CODES.CREATED,
    INTERNATIONAL_DELIVERY_STATUS_CODES.IN_PROGRESS
  ]
};

export const INTERNATIONAL_DELIVERY_ACTION_ERROR = {
  [INTERNATIONAL_DELIVERY_UPDATE_TYPES.TRANSFER]: fmt({
    id: 'international_orders.transfer.scan.trasnfer_scan_error'
  }),
  [INTERNATIONAL_DELIVERY_UPDATE_TYPES.RECEIVE_AT_WAREHOUSE]: fmt({
    id: 'international_orders.transfer.scan.receive_scan_error'
  })
};

export const FLIGHT_DETAILS_DATE_FORMAT = 'ddd, D MMM [at] h:mm A';
export const FLIGHT_DETAILS_PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const VAT_TYPE_OPTIONS = [
  { label: 'DDU', value: 'DDU' },
  { label: 'DDP', value: 'DDP' }
];

export const INTERNATIONAL_DELIVERIES_E2E_DATE_FILTERS = [
  ['createdAt', 'sourcePickedUpAt', 'sourceTransferredAt'],
  ['clearedFromCustomsAt', 'completedAt']
];

export const INTERNATIONAL_DELIVERY_E2E_MULTI_SEARCH_KEYS = [
  'internationalOrDomesticTN',
  'receiverPhone',
  'id'
];

export const INTERNATIONAL_SOURCE_COUNTRIES = [
  ALL_COUNTRIES[0],
  ALL_COUNTRIES[2]
];

export const INTERNATIONAL_DESTINATION_COUNTRIES = [
  ALL_COUNTRIES[1],
  QATAR_COUNTRY_DATA,
  OMAN_COUNTRY_DATA,
  KUWAIT_COUNTRY_DATA
];

export const INTERNATIONAL_BILL_TYPE = {
  INVOICE_BILL: 'invoiceBill',
  VAT_RECEIPT: 'vatReceipt'
};

export const INVOICE_BILL_TYPES = {
  PREFILLED: 'prefilled',
  EMPTY: 'empty'
};

export const INTERNATIONAL_DELIVERY_EVENTS = {
  INTERNATIONAL_ORDER_CREATION: 'INTERNATIONAL_ORDER_CREATION',
  INTERNATIONAL_PICKUP_SCHEDULED: 'INTERNATIONAL_PICKUP_SCHEDULED',
  INTERNATIONAL_PICKED_UP: 'INTERNATIONAL_PICKED_UP',
  INTERNATIONAL_RECEIVED_AT_WAREHOUSE: 'INTERNATIONAL_RECEIVED_AT_WAREHOUSE',
  INTERNATIONAL_TRANSFER: 'INTERNATIONAL_TRANSFER',
  INTERNATIONAL_IN_TRANSIT_SOURCE: 'INTERNATIONAL_IN_TRANSIT_SOURCE',
  INTERNATIONAL_IN_TRANSIT_DESTINATION: 'INTERNATIONAL_IN_TRANSIT_DESTINATION',
  INTERNATIONAL_CLEARED_FROM_CUSTOMS: 'INTERNATIONAL_CLEARED_FROM_CUSTOMS',
  INTERNATIONAL_UPDATE_DROP_OFF_ADDRESS:
    'INTERNATIONAL_UPDATE_DROP_OFF_ADDRESS',
  INTERNATIONAL_UPDATE_CUSTOM_VAT_TYPE: 'INTERNATIONAL_UPDATE_CUSTOM_VAT_TYPE',
  INTERNATIONAL_UPDATE_RETURN_ADDRESS: 'INTERNATIONAL_UPDATE_RETURN_ADDRESS',
  INTERNATIONAL_EXCEPTION: 'INTERNATIONAL_EXCEPTION',
  NTERNATIONAL_UPDATE_CUSTOM_VAT_VALUE: 'INTERNATIONAL_UPDATE_CUSTOM_VAT_VALUE',
  INTERNATIONAL_COMPLETED: 'INTERNATIONAL_COMPLETED',
  INTERNATIONAL_OUT_FOR_DELIVERY: 'INTERNATIONAL_OUT_FOR_DELIVERY',
  INTERNATIONAL_TERMINATED: 'INTERNATIONAL_TERMINATED'
};

export const INTERNATIONAL_DELIVERY_EVENTS_MAPPING = {
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_ORDER_CREATION]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.created'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_PICKUP_SCHEDULED]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.pickup_scheduled'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_PICKED_UP]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.picked_up'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_RECEIVED_AT_WAREHOUSE]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.received_at_warehouse'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_TRANSFER]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.ready_for_transfer'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_IN_TRANSIT_SOURCE]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.in_transit_source'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_IN_TRANSIT_DESTINATION]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.in_transit'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_CLEARED_FROM_CUSTOMS]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.completed'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_UPDATE_DROP_OFF_ADDRESS]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.address_updated'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_UPDATE_CUSTOM_VAT_TYPE]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.custom_vat_updated'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_UPDATE_RETURN_ADDRESS]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.return_location_updated'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_EXCEPTION]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.received_exception'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.NTERNATIONAL_UPDATE_CUSTOM_VAT_VALUE]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.custom_vat_value_updated'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_COMPLETED]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.international_completed'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_OUT_FOR_DELIVERY]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.international_out_for_delivery'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_TERMINATED]: fmt({
    id: 'international_orders.deliveries.delivery_details.logs.international_termianted'
  })
};

export const INTERNATIONAL_DELIVERY_EVENT_DATA_LABEL = ({
  eventData,
  deliveryDetails
} = {}) => ({
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_UPDATE_CUSTOM_VAT_TYPE]: fmt(
    {
      id: 'international_orders.deliveries.delivery_details.logs.custom_vat_updated_to'
    },
    {
      value: eventData?.customVATType
    }
  ),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_EXCEPTION]: eventData?.code && (
    <div>
      {
        exceptionsMapping(
          {
            ...deliveryDetails,
            type: ORDER_TYPES_VALUE.DELIVER
          },
          eventData.code
        )[eventData.code]
      }
      {eventData?.exceptionProofUrl && (
        <BRButton
          type="link-color"
          label={fmt({
            id: 'pickups.pickup_details.proof_of_exception'
          })}
          onClick={() => {
            openModal(BRPreviewImage, {
              previewImageTitle: fmt({
                id: 'pickups.pickup_details.proof_of_exception'
              }),
              previewImage: eventData?.exceptionProofUrl
            });
          }}
        />
      )}
    </div>
  ),
  [INTERNATIONAL_DELIVERY_EVENTS.NTERNATIONAL_UPDATE_CUSTOM_VAT_VALUE]: fmt(
    {
      id: 'international_orders.deliveries.delivery_details.logs.custom_vat_value_updated_to'
    },
    {
      value: eventData?.customVATValue
    }
  )
});

export const INTERNATIONAL_ORDER_LOCATION = {
  SOURCE: 'source',
  DESTINATION: 'destination'
};

export const RETURN_TYPES = {
  RETURN_ADDRESS: 'returnAddress',
  RETURN_TO_FULFILLMENT: 'returnToFulfillmentHub',
  TERMINATE: 'terminateIfFailed',
  RETURN_TO_ORIGIN: 'returnToDefaultLocation'
};

export const RETURN_LOCATIONS_OPTIONS = (deliveryDetails) => [
  {
    value: RETURN_TYPES.RETURN_ADDRESS,
    label: fmt({
      id: 'international_orders.deliveries.delivery_details.return_options.return_to_address'
    })
  },
  {
    value: RETURN_TYPES.RETURN_TO_FULFILLMENT,
    label: fmt({
      id: 'international_orders.deliveries.delivery_details.return_options.return_to_fulfillment'
    }),
    disabled: !isReturnToFulfillmentAvailable(
      get(deliveryDetails, 'pickupAddress.country.code')
    )
  },
  {
    value: RETURN_TYPES.TERMINATE,
    label: fmt({
      id: 'international_orders.deliveries.delivery_details.return_options.terminate'
    })
  },
  {
    value: RETURN_TYPES.RETURN_TO_ORIGIN,
    label: fmt({
      id: 'international_orders.deliveries.delivery_details.return_options.return_to_origin'
    })
  }
];
