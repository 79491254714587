import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Form, Select } from 'antd';

import { getPOStatistics } from 'services/fulfillment';
import { SllrWrapper } from 'contexts/sllr.context';
import {
  FULFILLMENT_INBOUND_PO_MAIN_PATH,
  PO_STATE_IDS,
  PUT_AWAY_KEY
} from 'constants/fulfillment';
import { getPutawayOptions, isInPutawayState } from 'utils/fulfillment';

import FxFStatistics from 'components/NewFulfillment/CommonComponents/FxFStatistics/FxFStatistics';
import FxFFilters from 'components/NewFulfillment/CommonComponents/FxFFilters/FxFFilters';
import POTable from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/POTable/POTable';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import BRButton from 'components/BRButton/BRButton';
import CreatedBy from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/CreatedBy/CreatedBy';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';
import { ReactComponent as ConfirmIcon } from 'assets/bosta-icons/ConfirmIcon.svg';
import { ReactComponent as QualityCheckIcon } from 'assets/bosta-icons/QualityCheckIcon.svg';
import { ReactComponent as CompletedIcon } from 'assets/bosta-icons/CompletedIcon.svg';
import { ReactComponent as CanceledIcon } from 'assets/bosta-icons/CanceledIcon.svg';
import { ReactComponent as ProductBoxIcon } from 'assets/bosta-icons/ProductBoxIcon.svg';

import './POList.less';

const POList = ({ intl, history }) => {
  const { isLoading, setIsLoading } = useContext(SllrWrapper);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const [poFilters, setPOFilters] = useState({
    state: null
  });
  const [isFirstFetchDone, setIsFirstFetchDone] = useState(false);

  const { CREATED, CONFIRMED, COMPLETED, CANCELED } = PO_STATE_IDS;

  const getStatisticsListToRender = ({
    total,
    createdCount,
    confirmedCount,
    readyToPutAwayCount,
    partiallyPutAwayCount,
    putAwayCount,
    canceledCount
  }) => [
    {
      key: 'total',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_statistics.titles.total'
      }),
      content: total,
      stateIdFilterValue: null
    },
    {
      key: 'created',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_statistics.titles.confirm'
      }),
      content: createdCount,
      icon: <ConfirmIcon />,
      stateIdFilterValue: CREATED
    },
    {
      key: 'confirmed',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_statistics.titles.quality_check'
      }),
      content: confirmedCount,
      icon: <QualityCheckIcon />,
      stateIdFilterValue: CONFIRMED
    },
    {
      key: 'allPutaway',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_statistics.titles.putaway'
      }),
      content: readyToPutAwayCount + partiallyPutAwayCount || 0,
      icon: <ProductBoxIcon />,
      typeFilterValue: PUT_AWAY_KEY
    },
    {
      key: 'completed',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_statistics.titles.completed'
      }),
      content: putAwayCount,
      icon: <CompletedIcon />,
      stateIdFilterValue: COMPLETED
    },
    {
      key: 'canceled',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_statistics.titles.canceled'
      }),
      content: canceledCount,
      icon: <CanceledIcon />,
      stateIdFilterValue: CANCELED
    }
  ];

  const updateFilters = (filter) => {
    if (isFirstFetchDone) {
      setPOFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  const onPutawayFilterChange = (value) => {
    updateFilters({
      state: !isNaN(value) ? value : undefined,
      type: isNaN(value) ? value : undefined
    });
  };

  const renderPutawayFilter = () =>
    isInPutawayState({
      type: poFilters.type,
      stateId: poFilters.state
    }) && (
      <Form.Item
        label={intl.formatMessage({
          id: 'fulfillment_inbound.po_filters.putaway.label'
        })}
      >
        <Select
          options={getPutawayOptions()}
          onChange={onPutawayFilterChange}
          defaultValue={PUT_AWAY_KEY}
        />
      </Form.Item>
    );

  const renderExtraFilters = () => (
    <>
      <CreatedBy updateFilters={updateFilters} />
      {renderPutawayFilter()}
    </>
  );

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'fulfillment_inbound.title'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div>
            <FxFStatistics
              updateFilters={updateFilters}
              poFilters={poFilters}
              fetchData={getPOStatistics}
              getStatisticsListToRender={getStatisticsListToRender}
            />
            <FxFFilters
              updateFilters={updateFilters}
              renderExtraFilters={renderExtraFilters}
            />
            <div className="br-create-new-po-btn">
              <BRButton
                type="primary"
                label={intl.formatMessage({
                  id: 'fulfillment_inbound.create_new_po_btn'
                })}
                prefixIcon={<PlusIcon />}
                onClick={() =>
                  history.push(`${FULFILLMENT_INBOUND_PO_MAIN_PATH}/create`)
                }
              />
            </div>
            <POTable
              poFilters={poFilters}
              isFirstFetchDone={isFirstFetchDone}
              setIsFirstFetchDone={setIsFirstFetchDone}
            />
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(withRouter(POList));
