import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Tag, Tooltip } from 'antd';
import classnames from 'classnames';

import { OFDDeliveriesSearch } from 'services/hubs';
import {
  ATTEMPTS_STATE,
  ATTEMPTS_STATE_COLORS,
  OFD_SEARCH_DATE_FORMAT,
  OFD_SEARCH_TIME_FORMAT,
  OFD_TABLE_LIMIT
} from 'constants/dispatching';
import { cleanEmptyString } from 'utils/helpers';

import BRTable from 'components/BRTable/BRTable';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Tooltip.svg';
import { ReactComponent as CircleCheck } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as CircleClear } from 'assets/bosta-icons/Circle-clear.svg';

import './OFDSearchTable.less';

const OFDSearchTable = ({
  appliedFilters,
  getSuccessRate,
  setGetSuccessRate,
  intl
}) => {
  const [successRate, setSuccessRate] = useState(0);
  const [total, setTotal] = useState(0);

  const refreshTableRef = useRef('');

  useEffect(() => {
    appliedFilters && refreshTable();
  }, [appliedFilters]);

  const acceptMethods = (refreshMethod) => {
    refreshTableRef.current = refreshMethod;
  };

  const refreshTable = () => {
    refreshTableRef.current();
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.table_columns.tracking_number'
      }),
      dataIndex: 'trackingNumber',
      render: (trackingNumber) => (
        <Link to={`/deliveries/${trackingNumber}/details`}>
          {trackingNumber}
        </Link>
      )
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.table_columns.business'
      }),
      dataIndex: 'business',
      render: (business = {}) => (
        <Link to={`/businesses/${business._id}/details`}>{business.name}</Link>
      )
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.table_columns.attempt_type'
      }),
      dataIndex: 'type',
      render: (type) =>
        type
          ? intl.formatMessage({
              id: `monitor_hub_operations.ofd_search.attempts_types.${type}`
            })
          : intl.formatMessage({ id: 'common.empty_field' })
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.table_columns.attempt_date'
      }),
      dataIndex: 'attemptDate',
      render: (attemptDate) => (
        <div className="br-ofd-search__attempt-date">
          {dayjs(attemptDate).format(OFD_SEARCH_DATE_FORMAT)}
          <span className="br-ofd-search__attempt-date-time caption">
            {dayjs(attemptDate).format(OFD_SEARCH_TIME_FORMAT)}
          </span>
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.table_columns.route'
      }),
      dataIndex: 'routeId'
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.table_columns.hub'
      }),
      dataIndex: 'warehouse',
      render: (warehouse, record) => (
        <div className="br-ofd-search__hub-star-container">
          {warehouse?.name}
          <span className="br-ofd-search__star caption">
            {record.star?.name}
          </span>
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'pickups.pickup_request.table_columns.fm_district_code'
      }),
      dataIndex: 'districtFMCode',
      render: (districtFMCode) =>
        districtFMCode || intl.formatMessage({ id: 'common.empty_field' })
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.table_columns.attempt_state'
      }),
      dataIndex: 'state',
      render: (state) => (
        <Tag
          className={classnames(
            'br-ofd-search-state__tag',
            ATTEMPTS_STATE_COLORS[state]
          )}
        >
          {ATTEMPTS_STATE[state]}
        </Tag>
      )
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.table_columns.international_order'
      }),
      dataIndex: 'internationalTrackingNumber',
      align: 'center',
      render: (internationalTrackingNumber) => (
        <div
          className={classnames('br-ofd-search__check', {
            'br-ofd-search__check__valid': internationalTrackingNumber
          })}
        >
          {internationalTrackingNumber ? <CircleCheck /> : <CircleClear />}
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.table_columns.exception'
      }),
      dataIndex: 'exception',
      align: 'center',
      render: (exception) =>
        exception ? (
          <Tooltip title={exception.reason}>
            <TooltipIcon />
          </Tooltip>
        ) : (
          intl.formatMessage({ id: 'common.empty_field' })
        )
    },
    {
      title: intl.formatMessage({
        id: 'monitor_hub_operations.ofd_search.table_columns.exception_validation'
      }),
      dataIndex: 'exception',
      align: 'center',
      render: (exception) => {
        const isFakeException = exception?.whatsAppVerification.fakeAttempt;
        const isValidException = exception?.whatsAppVerification.verified;

        if (!isFakeException && !isValidException) {
          return '-';
        }

        return (
          <Tooltip
            title={intl.formatMessage({
              id: 'monitor_hub_operations.ofd_search.validated_by_whatsapp'
            })}
          >
            <div
              className={classnames(
                'br-ofd-search__check br-ofd-search__check',
                {
                  'br-ofd-search__check__valid': isValidException
                }
              )}
            >
              {isValidException ? <CircleCheck /> : <CircleClear />}
            </div>
          </Tooltip>
        );
      }
    }
  ];

  const getData = async ({ page }) => {
    if (!appliedFilters) {
      return;
    }

    try {
      const { data, attemptsuccessRate = {} } = await OFDDeliveriesSearch(
        cleanEmptyString({
          ...appliedFilters,
          successRate: getSuccessRate,
          pageNumber: page
        })
      );

      if (getSuccessRate) {
        setSuccessRate(attemptsuccessRate.successRate || 0);
        setTotal(attemptsuccessRate.total || 0);
        setGetSuccessRate(null);
      }

      return {
        list: data,
        total: getSuccessRate ? attemptsuccessRate.total : total
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const tableEmptyState = () => {
    return (
      <BREmptyState
        title={intl.formatMessage({
          id: 'monitor_hub_operations.ofd_search.empty_state_title'
        })}
      />
    );
  };
  return (
    <div className="br-ofd-search-table__container">
      <div className="br-ofd-search__cards-container">
        <div className="br-ofd-search-total-card__container">
          <div className="br-ofd-search-total-card__title display-md">
            <Tooltip
              title={intl.formatMessage({
                id: 'monitor_hub_operations.ofd_search.total_attempts_tooltip'
              })}
            >
              {intl.formatMessage({
                id: 'monitor_hub_operations.ofd_search.total_attempts'
              })}
            </Tooltip>
          </div>
          <div className="br-ofd-search-total-card__value display-sm">
            {total}
          </div>
        </div>

        <div className="br-ofd-search-asr-card__container">
          <div className="br-ofd-search-asr-card__title display-md">
            <Tooltip
              title={intl.formatMessage({
                id: 'monitor_hub_operations.ofd_search.asr_tooltip'
              })}
            >
              {intl.formatMessage({
                id: 'monitor_hub_operations.ofd_search.asr'
              })}
            </Tooltip>
          </div>
          <div className="br-ofd-search-asr-card__value display-sm">
            {successRate.toFixed(2)}%
          </div>
        </div>
      </div>
      <BRTable
        columns={columns}
        listFunction={getData}
        showFilter={false}
        shareMethods={acceptMethods}
        pageLimit={OFD_TABLE_LIMIT}
        emptyState={tableEmptyState}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default injectIntl(OFDSearchTable);
