import { useContext, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { OpsControl } from 'contexts/ops-control.context';
import {
  HUBS_TABLE_COLUMNS,
  PENDING_TRANSIT_DATE_FORMAT,
  TOGGLE_BUTTON_INITAL_VALUE,
  TOGGLE_BUTTONS_LIST
} from 'constants/pending-transit';
import {
  exportInTransitReport,
  inTransitDeliveriesCount
} from 'services/ops-control';
import { getPaginationCount } from 'utils/helpers';
import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/helper';
import { downloadAsXlsx } from 'utils/download';

import PendingTransitFilter from './PendingTransitFilter/PendingTransitFilter';
import BRToggleButton from 'components/BRToggleButton/BRToggleButton';
import SingleInfoCard from '../SingleCardInfo/SingleCardInfo';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import HubDeliveries from './HubDeliveries/HubDeliveries';

import { ReactComponent as HubsIcon } from 'assets/bosta-icons/hubs.svg';

import './PendingTransit.less';
import { getEscalationAge } from 'utils/escalation';

const PendingTransit = ({ intl }) => {
  const refreshTable = useRef();

  const [currentHub, setCurrentHub] = useState();
  const [toggleButtonValue, setToggleButtonValue] = useState(
    TOGGLE_BUTTON_INITAL_VALUE
  );
  const [HubDetails, setHubDetails] = useState();
  const [totalPendingTransit, setTotalPendingTransit] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalPendingTransitPlus, setTotalPendingTrasitPlus] = useState(0);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const { allHubs } = useContext(OpsControl);

  const getPendingValues = ({
    toggleButton,
    el,
    pendingFromForwardValue,
    pendingToForwardValue,
    PendingFromReturnValue,
    pendingToReturnValue,
    maxOrderForwardAging,
    maxOrderReturnAging,
    receivedForwardDate,
    receivedReturnDate
  }) => {
    const values = {
      pendingFromValue: 0,
      pendingToValue: 0,
      pendingFromPlusValue: 0,
      pendingToPlusValue: 0,
      maxOrderAging: ''
    };

    switch (toggleButton) {
      case TOGGLE_BUTTONS_LIST[1].value:
        values.pendingFromValue = pendingFromForwardValue;
        values.pendingToValue = pendingToForwardValue;
        values.pendingFromPlusValue =
          el?.outgoing_forward_deliveries_exceeded_duration;
        values.pendingToPlusValue =
          el?.incoming_forward_deliveries_exceeded_duration;
        values.maxOrderAging = maxOrderForwardAging;
        break;

      case TOGGLE_BUTTONS_LIST[2].value:
        values.pendingFromValue = PendingFromReturnValue;
        values.pendingToValue = pendingToReturnValue;
        values.pendingFromPlusValue =
          el?.outgoing_return_deliveries_exceeded_duration;
        values.pendingToPlusValue =
          el?.incoming_return_deliveries_exceeded_duration;
        values.maxOrderAging = maxOrderReturnAging;
        break;

      default:
        values.pendingFromValue =
          pendingFromForwardValue + PendingFromReturnValue;
        values.pendingToValue = pendingToForwardValue + pendingToReturnValue;
        values.pendingFromPlusValue =
          el?.outgoing_forward_deliveries_exceeded_duration +
          el?.outgoing_return_deliveries_exceeded_duration;
        values.pendingToPlusValue =
          el?.incoming_forward_deliveries_exceeded_duration +
          el?.incoming_return_deliveries_exceeded_duration;
        values.maxOrderAging =
          el?.oldest_received_at_forward || el?.oldest_received_at_return
            ? receivedForwardDate?.isBefore(receivedReturnDate)
              ? receivedForwardDate
              : receivedReturnDate
            : intl.formatMessage({ id: 'common.empty_field' });
    }

    return values;
  };

  const formatTableData = (data, toggleButton, selectedHub) => {
    let totalPendingSum = 0;
    let totalPendingPlusSum = 0;
    const formattedData = data?.map((el) => {
      const pendingFromForwardValue =
        el?.outgoing_forward_deliveries_not_exceeded_duration +
        el?.outgoing_forward_deliveries_exceeded_duration;

      const PendingFromReturnValue =
        el?.outgoing_return_deliveries_not_exceeded_duration +
        el?.outgoing_return_deliveries_exceeded_duration;

      const pendingToForwardValue =
        el?.incoming_forward_deliveries_not_exceeded_duration +
        el?.incoming_forward_deliveries_exceeded_duration;

      const pendingToReturnValue =
        el?.incoming_return_deliveries_not_exceeded_duration +
        el?.incoming_return_deliveries_exceeded_duration;

      const receivedReturnDate = el?.oldest_received_at_return
        ? dayjs(el?.oldest_received_at_return)
        : dayjs();

      const receivedForwardDate = el?.oldest_received_at_forward
        ? dayjs(el?.oldest_received_at_forward)
        : dayjs();

      const maxOrderForwardAging = el?.oldest_received_at_forward
        ? receivedForwardDate
        : intl.formatMessage({ id: 'common.empty_field' });

      const maxOrderReturnAging = el?.oldest_received_at_return
        ? receivedReturnDate
        : intl.formatMessage({ id: 'common.empty_field' });

      const {
        pendingFromValue,
        pendingToValue,
        pendingFromPlusValue,
        pendingToPlusValue,
        maxOrderAging
      } = getPendingValues({
        toggleButton,
        el,
        pendingFromForwardValue,
        pendingToForwardValue,
        PendingFromReturnValue,
        pendingToReturnValue,
        maxOrderForwardAging,
        maxOrderReturnAging,
        receivedForwardDate,
        receivedReturnDate
      });
      const totalPending = selectedHub
        ? pendingFromValue + pendingToValue
        : pendingFromValue;

      const totalPendingPlus = selectedHub
        ? pendingFromPlusValue + pendingToPlusValue
        : pendingFromPlusValue;

      totalPendingSum += totalPending;
      totalPendingPlusSum += totalPendingPlus;

      return {
        hub: (
          <div
            style={{ width: '200px' }}
            onClick={() => {
              setCurrentHub();
              setHubDetails({ id: el?.warehouse_id, name: el?.warehouse_name });
            }}
            className="hub-deliveries__hubname-column"
          >
            {el.warehouse_name}
          </div>
        ),
        pending_from: pendingFromValue,
        pending_to: pendingToValue,
        pending_from_plus: pendingFromPlusValue,
        pending_to_plus: pendingToPlusValue,
        total_pending: pendingFromValue + pendingToValue,
        total_pending_plus: pendingFromPlusValue + pendingToPlusValue,
        max_order_age:
          Math.ceil(dayjs().diff(dayjs(maxOrderAging), 'day', true)) ||
          intl.formatMessage({ id: 'common.empty_field' })
      };
    });
    setTotalPendingTransit(totalPendingSum);
    setTotalPendingTrasitPlus(totalPendingPlusSum);
    return formattedData;
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const changeToggleButton = (value) => {
    setToggleButtonValue(value);
    refreshTable.current({
      filterValues: {
        toggleButton: value,
        selectedDate: selectedDate,
        currentHub: currentHub
      }
    });
  };

  const changeDateValue = (dateValue) => {
    setSelectedDate(dateValue);
    refreshTable.current({
      filterValues: {
        toggleButton: toggleButtonValue,
        selectedDate: dateValue,
        currentHub: currentHub
      }
    });
  };

  const fetchTableData = async ({ page, limit, filterValues }) => {
    try {
      setLoading(true);
      const payload = {
        date: filterValues?.selectedDate
          ? filterValues?.selectedDate?.format(PENDING_TRANSIT_DATE_FORMAT)
          : dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(filterValues?.currentHub && { hubId: filterValues?.currentHub })
      };

      const res = await inTransitDeliveriesCount(payload);
      const result = res.data?.slice((page - 1) * limit, limit);
      setLoading(false);

      return {
        list: formatTableData(
          res.data,
          filterValues?.toggleButton || toggleButtonValue,
          filterValues?.currentHub
        ),
        total: getPaginationCount({
          page,
          result: result,
          pageSize: limit
        })
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleHubChange = (hubValue) => {
    setCurrentHub(hubValue);
    refreshTable.current({
      filterValues: {
        toggleButton: toggleButtonValue,
        selectedDate: selectedDate,
        currentHub: hubValue
      }
    });
  };

  const exportTableData = async () => {
    try {
      const payload = {
        date: selectedDate
          ? selectedDate?.format(PENDING_TRANSIT_DATE_FORMAT)
          : dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(currentHub && { hubId: currentHub }),
        ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[1].value && {
          forwardDeliveriesOnly: true
        }),
        ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[2].value && {
          forwardDeliveriesOnly: false
        })
      };
      const data = await exportInTransitReport(payload);
      downloadAsXlsx(data, `inTransit_report_${selectedDate || dayjs()}.xlsx`);
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <div className="pending-transit__container">
      {!HubDetails ? (
        <>
          <p className="display-xs pending-transit__title">
            {intl.formatMessage({ id: 'ops_control.pending_transit.header' })}
          </p>

          <div className="pending-transit__header">
            <BRToggleButton
              loading={loading}
              buttonsList={TOGGLE_BUTTONS_LIST}
              setValue={setToggleButtonValue}
              value={toggleButtonValue}
              changeToggleButton={changeToggleButton}
            />
            <PendingTransitFilter
              loading={loading}
              intl={intl}
              allHubs={allHubs}
              currentHub={currentHub}
              selectedDate={selectedDate}
              setCurrentHub={setCurrentHub}
              setSelectedDate={setSelectedDate}
              handleHubChange={handleHubChange}
              changeDateValue={changeDateValue}
            />
          </div>
          <div className="pending-transit__cards-container">
            <SingleInfoCard
              loading={loading}
              data={totalPendingTransit}
              title={intl.formatMessage({
                id: 'ops_control.pending_transit.totat_pending_orders'
              })}
            />

            <SingleInfoCard
              loading={loading}
              data={totalPendingTransitPlus}
              title={intl.formatMessage({
                id: 'ops_control.pending_transit.totat_pending_orders_plus'
              })}
            />
          </div>
          <div className="pending-transit__hubs-table-container">
            <BRTable
              exportListFileFunction={exportTableData}
              shareMethods={acceptMethods}
              listFunction={fetchTableData}
              pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
              columns={HUBS_TABLE_COLUMNS}
              hideFilterButton
              title={
                <div className="pending-transit__hubs-table-title">
                  <HubsIcon />
                  {intl.formatMessage({
                    id: 'ops_control.pending_transit.hubs_table.title'
                  })}
                </div>
              }
            />
          </div>
        </>
      ) : (
        <HubDeliveries
          allHubs={allHubs}
          hubDetails={HubDetails}
          setHubDetails={setHubDetails}
          intl={intl}
        />
      )}
    </div>
  );
};
export default injectIntl(PendingTransit);
