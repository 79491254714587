import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Tag, Form, Input } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

import { createHubTransfer, getSeal, searchSeal } from 'services/hubs';
import {
  HUB_TRANSFER_DISPATCH_TABLE_FILTERS,
  HUB_TRANSFER_DISPATCHING_TYPES,
  PENDING,
  SEALS,
  SEAL_SCAN_TYPE,
  SEAL_STATE,
  SEAL_STATE_COLOR,
  SEAL_DISPATCH_LIMIT
} from 'constants/hubs';
import { HubsContext } from 'contexts/hub.context';
import Sounds from 'common/Sounds';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import HubSelectors from '../HubSelectors/HubSelectors';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import BRSwappingTables from 'components/BRSwappingTables/BRSwappingTables';
import BRButton from 'components/BRButton/BRButton';
import DateTime from 'containers/Hub/SealsLogs/components/DateTime/DateTime';

import { ReactComponent as AlertIcon } from 'assets/bosta-icons/alert-triangle.svg';

import './DispatchSeals.less';

const DEFAULT_PAGE_SIZE = 50;

const DispatchSeals = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sourceHubId, setSourceHubId] = useState(null);
  const [destinationHubId, setDestinationHubId] = useState(null);
  const [destinationHubsOptions, setDestinationHubsOptions] = useState([]);
  const [sealsData, setSealsData] = useState([]);
  const [scannedSeals, setScannedSeals] = useState([]);
  const [masterSeal, setMasterSeal] = useState(null);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerProps, setErrorBannerProps] = useState({});

  const formRef = useRef('');
  const tableRef = useRef('');
  const sealNumberInputRef = useRef('');
  const masterSealInputRef = useRef('');

  const { beep, warning } = Sounds;

  const { allHubs, scopedHubs } = useContext(HubsContext);

  const acceptMethods = (refreshMethod) => {
    tableRef.current = refreshMethod;
  };

  const refreshTable = () => {
    tableRef.current();
  };

  useEffect(() => {
    refreshTable();
  }, [allHubs, sourceHubId]);

  useEffect(() => {
    setScannedSeals([]);

    if (destinationHubId) {
      const payload = {
        pageId: 1,
        pageLimit: SEAL_DISPATCH_LIMIT,
        type: HUB_TRANSFER_DISPATCHING_TYPES.join(','),
        state: PENDING,
        sourceHubId,
        destinationHubId,
        searchType: SEALS
      };

      getSeals(payload);
    }
  }, [destinationHubId]);

  const clearSealNumberField = () => {
    formRef.current.setFieldsValue({ seal: '' });
  };

  const clearMasterSealNumberField = () => {
    formRef.current.setFieldsValue({ masterSeal: '' });
  };

  const handleConfirmTransfer = async () => {
    if (sealsData.length) {
      warning();
      setErrorBannerProps({
        type: 'danger',
        icon: <AlertIcon />,
        subText: intl.formatMessage({
          id: 'hubs.hub_transfer.dispatch_seals.confirm_transfer_error'
        })
      });
      setShowErrorBanner(true);
    }
    setIsLoading(true);
    try {
      const payload = {
        warehouseTransfer: {
          masterSealNumber: masterSeal,
          originWarehouseId: sourceHubId,
          destinationWarehouseId: destinationHubId,
          sealNumbers: scannedSeals.map((seal) => seal.sealNumber)
        }
      };
      await createHubTransfer(payload);
      clearMasterSealNumberField();
      setDestinationHubId(null);
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const scanMasterSeal = debounce(async (e) => {
    const { value } = e.target;

    const masterSealNumber = value?.trim()?.replace(/\t/g, ' ');

    if (masterSealNumber) {
      setIsLoading(true);
      try {
        const payload = {
          sourceHubId
        };

        const { data } = await getSeal({
          sealNumber: masterSealNumber,
          scanType: SEAL_SCAN_TYPE.MASTER,
          payload
        });

        if (data?.trackingNumbers) {
          notify(
            intl.formatMessage({
              id: 'hubs.hub_transfer.transfer_seals.scanning_errors.seal_already_used'
            })
          );
          warning();
          clearMasterSealNumberField();
        } else {
          setMasterSeal(masterSealNumber);
        }
      } catch (error) {
        notify(error.message);
        clearMasterSealNumberField();
      }
      setIsLoading(false);
    }
  }, 500);

  const getSeals = async (payload) => {
    setIsLoading(true);
    try {
      const {
        data: { results = [], totalBins = 0, totalPackages = 0, totalSeals = 0 }
      } = await searchSeal(payload);

      setSealsData(results);
      setIsLoading(false);
      return {
        results,
        totalBins,
        totalPackages,
        totalSeals
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const scanSeal = debounce(({ sealNumber }) => {
    if (sealNumber) {
      const sealIndex = sealsData.findIndex(
        (seal) => seal.sealNumber === sealNumber
      );

      if (sealIndex > -1) {
        if (!sealsData[sealIndex].trackingNumbers.length) {
          warning();
          notify(
            intl.formatMessage({
              id: 'hubs.hub_transfer.dispatch_seals.scanning.bulky_seal_empty'
            })
          );
        } else {
          beep();
          setScannedSeals((prevState) => [...prevState, sealsData[sealIndex]]);
          setSealsData((prevState) =>
            prevState.filter((_, index) => index !== sealIndex)
          );
        }
      } else {
        warning();
        notify(
          intl.formatMessage({
            id: 'hubs.hub_transfer.dispatch_seals.scanning.seal_not_exist'
          })
        );
      }

      clearSealNumberField();
      sealNumberInputRef.current.focus();
    }
  }, 500);

  const formatSealsData = (data) =>
    data.map((seal) => {
      const {
        _id,
        sealNumber,
        destinationHubId: hubId,
        masterSealNumber,
        state,
        trackingNumbers = [],
        updatedAt,
        type,
        binCode
      } = seal;

      return {
        _id,
        sealNumber: binCode ? (
          <Link
            to={`/hubs/hub-operation/transfer-to-hub/scan-seals?editSeal=${binCode}&sourceHubId=${sourceHubId}&sealType=${SEAL_SCAN_TYPE.BINS}&destinationHub=${hubId}`}
            target="_blank"
          >
            {binCode}
          </Link>
        ) : state === PENDING ? (
          <Link
            to={`/hubs/hub-operation/transfer-to-hub/scan-seals?editSeal=${sealNumber}&sourceHubId=${sourceHubId}&sealType=${type}`}
            target="_blank"
          >
            {sealNumber}
          </Link>
        ) : (
          sealNumber
        ),
        noOfPackages: trackingNumbers.length,
        destinationHub: allHubs.find((hub) => hub._id === hubId)?.name,
        state: state ? (
          <Tag className={SEAL_STATE_COLOR[state]}>{SEAL_STATE[state]}</Tag>
        ) : (
          '-'
        ),
        masterSeal: masterSealNumber || '-',
        lastUpdate: <DateTime dateTime={updatedAt} />
      };
    });

  const columns = [
    {
      dataIndex: 'sealNumber',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.seal_code'
      })
    },
    {
      dataIndex: 'noOfPackages',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.no_of_packages'
      })
    },
    {
      dataIndex: 'destinationHub',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.transfer_to'
      })
    },
    {
      dataIndex: 'state',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.state'
      })
    },
    {
      dataIndex: 'masterSeal',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.master_seal'
      })
    },
    {
      dataIndex: 'lastUpdate',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.last_update'
      })
    }
  ];

  const transferScanningColumns = [
    {
      dataIndex: 'sealNumber',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.seal_code'
      })
    },
    {
      dataIndex: 'noOfPackages',
      title: intl.formatMessage({
        id: 'hubs.hub_transfer.dispatch_seals.table_columns.no_of_packages'
      })
    }
  ];

  const getTableData = async ({
    page,
    limit,
    searchInputText,
    state,
    searchType
  }) => {
    try {
      const payload = {
        pageId: page,
        pageLimit: limit,
        type: HUB_TRANSFER_DISPATCHING_TYPES.join(','),
        state: state?.join(','),
        searchString: searchInputText?.trim(),
        sourceHubId,
        searchType
      };

      if (allHubs.length && sourceHubId) {
        const { results = [] } = await getSeals(payload);

        const totalCount =
          results.length === DEFAULT_PAGE_SIZE
            ? page * DEFAULT_PAGE_SIZE + 1
            : page * DEFAULT_PAGE_SIZE;

        return {
          list: formatSealsData(results),
          total: totalCount
        };
      }
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <HubSelectors
        dispatchingTab
        allHubs={allHubs}
        scopedHubs={scopedHubs}
        setIsLoading={setIsLoading}
        sourceHubId={sourceHubId}
        destinationHubId={destinationHubId}
        setSourceHubId={setSourceHubId}
        setDestinationHubId={setDestinationHubId}
        setShowErrorBanner={setShowErrorBanner}
        showErrorBanner={showErrorBanner}
        errorBannerProps={errorBannerProps}
        destinationHubsOptions={destinationHubsOptions}
        setDestinationHubsOptions={setDestinationHubsOptions}
      />
      {destinationHubId && (
        <div className="br-hub-transfer-scanning__container">
          <div className="br-hub-transfer__scanning display-flex">
            <Form ref={formRef}>
              <Form.Item
                name="seal"
                label={intl.formatMessage({
                  id: 'hubs.hub_transfer.dispatch_seals.scanning.scan_seals'
                })}
              >
                <Input
                  ref={sealNumberInputRef}
                  autoComplete="off"
                  placeholder={intl.formatMessage({
                    id: 'hubs.hub_transfer.transfer_seals.scanning.scanning_seal_placeholder'
                  })}
                  onChange={(e) =>
                    scanSeal({ sealNumber: e.target.value?.trim() })
                  }
                />
              </Form.Item>
              <span>and</span>
              <Form.Item
                name="masterSeal"
                label={intl.formatMessage({
                  id: 'hubs.hub_transfer.dispatch_seals.scanning.master_seal'
                })}
              >
                <Input
                  ref={masterSealInputRef}
                  autoComplete="off"
                  placeholder={intl.formatMessage({
                    id: 'hubs.hub_transfer.dispatch_seals.scanning.master_seal_placeholder'
                  })}
                  onChange={scanMasterSeal}
                />
              </Form.Item>
            </Form>
            <div className="br-hub-transfer-dispatch-scanning__actions display-flex">
              <BRButton
                type="primary"
                disabled={!masterSeal || !scannedSeals.length}
                label={intl.formatMessage({
                  id: 'hubs.hub_transfer.dispatch_seals.confirm_transfer'
                })}
                onClick={handleConfirmTransfer}
              />
            </div>
          </div>
        </div>
      )}

      <div className="br-hub-transfer-dispatch-table__container">
        {destinationHubId ? (
          <BRSwappingTables
            leftTableProps={{
              columns: transferScanningColumns,
              preTableHeaderContent: (
                <div className="br-hub-transfer-table__title heading">
                  {intl.formatMessage(
                    {
                      id: 'hubs.hub_transfer.dispatch_seals.scanning_table.seals_count'
                    },
                    {
                      count: sealsData?.length || 0
                    }
                  )}
                </div>
              )
            }}
            rightTableProps={{
              columns: transferScanningColumns,
              preTableHeaderContent: (
                <div className="br-hub-transfer-table__title heading">
                  {intl.formatMessage(
                    {
                      id: 'hubs.hub_transfer.dispatch_seals.scanning_table.scanned_seals'
                    },
                    {
                      count: scannedSeals?.length || 0
                    }
                  )}
                </div>
              )
            }}
            leftTableDataSource={formatSealsData(sealsData)}
            rightTableDataSource={formatSealsData(scannedSeals)}
          />
        ) : (
          <BRTable
            className="br-hub-transfer-dispatch__table"
            columns={columns}
            listFunction={getTableData}
            pageLimit={DEFAULT_PAGE_SIZE}
            showSearch
            showExport={false}
            searchPlaceholder={intl.formatMessage({
              id: 'hubs.hub_transfer.dispatch_seals.search_placeholder'
            })}
            tableFilters={HUB_TRANSFER_DISPATCH_TABLE_FILTERS}
            keepDataBetweenTabs
            shareMethods={acceptMethods}
          />
        )}
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(DispatchSeals);
