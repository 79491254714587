import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { getReturnOrdersStatistics } from 'services/fulfillment-return-orders';
import { SllrWrapper } from 'contexts/sllr.context';
import {
  RETURN_ORDER_STATE_IDS,
  shouldShowIssues
} from 'constants/fulfillment-return-orders';

import FxFStatistics from 'components/NewFulfillment/CommonComponents/FxFStatistics/FxFStatistics';
import FxFFilters from 'components/NewFulfillment/CommonComponents/FxFFilters/FxFFilters';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import TypeFilter from 'components/NewFulfillment/FulfillmentReturns/ReturnsList/components/TypeFilter/TypeFilter';
import IssuesFilter from 'components/NewFulfillment/FulfillmentReturns/ReturnsList/components/IssuesFilter/IssuesFilter';
import ReturnOrdersTable from 'components/NewFulfillment/FulfillmentReturns/ReturnsList/components/ReturnOrdersTable/ReturnOrdersTable';
import FxFDateFilter from 'components/NewFulfillment/CommonComponents/FxFDateFilter/FxFDateFilter';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as ReturnsIcon } from 'assets/bosta-icons/ReturnsIcon.svg';
import { ReactComponent as QualityCheckIcon } from 'assets/bosta-icons/QualityCheckIcon.svg';
import { ReactComponent as ProductBoxIcon } from 'assets/bosta-icons/ProductBoxIcon.svg';
import { ReactComponent as CompletedIcon } from 'assets/bosta-icons/CompletedIcon.svg';

import './ReturnsList.less';

const ReturnsList = ({ intl }) => {
  const { isLoading, setIsLoading } = useContext(SllrWrapper);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const [returnOrdersFilters, setReturnOrderFilters] = useState({
    state: null
  });
  const [isFirstFetchDone, setIsFirstFetchDone] = useState(false);
  const [statisticsFilters, setStatisticsFilters] = useState({});

  const { ON_HOLD, CONFIRMED, READY_TO_PUTAWAY, PUTAWAY } =
    RETURN_ORDER_STATE_IDS;

  const getStatisticsListToRender = ({
    total,
    onHoldCount,
    confirmedCount,
    readyToPutAwayCount,
    putAwayCount
  }) => [
    {
      key: 'total',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.total'
      }),
      content: total,
      stateIdFilterValue: null
    },
    {
      key: 'return',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.return'
      }),
      content: onHoldCount,
      icon: <ReturnsIcon />,
      stateIdFilterValue: ON_HOLD
    },
    {
      key: 'qualityCheck',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.quality_check'
      }),
      content: confirmedCount,
      icon: <QualityCheckIcon />,
      stateIdFilterValue: CONFIRMED
    },
    {
      key: 'putaway',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.putaway'
      }),
      content: readyToPutAwayCount,
      icon: <ProductBoxIcon />,
      stateIdFilterValue: READY_TO_PUTAWAY
    },
    {
      key: 'completed',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.completed'
      }),
      content: putAwayCount,
      icon: <CompletedIcon />,
      stateIdFilterValue: PUTAWAY
    }
  ];

  const updateFilters = (filter) => {
    if (isFirstFetchDone) {
      setReturnOrderFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  const updateStatisticsFilters = (filter) => {
    if (isFirstFetchDone) {
      setStatisticsFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  const renderExtraFilters = () => (
    <>
      <TypeFilter updateFilters={updateFilters} />
      {shouldShowIssues(returnOrdersFilters?.state) && (
        <IssuesFilter updateFilters={updateFilters} />
      )}
    </>
  );

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'fulfillment_returns.title'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div>
            <div className="br-fulfillment-return-list__date-filter-section">
              <FxFDateFilter
                updateFilters={updateFilters}
                updateStatisticsFilters={updateStatisticsFilters}
              />
            </div>

            <FxFStatistics
              updateFilters={updateFilters}
              poFilters={returnOrdersFilters}
              fetchData={getReturnOrdersStatistics}
              getStatisticsListToRender={getStatisticsListToRender}
              statisticsFilters={statisticsFilters}
            />
            <FxFFilters
              updateFilters={updateFilters}
              renderExtraFilters={renderExtraFilters}
              hideDateFilter
            />
            <ReturnOrdersTable
              returnOrdersFilters={returnOrdersFilters}
              isFirstFetchDone={isFirstFetchDone}
              setIsFirstFetchDone={setIsFirstFetchDone}
            />
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(withRouter(ReturnsList));
