import { printAirwaybill } from 'services/shipments';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import {
  FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH,
  SALES_ORDER_STATE_IDS
} from 'constants/fulfillment';
import { formatPrice, goToSalesOrdersList } from 'utils/fulfillment';
import { openModal } from 'utils/modal';
import { showBill } from 'utils';
import {
  getSalesOrderLineSerialNumbers,
  packSalesOrder
} from 'services/sales-orders';
import {
  SERIAL_NUMBERS_TYPES,
  renderSerialsCount,
  renderSerialsView
} from 'constants/fulfillment-return-orders';

import SerialNumbersDataTooltip from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/SerialNumbersDataTooltip/SerialNumbersDataTooltip';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import { notify } from 'components/Notify/Notify';
import SuccessModal from 'components/BRModals/SuccessModal/SuccessModal';

import { ReactComponent as WrongScanErrorIcon } from 'assets/bosta-icons/WrongScanErrorIcon.svg';
import { ReactComponent as ScannedBeforeErrorIcon } from 'assets/bosta-icons/ScannedBeforeErrorIcon.svg';
import { ReactComponent as CheckIcon } from 'assets/bosta-icons/Check.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/bosta-icons/Chevron-left.svg';
import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';

export const SKU_SERIAL_SEPARATOR = '/';
export const SKU_SERIAL_REGEX = /^(BO-)\d+\/(SN)\d+/;
export const SERIAL_REGEX = /^(SN)\d+/;
export const SO_KEY = 'SO-';

export const SALES_ORDER_TYPES_IDS = {
  DELIVER: 1,
  EXCHANGE_FORWARD: 2
};

export const SALES_ORDER_TYPES_NAMES = {
  [SALES_ORDER_TYPES_IDS.DELIVER]: fmt({
    id: 'fulfillment_outbound.order_types.deliver'
  }),
  [SALES_ORDER_TYPES_IDS.EXCHANGE_FORWARD]: fmt({
    id: 'fulfillment_outbound.order_types.exchange_forward'
  })
};

export const SALES_ORDER_TYPES_OPTIONS = [
  {
    label: fmt({
      id: 'fulfillment_returns.order_types.all'
    }),
    value: null
  },
  {
    label: SALES_ORDER_TYPES_NAMES[SALES_ORDER_TYPES_IDS.DELIVER],
    value: SALES_ORDER_TYPES_IDS.DELIVER
  },
  {
    label: SALES_ORDER_TYPES_NAMES[SALES_ORDER_TYPES_IDS.EXCHANGE_FORWARD],
    value: SALES_ORDER_TYPES_IDS.EXCHANGE_FORWARD
  }
];

export const getSkuSerial = (value) => {
  let skuSerial = {};

  if (SKU_SERIAL_REGEX.test(value)) {
    const [sku, serial] = value.split(SKU_SERIAL_SEPARATOR);
    skuSerial = {
      sku,
      serial
    };
  }

  return skuSerial;
};

export const getSkuForScan = (value) =>
  SKU_SERIAL_REGEX.test(value) ? getSkuSerial(value).sku : value;

export const getSerialForScan = (value) =>
  SKU_SERIAL_REGEX.test(value) ? getSkuSerial(value).serial : value;

export const SCAN_SERIALS_ERRORS_KEYS = {
  SCANNED_BEFORE: 'SCANNED_BEFORE',
  WRONG: 'WRONG'
};

export const SCAN_SERIALS_ERRORS = {
  [SCAN_SERIALS_ERRORS_KEYS.SCANNED_BEFORE]: {
    icon: <ScannedBeforeErrorIcon />,
    message: fmt({
      id: 'fulfillment_outbound.scan_serials.errors.scanned_before.message'
    }),
    cancelBtnLabel: fmt({
      id: 'fulfillment_outbound.scan_serials.errors.scanned_before.btn_text'
    })
  },
  [SCAN_SERIALS_ERRORS_KEYS.WRONG]: {
    icon: <WrongScanErrorIcon />,
    message: fmt({
      id: 'fulfillment_outbound.scan_serials.errors.wrong.message'
    }),
    cancelBtnLabel: fmt({
      id: 'fulfillment_outbound.scan_serials.errors.wrong.btn_text'
    })
  }
};

export const goToSalesOrder = (id) =>
  window.open(`${FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH}/${id}`, '_target');

export const confirmOnUserBeforeExitPacking = () => {
  openModal(BRConfirmationModal, {
    wrapClassName: 'br-cancel-po__confirmation-modal',
    title: fmt({
      id: 'fulfillment_outbound.confirmation_modal.title'
    }),
    message: fmt({
      id: 'fulfillment_outbound.confirmation_modal.message'
    }),
    onConfirm: goToSalesOrdersList,
    confirmButtonText: fmt({
      id: 'fulfillment_outbound.confirmation_modal.confirm'
    }),
    cancelButtonText: fmt({
      id: 'fulfillment_outbound.confirmation_modal.cancel'
    }),
    width: 536
  });
};

export const handlePrintAirwaybill = async ({
  list,
  isTrackingNumbers = true,
  setIsLoading = () => {},
  doAfterPrinting = () => {}
}) => {
  setIsLoading(true);

  try {
    const { data } = await printAirwaybill(list.toString(), {
      isTrackingNumbers
    });
    if (data) {
      showBill(data);
      doAfterPrinting();
    }
  } catch (error) {
    notify(error.message);
  }

  setIsLoading(false);
};

const handlePackSalesOrder = async ({
  id,
  trackingNumber,
  serials,
  setIsLoading
}) => {
  setIsLoading(true);
  try {
    const payload = {
      serials
    };
    await packSalesOrder(id, payload);
    setIsLoading(false);

    openModal(SuccessModal, {
      title: fmt(
        {
          id: 'fulfillment_outbound.packed_success_modal.title'
        },
        {
          trackingNumber
        }
      ),
      confirmButtonLabel: fmt({
        id: 'fulfillment_outbound.actions.print_airwaybill'
      }),
      confirmButtonPrefixIcon: <PrinterIcon />,
      cancelButtonLabel: fmt({
        id: 'fulfillment_outbound.packed_success_modal.cancel_btn'
      }),
      modalAction: async () => {
        await handlePrintAirwaybill({
          list: trackingNumber,
          doAfterPrinting: () =>
            setTimeout(() => window.location.reload(), 3000),
          setIsLoading
        });
      },
      cancelAction: goToSalesOrdersList,
      onCancel: null
    });
  } catch (error) {
    notify(error.message);
    setIsLoading(false);
  }
};

export const getViewSalesOrderContentForRendering = ({
  salesOrder = {},
  isLoading,
  setIsLoading,
  scannedSerials = [],
  isAllSerialsScanned,
  allSerialsCount,
  clickedLineData,
  setClickedLineData
}) => {
  const { CREATED, PICKED, PACKED, TRANSFERRED, CANCELED } =
    SALES_ORDER_STATE_IDS;
  const { state = {}, trackingNumber, id, type = {} } = salesOrder;

  const isTransferred = state.stateId === TRANSFERRED;
  const isExchangeForward =
    type.typeId === SALES_ORDER_TYPES_IDS.EXCHANGE_FORWARD;

  const getSharedViewProps = (serialType) => ({
    headerProps: {
      icon: LeftArrowIcon,
      iconOnClick: goToSalesOrdersList,
      actions: [
        ...(isTransferred && isExchangeForward
          ? []
          : [
              {
                label: fmt({
                  id: 'fulfillment_outbound.actions.print_airwaybill'
                }),
                type: 'link-gray',
                prefixIcon: <PrinterIcon />,
                disabled: isLoading,
                onClick: () =>
                  handlePrintAirwaybill({
                    list: trackingNumber,
                    setIsLoading
                  })
              }
            ])
      ]
    },
    productsTableColumns: [
      {
        dataIndex: 'quantity',
        title: fmt({
          id: 'fulfillment_outbound.columns.quantity'
        }),
        render: renderSerialsCount
      },
      {
        dataIndex: 'unitPrice',
        title: fmt({
          id: 'fulfillment_outbound.columns.unit_price'
        }),
        render: formatPrice
      },
      {
        title: fmt({
          id: 'fulfillment_outbound.columns.subtotal'
        }),
        render: ({ quantity, unitPrice }) => formatPrice(quantity * unitPrice)
      },
      {
        render: (line = {}) =>
          renderSerialsView({
            clickedLineId: clickedLineData?.id,
            setClickedLineData,
            line,
            fetchData: () => getSalesOrderLineSerialNumbers(line.id),
            formatTypedSerialsList: (allSerials) => [
              {
                type: serialType,
                title: fmt({
                  id: `fulfillment_returns.serials_view.${serialType}.title`
                }),
                serials: allSerials
              }
            ],
            getSerialType: () => serialType,
            showExpandBtn: true
          })
      }
    ]
  });

  const SALES_ORDER_VIEW = {
    [CREATED]: {
      headerProps: {
        icon: LeftArrowIcon,
        iconOnClick: goToSalesOrdersList
      },
      productsTableColumns: [
        {
          dataIndex: 'quantity',
          title: fmt({
            id: 'fulfillment_outbound.columns.required_quantity'
          })
        },
        {
          dataIndex: 'onhandQuantity',
          title: fmt({
            id: 'fulfillment_outbound.columns.on_hand'
          })
        },
        {
          dataIndex: 'unitPrice',
          title: fmt({
            id: 'fulfillment_outbound.columns.unit_price'
          }),
          render: formatPrice
        },
        {
          title: fmt({
            id: 'fulfillment_outbound.columns.subtotal'
          }),
          render: ({ quantity, unitPrice }) => formatPrice(quantity * unitPrice)
        }
      ]
    },
    [PICKED]: {
      headerProps: {
        icon: CloseIcon,
        iconOnClick: scannedSerials.length
          ? confirmOnUserBeforeExitPacking
          : goToSalesOrdersList,
        actions: [
          {
            label: fmt({
              id: 'common.confirm'
            }),
            type: 'primary',
            prefixIcon: <CheckIcon />,
            disabled: isLoading || !isAllSerialsScanned,
            onClick: () =>
              handlePackSalesOrder({
                id,
                trackingNumber,
                serials: scannedSerials.map(
                  (skuSerial) => skuSerial.split(SKU_SERIAL_SEPARATOR)[1]
                ),
                setIsLoading
              })
          }
        ],
        rightLabel: !isAllSerialsScanned && (
          <span className="br-sales-order__left-items">
            {fmt(
              {
                id: 'fulfillment_outbound.left_items'
              },
              {
                count: allSerialsCount - scannedSerials.length
              }
            )}
          </span>
        )
      },
      productsTableColumns: [
        {
          dataIndex: 'quantity',
          title: fmt({
            id: 'fulfillment_outbound.columns.required_quantity'
          })
        },
        {
          dataIndex: 'onhandQuantity',
          title: fmt({
            id: 'fulfillment_outbound.columns.on_hand'
          })
        },
        {
          title: fmt({
            id: 'fulfillment_outbound.columns.serials_progress'
          }),
          render: (line = {}) => (
            <SerialNumbersDataTooltip
              line={line}
              lineScannedSerials={scannedSerials.filter((skuSerial) =>
                skuSerial.includes(line.productSku)
              )}
            />
          )
        }
      ],
      hideTotalOrderSection: true,
      showScanSerialsSection: true
    },
    [PACKED]: getSharedViewProps(SERIAL_NUMBERS_TYPES.PACKED),
    [TRANSFERRED]: getSharedViewProps(SERIAL_NUMBERS_TYPES.TRANSFERRED),
    [CANCELED]: {
      headerProps: {
        icon: LeftArrowIcon,
        iconOnClick: goToSalesOrdersList
      },
      productsTableColumns: [
        {
          dataIndex: 'quantity',
          title: fmt({
            id: 'fulfillment_outbound.columns.required_quantity'
          })
        }
      ]
    }
  };

  return SALES_ORDER_VIEW[state?.stateId] || {};
};

export const isSalesOrdersSelectionAllowed = (stateId) => {
  const { PACKED } = SALES_ORDER_STATE_IDS;

  return [PACKED].includes(stateId);
};

export const PACK_TYPE = 'pack';
