import dayjs from 'dayjs';

import { MAX_SEVERITY_LEVEL } from 'constants/escalation';

export const getEscalationAge = (date) => {
  const currentDate = dayjs();
  const escalationDate = dayjs(date);

  const daysDiff = currentDate.diff(escalationDate, 'day');

  return daysDiff + 1;
};

export const getSeverityLevel = (date) => {
  const escalationAge = getEscalationAge(date);

  return escalationAge >= MAX_SEVERITY_LEVEL
    ? MAX_SEVERITY_LEVEL
    : escalationAge;
};
