import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { COUNTRY_CURRENCY } from 'constants/helper';
import { formatMaterialItems, useMaterialLogs } from 'utils/packaging';
import { openModal } from 'utils/modal';
import { exportMaterials, getPackagingMaterials } from 'services/packaging';
import {
  isPackagingFreeItemsAvilable,
  isPriceColumnInPackagingMaterialsVailable
} from 'common/countries/countries-mapping';

import BRTable from 'components/BRTable/BRTable';
import EditMaterialModal from '../EditMaterialModal/EditMaterialModal';
import MaterialLogs from '../MaterialLogs/MaterialLogs';
import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import MaterialBulkEditModal from '../MaterialBulkEditModal/MaterialBulkEditModal';

import { ReactComponent as EditIcon } from 'assets/icons/EditIcon.svg';
import { ReactComponent as ExportIcon } from 'assets/imgRevamp/export.svg';

import './ManageMaterials.less';

const ManageMaterials = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [refreshLogs, setRefreshLogs] = useState(false);
  const [count, setCount] = useState(0);

  const refreshTable = useRef();

  const intl = useIntl();

  const COLUMNS = [
    {
      title: intl.formatMessage({
        id: 'packaging.manage_materials.table.material'
      }),
      dataIndex: 'material',
      render: ({ name, size, description }) => (
        <div className="br-manage-material__material-column">
          <span>{name}</span>
          <span className="caption">{size ? size : description}</span>
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'packaging.manage_materials.table.quantity'
      }),
      dataIndex: 'quantity'
    },

    {
      ...(isPriceColumnInPackagingMaterialsVailable() && {
        title: intl.formatMessage({
          id: 'packaging.manage_materials.table.price'
        }),
        dataIndex: 'price',
        render: (price) =>
          price ? (
            intl.formatMessage(
              {
                id: COUNTRY_CURRENCY
              },
              {
                cod: price
              }
            )
          ) : (
            <span className="br-manage-materials__free-item-text">Free</span>
          )
      })
    },
    {
      title: intl.formatMessage({
        id: 'packaging.manage_materials.table.status'
      }),
      dataIndex: 'status',
      render: (status) => (
        <span
          className={classnames('br-manage-material__status-tag', {
            'br-manage-material__enabled-tag': status
          })}
        >
          {intl.formatMessage({
            id: `business_details.api_integration.states.${
              status ? 'enabled' : 'disabled'
            }`
          })}
        </span>
      )
    },
    {
      dataIndex: 'actions',
      render: (_, data) => (
        <div className="br-manage-material__edit-column">
          <EditIcon
            className="br-manage-material__edit-icon"
            onClick={() => handleOnEditClick(data)}
          />
        </div>
      )
    }
  ];

  const handleOnEditClick = (item) => {
    openModal(EditMaterialModal, {
      material: item,
      onSuccess: () => {
        updateTable();
        setRefreshLogs(true);
      },
      setIsLoading
    });
  };

  const getFlyersData = async ({ page, limit }) => {
    setIsLoading(true);
    try {
      const { ItemsList, totalItems } = await getPackagingMaterials({
        pageSize: limit,
        pageNumber: page - 1,
        payload: isPackagingFreeItemsAvilable() ? { free: 0 } : null
      });
      const formattedItemsList = formatMaterialItems(ItemsList);
      setIsLoading(false);
      setCount(totalItems);
      return {
        list: formattedItemsList,
        total: totalItems
      };
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };
  const updateTable = () => {
    refreshTable.current();
  };

  const handleOnBulkEditClick = () => {
    openModal(MaterialBulkEditModal, {
      onSuccess: () => {
        updateTable();
        setRefreshLogs(true);
      },
      setIsLoading
    });
  };

  const handleOnExportClick = async () => {
    try {
      setIsExportLoading(true);
      await exportMaterials();
      notify(
        intl.formatMessage({
          id: 'monitor_hub_operations.common.export_successful'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsExportLoading(false);
  };

  return (
    <div className="br-manage-materilas-page__container">
      <div className="br-manage-materials__first-section">
        <span className="display-sm">
          {intl.formatMessage({ id: 'packaging.manage_materials.title' })}
        </span>
        <BRTable
          columns={COLUMNS}
          title={
            <div className="br-manage-materials__table-header">
              {intl.formatMessage(
                { id: 'packaging.manage_materials.table.header' },
                {
                  count
                }
              )}
              <span className="br-manage-materials__table-header__actions">
                <BRButton
                  label={'Bulk Edit'}
                  prefixIcon={<EditIcon />}
                  onClick={handleOnBulkEditClick}
                />
                <BRButton
                  label={intl.formatMessage({ id: 'common.export' })}
                  prefixIcon={<ExportIcon />}
                  onClick={handleOnExportClick}
                  loading={isExportLoading}
                />
              </span>
            </div>
          }
          showFilter={false}
          showUpperPart={true}
          listFunction={getFlyersData}
          pageLimit={7}
          pageSizeOptions={[7, 15, 50]}
          shareMethods={acceptMethods}
        />
      </div>
      <MaterialLogs
        callRefreshLogs={refreshLogs}
        setRefreshLogs={setRefreshLogs}
        isPackagingPage
      />
    </div>
  );
};

export default ManageMaterials;
