import { fmt } from 'IntlWrapper/IntlWrapper';

export const HUBS_OVERVIEW_TABLE_COLUMNS = [
  {
    title: fmt({ id: 'ops_control.hubs_overview.hubs_table.hub' }),
    dataIndex: 'hub'
  },
  {
    title: fmt({ id: 'ops_control.hubs_overview.hubs_table.to_be_dispatched' }),
    dataIndex: 'to_be_dispatched'
  },
  {
    title: fmt({ id: 'ops_control.hubs_overview.hubs_table.dispatched' }),
    dataIndex: 'dispatched'
  },
  {
    title: fmt({ id: 'ops_control.hubs_overview.hubs_table.late' }),
    dataIndex: 'late'
  },
  {
    title: fmt({
      id: 'ops_control.hubs_overview.hubs_table.missed'
    }),
    dataIndex: 'missed'
  }
];

export const SINGLE_CARDS_INFOS = ({
  toBeDispatched,
  pending,
  addedToRoute,
  dispatched,
  late,
  missed
}) => [
  {
    data: toBeDispatched,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.to_be_dispatched'
    })
  },
  {
    data: pending,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.pending'
    })
  },
  {
    data: addedToRoute,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.added_to_route'
    })
  },
  {
    data: dispatched,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.dispatched'
    })
  },
  {
    data: late,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.late'
    })
  },
  {
    data: missed,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.missed'
    })
  }
];

export const HUB_VIEW_TABLE_COLUMNS = [
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.tracking_number'
    }),
    dataIndex: 'tracking_number'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.comments'
    }),
    dataIndex: 'comments'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.order_type'
    }),
    dataIndex: 'order_type'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.hub_aging'
    }),
    dataIndex: 'hub_aging'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.e2e_aging'
    }),
    dataIndex: 'e2e_aging'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.attempts'
    }),
    dataIndex: 'attempts'
  }
];
