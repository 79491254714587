import { Skeleton } from 'antd';

import SingleInfoCard from '../SingleCardInfo/SingleCardInfo';

import './GeneralInfoCard.less';
const GeneralInfoCard = ({ Icon, title, total, cardsData, loading }) => {
  return (
    <div className="general-info__container">
      <div className="general-info__header">
        {Icon && <Icon />}
        <h3 className="display-xs general-info__title">{title}</h3>
        {total &&
          (loading ? (
            <Skeleton active paragraph={{ rows: 0 }} />
          ) : (
            <h3 className="general-info__total display-xs">{total}</h3>
          ))}
      </div>
      <div className="general-info__data-container">
        {cardsData.map((card) => {
          return (
            <SingleInfoCard
              title={card.title}
              data={card.data}
              loading={loading}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GeneralInfoCard;
