import { injectIntl } from 'react-intl';
import { Timeline } from 'antd';

import { formatDateTime } from 'utils/fulfillment';

import './OrderLogsCard.less';

const OrderLogsCard = ({ intl, orderLogs = [] }) => {
  const renderLog = ({ id, action, createdAt, actionTakenByName }) => {
    const { formattedDate, formattedTime } = formatDateTime(createdAt);

    return (
      <Timeline.Item key={id}>
        <span className="br-order-logs__date">
          {formattedDate}, {formattedTime}
        </span>
        <div>
          {`${action} ${intl.formatMessage({
            id: 'common.by'
          })} ${actionTakenByName}`}
        </div>
      </Timeline.Item>
    );
  };

  return (
    <div className="br-info-card br-order-logs-card br-po-section">
      <div className="br-po-section__title">
        {intl.formatMessage({
          id: 'fulfillment_outbound.order_logs.title'
        })}
      </div>
      <div className="br-po-section__content">
        <Timeline>{orderLogs.map(renderLog)}</Timeline>
      </div>
    </div>
  );
};

export default injectIntl(OrderLogsCard);
