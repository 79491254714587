import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Spin, Progress, Table, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { saveAs } from 'file-saver';
import classnames from 'classnames';
import { mediaHook } from 'customHooks';

import { validatePOBeforeCreation } from 'services/fulfillment';
import { getSKU } from 'utils/fulfillment';
import { LOCALE } from 'constants/intl-wrapper';
import { SHEET_MAX_NUM_OF_PO_PRODUCTS } from 'constants/fulfillment';
import {
  FILE_SHEET_UPLOAD_ERROR_TYPES,
  MULTIPLE_IMPORT_STEPS_KEYS,
  UPLOADED_FILE_TYPES
} from 'constants/upload';
import { convertSheetToJSON } from 'utils/upload';

import { sleep } from 'utils/helpers';
import UploadAttachment from 'components/SupportTickets/components/UploadAttachment/UploadAttachment';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import CreatePOProductsTemplateXLSX from 'assets/Create PO Products Template.xlsx';
import { ReactComponent as ArrowLeftIcon } from 'assets/bosta-icons/Arrow-left.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/bosta-icons/Info-Circle.svg';
import { ReactComponent as ExcelIcon } from 'assets/bosta-icons/excel.svg';
import { ReactComponent as AlertTriangleIcon } from 'assets/bosta-icons/alert-triangle.svg';
import UploadIcon from 'assets/bosta-icons/Upload-Attachment.svg';

import './ImportMultipleProductsModal.less';

const { UPLOAD_FILE, PREVIEW_FILE, VALIDATING_AND_UPLOADING, PREVIEW_ERRORS } =
  MULTIPLE_IMPORT_STEPS_KEYS;

const ErrorAlert = ({ msg }) => {
  return (
    <div className="br-preview-uploaded-file__error">
      <div>
        <Icon component={AlertTriangleIcon} />
      </div>
      <div>{msg}</div>
    </div>
  );
};

const UploadFile = ({
  intl,
  isSmallMobileScreen,
  isUploading,
  beforeUpload,
  renderSampleTemplate
}) => {
  return (
    <div className="br-upload-file">
      <div className="br-import-multiple-products-modal__info">
        <div>
          <Icon component={InfoCircleIcon} />
        </div>
        <div>
          {intl.formatMessage(
            {
              id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.info'
            },
            {
              span: renderSampleTemplate
            }
          )}
        </div>
      </div>
      {isUploading ? (
        <div className="br-upload-file__spin-container">
          <Spin />
        </div>
      ) : (
        <UploadAttachment
          customTitle={
            isSmallMobileScreen ? (
              <></>
            ) : (
              intl.formatMessage({
                id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.upload.title'
              })
            )
          }
          accept=".csv, .xlsx"
          customSubtitle={intl.formatMessage(
            {
              id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.upload.subtitle'
            },
            {
              maxNumOfProducts: SHEET_MAX_NUM_OF_PO_PRODUCTS
            }
          )}
          beforeUpload={beforeUpload}
          multiple={false}
          showUploadList={false}
        />
      )}
    </div>
  );
};

const PreviewFile = ({ intl, sheetData, errorsCount, handleReplace }) => {
  const { name, size, rowsData, fileError } = sheetData || {};
  const totalQuantity = rowsData?.reduce((acc, { quantity }) => {
    return acc + (!isNaN(quantity) && quantity > 0 ? quantity : 0);
  }, 0);

  return (
    <div className="br-preview-uploaded-file">
      {fileError && <ErrorAlert msg={fileError} />}
      <div className="br-preview-uploaded-file__content">
        <div className="br-preview-uploaded-file__content__data">
          <div>
            <div className="br-preview-uploaded-file__icon">
              <Icon component={ExcelIcon} />
            </div>
            <div className="br-preview-uploaded-file__title-subtitle">
              <div className="br-preview-uploaded-file__title">
                {intl.formatMessage(
                  {
                    id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.file_name'
                  },
                  {
                    fileName: name || 'N/A'
                  }
                )}
              </div>
              <div className="br-preview-uploaded-file__subtitle">
                <span>
                  {intl.formatMessage({
                    id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.total_skus'
                  })}
                  <span
                    className={classnames('br-preview-uploaded-file__data', {
                      error: !!fileError
                    })}
                  >
                    {rowsData?.length}
                  </span>
                </span>
                <span>
                  {intl.formatMessage({
                    id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.total_qty'
                  })}
                  <span
                    className={classnames('br-preview-uploaded-file__data', {
                      error: !!fileError
                    })}
                  >
                    {totalQuantity}
                  </span>
                </span>

                {!!errorsCount && (
                  <span>
                    {intl.formatMessage(
                      {
                        id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.file_size'
                      },
                      {
                        span: (children) => (
                          <span className="br-preview-uploaded-file__data">
                            {children}
                          </span>
                        ),
                        fileSize: (size / 1024 / 1024).toFixed(2)
                      }
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="br-preview-uploaded-file__content__replace-btn">
            <UploadAttachment
              accept=".csv, .xlsx"
              beforeUpload={handleReplace}
              multiple={false}
              showUploadList={false}
            >
              <BRButton
                label={intl.formatMessage({
                  id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.replace_file'
                })}
              />
            </UploadAttachment>
          </div>
        </div>
        {!!errorsCount && (
          <ErrorAlert
            msg={intl.formatMessage(
              {
                id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.upload.error_msgs.validations'
              },
              {
                count: errorsCount
              }
            )}
          />
        )}
      </div>
    </div>
  );
};

const ValidatingAndUploading = ({
  intl,
  sheetData,
  errorsObj,
  setErrorsObj,
  setCurrentStepKey,
  setIsUploadBtnDisabled,
  onConfirm,
  selectedBusiness
}) => {
  const [percent, setPercent] = useState(0);

  let timer;

  const finishValidatingAndUploading = async () => {
    clearInterval(timer);
    setPercent(100);
    await sleep(1000);
    if (errorsObj.count) {
      setCurrentStepKey(PREVIEW_ERRORS);
    }
  };

  const startValidatingAndUploading = () => {
    let randomNumber = 1;
    timer = setInterval(() => {
      randomNumber = Math.floor(Math.random() * 10) + 1;
      setPercent((currentPercentage) => {
        if (currentPercentage >= 70) {
          clearInterval(timer);
          return currentPercentage;
        }

        return currentPercentage + randomNumber;
      });
    }, randomNumber * 100);
    validate();
  };

  const checkIfNotBelongToBusiness = async () => {
    try {
      const uploadedProducts = sheetData?.rowsData || [];
      const payload = {
        businessId: selectedBusiness._id,
        lines: uploadedProducts.map(({ quantity, ...rest }) => ({
          productSku: getSKU(rest),
          quantity
        }))
      };
      const { data } = await validatePOBeforeCreation(payload);

      return data;
    } catch (error) {
      notify(error.message);
    }
  };

  const validate = async () => {
    let newErrorsObj = errorsObj;

    const addError = (errorField, errorType, rowIndex, errObj) => {
      if (errObj.errors[errorField][errorType]) {
        errObj.errors[errorField][errorType].push(rowIndex);
      } else {
        errObj.count += 1;
        errObj.errors[errorField][errorType] = [rowIndex];
      }
      return errObj;
    };

    const checkRequired = (rowName, rowValue, rowIndex) => {
      if (!rowValue?.toString()?.trim()) {
        newErrorsObj = addError(rowName, 'empty', rowIndex, newErrorsObj);
      }
    };

    const duplicatedSkus = {};

    const checkSKUDuplication = (sku, rowIndex) => {
      const isSKUDuplicated = duplicatedSkus.hasOwnProperty(sku);
      if (isSKUDuplicated) {
        newErrorsObj = addError('sku', 'duplicated', rowIndex, newErrorsObj);
      }

      duplicatedSkus[sku] = isSKUDuplicated;
    };

    sheetData?.rowsData?.forEach((row, index) => {
      const { quantity, sku } = row;
      const rowIndex = index + 2;

      ['productName', 'sku', 'quantity'].forEach((key) =>
        checkRequired(key, row[key], rowIndex)
      );

      if (quantity !== undefined && (isNaN(quantity) || Number(quantity) < 1)) {
        newErrorsObj = addError(
          'quantity',
          'notNumberOrLessThanMin',
          rowIndex,
          newErrorsObj
        );
      }

      checkSKUDuplication(sku, rowIndex);
    });

    let succeededList = [];
    let isUnexpectedError = false;

    if (newErrorsObj.count) {
      setIsUploadBtnDisabled(true);
    } else {
      const data = await checkIfNotBelongToBusiness();
      if (data) {
        const { failedSkus, succeededSkus } = data;
        if (failedSkus.length) {
          setIsUploadBtnDisabled(true);
          failedSkus.forEach((sku) => {
            newErrorsObj = addError(
              'sku',
              'notBelongToBusiness',
              sku.index + 2,
              newErrorsObj
            );
          });
        } else {
          succeededList = succeededSkus.map(
            ({ variantDefaultImage, ...rest }) => ({
              ...rest,
              productImage: variantDefaultImage,
              key: getSKU(rest)
            })
          );
        }
      } else {
        isUnexpectedError = true;
      }
    }

    setErrorsObj(newErrorsObj);

    if (!newErrorsObj.count && succeededList.length && !isUnexpectedError) {
      onConfirm({
        succeededSkus: succeededList,
        callback: finishValidatingAndUploading
      });
    } else if (isUnexpectedError) {
      setCurrentStepKey(PREVIEW_FILE);
    }
  };

  useEffect(() => {
    startValidatingAndUploading();

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (percent >= 70 && percent < 100 && errorsObj.count) {
      finishValidatingAndUploading();
    }
  }, [percent]);

  return (
    <div className="br-uploading-file">
      <img src={UploadIcon} alt="upload-icon" />
      <div className="br-uploading-file__progress">
        <span className="br-uploading-file__progress-text">{percent}%</span>
        <Progress percent={percent} showInfo={false} />
      </div>

      <div>
        <span className="br-uploading-file__title">
          {intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.title'
          })}
        </span>
        <span className="br-uploading-file__file-name">
          {sheetData?.name || 'N/A'}
        </span>
      </div>
      <div className="br-uploading-file__subtitle">
        {intl.formatMessage({
          id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.subtitle'
        })}
      </div>
    </div>
  );
};

const PreviewErrors = ({
  intl,
  sheetData,
  handleReplace,
  errorsObj,
  setModalWidth,
  isSmallMobileScreen,
  isLargeMobileScreen
}) => {
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      key: 'title',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.header.column_name'
      }),
      dataIndex: 'columnName',
      width: 138
    },
    {
      key: 'issue',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.header.issue'
      }),
      dataIndex: 'issue',
      width: 300
    },
    {
      key: 'inRows',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.header.in_rows'
      }),
      dataIndex: 'inRows',
      width: 272,
      render: (rows) => {
        const maxCharCount = 28;
        let joinedRows = rows?.join(',');
        let restRowsCount;
        let restJoinedRows;
        const exceededMax = joinedRows.length > maxCharCount;
        if (exceededMax) {
          joinedRows = joinedRows.substring(
            0,
            joinedRows[maxCharCount] === ',' ? maxCharCount + 1 : maxCharCount
          );
          joinedRows = joinedRows.substring(0, joinedRows.lastIndexOf(','));
          const shownRows = joinedRows
            .split(',')
            .filter((num) => !isNaN(parseInt(num)));
          restRowsCount = rows.length - shownRows.length;
          restJoinedRows = rows.slice(shownRows.length).join(', ');
          joinedRows += '...';
        }
        return (
          <span className="br-in-rows-column">
            {joinedRows}
            {exceededMax && (
              <Tooltip
                title={restJoinedRows}
                placement={isLargeMobileScreen ? 'topRight' : 'top'}
                getPopupContainer={(trigger) =>
                  trigger.parentElement.parentElement.parentElement
                }
                overlayClassName="br-in-rows-column__tooltip"
              >
                <span className="br-in-rows-column__tag caption">
                  +{restRowsCount}
                </span>
              </Tooltip>
            )}
          </span>
        );
      }
    }
  ];

  const getDataSource = () => {
    const errorsTypes = {
      productName: {
        empty: {
          name: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.productName.column_name'
          }),
          msg: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.productName.issue_required'
          })
        }
      },
      quantity: {
        empty: {
          name: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.quantity.column_name'
          }),
          msg: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.quantity.issue_required'
          })
        },
        notNumberOrLessThanMin: {
          name: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.quantity.column_name'
          }),
          msg: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.quantity.issue_numbers_only_with_min'
          })
        }
      },
      sku: {
        empty: {
          name: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.sku.column_name'
          }),
          msg: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.sku.issue_required'
          })
        },
        notBelongToBusiness: {
          name: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.sku.column_name'
          }),
          msg: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.sku.not_belong_to_business'
          })
        },
        duplicated: {
          name: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.sku.column_name'
          }),
          msg: intl.formatMessage({
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.table.rows.sku.duplicated'
          })
        }
      }
    };

    if (errorsObj.count) {
      setModalWidth(777);
    }
    // NOTE: Need to be improved and automated
    setDataSource([
      ...(errorsObj.errors.productName?.empty
        ? [
            {
              columnName: errorsTypes.productName.empty.name,
              issue: errorsTypes.productName.empty.msg,
              inRows: errorsObj.errors.productName.empty
            }
          ]
        : []),
      ...(errorsObj.errors.quantity?.empty
        ? [
            {
              columnName: errorsTypes.quantity.empty.name,
              issue: errorsTypes.quantity.empty.msg,
              inRows: errorsObj.errors.quantity.empty
            }
          ]
        : []),
      ...(errorsObj.errors.quantity?.notNumberOrLessThanMin
        ? [
            {
              columnName: errorsTypes.quantity.notNumberOrLessThanMin.name,
              issue: errorsTypes.quantity.notNumberOrLessThanMin.msg,
              inRows: errorsObj.errors.quantity.notNumberOrLessThanMin
            }
          ]
        : []),
      ...(errorsObj.errors.sku?.empty
        ? [
            {
              columnName: errorsTypes.sku.empty.name,
              issue: errorsTypes.sku.empty.msg,
              inRows: errorsObj.errors.sku.empty
            }
          ]
        : []),
      ...(errorsObj.errors.sku?.notBelongToBusiness
        ? [
            {
              columnName: errorsTypes.sku.notBelongToBusiness.name,
              issue: errorsTypes.sku.notBelongToBusiness.msg,
              inRows: errorsObj.errors.sku.notBelongToBusiness
            }
          ]
        : []),
      ...(errorsObj.errors.sku?.duplicated
        ? [
            {
              columnName: errorsTypes.sku.duplicated.name,
              issue: errorsTypes.sku.duplicated.msg,
              inRows: errorsObj.errors.sku.duplicated
            }
          ]
        : [])
    ]);
  };

  useEffect(() => {
    getDataSource();
  }, []);

  return (
    <div className="br-uploaded-file">
      <PreviewFile
        intl={intl}
        sheetData={sheetData}
        handleReplace={handleReplace}
        errorsCount={errorsObj.count}
      />
      {!!errorsObj.count && (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{
            y: isSmallMobileScreen ? 150 : 300
          }}
        />
      )}
    </div>
  );
};

const ImportMultipleProductsModal = ({
  intl,
  close,
  mobileScreenSizes: { isSmallMobileScreen, isLargeMobileScreen },
  onSuccess = () => {},
  hideBackButton = true,
  selectedBusiness = {},
  ...props
}) => {
  const [sheetData, setSheetData] = useState(null);
  const [isUploadBtnDisabled, setIsUploadBtnDisabled] = useState(true);
  const [currentStepKey, setCurrentStepKey] = useState(UPLOAD_FILE);
  const [modalWidth, setModalWidth] = useState(536);
  const [errorsObj, setErrorsObj] = useState({
    count: 0,
    errors: {
      productName: {},
      quantity: {},
      sku: {}
    }
  });
  const [isUploading, setIsUploading] = useState(false);

  const { XLSX, CSV } = UPLOADED_FILE_TYPES;

  const reupload = (replaceFile) => {
    if (!replaceFile) {
      setSheetData(null);
    }

    setModalWidth(536);
    setIsUploadBtnDisabled(false);
    setErrorsObj({
      count: 0,
      errors: {
        productName: {},
        quantity: {},
        sku: {}
      }
    });
  };

  const handleReplace = (props) => {
    reupload(true);
    beforeUpload(props);
  };

  const handleBackClick = () => {
    if (currentStepKey !== VALIDATING_AND_UPLOADING) {
      close();
    }
  };

  const onConfirm = async ({ succeededSkus = [], callback = () => {} }) => {
    await callback();
    onSuccess(succeededSkus);
    close();
    notify(
      intl.formatMessage(
        {
          id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.uploading.created_products_successfully'
        },
        {
          count: succeededSkus.length
        }
      ),
      'success'
    );
  };

  const afterUpload = (data) => {
    setSheetData(data);
    setIsUploading(false);
    setCurrentStepKey(PREVIEW_FILE);
  };

  const handleDownloadSampleTemplate = () => {
    return saveAs(
      CreatePOProductsTemplateXLSX,
      'Create PO Products Template.xlsx'
    );
  };

  const renderSampleTemplate = (children, className) => (
    <a
      className={classnames('br-sample-template', className)}
      onClick={handleDownloadSampleTemplate}
    >
      {children}
    </a>
  );

  const getFileErrorMsg = (errorType) => {
    const { INVALID_FILE, EMPTY_FILE, EXCEEDED_MAX_ROWS } =
      FILE_SHEET_UPLOAD_ERROR_TYPES;

    switch (errorType) {
      case INVALID_FILE:
        return intl.formatMessage(
          {
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.upload.error_msgs.invalid_file'
          },
          {
            span: (children) => renderSampleTemplate(children, 'error')
          }
        );

      case EMPTY_FILE:
        return intl.formatMessage(
          {
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.upload.error_msgs.empty_file'
          },
          {
            span: (children) => renderSampleTemplate(children, 'error')
          }
        );

      case EXCEEDED_MAX_ROWS:
        return intl.formatMessage(
          {
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.upload.error_msgs.max_count'
          },
          {
            maxNumOfProducts: SHEET_MAX_NUM_OF_PO_PRODUCTS
          }
        );

      default:
        return;
    }
  };

  const beforeUpload = (file) => {
    setIsUploading(true);

    const { name, type, size } = file;
    const isRequiredType = [XLSX, CSV].includes(type);

    if (isRequiredType) {
      let rows = [];
      let fileError;

      convertSheetToJSON(file)
        .then((rowsData) => {
          rows = rowsData;
        })
        .catch(({ errorType, rowsData }) => {
          fileError = errorType;
          rows = rowsData || [];
        })
        .finally(() => {
          afterUpload({
            name,
            size,
            rowsData: rows,
            ...(!!fileError && {
              fileError: getFileErrorMsg(fileError)
            })
          });
        });
    } else {
      notify({
        msg: intl.formatMessage(
          {
            id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.upload.error_msgs.type'
          },
          {
            maxNumOfProducts: SHEET_MAX_NUM_OF_PO_PRODUCTS
          }
        ),
        status: -1
      });
      setIsUploading(false);
    }

    return isRequiredType;
  };

  const handleUploadFileClick = () => {
    setCurrentStepKey(VALIDATING_AND_UPLOADING);
  };

  const renderContent = () => {
    const content = {
      UPLOAD_FILE: {
        Component: UploadFile,
        props: {
          intl,
          isSmallMobileScreen,
          beforeUpload,
          isUploading,
          renderSampleTemplate
        }
      },
      PREVIEW_FILE: {
        Component: PreviewFile,
        props: {
          intl,
          sheetData,
          setCurrentStepKey,
          handleReplace
        }
      },
      VALIDATING_AND_UPLOADING: {
        Component: ValidatingAndUploading,
        props: {
          intl,
          sheetData,
          setCurrentStepKey,
          errorsObj,
          setErrorsObj,
          onConfirm,
          setIsUploadBtnDisabled,
          selectedBusiness
        }
      },
      PREVIEW_ERRORS: {
        Component: PreviewErrors,
        props: {
          intl,
          sheetData,
          handleReplace,
          errorsObj,
          setModalWidth,
          setCurrentStepKey,
          isSmallMobileScreen,
          isLargeMobileScreen
        }
      }
    };

    const { Component, props } = content[currentStepKey];
    return <Component {...props} />;
  };

  useEffect(() => {
    if (sheetData) {
      setIsUploadBtnDisabled(!!sheetData.fileError);
    } else {
      setIsUploadBtnDisabled(true);
      setCurrentStepKey(UPLOAD_FILE);
    }
  }, [sheetData]);

  return (
    <Modal
      {...props}
      className="br-import-multiple-products-modal"
      title={
        <div className="br-import-multiple-products-modal__header">
          {!hideBackButton && (
            <div
              className={classnames({
                mirror: intl.locale === LOCALE.AR
              })}
            >
              <Icon component={ArrowLeftIcon} onClick={handleBackClick} />
            </div>
          )}
          <div>
            <div className="display-xs">
              {intl.formatMessage({
                id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.title'
              })}
            </div>
            <div className="br-import-multiple-products-modal__subtitle">
              {intl.formatMessage({
                id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.subtitle'
              })}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="br-import-multiple-products-modal__footer">
          <div className="br-import-multiple-products-modal__footer__actions">
            <BRButton
              label={intl.formatMessage({
                id: 'common.cancel'
              })}
              onClick={close}
              disabled={currentStepKey === VALIDATING_AND_UPLOADING}
            />
            <BRButton
              type="primary"
              label={intl.formatMessage({
                id: 'fulfillment_inbound.create_po.products.import_multiple_products_modal.confirm'
              })}
              disabled={isUploadBtnDisabled}
              loading={currentStepKey === VALIDATING_AND_UPLOADING}
              onClick={handleUploadFileClick}
            />
          </div>
        </div>
      }
      width={modalWidth}
      visible
      centered
    >
      {renderContent()}
    </Modal>
  );
};

export default injectIntl(mediaHook(ImportMultipleProductsModal));
