export default {
  common: {
    success: 'success',
    create: 'Create',
    edit: 'Edit',
    delete: 'Delete',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    close: 'Close',
    done: 'Done',
    next: 'Next',
    back: 'Back',
    ok: 'Ok',
    save: 'Save',
    submit: 'Submit',
    export: 'Export',
    true: 'True',
    false: 'False',
    clear: 'Clear',
    filter: 'Filter',
    EGP: '{cod} EGP',
    SAR: '{cod} SAR',
    AED: '{cod} AED',
    egp_amount: '{amount} EGP',
    SAR_amount: '{cod} SAR',
    aed_amount: '{cod} AED',
    empty_field: 'N/A',
    confirm: 'Confirm',
    weight_kg: '{weight} KG',
    days_amount: '{days} Days',
    replace: 'Replace',
    filters: 'Filters',
    per_page: ' / page',
    loading: 'Loading...',
    apply: 'Apply',
    EGP_label: 'EGP',
    SAR_label: 'SAR',
    accept: 'Accept',
    reject: 'Reject',
    search: 'Search',
    collect: 'Collect',
    replace: 'Replace',
    amount: 'Amount',
    file_downloaded_successfully: 'File downloaded successfully.',
    clear_search: 'Clear Search',
    cod: 'COD',
    ccod: 'CCOD',
    clear_filters: 'Clear Filters',
    confirm_receiving: 'Confirm Receiving',
    kg: 'KG',
    view: 'View',
    EGP_modified: 'EGP {cod}',
    SAR_modified: 'SAR {cod}',
    AED_modified: 'AED {cod}',
    QAR_modified: 'QAR {cod}',
    OMR_modified: 'OMR {cod}',
    KWD_modified: 'KWD {cod}',
    map_failed_to_load: 'Map cannot be loaded right now.',
    select: 'Select',
    remove: 'Remove',
    close: 'Close',
    download: 'Download',
    no_cash_on_delivery: 'No Cash Collection',
    re_evaluate: 'Re-Evaluate',
    change: 'Change',
    from: 'From',
    to: 'To',
    continue: 'Continue',
    no_results_found: 'No results found',
    seconds_shortcut: 's',
    by: 'by',
    all: 'All',
    timer: {
      seconds: '{seconds, plural, one {# second} other {# seconds}}',
      minutes: '{minutes, plural, one {# minute} other {# minutes}}',
      hours: '{hours, plural, one {# hour} other {# hours}}'
    },
    export_error: 'You need to select at least one filter to export.',
    all_good: 'All good',
    no_data: 'No data',
    print: 'Print',
    bulky_actions: 'Bulky Actions',
    reset: 'Reset',
    dismiss: 'Dismiss',
    view_all: 'View all',
    empty_file: 'The file is empty.',
    invalid_file: 'Invalid file..',
    yes_confirm: 'Yes, Confirm',
    size_error: 'Image must be smaller than or equal to {size}Mb',
    by: 'By',
    apply_filters: 'Apply Filters'
  },
  days_of_week: {
    saturday: 'Saturday',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday'
  },
  form: {
    required: '${label} is required!',
    required_custom: '{label} is required!',
    email_not_valid: 'Email is not valid',
    url_not_valid: 'Url is not valid',
    phone_not_valid: 'Invalid phone number',
    phone_or_landline_length: 'Please enter a mobile or landline',
    phone_length: 'Please enter a mobile',
    maxLen: '${label} cannot be longer than ${max} characters',
    field_min_length: '{label} must consist of at least {count} characters.',
    new_optional_label: 'Optional',
    optional_label: ' Optional ',
    select_placeholder: 'Select',
    uncovered_zone: 'Uncovered',
    area_select_placeholder: 'Search or type-in the area',
    not_valid_password:
      'At least 8 characters, upper case, lower case, numbers and special characters',
    egypt_phone_placeholder: 'Ex 01234567890',
    saudi_arabia_phone_placeholder: 'Ex: 0503344556 or +966503344556',
    international_phone_placeholder: 'Ex. +201234567890'
  },
  otp_screen: {
    resend_code: 'Resend code',
    receive_confirmation:
      'The business shall receive the confirmation code within 2 minutes {time}.',
    error_message: 'Invalid code. Please enter a valid validation code.'
  },
  nav_bar: { settings: 'Settings', orders: 'Orders' },
  br_area: {
    dropdown_search_title: 'Search for area',
    dropdown_search_sub_title: 'Start typing to find your area'
  },
  br_searchable_table: {
    filter_by_date: 'Date',
    empty_view: {
      title: 'There is no data to view',
      no_search_title: 'No search results found'
    }
  },
  fulfillment: {
    tabs: {
      products: 'Products',
      inventory_requests: 'Inventory requests'
    },
    inventory: {
      business: 'Business',
      title: 'Inventory',
      subtitle:
        'Manage your inventory stock and enjoy faster fulfillment with Bosta.',
      analytics_section: {
        analytics_header: 'Inventory Summary',
        inventory_details: {
          total_sales: 'Total Sales',
          avg_orders: 'Avg. Orders/Day',
          avg_time: 'Fullfilment Avg. Lead Time',
          delivery_avg_time: 'Delivery Avg. Lead Time',
          damaged_orders: 'Damaged Orders',
          rts: 'Returned to stock',
          days: '{days} Days',
          hours: '{hours} Hours',
          orders: '{orders} Orders',
          tooltips: {
            total_sales: 'Total amount collected for your delivered orders',
            avg_orders: 'Number of orders per day',
            avg_time: 'The time taken for the orders to get fulfilled',
            delivery_avg_time: 'The time taken for the orders to get delivered',
            damaged_orders: 'Number of damaged orders',
            rts: 'Number of returned to stock orders'
          }
        }
      },
      top_selling: 'Total 10 Selling SKUs',
      products_count: '{count} Products',
      table: {
        empty_table_title: 'Add your first product',
        empty_table_subtitle:
          'Your products will be listed here once we receive them from your side',
        empty_table_button: 'Add Product',
        bosta_sku: 'Product SKU',
        product_name: 'Product Name',
        price: {
          title: 'Price',
          price: '{value} EGP'
        },
        quantity: {
          title: 'Forecasted Quantity',
          quantity_tooltip:
            'Computed as: Onhand quantity - Outgoing + Incoming',
          quantity_cardsRow: 'Forecasted Quantity {value}',
          onhand_quantity: 'Onhand Quantity',
          onhand_quantity_tooltip: 'Available quantity'
        },
        category: {
          title: 'The Product Category',
          category_list: {
            general: 'General',
            all: 'All',
            computing: 'Computing',
            laptops: 'Laptops',
            desktop_computers: 'Desktop Computers',
            'computer_accessories_&_components':
              'Computer Accessories & Components',
            networking_products: 'Networking Products',

            electronics: 'Electronics',
            't.vs_&_videos': 'T.Vs & Videos',
            home_audio: 'Home Audio',
            cameras: 'Cameras',
            headphones: 'Headphones',

            fashion: 'Fashion',
            "men's_fashion": "Men's Fashion",
            "women's_fashion": "Women's Fashion",
            "kid's_fashion": "Kid's Fashion",

            gaming: 'Gaming',
            play_station_5: 'Play Station 5',
            play_station_4: 'Play Station 4',
            xbox: 'XBOX',
            pc_gaming: 'PC Gaming',

            'health_&_beauty': 'Health & Beauty',
            'beauty_&_personal_care': 'Beauty & Personal Care',
            hair_care: 'Hair Care',
            fragrance: 'Fragrance',
            make_up: 'Makeup',
            health_care: 'Health Care',

            'phones_&_tablets': 'Phones & Tablets',
            mobile_phones: 'Mobile Phones',
            tablets: 'Tablets',
            mobile_accessories: 'Mobile Accessories'
          }
        },
        last_order: 'Last Order',
        action: {
          title: 'Action',
          menu_items: {
            create_order: 'Create Order',
            product_details: 'View Product Details'
          }
        },
        cards_row: {
          bosta_sku: 'Item Code (SKU) {value}'
        }
      },
      action_options: {
        view_details: 'View Details',
        create_product: 'Create Product'
      },
      search_section: {
        search_placeholder: 'Search by Product Name or Product SKU',
        filter: {
          title: 'Filter'
        },
        export: {
          title: 'Export'
        }
      },
      product_not_found:
        'No Products Found Try changing the filters or search term',
      product_details: {
        title: 'Product Details',
        product_name: 'Product Name',
        product_description: 'Product Description',
        no_image: 'No Image',
        sales_price: 'Sales Price',
        product_category: 'Product Category',
        internal_reference: 'Internal Reference',
        product_sku: 'Product SKU',
        invoicing_policy: {
          title: 'Invoicing Policy',
          order: 'Ordered quantities',
          delivery: 'Delivered quantities'
        },
        barcode: 'Barcode',
        available_quantity: 'Available Quantity',
        product_color: 'Product Color',
        product_size: 'Product Size',
        create_order: 'Create Order',
        product_type: {
          title: 'Product Type',
          consumable: 'Consumable',
          service: 'Service',
          product: 'Product'
        }
      }
    },
    inventory_requests: {
      title: '{count} Requests',
      search_placeholder: 'Search by request ID',
      delete_request_title: 'Delete Request #{requestNumber}',
      cancel_request_title: 'Cancel Request #{requestNumber}',
      delete_request_confirmation_message:
        'Are you sure you want to delete this request?',
      cancel_request_confirmation_message:
        'Are you sure you want to cancel this request?',
      request_deleted_successfully: 'Your request deleted successfully',
      request_canceled_successfully: 'Your request canceled successfully',
      filters: {
        all: 'All',
        processing: 'Processing',
        received: 'Received',
        partially_received: 'Partially received',
        canceled: 'Canceled',
        received_date: 'Received Date',
        request_status: 'Request Status'
      },
      table_columns: {
        request_id: 'Request id',
        bosta_warehouses: 'BOSTA Warehouses',
        no_of_products: 'No. of PRODUCTS',
        request_date: 'request Date',
        status: 'Status',
        received_tooltip:
          'Your request is successfully received at Bosta warehouses.',
        canceled_tooltip:
          'Your request has been cancelled. Feel free to create a new one whenever you need.',
        processing_tooltip:
          'Your request has been created. It is expected to receive your products as soon as possible.',
        partially_received_tooltip:
          'There is a difference in the total quantity when receiving your products at Bosta warehouses.',
        can_not_cancel_tooltip: 'You can only cancel if it is processing.',
        can_not_Edit_tooltip: 'You can only edit if it is processing.',
        view_details: 'View details'
      }
    },
    inventory_request_details: {
      products: 'Products',
      page_title: 'Request #{requestId}',
      shipment_details: 'Shipment details',
      sku: 'SKU',
      quantity: 'Quantity',
      recieved: 'recieved',
      sending_to: 'Sending to',
      date: 'Date',
      time: 'Time',
      notes: 'Notes',
      notes_placeholder: 'Add notes to the warehouse'
    },
    fulfillment_service: 'Fulfillment Service',
    fulfillment_flag: 'FXF',
    fulfillment_orders: 'Fulfillment Orders',
    activate: 'Activate',
    deactivate: 'Deactivate',
    active: 'Active',
    inactive: 'Inactive',
    fulfillment_activation:
      'Are you sure you want to {value} the fulfillment service?'
  },
  business_details: {
    title: 'Business Details',
    business_info: {
      title: 'Business Info',
      business_name: 'Business Name',
      business_arabic_name: 'Business Arabic Name',
      sales_channel: 'Sales Channel',
      business_category: 'Business Category',
      status: 'Status',
      phone: 'Phone',
      valid: 'Valid',
      invalidate: 'Invalidate',
      no_of_pickup_location: 'Number Of Pickup Locations',
      type: 'Type',
      industry: 'Industry',
      website: 'Website',
      store_url: 'Store URL',
      invalidation_reason: 'Invalidation Reason',
      duplicate_account: 'Duplicate of Account',
      service_not_available: 'Service not available',
      invalidation_note: 'Invalidation Note',
      description: 'Description',
      business_category: 'Business Category',
      account_type: 'Account Type',
      individual: 'Individual',
      company: 'Company',
      legal_documents: 'Legal Documents',
      payment_documents: 'Payment Documents',
      no_document_upload: 'No Documents Uploaded',
      download_document: 'Download Documents',
      recurring_pickup: 'Recurring Pickups',
      placeholders: {
        business_name: 'Business Name',
        business_arabic_name: 'Business Arabic Name',
        phone: 'Phone',
        description: 'Description',
        industry: 'Industry',
        sales_channel: 'Sales Channel',
        description: 'Description'
      },
      netsuit_id: 'NetSuit ID',
      reg_src: 'Sign-up source'
    },
    business_location: {
      title: 'Business Location',
      sub_title: 'This is the address in which the business is performed.',
      placeholders: {
        firstLine: 'First Line',
        city: 'City',
        area: 'Area',
        building: 'Building No',
        floor: 'Floor No',
        apartment: 'Apartment No',
        landmarks: 'Landmarks'
      }
    },
    business_default_pickup_location: {
      title: 'Default Pickup Location'
    },
    business_default_pickup_contacts: {
      title: 'Default Pickup Location Contacts',
      add_return_contact: 'Add Return Contact',
      table_columns: {
        contact_name: 'Contact Name',
        return_default_contact: 'Return Default Contact',
        pickup_default_contact: 'Pickup Default Contact'
      }
    },
    business_bank_info: {
      title: 'Business Bank Info',
      international: 'International Bank Info',
      bank_name: 'Bank Name',
      account_owner_name: 'Account Owner Name',
      iban: 'IBAN Number',
      account_number: 'Account Number'
    },
    pricing_info: {
      title: 'Pricing Info',
      tier: 'Tier',
      zero_cod_discount: 'Zero COD discount',
      extra_cod_fees: 'Extra COD fees',
      insurance_fees: 'Insurance fees',
      next_day_cod_fees: 'Next Day COD Fees',
      cod_fees: 'COD fees',
      pos_fees: 'CCOD fees',
      applied: 'Applied',
      not_applied: 'Not Applied',
      bosta_material_fee: 'Bosta material fee',
      open_package_fees: 'Opening package fees',
      sllr_pay: 'Sllr Pay',
      allow_refund: {
        label: 'Allow Refund',
        disabled_tooltip: 'This can only be editable by the “Accountant” role.”'
      }
    },
    wallet_payment_info: {
      title: 'Wallet Payment Info',
      subtitle:
        'Configure the properties which will impact the order pricing and wallet behavior.',
      business_type: 'Business Type',
      business_category: 'Business Category',
      business_tier: 'Business Tier',
      payment_gateway: 'Payment Gateway',
      pricing_plan: 'Pricing Plan',
      payment_type: 'Payment Type',
      credit_limit: 'Credit Limit',
      cashout_frequency: 'Cash-out Frequency',
      fulfillment_plan: 'Fulfillment Pricing Plan'
    },
    international_wallet: {
      title: 'International Wallet Payment Info',
      subtitle:
        'Configure the properties which will impact the international order pricing and wallet behavior.',
      pricing_plan: 'International Pricing Plan',
      cashout_frequency: 'Cash-out Frequency',
      edit_plan_modal_title: 'Edit international tier',
      edit_plan_modal_placeholder: 'Enter new international tier ID',
      edit_price_modal: 'Enter new price'
    },
    operations_settings: {
      title: 'Operations Settings',
      number_of_attempts: 'Number of Attempts',
      proof_of_return: 'Proof of return',
      otp: 'OTP',
      signature: 'Signature',
      attempts: '{number} Attempts',
      pickup_proof: 'Pickup Proof'
    },
    api_key: {
      title: 'API KEY'
    },
    logs: {
      title: 'Logs',
      table_columns: {
        type: 'Log Type',
        value: 'Value',
        date: 'Date',
        taken_by: 'Taken By'
      }
    },
    api_integration: {
      title: 'Api Integration',
      table_columns: {
        name: 'Name',
        key: 'Key',
        created_at: 'Created At',
        state: 'State',
        permission: 'Permission',
        actions: {
          title: 'Actions'
        }
      },
      states: {
        enabled: 'Enabled',
        disabled: 'Disabled'
      },
      permissions_scopes: {
        read: 'Read',
        read_write: 'Read/Write',
        full_access: 'Full Accress'
      },
      remove: {
        title: 'Are you sure you want to delete this api-key?',
        message: 'By deleting this key all traffic will be blocked'
      },
      create_edit_modal: {
        title: 'API Key Integration',
        key_name: 'Name',
        key_active_state: 'State Enabled'
      }
    },
    team_members: {
      title: 'Team Members',
      organizational_account: 'This is the account owner.',
      table_columns: {
        name: 'Name',
        phone: 'Phone',
        email: 'Email',
        job_title: 'Job Title',
        permissions: 'Permissions',
        status: 'Status',
        actions: 'Actions',
        edit_email: 'Edit email',
        edit_phone: 'Edit phone'
      },
      state: {
        10: 'Invited',
        15: 'Invitation opened',
        20: 'Active',
        30: 'Not Active'
      },
      otp_screens: {
        business_validation: {
          edit_phone: {
            title: 'To confirm the business ownership of this account.',
            subtitle:
              'Ask the business to give you a 4 digit code that is sent to their email address. Enter this code below.'
          },
          edit_email: {
            title: 'To confirm the business ownership of this account.',
            subtitle:
              'Ask the business to give you a 4 digit code that is sent to their phone number ending with {phone}. Enter this code below.'
          }
        },
        business_verification: {
          edit_phone: {
            title: 'To confirm the business ownership of the new phone',
            subtitle:
              'Ask the business to give you a 4 digit code that is sent to their new phone ending with {phone}. Enter this code below.'
          },
          edit_email: {
            title: 'To confirm the business ownership of this account.',
            subtitle:
              'Ask the business to give you a 4 digit code is sent to their new email address ending with {email}. Enter this code below.'
          }
        }
      },
      job_titles: {
        owner: 'Owner',
        administration: 'Administration',
        sales: 'Sales',
        customer_service: 'Customer Service / Account Manager',
        finance_and_accounting: 'Finance and Accounting',
        operations_manager: 'Operations Manager',
        operations_supervisor: 'Operations Supervisor',
        operations_clerk: 'Operations Clerk',
        marketing_and_advertising: 'Marketing and Advertising',
        legal: 'Legal',
        product_manager: 'Product Manager',
        procurement: 'Procurement'
      }
    },
    bosta_shop: {
      title: 'Flyers',
      current_quota: 'Current merchant quota',
      consumed_quota: 'Consumed quota',
      add_quota: 'Add extra quota',
      action: 'Action',
      add_quota_modal: {
        title: 'Add Free Quote',
        extra_amount: 'Business quota',
        reason: 'Reason'
      },
      initial_quota: 'Initial quota'
    },
    sllr: {
      title: 'Sllr Info',
      load_btn: 'Load More Sllr Info',
      basic_info_title: 'Sll Basic Info',
      table_columns: {
        sllr_store: 'Sllr Store',
        store_name: 'Store Name',
        store_link: 'Store Link',
        products_inventory: 'Products_I',
        products_catalog: 'Products_C',
        activated_bosta: 'Activated Bosta',
        placed_orders: 'Placed orders',
        confirmed_orders: 'Confirmed orders',
        pickedup_orders: 'Picked up orders',
        successful_orders: 'Successful orders',
        total_sllr_revenue: 'Total Sllr revenue',
        business_status: 'Business Status'
      }
    },
    export_bank_info_success:
      'Bank Info loaded successfully, exporting in progress.',
    export_bank_info: 'Export Bank Details',
    bulk_promotion: 'Promotions Upload',
    api_key_approve: 'Business API Key has been approved successfully',
    api_generate_success: 'API key generated successfully',
    api_key_waiting:
      'This business has finished testing and waiting for approval',
    api_approve: 'Approve Live Integration',
    generate_new_key: 'Generate New Key',
    promotions: {
      title: 'Promotions',
      table_columns: {
        created: 'Created At',
        active: 'Effective From',
        inactive: 'Expiry Date',
        target: 'Target',
        subtype: 'Subtype',
        percentage: 'Percentage',
        actions: 'Actions',
        taken_by: 'Taken By',
        value: 'Value'
      },
      button: {
        add: 'Add promotion'
      },
      delete_modal: {
        title: 'Delete promotion',
        message: 'Are you sure you want to delete this promotion?'
      }
    },
    consignee_notifications: {
      title: 'Consignee Notifications',
      send_automated_calls: 'Send automated calls with exceptions',
      success_message: 'Field is updated successfully'
    },

    business_invalidation: {
      select_business_label: 'Choose Business Name',
      field_is_required: 'TThis field is required',
      reasons: {
        duplicate: 'Duplicate account',
        unpermitted: 'Unpermitted items',
        service_not_available: 'Service not available',
        testing_account: 'Testing Account',
        competition: 'Competition',
        lost: 'Lost',
        not_interested: 'Not interested',
        dead: 'Dead'
      },
      services_reasons: {
        fulfillment: 'Fulfillment',
        international: 'International',
        same_day_delivery: 'Same day delivery',
        bulky: 'Bulky',
        c_to_c: 'C2C',
        banks_documents: 'Banks/Documents',
        other: 'Other'
      }
    },
    activating_recurring_pickups: {
      activate_title: 'Activate Recurring Pickups',
      activate_content:
        'Are you sure you want to activate recurring pickups for <span>{businessName} ({businessId})</span>?',
      deactivate_title: 'Deactivate Recurring Pickups',
      deactivate_content:
        'Are you sure you want to deactive recurring pickups for <span>{businessName} ({businessId})</span>?'
    }
  },
  businesses: {
    title: 'Businesses',
    search_filters: {
      title: 'Search Businesses:',
      name: 'Name',
      business_id: 'Business ID',
      email: 'Email',
      phone: 'Phone',
      city: 'City',
      status: 'Status',
      registered_at: 'Registered At',
      active_at: 'Active At',
      promotion_upload: 'Promotion Upload',
      export_bank_details: 'Export Bank Details',
      bulk_upload: 'Bulk Upload',
      export_all_businesses: 'Export All Businesses',
      bulk_update_pricing_tier: 'Bulk Update Pricing Tiers'
    },
    table_columns: {
      title: 'Total Businesses: {count}',
      business_name: 'Business Name',
      address: 'Address',
      registered_at: 'Registered At',
      status: 'Status',
      account_manager: 'Account Manager',
      sales_manager: 'Sales Manager',
      actions: 'Actions'
    },
    status: {
      valid: 'Validate Business',
      invalid: 'Invalidate Business'
    },
    actions: {
      assign_account_manager: {
        title: 'Assign Account Manager',
        placeholder: 'Select Account Manager'
      },
      assign_sales_manager: {
        title: 'Assign Sales Manager',
        placeholder: 'Select Sales Manager'
      },
      add_note: 'Add Note'
    },
    invalidate_business_modal: {
      title: 'Select Invalidation Reason',
      choose_reason: 'Choose Invalidated Reason',
      enter_service_name: 'Enter the service name',
      note_label: 'Note (Optional)'
    },
    add_note_modal: {
      title: 'Enter the Note',
      label: 'Note'
    },
    delete_business_modal: {
      title: 'Delete Business',
      content: 'Are you sure you want to delete Business?'
    }
  },
  deliveries: {
    details: {
      payment_method: 'Payment method'
    },
    search_filters: {
      title: 'Search',
      tracking_number: 'Tracking Number',
      delivery_type: 'Delivery Type',
      package_size: 'Package Size',
      delivery_state: 'Delivery State',
      soho_pickups: 'SOHO Pickups',
      hub: 'Hub',
      in_transit_between_hubs: 'In transition between hubs',
      exception_by: 'Exception By',
      business: 'Business',
      business_id: 'Business ID',
      business_reference: 'Business Reference',
      invalid_business: 'Is Invalid Business',
      customer_first_name: 'Customer First Name',
      customer_last_name: 'Customer Last Name',
      customer_phone: 'Customer Phone',
      fxf_orders: 'Fulfillment Orders',
      sllr_orders: 'SLLR Orders',
      international_orders: 'International Orders',
      stars: 'Stars',
      number_of_attempts: 'Number of Attempts',
      located_in_hub: 'Located In Hub',
      hub_shelves: 'Shelf/Bin',
      active_deliveries_within_sla: 'Active deliveries within E2E SLA',
      active_deliveries_exceeded_sla: 'Active deliveries exceeded E2E SLA',
      active_deliveries_within_promise_sla:
        'Active deliveries within delivery promise SLA',
      active_deliveries_exceeded_promise_sla:
        'Active deliveries exceeded delivery promise SLA',
      deliveries_with_fake_attempts: 'Deliveries with fake attempts',
      on_hold: 'On Hold',
      ofd_by_star: 'OFD By Star',
      in_progress: 'In Progress',
      assigned_hub: 'Assigned Hub',
      notes: 'Notes',
      number_of_calls: 'No. of Calls',
      number_of_sms: 'No. of SMSes',
      pickedUpAt: 'Picked At',
      deliveredAt: 'Delivered At',
      exceptionAt: 'Exception At',
      canceledAt: 'Cancelled At',
      createdAt: 'Created At',
      lostAt: 'Lost At',
      damagedAt: 'Damaged At',
      ofdAt: 'OFD At',
      investigationAt: 'Investigation At',
      scheduledAt: 'Scheduled At',
      slaAt: 'E2E SLA At',
      deliverySlaAt: 'Delivery Promise SLA At',
      confirmedAt: 'Confirmed At',
      collectedAt: 'Collected From Consignee',
      smart_sticker_no: 'Smart Sticker No.',
      sub_account: 'Sub Account',
      payment_methods: 'Payment Methods',
      international_tracking: 'International Tracking Number'
    },
    search_filters_placeholder: {
      tracking_number: 'Enter tracking numbers seperated by a comma or space.',
      business_id: 'Enter business ID',
      business_reference: 'Enter business reference',
      customer_first_name: 'Enter customer first name',
      customer_last_name: 'Enter customer last name',
      customer_phone: 'Enter customer phone number',
      notes: 'Enter notes',
      smart_sticker_no: 'Enter Smart Sticker No.'
    },
    export_bank_info_success:
      'Bank Info loaded successfully, exporting in progress.',
    export_bank_info: 'Export Bank Details',
    export_error: 'Please select filters first.',
    states: {
      archived: 'Archived',
      created: 'Created',
      route_assigned: 'Route assigned',
      picked_up_from_business: 'Picked up from business',
      recieved_at_warehouse: 'Received at warehouse',
      in_transit_between_hubs: 'In transit between Hubs',
      in_transit: 'In Transit',
      out_for_delivery: 'Out for delivery',
      deliverd: 'Delivered',
      exception: 'Exception',
      returned_to_business: 'Returned to business',
      on_hold: 'On hold',
      canceled: 'Canceled',
      deleted: 'Deleted',
      lost: 'Lost',
      damaged: 'Damaged',
      investigation: 'Investigation',
      requested: 'Requested',
      pickup_route_assigned: 'Pickup route assigned',
      picked_up: 'Picked up',
      out_for_pickup: 'Out for pickup',
      out_for_return: 'Out for Return',
      picked_up_from_consignee: 'Picked up from consignee',
      waiting_for_route: 'Waiting for route',
      out_for_collection: 'Out for collection',
      collected: 'Collected',
      exchanged_returned: 'Exchanged & Returned',
      returned_to_origin: 'Returned to origin',
      returned: 'Returned',
      pickup_requested: 'Pickup Requested',
      debriefed_successfully: 'Debriefed Successfully',
      on_hold_three_attempts: 'Onhold (3rd exception)',
      fulfilled: 'Fulfilled',
      returned_to_stock: 'Returned to Stock',
      in_transit_to_bosta: 'In transit to Bosta',
      received_at_bosta_warehouse: 'Received at Bosta warehouse',
      awaiting_business_action: 'Awaiting for business action',
      recieved_at_warehouse_pudo: 'Received at PUDO warehouse',
      out_for_delivery_pudo: 'Waiting for customer pickup',
      deliverd_pudo: 'Delivered',
      cancelled_pudo: 'RTO- canceled',
      returned_to_fulfillment_center: 'Returned to FC'
    },
    delivery_details: {
      delivery_images: {
        consignee_images_header: 'Images taken by Business',
        star_images_header: ' Images taken by Star'
      },
      goods_value: {
        value: 'Item Value',
        proof: 'Proof of value'
      },
      header: {
        more_actions: 'More Actions',
        business_view: 'Business View',
        print_awb: 'Print Air Waybill',
        print_awb_final_state:
          "The order is in a final state. You can't print the AWB",
        reschedule: 'Reschedule',
        rate_star: 'Rate Star',
        print_por: 'Print POR',
        escluate_order: 'ESCALATE ORDER',
        cancel_escalation: 'Cancel Escalation',
        cancel_escalation_success: 'Ticket escalation canceled successfuly',
        ccod: 'CCOD',
        cancel: 'Cancel Order'
      },
      escalation_modal: {
        success_msg: 'Delivery escalated successfuly',
        title: 'Escalate delivery',
        select_placeholder: 'Select severity level',
        header: 'Severity level',
        delete_severity: 'Delete severity',
        severy_option_1: 'Severity 1',
        ticket_number: 'Ticket Number'
      },
      cancel_escalation_modal: {
        title: 'Cancel Escalation',
        content:
          'are you sure you want to Cancel Escalation for this delivery?',
        confirm: 'Confirm',
        cancel: 'cancel'
      },
      rate_star_modal: {
        title: 'Ask the consignee to rate the star from 1 to 10.',
        rate_validation_error: 'rate is required!',
        consignee_feedback_validation_error: 'consignee feedback is required!'
      },
      proof_of_return: {
        action: 'Proof of return',
        por_is_otp:
          'OTP is the proof of return on this order, No signature is available.',
        error_msg: 'Proof of return is not available.'
      },
      exception_log: {
        open_image: 'Open image',
        no_image: 'No return rejection image is available'
      },
      call_logs: {
        title: 'Call Logs',
        call_log: 'Call Log',
        sms: 'SMS',
        outgoing_call: 'Outgoing Call',
        missed_call: 'Missed Call',
        incoming_call: 'Incoming Call',
        whatsapp: 'WhatsApp',
        whatsapp_message: 'Star sent WhatsApp message'
      },
      cancel_modal: {
        title: 'Please add the Ticket-ID to cancel this order',
        cancel_success: 'Cancellation request has been added successfully!'
      },
      delivery_logs: {
        order_repacked: 'Order repacked',
        ticket_log:
          'A ticket is created with ID: [<Link>{ticketId}</Link>] based on {requester} request.',
        business: 'business',
        consignee: 'consignee',
        admin: 'admin',
        agent_name: 'Agent Name',
        refusal_reason: {
          package_was_open: 'Package was open',
          missing_product: 'A product was missing',
          broken_shipment: 'Shipment was broken',
          not_consignee_shipment: "Not consignee's shipment"
        }
      },
      delivery_rate: {
        title: 'Consignee Rate',
        delivery_experience_rate: 'Delivery experience rate'
      }
    }
  },
  escalation: {
    ticket_age: '{escalationAge, plural, one {# day} other {# days}}',
    orders: '{ordersNumber, plural, one {# order} other {# orders}}',
    table_search_placeholder: 'Search by Ticket Number or Order ID.',
    hubs_filter_placeholder: 'Select Hub',
    severity_level: 'Severity {level}',
    escalation_table_columns: {
      order_id: 'ORDER ID',
      ticket_category: 'TICKET CATEGORY',
      state: 'State',
      type: 'Type',
      recieved_hub: 'RECEIVED HUB',
      severity_level: 'SEVERITY LEVEL',
      ticket_number: 'TICKET NUMBER',
      ticket_age: 'TICKET AGE',
      order_age: 'Order age'
    },
    transfers_aging: {
      filters: {
        title: 'Filter',
        form_labels: {
          current_hub: 'Current Hub',
          transferred_hub: 'Transferred Hub',
          order_aging: 'Order Aging'
        }
      },
      table_columns: {
        tracking_number: 'Tracking Number',
        type: 'Type',
        business_name: 'Business Name',
        current_hub: 'Current Hub',
        transferred_hub: 'Transferred Hub',
        no_of_transfers: '#No. Transfers',
        order_aging: 'Order Aging (Days)'
      },
      table_search_placeholder: 'Search by Tracking Number',
      forward: 'Forward',
      return: 'Return'
    },
    edit_escalation_modal: {
      hub_transfer: 'Change Assigned Hub',
      delete_escalation: 'Delete Escalation',
      edit_escalation: 'Edit Escalation',
      hub_dropdown_label: 'hub',
      severity_dropdown_lable: 'Severity level',
      select_hub_place_holder: 'select a hub',
      select_severity_place_holder: 'Escalation level'
    }
  },
  ops_control: {
    hub_selector_placeholder: 'All Hubs',
    overview_label: 'Overview',
    sorting_facility_label: 'Sorting Facility',
    pending_in_transit_label: 'Pending in Transit',
    sorting_facility: {
      to_sorting_facility: 'To Sorting Facility',
      recieved_at_sorting_facility: 'Received at SF',
      total_recieved_at_sf: '(Total Received now: {total})',
      transferred_from_hubs: 'Transferred from hubs',
      fresh: 'Fresh',
      transferred: 'Transferred',
      rto: 'RTO'
    },
    dispatching: {
      dispatching_title: 'SF Dispatching',
      over_all_recieved: ' (Overall received: {total})',
      ready_to_dispatch: 'Ready to dispatch',
      dispatched: 'Dispatched',
      completed: 'Completed',
      seals_number: ' ({seals_number, plural, one {# seal} other {# seals}})',
      all_pending: 'All Pending',
      pending_transfer: 'Pending Transfer',
      pending_dispatching: 'Pending Dispatching',
      lost: 'Lost',
      damaged: 'Damaged'
    },
    orders_table: {
      header: 'Orders Overview',
      export_orders: 'Export Orders',
      export_orders_discreption:
        'Earlier dates Orders can be only accessed through Excel sheets',
      columns: {
        tracking_number: 'TRACKING NUMBER',
        business_name: 'BUSINESS NAME',
        order_type: 'ORDER TYPE',
        order_state: 'ORDER STATE',
        transferred_to: 'TRANSFERRED TO',
        picked_up: 'PICKED-UP',
        last_receive: 'LAST RECEIVE',
        seal_number: 'SEAL NUMBER'
      },
      filters: {
        transfer_to: 'Transfer to',
        order_state: 'order state',
        order_type: 'order type',
        business: 'Business',
        order_state_menu: {
          received_label: 'Received at warehouse',
          ready_to_dispatch_label: 'Ready to dispatch'
        }
      },
      search_placeholder: 'Search by TN/Seal number'
    },
    pending_transit: {
      header: 'All Hubs',
      totat_pending_orders: 'Total pending in transit orders',
      totat_pending_orders_plus: 'Total pending in transit orders +1 day',
      toggle_bottons: {
        all: 'All',
        forward: 'Forward',
        return: 'Return'
      },
      hubs_table: {
        title: ' Hubs Overview',
        hub: 'HUB',
        pending_from: 'PEND. TRANSIT FROM',
        pending_to: 'PEND. TRANSIT TO',
        total_pending: 'TOTAL PEND. TRANSIT',
        pending_from_plus: 'PEND. TRANSIT +1 DAY FROM',
        pending_to_plus: 'PEND. TRANSIT +1 DAY TO',
        total_pending_plus: 'TOTAL PEND. +1 TRANSIT',
        max_order_age: 'MAX ORDER AGING'
      }
    },
    hub_deliveries: {
      total_pending: 'Total Pending in transit',
      total_pending_plus: 'Total Pending in transit +1 day',
      overall_pending: '(Overall: {total})',
      overall_pending_plus: '(Overall: {total})',
      transferred_from_hub: 'Transferred from selected hub',
      transferred_to_hub: 'Transferred to selected hub',
      deliveries_table: {
        tracking_number: 'TRACKING NUMBER',
        transferred_from: 'TRANSFERRED FROM',
        transferred_to: 'TRANSFERRED TO',
        seal_number: 'SEAL NUMBER',
        trasnfer_aging: 'TRANSFER AGING (DAYS)',
        e2e_aging: 'E2E AGING (DAYS)',
        search_placeholder: 'Search by TN or seal number'
      }
    },
    hubs_overview: {
      title: 'Hubs Overview',
      hubs_table: {
        hub: 'HUB',
        to_be_dispatched: 'TO BE DISPATCHED',
        dispatched: 'DISPATCHED',
        late: 'LATE',
        missed: 'MISSED'
      },
      single_cards_titles: {
        to_be_dispatched: 'To be dispatched',
        pending: 'Pending "Not received"',
        added_to_route: 'Added to route',
        dispatched: 'Dispatched',
        late: 'Late',
        missed: 'Missed'
      }
    },
    hub_view: {
      table_columns: {
        tracking_number: 'TRACKING NUMBER',
        comments: 'COMMENTS',
        order_type: 'ORDER TYPE',
        hub_aging: 'HUB AGING (DAYS)',
        e2e_aging: 'E2E AGING (DAYS)',
        attempts: 'ATTEMPTS'
      }
    }
  },
  analytics: {
    title: 'Analytics',
    select_placeholder: 'Please select',
    today: 'Today',
    yesterday: 'Yesterday',
    last_week: 'Last week',
    last_month: 'Last month',
    last_three_months: 'Last 3 months',
    route: 'Route',
    action: 'Action',
    ticket: 'Ticket',
    req: 'Req',
    prcl: 'Prcl',
    prcls: 'Prcls',
    le: 'L.E.',
    transfer: 'Transfer',
    business: 'Business',
    star: 'Star',
    out_of_total: 'Out of Total',
    pm: 'PM',
    total_businesses: 'Businesses',
    out_of_received_prcls: 'of prcls you received',
    out_of_prcls_dispatched: 'of prcls you dispatched',
    out_of_total_picked: 'of prcls picked up',
    hub_overview: {
      title: 'Hub Overview',
      header: {
        hubs: 'Hubs',
        all_hubs: 'All Hubs'
      },
      cards: {
        parcels_summary: 'Parcels Summary',
        delivered_mtd: 'Delivered MTD',
        delivered_mtd_tooltip: `The total number of packages delivered since the 1st day of the current month
        Percentage is the number delivered packages out of total pickups of the same period`,
        exceeded_sla: 'Exceeded E2E SLA',
        exceeded_sla_tooltip: `The number of packages exceed their order age
        Percentage is the number of packages exceeded E2E SLA out of total pickups of the same period`,
        lost_and_damaged: 'Lost or Damaged',
        lost_and_damaged_tooltip:
          'The total number of deliveries that were marked as lost or damaged in the selected date range',
        on_hold: 'On Hold',
        on_hold_tooltip:
          'The number of deliveries received an exception by stars and awaits the confirmation of the outbound team',
        pickup_requests: 'Pickup Requests',
        cancelled: 'Canceled',
        cancelled_tooltip:
          'The number of pickup requests that were canceled without the reason "Failed to pickup" in the selected date range',
        failed: 'Failed',
        failed_tooltip:
          'The number of pickup requests that were canceled with reason "Failed to pickup" in the selected date range',
        number_of_requested_business: 'Scheduled By',
        number_of_requested_business_tooltip:
          'The total number of businesses scheduled pickup requests in the selected date range',
        requested: 'Scheduled Requests',
        requested_tooltip:
          'The total number of pickup requests scheduled in the selected date range',
        success: 'Succeeded',
        success_tooltip:
          'The number of pickup requests that were marked as successfully picked up in the selected date range',
        picked_up_deliveries: 'Picked up Deliveries',
        number_of_picked_business: 'Picked up From',
        number_of_picked_business_tooltip:
          'The number of businesses that we picked deliveries from in the selected date range',
        in_hub: 'In Hub',
        in_hub_tooltip:
          'The number of picked up packages that were picked up and are going to be delivered by the same hub',
        out_of_hub: 'Out of Hub',
        out_of_hub_tooltip:
          'The number of picked up packages that should be transfered to the sorting facility',
        picked: 'Picked Up Deliveries',
        picked_tooltip:
          'The number of deliveries picked up in the selected date range',
        dispatch: 'Dispatch',
        active_stars: 'Stars on Routes',
        active_stars_tooltip:
          'The number stars that had a route in the selected date range',
        late_dispatch: 'Late Dispatch',
        late_dispatch_tooltip:
          'The number of deliveries that were scheduled to be dispatched on the selected date range but were dispatched after the Cutt-off time',
        missed_dispatch: 'Missed Dispatch',
        missed_dispatch_tooltip:
          'The number of deliveries that were scheduled to be dispatched on the selected date range but were not dispatched',
        number_of_businesses: 'For Businesses',
        number_of_businesses_tooltip:
          'The number of businesses that had packages scheduled for dispatch within the selected date range',
        pending_stars: 'Stars Pending Routes',
        pending_stars_tooltip:
          'The number of stars that are pending (waiting) for routes in the selected date range',
        to_be_dispatched: 'To be Dispatched',
        to_be_dispatched_tooltip:
          'The number of deliveries that are scheduled to be dispatched on the selected date range',
        first_missed_dispatch: 'First Missed Dispatch',
        first_missed_dispatch_tooltip:
          'The number of deliveries that were scheduled to be dispatched on the selected date range but were not dispatched until 5:00 PM',
        last_missed_dispatch: 'Last Missed Dispatch',
        last_missed_dispatch_tooltip:
          'The number of deliveries that were scheduled to be dispatched on the selected date range but were not dispatched until 11:00 PM',
        cut_off_time: 'Cut-off Time',
        cut_off_time_tooltip:
          'The deadline of performing the dispatching process. Hub should dispatch all scheduled packages before the cut-off time.',
        out_for_delivery: 'Out for Delivery',
        out_for_deliver_cancelled: 'Canceled',
        out_for_deliver_cancelled_tooltip: `The number of packages that received an exception with reason "Canceled by customer"within the selected date range 
        The percentage of packages that received an exception "Canceled by customer" out of the total OFD`,
        delivered: 'Delivered',
        delivered_tooltip: `The number of delivered packages within the selected date range
        The percentage of deliveried packages out of the total OFD`,
        exception: 'Exception',
        exception_tooltip: `The number of packages that received an exception within the selected date range - excluding the reasons 1. canceled by customer and 2. scheduled for another date
        The percentage of packages that received an exception within the selected date range - excluding the reasons 1. canceled by customer and 2. scheduled for another date out of the total OFD`,
        scheduled: 'Scheduled',
        scheduled_tooltip: `The number of packages that received an exception with reason "Scheduled for another date"within the selected date range 
        The number of packages that received an exception with reason "Scheduled for another date" out of the total OFD`,
        total_ofd: 'Total OFD',
        total_ofd_tooltip:
          'The number of deliveries that were dispatched ( out for delivery ) in a selected date range',
        routes: 'Routes',
        created_routes: 'Created',
        dispatched_routes: 'Dispatched',
        unassigned_routes: 'Unassigned',
        done_routes: 'Done',
        debriefed_routes: 'Debriefed',
        ops_actions: 'Ops Actions',
        ops_actions_needed: 'Actions Needed',
        ops_actions_taken: 'Actions Taken',
        ops_pending_actions: 'Pending Action',
        ops_all_pending_actions: 'All Pending Actions',
        outbound: 'Outbound',
        created_outBound: 'Created',
        active_outbound_actions: 'Active Actions',
        cleared_outBound_actions: 'Cleared',
        unreachable_outbound_actions: 'Unreachable',
        all_outbounds: 'All',
        avg_outbounds_per_agent: 'Average Per Agent',
        hub_performance: 'Hub Performance',
        received_at_warehouse: 'Prcls you received',
        dispatched: 'Dispatched',
        deliveried: 'Deliveried',
        next_day_attempt: 'NDA',
        next_day_delivery: 'NDD',
        first_day_delivery_attempt: 'FDDA',
        first_day_delivery_success: 'FDDS',
        rescheduled: 'Rescheduled',
        fakeUpdate: 'Fake updates',
        exceededE2ESla: 'Exceeded Sla',
        lost: 'Lost',
        damaged: 'Damaged',
        onHold: 'On Hold',
        transfers: 'Transfers',
        to_be_transferred: 'To Be Transferred',
        transferred: 'Transferred',
        late_transfer: 'Late Transfer',
        missed_transfer: 'Missed Transfer',
        incoming_transfer: 'Incoming Transfer',
        debrief: 'Debrief',
        packages_to_be_received: 'Packages To Be Received',
        packages_received: 'Packages Received',
        packages_in_investigation: 'Packages in Investigation',
        delivery_breakdown: 'Packages Breakdown',
        in_progress: 'In Progress',
        send: 'Send',
        exchange: 'Exchange',
        crp: 'CRP',
        cash_collection: 'Cash Collection',
        rto: 'RTO',
        cod_breakdown: 'COD Breakdown',
        total_cod: 'Total COD',
        pending_cod: 'Pending COD',
        collected_cod: 'Collected COD'
      }
    },
    business_overview: {
      title: 'Business Overview',
      header: {
        business: 'Business',
        all_businesses: 'All businesses',
        filter_by: 'Filter By',
        account_manager: 'Account Manager'
      },
      cards: {
        pickups_overview: 'Pickups Overview',
        scheduled_requests: 'Scheduled Requests',
        scheduled_requests_tooltip:
          'The total number of pickup requests scheduled in the selected date range',
        success: 'Succeeded',
        success_tooltip:
          'The number of pickup requests that were marked as successfully picked up in the selected date range',
        cancelled: 'Canceled',
        cancelled_tooltip:
          'The number of pickup requests that were canceled without the reason "Failed to pickup" in the selected date range',
        failed: 'Failed',
        failed_tooltip:
          'The number of pickup requests that were canceled with reason "Failed to pickup" in the selected date range',
        picked: 'Picked Up Deliveries',
        picked_tooltip:
          'The number of deliveries picked up in the selected date range',
        deliveries_overview: 'Deliveries Overview',
        shipments_in_progress: 'Shipping in Progress',
        shipments_in_progress_tooltip:
          'The number of packages that are not in a final state, not (Delivered, Returned to business, Created nor Pickup Requested)',
        out_for_delivery: 'Out for Delivery',
        out_for_delivery_tooltip:
          'The number of deliveries that were dispatched ( out for delivery ) in a selected date range',
        in_transit: 'In Transit',
        in_transit_tooltip:
          'The number of deliveries that were in transit state in the selected date range',
        in_hub: 'In Hub',
        in_hub_tooltip:
          'The number of deliveries that were stored in the hub in a selected date range',
        on_hold: 'On Hold',
        on_hold_tooltip:
          'The number of deliveries received an exception by stars and awaits the confirmation of the outbound team',
        lost: 'Lost or Damaged',
        lost_tooltip:
          'The total number of deliveries that were marked as lost or damaged in the selected date range',
        cancelled_deliveries: 'Cancelled',
        cancelled_deliveries_tooltip: '',
        delivered: 'Delivered',
        delivered_tooltip: '',
        exception: 'Exception',
        exception_tooltip: '',
        scheduled: 'Scheduled',
        scheduled_tooltip: '',
        deliveries_breakdown: 'Delivery Breakdown',
        in_progress: 'In Progress',
        send: 'Send',
        exchange: 'Exchange',
        crp: 'CRP',
        cash_collection: 'Cash Collection',
        rto: 'RTO'
      }
    }
  },
  shipments: {
    edit_order_successfully:
      'Delivery updated successfully!, Please print the Air waybill again after any update you do to an order.',
    title: 'orders',
    order_created_from_sllr: 'Ordered via Sllr store',
    exception_modal: {
      title: 'Exception Modal',
      reason_input: 'Add your reason here'
    },
    new_order: {
      create_title: 'Create Order',
      edit_title: 'Edit Order',
      first_step_sub_title: "You're three steps away from creating your order",
      second_step_sub_title: "You're two steps away from creating your order",
      third_step_sub_title: "You're one step away from creating your order",
      open_package: 'Opening Package',
      terms_and_conditions: 'Terms & Conditions',
      proof_value_upload_support: 'PNG, JPG, PDF(max. 5MBs)',
      tooltips: {
        package_description_tooltip:
          'Note that white bags are charged 20 EGP (including VAT) in addition to normal shipping fees per order.',
        open_package_tooltip:
          'Allowing customers to open package allows them to refuse taking it. In this case Bosta will return it back to you. Return fees will be applied.',
        work_Address_tootip:
          'Mark it to deliver it withing business days and working hours.',
        secondry_phone_tootltip:
          'Having another phone number will secure reaching the customer and delivering your package.',
        tooltips_light_bulky:
          'Big items like a Microwave, Fan, Carpet, Beanbags, side tables, etc..',
        tooltips_heavy_bulky:
          'White goods and furniture like an AC, Refrigerator, washing machine, sofa bed, dining table, bedroom, etc..',
        item_value_tooltip_title: 'How item value feature works: ',
        item_value_tooltip:
          'For a smoother experience, please ensure the correct cost is added to the items, along with proof of value. This helps us expedite any investigation in case something happens to your packag.',
        value_proof_tooltip_title: 'Proof of Cost',
        value_proof_tooltip:
          "Please provide proof of the item's value if it differs from the Cash on Delivery (COD) amount. This ensures we can assist you more efficiently and resolve any issues promptly.",
        proof_feature: {
          title: 'New Feature Alert! 🚀',
          subtitle:
            'We’re thrilled to introduce Item Value, a new feature designed to provide you with a seamless experience and enhanced protection for your shipments!'
        }
      },
      item_value_terms_modal: {
        title_1: 'Maximum Item Value',
        subtitle_1:
          'You can ship items with a maximum value of {max} {currency}. In case of any issues with your shipment, the maximum compensation amount is {max} {currency}.',
        title_2: 'Maximum Cash on Delivery (COD)',
        subtitle_2:
          'The maximum allowable Cash on Delivery (COD) amount is {maxCod} {currency}, which represents the amount collected from your customer upon delivery.'
      },
      item_value_err: 'Item value should be less than {max} {currency}',
      package_type: {
        parcel: 'Parcel',
        document: 'Document',
        light_bulky: 'Light Bulky',
        heavy_bulky: 'Heavy Bulky',
        sign_and_return: 'Sign & Return'
      },
      order_details: {
        title: 'Order Details',
        order_type: 'Order Type',
        package_type: 'Package Type',
        package_details: 'Package Details',
        package_weight: 'Package Weight',
        delivery_package_details: 'Delivery Package Details',
        return_package_details: 'Return Package Details',
        view_packing_guidelines: 'View Packing Guidelines',
        return_package: 'Return package details',
        collect_cash_from_customer: 'Collect Cash from Customer',
        refund_cash_to_customer: 'Refund Cash to Customer',
        open_package_fees_note: '{value} fees will apply to this delivery.',
        cod_greater_than_30000:
          'The COD amount should be less than or equal 30,000 EGP',
        cod_greater_than_limit:
          'The COD amount should be less than or equal {amount} EGP',
        cod_change_warning:
          'Changing COD won’t take place until the hub approves the request.',
        flyer_sizes: {
          small: 'Small',
          large: 'Large',
          x_large: 'X.Large',
          medium: 'Medium',
          white_bag: 'White Bag'
        },
        order_types: {
          deliver: 'Deliver',
          exchange: 'Exchange',
          return: 'Return',
          cash_collection: 'Cash Collection',
          deliver_sub_title: 'Deliver a package to your customer.',
          exchange_sub_title: 'Exchange packages with your customer',
          return_sub_title: 'Pickup a returned package from your customer.',
          cash_collection_sub_title: 'Collect or refund cash to your customer.',
          deliver_options: {
            with_cash_collection: 'With Cash Collection',
            without_cash_collection: 'Without Cash Collection'
          },
          exchange_options: {
            with_cash_difference: 'With cash difference',
            without_cash_difference: 'Without cash difference'
          },
          return_options: {
            with_refund: 'With refund',
            without_refund: 'Without refund'
          },
          cash_collection_options: {
            with_cash_collection: 'Collect cash from customer',
            with_refund: 'Refund cash to customer'
          }
        },
        package_types: {
          parcel: 'Parcel',
          document: 'Document',
          bulky: 'Bulky',
          heavybulky: 'Heavy Bulky',
          lightbulky: 'Light Bulky'
        },
        allowed_to_open_package: 'Allowed',
        not_allowed_to_open_package: 'Not Allowed',
        cod_greater_than_30000_text: 'Cash collection amount shall not exceed ',
        multi_packages_error:
          'Multi packages must be a number between {min} and {max}'
      },
      customer_details: {
        title: 'Customer Details',
        order_notes: 'Delivery Notes',
        address_info: 'Address Info',
        customer_info: 'Customer Info'
      },
      request_pickup: {
        title: 'Request Pickup',
        pickup_info: 'Pickup Info',
        pickup_created_successfully: 'Your pickup created successfully',
        skip_pickup_create_note:
          'You can skip requesting a pickup now but make sure to request a pickup when you have packages ready to be shipped.'
      },
      order_summary: {
        title: 'Order Summary',
        order_created_successfully: 'Your order has been created successfully',
        air_waybills_note:
          'Your packages cannot be shipped without the printed Air waybills.',
        download_airwaybill_successfully:
          'The Air waybill is downloaded successfully',
        skip_printing_note:
          'You can skip printing the Air waybill now and print it later, make sure to print them before the pickup day.'
      },
      request_pickup_modal: {
        title: 'Request New Pickup',
        select: 'Select',
        form_labels: {
          contact_person: 'Contact Person',
          pickup_location: 'Pickup Location',
          pickup_date: 'Pickup Date'
        },
        actions: {
          create_new_location: 'Create New Location',
          create_new_contact: 'Create New Contact',
          schedule_pickup: 'Schedule Pickup'
        }
      },

      form_labels: {
        send_cod: 'Cash on Delivery',
        forward_cod: 'Cash on Delivery',
        exchange_cod: 'Cash Exchange Amount',
        customer_return_pickup_cod: 'Refund Amount',
        cash_collection_cod: 'Cash to Collect',
        return_to_origin_cod: 'Cash on Delivery',
        cash_collection_cod_sub_title:
          'Bosta courier shall collect this amount from your customer.',
        cash_collection_refund_sub_title:
          'Bosta courier shall pay this amount to your customer.',
        send_cod_sub_title:
          'Your customer shall pay this amount to courier upon delivery.',
        forward_cod_sub_title:
          'Your customer shall pay this amount to courier upon delivery.',
        return_to_origin_cod_sub_title:
          'Your customer shall pay this amount to courier upon delivery.',
        exchange_cod_sub_title:
          'Bosta courier shall receive this amount from your customer upon exchange.',
        exchange_refund_sub_title:
          'Bosta courier shall pay this amount to your customer upon exchange.',
        customer_return_pickup_cod_sub_title:
          'Bosta courier shall pay this amount to your customer upon pickup.',
        number_of_items: 'Number of Items',
        number_of_items_sub_title:
          "If your items don't fit in one flyer of any size, create multiple shipments.",
        number_of_items_sub_title_bulky:
          'When requesting a pickup, make sure to request a truck for this bulky item.',
        return_location_sub_title:
          'Select the location to which the package should be returned.',
        cod_amount: 'The cash on delivery amount',
        refund_amount: 'The refund amount',
        egp: 'EGP',
        order_reference: 'Order Reference',
        order_reference_sub_title:
          'Add a reference that you can later use to search for the order.',
        package_description: 'Package Description',
        open_package: 'Allow customers to open packages ?',
        remember_my_choice: 'Remember my choice.',
        work_address: 'This is a work address',
        scheduled_pickups: 'scheduled pickups',
        select_from_scheduled_pickups: 'Select from scheduled pickups',
        landmarks: 'Landmarks',
        return_location: 'Return Location',
        return_notes: 'Return Notes',
        flyer_size: 'Flyer Size',
        package_type_sub_title:
          'Select the package type to ensure the right vehicle is selected for pickup.',
        package_type: 'Package Type',
        sign_and_collect: 'Sign and Collect',
        sign_and_collect_tooltip:
          'For documents that need to be signed, and returned back to you.',
        multi_packages: 'Multi-Packages',
        item_value: 'Item value',
        value_proof: 'Proof of value'
      },
      form_placeholder: {
        select: 'Select',
        order_reference_placeholder: 'B-123234.',
        order_reference_placeholder_bulky: 'Item serial number.',
        description_placeholder: 'Product code - Color - Size',

        description_placeholder_parcel:
          'ex: Mobile phone, Perfume, Shoes .. etc.',
        description_placeholder_bulky: 'Refrigerator, Oven, Microwave, etc..',
        description_placeholder_document: 'Contract - Credit Card - ATM Card',
        description_placeholder_ligth_bulky:
          'Microwave, Fan, Carpet, Beanbags, side tables, etc..',
        description_placeholder_heavy_bulky:
          'AC, Refrigerator, washing machine, sofa bed, dining table, bedroom, etc.',
        select_city: 'Select city',
        select_area: 'Select area',
        street_placeholder: 'ex: Mosaddak street',
        building_placeholder: '1A',
        apartment_placeholder: '22',
        phone_placeholder: 'ex: 0123456789 ',
        secondry_phone_placeholder: 'ex: 0123456789 or 0224541256',
        floor_placeholder: '2',
        landmarks_placeholder: 'ex: Near Macdonalds, Al Ahly Bank',
        notes_placeholder:
          'ex: Make sure to deliver between 9:00 AM and 5:00 PM',
        delivery_notes_placeholder:
          'Please contact the customer before delivering the order.',
        ticket_number: 'Ticket Number'
      },
      actions: {
        create_new_pickup: 'Create New Pickup',
        create_order: 'Create Order',
        print_awb: 'Print AirWaybill',
        create_more_orders: 'Create More Orders',
        view_orders: 'View Orders'
      }
    },
    'shipment-details': {
      title: 'Tracking No. {trackingNumber}',
      actions: {
        'create-ticket': 'Create ticket',
        'confirm-delete-message':
          'Are you sure you want to delete shipment {shipmentId}',
        'print-airwaybill': 'Print Airwaybill',
        delete: 'Delete'
      },
      date_titles: {
        expected_return_date: 'Expected return date:',
        delivery_date: 'Delivered at:',
        expected_delivery_date: 'Expected delivery date:',
        returened_date: 'Returned at:',
        exception_reason: 'Exception Reason: ',
        expected_exchange_date: 'Expected exchange date: '
      },
      shipment_age_tooltips: {
        10: 'A shipment is expected to be delivered or returned to you within 10 days starting from the pickup date. If the shipment is not delivered for more than 10 days, it is an overdue shipment (late shipment).',
        15: 'A shipment is expected to be picked up from your customer within 10 days starting from the creation date. If the shipment is not picked up within 10 days, it is an overdue shipment (late shipment).',
        20: 'A shipment is expected to be picked up from your customer and returned to you within 10 days starting from the creation date. If the shipment is not returned to you within 10 days, it is an overdue shipment (late shipment).',
        25: 'A shipment is expected to be picked up from your customer and returned to you within 10 days starting from the creation date. If the shipment is not returned to you within 10 days, it is an overdue shipment (late shipment).',
        30: 'The process of delivering a shipment to your customer (forward), picking up another one from your customer and returning it to you (Customer return pickup) should take the maximum of 10 days, starting from the pick up date of the forward delivery. If the shipment age exceeded 10 days and its not yet complete, then it’s a late shipment. If your shipment is late, you can create a ticket and we’ll help you.'
      },
      sidebar: {
        'summary-label': 'Summary',
        'tracking-label': 'Tracking',
        'relatedtickets-label': 'Related Tickets',
        'items-label': 'Items'
      },
      footer: {
        next_action_label: 'Next Action:',
        shipment_age_label: 'Shipment age:',
        overdue_sla_label: 'Overdue SLA',
        shipment_age_tail_label: 'out of 10 days'
      },
      'shipment-details_summary': {
        titles: {
          Shipment_Info: 'Shipment Info',
          Pickup_Info: 'Pickup Info',
          Bosta_Performance: 'Bosta Performance',
          Customer_Info: 'Customer Info',
          Customer_Info_Reciever: 'Customer Info (Reciever)',
          Return_Location: 'Return Location',
          Exchange: 'Business Info',
          Parcels: 'Parcels',
          desc: 'Description'
        },
        info_tabs: {
          Type: 'Type',
          Business_Refrence: 'Business refrence',
          COD: 'Cash on delivery',
          Cash_To_Be_Collected: 'Cash to be collected',
          Shipping_fees: 'Shipping fees',
          itemsCount: 'No. of items',
          description: 'Description',
          last_update_date: 'Last update date',
          notes: 'Notes',
          pickedupItems: 'Items pickedup from customer',
          Reciever_Name: 'Customer name',
          Phone_Number: 'Phone number',
          Landline_Number: 'Landline number',
          Customer_Address: 'Customer address',
          Area: 'Area',
          City: 'City',
          Pickup_request_ID: 'Pickup request ID',
          Pickup_date: 'Pickup date',
          Delievery_Attempts_Number: 'No. of delivery attempts',
          Outbound_Effort: 'Outbound effort',
          Bosta_Promise: 'Bosta promise',
          Star_Name: 'Star name',
          Star_Phone: 'Star phone',
          Return_Attempts: 'No. of return attempts',
          pickedup_Items_Image_alt:
            '*Image is going to be available at the pickup time',
          dropOff_Address: 'Dropoff address',
          Empty_value: 'N/A',
          inProgress: 'In progress',
          Met: 'Met',
          'Not Met': 'Not Met',
          'Not started yet': 'Not started yet',
          Pickup_Attempts: 'No. of pickup attempts',
          Location_Name: 'Location name',
          Tracking_Number: 'Tracking No.',
          state: 'State',
          'out of 3 attempts': 'out of 3 attempts',
          'out of 2 attempts': ' out of 2 attempts',
          Calls: ' Calls',
          EGP: 'EGP'
        },
        exchange_table: {
          type: 'Type',
          desc: 'Description',
          state: 'State',
          number_of_items: 'No. of items',
          crp_image: 'CRP parcel image taken by star',
          forward_table_title: 'Forward parcel info',
          crp_table_title: 'CRP parcel info',
          delivery_notes: 'Delivery Notes',
          return_notes: 'Return Notes',
          return_address: 'Return Address'
        },
        Star_note:
          '*star info will appear when ehe current shipment state is  pickedup from warehouse',
        tooltips: {
          'bosta-performace':
            'This section will help you track the efforts made by Bosta to deliver your shipment within the promised period of time.',
          Delivery_Attempts_Number:
            'The number of times the Bosta tried to deliver the shipment to your customer.',
          Return_Attempts:
            'The number of times the Bosta tried to return the shipment to you',
          Outbound_Effort:
            'The number of calls made by the outbound team to verify the star actions and take corrective actions if needed to deliver the shipment on time',
          Forward:
            'Bosta promises next day delivery (calculated from the pickup date) for shipments with Cairo as the pickup and drop city. The expected delivery period increases to two or three days depending on the distance between the pick up and the drop off cities i.e. Alexandria, Delta or Upper Egypt',
          'Cash Collection':
            'Bosta promises next day collection (from the order creation day). If the collection was made after the next day, Bosta promise is not met.” ',
          'Customer Return Pickup':
            'Bosta promises to 1) pickup the shipment on the next day from the creation date and to 2) return the shipment on the next day of picking up. Bosta promise is met if the shipment was returned to business on the expected date.',
          Exchange:
            'Bosta promises next day delivery (calculated from the pickup date) for shipments with Cairo as the pickup and drop city. The expected delivery period increases to two or three days depending on the distance between the pick up and the drop off cities i.e. Alexandria, Delta or Upper Egypt',
          'Return to Origin':
            'Bosta promises next day delivery (calculated from the pickup date) for shipments with Cairo as the pickup and drop city. The expected delivery period increases to two or three days depending on the distance between the pick up and the drop off cities i.e. Alexandria, Delta or Upper Egypt'
        }
      }
    },
    upload_orders: {
      title: 'Upload orders',
      content: 'Import bulk order orders using .xlsx',
      template: 'Template',
      template_instructions:
        '.Download the template and fill it and then import multiple records at once.',
      drag_drop_zone_content:
        'Drag & Drop your .xlsx orders file here or click to',
      browse: 'Browse',
      upload_invalid_file_error: 'Please upload xlsx file',
      error: 'Error',
      orders: 'Orders',
      confirm_upload: 'Confirm & Upload',
      table_fields: {
        order_type: 'Order type',
        description: 'Description',
        number_of_items: 'No. of items',
        return_description: 'Exchange-Return Package Description',
        return_number_of_items: 'Exchange-Return No of Items'
      },
      maxLength: '{label} cannot be longer than {max} characters'
    },
    order_types: {
      send: 'Deliver',
      exchange: 'Exchange',
      customer_return_pickup: 'Return',
      cash_collection: 'Cash Collection',
      all: 'All',
      sign_and_return: 'Sign & Return',
      fullfilment: 'Deliver',
      fxf_badge: 'FXF',
      fxf_badge__tooltip: 'Fulfilled from inventory'
    },
    actions: {
      reject_returns_success: 'delivery return rejected successfully',
      confirm_reject_return:
        'Are you sure you want to reject this delivery return?',
      confirm_reject_retrun_title: 'Reject return',
      archive_order: 'Archive Order',
      confirm_archive: 'Confirm Archive',
      confirm_archive_body:
        'You are going to Archive this order, are you sure ?',
      assign_hub: 'Assign Hub',
      reject_returns: 'Reject return',
      assign_hub_success: 'Assigned hub updated successfully.',
      assign_many_to_hub_failed:
        '[{trackingNumbers}] are in a final state and cannot change the assigned hub.',
      confirm_reset_title: 'Reset Orders',
      confirm_reset_content: 'Are you sure you want to reset these orders?',
      assign_many_to_hub_failed_pudo:
        'You cannot assign PUDO orders to any other hub.',
      combined_assign_many_failed_msg: `[{trackingNumbers}] are in a final state and cannot change the assigned hub and
          [{pudoTrackingNumbers}] are PUDO orders and cannot change the assigned hub.
        `,
      track_order: 'Track Order',
      bad_address: 'Bad Address',
      bad_address_confirmation_title:
        'Are you sure you want to mark deliveries as Bad Address?',
      bad_address_confirmation_content:
        'he select deliveries will be canceled because the addresses are considered bad address',
      bad_address_confirmation_success: 'Deliveries cancelled successfully!'
    }
  },
  settings: {
    tabs: {
      pickup_locations: 'Pickup Locations',
      api_key: 'Api Key',
      general: 'General',
      personal_profile: 'Personal Info',
      security: 'Security',
      system: 'System Settings',
      score_card: 'Score Card Settings',
      star_allowances: 'Star Allowances',
      live_ops_priorities: 'Live Ops Priorities',
      international_shipping: 'Bosta Box',
      bosta_shop: 'Bosta shop',
      dispatching_configurations: 'Monitor Hubs Configurations',
      active_sessions: 'Active Sessions',
      holiday_settings: 'Holiday Settings'
    },
    pickup_locations: {
      title: 'Business Locations',
      sub_title: 'Your business pickup locations',
      actions: {
        add_button: 'Add New Location',
        back_button: 'Pickup locations',
        create_button: 'Create Location',
        edit_button: 'Edit Location',
        add_person: 'Add New Person',
        remove_person: 'Remove person',
        set_default_contact: 'Set as default contact'
      },
      default_location: 'Default Location',
      delete_confirmation_msg: 'Are you sure you want to delete this pickup?',
      table_columns: {
        location: 'Location Name',
        address: 'Address',
        contact_person: 'Contact Person',
        action: 'Action'
      },
      delete_modal: {
        title: 'Delete pickup location',
        content: 'Are you sure you want to delete this location?'
      },
      pickup_location_form: {
        pickup_location_title: 'Create a new location',
        update_pickup_location: 'Update Pickup Locations',
        update_button: 'Update Locations',
        pickup_location_sub_title: 'Create your new pickup location',
        contact_person_title: 'Add contact persons',
        contact_person_sub_title: 'Add contact person to this location',
        set_default_location: 'Set as default location',
        add_contact_success: 'Your contact persons added successfully',
        add_contact_error:
          'You cannot add contact person to this pickup location as it has active pickups.',
        add_contact_before_location_error:
          'You have to choose a pickup location',
        edit_pickup_location_error:
          'You cannot edit this pickup location as it has active pickups',
        create_pickup_location_success:
          'You have added a new pickup location successfully',
        update_pickup_location_success:
          'Your pickup location update successfully',
        delete_pickup_location_success:
          'Your pickup location deleted successfully',
        form_placeholders: {
          location_name: 'Maadi Head Office',
          city: 'Ex: Head Branch',
          area: 'Ex: Head Branch',
          street: 'Write full street name',
          building: 'Building No.',
          floor: 'Floor No.',
          apartment: 'Apt No.',
          first_name: 'Write your first name',
          last_name: 'Write your last name',
          phone: 'Add phone number',
          second_phone: 'Add second phone number',
          email: 'Add Email address',
          landmarks: 'ex: Near Macdonalds, Al Ahly Bank'
        }
      }
    },
    personal_profile: {
      personal_info: {
        title: 'Personal Info',
        sub_title: 'Setup your personal info',
        phone_otp_screen: {
          title: 'Verify your new phone number',
          sub_title:
            'To make sure the new phone number is yours, a 4 digit code is sent to your phone number ending with {phone}. Enter this code below.'
        },
        email_otp_screen: {
          title: 'Verify your new email address',
          sub_title:
            'To make sure the new email address is yours, a 4 digit code is sent to your email address ****{email}. Enter this code below.'
        },
        first_name_updated_successfully: 'First name is updated successfully.',
        last_name_updated_successfully: 'Last name is updated successfully.',
        phone_updated_successfully:
          'Your phone number has been changed successfully.',
        email_updated_successfully:
          'Your email address has been changed successfully.'
      }
    },
    security: {
      title: 'Two-Factor Authentication',
      subtitle:
        "Increase your account's security by enabling Two-Factor Authentication (2FA).",
      enabled_status: 'Status: Enabled',
      disable_2FA_button: '  Disable two-Factor authentication',
      authentication_type_question:
        'How would you like to receive your two factor authentication codes?',
      authentication_type_note:
        'You will be asked for two factor authentication code when you sign in to your account.',
      bosta_Secret_token: 'Bosta Secret token',
      PIN_label: 'PIN',
      authentication_types: {
        app: {
          title: 'Use an app',
          subtitle:
            'Retrieve codes from an authentication app on your device, like ',
          action: 'Use Authentication App',
          modal_title:
            'Scan this QR code with your authenticator app. You will see a 6-digit code on your screen, please paste it here.'
        },
        email: {
          title: 'Email address',
          subtitle: 'Receive an email to your device when signing in.',
          action: 'Use Email',
          modal_title:
            'A verification code has been sent to your email, please paste it here.'
        },
        sms: {
          title: 'SMS Text Message',
          subtitle:
            'Receive a text message to your mobile device when signing in.',
          action: 'Use Text Message (SMS)',
          modal_title:
            'A verification code has been sent to your phone number, please paste it here.'
        }
      },
      banner: {
        title:
          'You didn’t complete your 2FA setup, {click_here} in order to fully enable 2FA on your account.',
        click_here: 'Click here'
      }
    },
    pricing_plan: {
      title: 'Pricing Plan',
      sub_title:
        'View your pricing plan for your order types and sizes in all cities.',
      cm: 'cm',
      package_sizes: 'Package Sizes',
      pickup_location: 'Pickup Location',
      search: 'search',
      currency_text: 'All prices are in {currency} currency',
      currency: 'EGP',
      max_20kg: '(Up to 20KG)',
      tooltips: {
        xxl_white_bag: 'With a weight up to 20KG',
        light_bulky:
          'Example: Microwave, Fan, Carpet, Beanbags, side tables, etc..',
        heavy_bulky:
          'AC, Refrigerator, washing machine, sofa bed, dining table, bedroom, etc..'
      },
      light_bulky_size: 'Bigger than 100 x 50cm',
      heavy_bulky_size: 'White goods/furniture',
      dropoff_sectors: {
        1: 'Cairo',
        2: 'Alexandria',
        3: 'Delta',
        4: 'Canal',
        5: 'Upper Egypt',
        6: 'North Coast',
        7: 'Sinai',
        8: 'Red Sea'
      },
      dropoff_sector_areas: {
        1: 'Cairo and Giza',
        2: 'Alexandria and Behira',
        3: 'Dakahlia, El Kalioubia, Gharbia, Kafr Alsheikh, Monufia, and Sharqia',
        4: 'Damietta, Isamilia, Port Said, and Suez',
        5: 'Assuit, Aswan, Bani Suif, Fayoum, Luxor, Menya, New Valley, Qena, and Sohag',
        6: 'Sahel',
        7: 'North Sinai and South Sinai',
        8: 'Red Sea'
      },
      table_columns: {
        dropoff_location: 'Drop-off Location',
        deliver: 'Deliver',
        exchange: 'Exchange',
        return: 'Return',
        cash_collection: 'Cash Collection',
        rto: 'Return to Origin',
        tool_tips: {
          deliver:
            'Pricing for the "Deliver" orders we deliver to your customers.',
          exchange:
            'Pricing for the "Exchange" orders we exchange from your customers.',
          return:
            'Pricing for the "Return" orders we pick up from your customers to return it to you.',
          cash_collection:
            'Pricing for the "Cash Collection" orders where we collect cash from your customers.',
          rto: 'Pricing for the orders we have to return it back to you due to cancellation.',
          sign_and_return: 'A document that is signed and collected other day.'
        }
      },
      vat: {
        title: 'View pricing with VAT',
        vat_14: '14% VAT',
        vat_14_sub_title: 'All prices are subjected to 14% VAT.',
        collection_fees: '1% Collection Fees',
        collection_fees_sub_title:
          'If COD is higher than 3,000, Bosta will deduct 1% fees from the extra amount above the 3,000.'
      },
      sizes: {
        normal: 'Normal',
        x_large: 'X-Large',
        bulky: 'Bulky',
        xxl_white_bag: 'XXL (White Bag)',
        large: 'Large',
        bulky_dimensions: 'Bigger Size',
        light_bulky: 'Light Bulky',
        heavy_bulky: 'Heavy Bulky',
        sign_and_return: 'Sign & Return'
      },
      size: {
        bulky: 'Bulky',
        xxl_white_bag: 'XXL (White Bag)',
        large: 'Large',
        bulky_dimensions: 'Bigger Size'
      }
    },
    system: {
      cod: {
        title: 'COD Collection',
        toggle_label: 'How should the system collect the COD?',
        first_on_subtitle:
          'COD is going to enter the system through an integration with Fawry.',
        second_on_subtitle:
          'Hub admins Cannot add, update or delete any COD receipts on the debriefing and money debrief pages.',
        off_subtitle:
          'Hub admins should enter the COD receipts manually through the debriefing and money debrief pages.',
        fawry_hint: 'The integration with Fawry is now active.',
        activate_cod: 'Activate Auto Collect',
        deactivate_cod: 'Deactivate'
      },
      time_slots: {
        title: 'Delivery Time Slots',
        add_time_slot: 'Add Time Slot',
        add_time_slot_hint:
          'Add the time slots in which the the stars will deliver the orders.',
        start_at: 'Start At',
        end_at: 'End At',
        remove: 'Remove',
        removing_last_time_slot_warning:
          'This is the last time slot, you can’t remove the last time slot.',
        removing_last_time_slot_confirmation_message:
          'Are you sure you want to remove this delivery time slot?',
        removing_time_slot_title: 'Remove Time Slot',
        adding_time_slot_warning:
          'The smallest time slot allowed be 2 hours long.',
        start_time: 'Start Time',
        end_time: 'End Time',
        select_time_placeholder: 'Select Time'
      },
      pickups: {
        title: 'Pickups',
        pickups_to_scan: 'Pickups to Scan',
        number_of_scanning: 'Number of pickups that required scanning',
        save_settings: 'Save Settings',
        success: 'Settings saved successfully'
      }
    },
    score_card: {
      title: 'Score Card Settings',
      sub_title:
        'Create score card templates to control the stars performance.',
      hubs_section: {
        title: 'Hubs',
        cta_text: 'Add Hub(s)',
        assigned_hubs: '{assignedHubs} Assigned Hubs',
        unassigned_hubs: '{unAssignedHubs} Unassigned Hubs',
        tool_tip:
          'The system will not be able to calculate star performance as long as a hub is not assigned to a template. Make sure that all hubs gets assigned to templates.'
      },
      template_section: {
        title: 'Templates',
        no_factors_text: 'Add factors to start using this template',
        add_factors: 'Add Factors',
        empty_state: {
          title: 'You don’t have any templates',
          sub_title: 'Create templates to start calculating star performance.'
        }
      }
    },
    star_allowances: {
      title: 'Star Allowances',
      subtitle: 'Extra amounts that gets paid to the stars.',
      phone_lines: 'Phone Lines',
      zones_validation_message:
        'At least one zone should be selected to apply the phone lines allowance.',
      amount_validation_message: {
        numeric_values_only: 'Phone lines amount accepts numeric values only.',
        min_amount: 'Minimum amount is 0 EGP',
        max_amount: 'Maximum amount is 10 EGP'
      },
      form_labels: {
        egp: 'EGP',
        sar: 'SAR',
        amount_per_day: 'Amount per day',
        zones: 'Applies to zones'
      },
      warning_modal: {
        title: 'Are you sure you want to update the phone lines amount?',
        hint: 'Note that, updates are going to be effective on star salaries starting from the next cycle.'
      },
      confirmation_message: 'Phone lines amount has been updates successfully',
      save_changes: 'Save Changes'
    },
    live_ops_priorities: {
      title: 'Live Ops Priorities',
      sub_title:
        'Select the businesses, zones and stars that live ops should validate their exceptions.',
      form: {
        priority_type: 'Priority Type',
        zone: 'Zone',
        business: 'Business',
        star: 'Star',
        weight: 'Weight',
        select: 'Select',
        clear_button: 'Cancel',
        add_button: 'Add',
        success: 'Priority added successfully',
        errors: {
          business: 'Business name is required',
          star: 'Star name is required',
          zone: 'Zone name is required',
          select: 'Select is required'
        }
      },
      table: {
        title: '{prioritiesCount} Priorities',
        columns: {
          type: 'Type',
          name: 'Name',
          weight: 'Weight',
          actions: 'Actions'
        },
        buttons: {
          edit: 'Edit'
        },
        delete_title: 'Delete Priority',
        delete_message: 'Are you sure you want to delete this rule?',
        delete_success: 'Deleted successfully',
        edit_title: 'Update Weight'
      },
      modal: {
        buttons: {
          save: 'Save'
        },
        weight: 'Select Weight',
        success_message: 'Weight updated successfully'
      }
    },
    international_shipping: {
      title: 'Bosta Box',
      subtitle: 'Manage bosta box countries and products fees.',
      view: 'View',
      card: {
        manage_categories: 'Manage Categories',
        add_category: 'Add Category'
      },
      modal: {
        title: 'Add New Category',
        name: 'Category Name',
        name_placeholder: 'Name',
        fees: 'Fixed fees per item',
        cost: 'Cost per 100g per item',
        delivery: 'Delivery SLA',
        delivery_placeholder: 'ex: 15 Business Days',
        select: 'Select Country',
        select_error: 'Country is required!',
        select_all: 'Apply to all countries',
        add_category: 'Add category',
        add_category_success: 'Category added successfully!'
      },
      table_columns: {
        product_category: 'Product Category',
        fees: 'Fixed fees per item',
        cost: 'Cost per 100g per item',
        delivery: 'Delivery SLA',
        actions: 'Actions',
        business_days: '{deliverySLA} Business days'
      },
      countries_labels: {
        us: 'United States',
        ae: 'United Arab Emirates',
        uk: 'United Kingdom',
        cn: 'China'
      },
      edit_category_error: '{title} is required!',
      edit_category_success: '{name} category was updated successfully!',
      confirm_delete: 'Are you sure you want to delete this category'
    },
    bosta_shop: {
      title: 'Bosta Shop',
      factor: 'Factor',
      minimum_quota: 'Minimum quota',
      factor_validation: 'Factor cannot be less than 0 or 0'
    },
    dispatching_configurations: {
      late_orders_cutoff_time: 'Late orders cut-off time',
      receiving_cutoff_time: 'Transfer cut-off time',
      missed_orders_cutoff_time: 'Missed orders cut-off time',
      late_transfer_cutoff_time_last_mile:
        'Late transfer cut-off time - Last Mile',
      missed_transfer_cutoff_time_last_mile:
        'Missed transfer cut-off time - Last Mile',
      late_transfer_cutoff_time_first_mile:
        'Late transfer cut-off time - First Mile',
      missed_transfer_cutoff_time_first_mile:
        'Missed transfer cut-off time - First Mile',
      late_transfer_cutoff_time_sorting: 'Late transfer cut-off time - Sorting',
      missed_transfer_cutoff_time_sorting:
        'Missed transfer cut-off time - Sorting',
      emails_to_be_notified: 'E-mails to be notified',
      edit_success_message: 'Monitor Hubs Configuration Updated Successfully'
    },
    active_sessions: {
      title:
        'Here are all the devices that are currently logged in with your bosta account. You can log out of each one.',
      subtitle:
        "If you see an entry that you don't recognize, log out of that device and change your bosta account password immediately.",
      current_session: 'Current Session',
      other_sessions: 'Other Sessions',
      logout_this_session: 'Logout this session',
      logout_all_sessions: 'Logout of all sessions',
      session_logout_success: 'Session logged out successfully'
    },
    holiday_settings: {
      title: 'Configure Holiday Settings',
      prevent_pickups: 'Prevent scheduling pickups',
      skip_automated_actions: 'Skip Wensh automated actions',
      prevent_orders: 'Prevent scheduling orders',
      success_message: 'Save successfully',
      modal_title: 'Add new holiday',
      modal_content: 'Are you sure you want to add this holiday?',
      modal_confirm: 'Confirm',
      table_title: 'Holidays: {count}',
      delete_message: 'Deleted successfully',
      prevent_pickups_tooltip:
        'Toggling this will prevent pickups and recurring pickups from being created.',
      prevent_orders_tooltip:
        'Toggling this will prevent orders from being rescheuled to the selected dates.',
      skip_automated_actions_tooltip:
        'Toggling this will prevent Bosta Wensh from being triggered.',
      table_columns: {
        date: 'Date',
        options: 'Options',
        business_tiers: 'Business Tiers',
        prevent_pickups: 'Prevent Pickups',
        prevent_orders: 'Prevent Orders',
        skip_wensh: 'Skip Wensh'
      }
    }
  },
  banner: {
    pickup_location: {
      title: 'Update Pickup Locations',
      sub_text:
        'Bosta started using a new address zoning. Please update your pickup locations for faster pickup'
    }
  },
  pickups: {
    cancel_reasons: {
      reschedule_business_needs_change_pickup_location:
        'Reschedule - The business needs to change the pickup location.',
      business_reschedule:
        'Reschedule - Postponed as per the business request.',
      business_unreachable: "Reschedule - The star can't reach the business.",
      orders_failed: 'Reschedule - The star failed to pick the orders.',
      reschedule_business_needs_onboarding:
        'Reschedule - The business needs Onboarding.',
      business_flyer: 'Canceled - The business needs flyers.',
      orders_not_ready: 'Canceled - The order is not ready.',
      canceled_order_size_inappropriate:
        'Canceled - The order size is inappropriate.',
      orders_no_tn: 'Canceled - The order without AWB.',
      business_cancelled: 'Canceled - The business canceled.',
      canceled_by_outbound_team: 'Canceled By OutBound Team - Fake Reschedule.',
      system_cancelled:
        'Canceled By System - The business deleted the created orders, leaving no new orders remaining to be picked',
      customer_is_not_in_address:
        'Retry delivery - the customer is not in the address.',
      customer_changed_address:
        'Retry delivery - the customer changed the address',
      customer_postponed:
        'Postponed - the customer requested postponement for another day.',
      customer_wants_to_open_order:
        'Cancellation - the customer wants to open the shipment.',
      address_is_not_clear:
        'Waiting for data modification - address or phone number not clear/wrong.',
      cancel_request: 'Cancellation of delivery at the request of the sender.',
      customer_not_answering: 'Customer is not answering.',
      customer_refused_to_receive:
        'Cancellation - the customer refuses to receive the shipment',
      customer_refused_to_give_the_order:
        'Cancellation - The customer refuses to give the shipment to the star',
      star_didnt_call: "The star didn't call the customer",
      uncovered_zone:
        'Cancellation - the delivery address is outside bosta coverage area',
      address_not_clear: 'Waiting for data modification - address not clear',
      wrong_phone: 'Waiting for data modification - wrong phone number',
      customer_refuse_to_sign:
        'Cancellation - the customer refuses to sign the document.',
      customer_changed_address_return:
        'Retry return - the Business changed the address.',
      business_postponed:
        'Postponed - the Business requested postponement for another day.',
      business_not_answering: 'Business is not answering.',
      business_not_in_address: 'Retry return - Business is not in the address.',
      bad_weather: 'Bad Weather',
      suspicious_consignee: 'Suspicious Consignee',
      order_damaged: 'The order is damaged',
      empty_order: 'Empty Order',
      incomplete_order: 'The order is incomplete',
      order_doesnt_belong_to_business: 'The order does not belong the business',
      the_order_was_opened: 'The order was opened while it should not'
    },
    auto_hub: 'Auto',
    new_pickup_location: 'New',
    new_pickups: 'new pickup ID:',
    old_pickups: 'due to Rescheduling of old pickup ID:',
    created_by_star: 'Created by star',
    failed_to_find_location: 'Failed to find this location',
    tabs: {
      history_pickups: 'History Pickups',
      upcoming_pickups: 'Upcoming Pickups'
    },
    title: 'Pickup Request',
    subtitle: 'Pickups list for your shipments',
    table_title: 'Total Pickups ( {count} )',
    search_input_placeholder: 'Search by pickup ID',
    table_columns: {
      pickupId: 'Pickup Id',
      location: 'Pickup location',
      scheduled_date: 'Scheduled date',
      type: 'Pickup type',
      status: 'Status',
      pickedup_date: 'Pickedup date',
      PCKGS: 'Picked up PCKGS',
      action: 'Action'
    },
    table_actions: {
      menu_headline: 'Actions',
      create_ticket: 'Create Ticket',
      delete: 'Delete',
      non_created_delete_message:
        'You cannot delete the pickup request as it started processing',
      non_created_edit_message: 'You cannot edit the pickup as its in progress',
      edit: 'Edit',
      deleted: 'Pickup is deleted successfully',
      confirm_delete_message:
        'Are you sure you want to delete this pickup request ?',
      ok_confirmation_text: 'Yes',
      cancel_confirmation_text: 'Cancel',
      non_confirmation_edit_message:
        'The pickup is already in progress, therefore it cannot be edited',
      created_ticket_message: 'Ticket is created successfully.'
    },
    pickup_details: {
      edit_button: 'Edit',
      logs: 'Activity Log',
      outbound_action: ' - outbound action',
      pickup: 'Pickup',
      action_reason:
        'Status updated to {action} with exception reason: {reason}',
      deliveries: 'Deliveries ( {count} )',
      customer_reply: 'Business did not reply',
      right_update: 'Right Update',
      fake_update: 'Fake Update',
      new_pickup: 'Create new Pickup',
      cancel_pickup: 'Cancel Rescheduled pickup',
      take_action: 'Take Action',
      action: 'Action',
      proof_of_exception: 'View proof of exception',
      repetition_info: {
        title: 'Repetition Info',
        start_date: 'Start date',
        end_date: 'End date',
        days: 'Days',
        repeated_type: 'Repeated',
        next_pickup_date: 'Next pickup date'
      },
      pickup_info: {
        title: 'Pickup Info',
        scheduled_date: 'Scheduled date',
        state: 'Status',
        business: 'Buisness',
        type: 'Type',
        pickedupDate: 'Pickup date',
        contact_person: {
          name: 'Contact person',
          phone: 'Phone',
          email: 'Email',
          signature: 'Signature'
        },
        address: 'Pickup address',
        number_of_packages: 'Number of packages',
        number_of_parcels: 'Number of parcels',
        zone: 'Zone',
        hub: 'Hub',
        route: 'Route',
        creation_date: 'Pickup creation date',
        star: { name: 'Star name', phone: 'Star phone' },
        notes: 'Notes',
        package_type: 'Package Type',
        city: 'City',
        pickup_date: 'Pickup date',
        pickup_id: 'Pickup Id',
        status: 'Status',
        area: 'Area',
        order_tn: 'Linked order tracking number',
        proof_of_pickup: 'Proof of Pickup',
        otp: 'OTP'
      },
      pickup_rating: {
        star_rate: 'Star Rate',
        pickup_rate: 'Overall pickup experience rate',
        rate_comment: 'Reason(s) for rating'
      }
    },
    create_edit_pickup: {
      pickup_create_title: 'Create Pickup',
      pickup_edit_title: 'Edit Pickup',
      pickup_sub_title: 'Setup the pickup info',
      create_pickup_success: 'The pickup request is created successfully',
      contact_title: 'Contact Persons',
      bulky_title: 'Bulky Pickup',
      contact_sub_title: 'Add contact person to this location',
      bulky_sub_title: 'Make sure to request a truck if you have a bulky item',
      error_empty_pickup_location: 'Please select pickup location first',
      error_empty_business_name: 'Please Choose business name first',
      edit_pickup_error:
        "You can't create a pickup with end date that is earlier than start date or pickup date!",
      delete_default_pickup_error:
        'Pickup location cannot be deleted as its the default location. Set another location as default and try again',
      delete_pickup_error:
        'The selected pickup location cannot be deleted because it is linked to pickup requests under processing. Please try again after the pickup requests are completed.',
      edit_pickup_success: 'Pickup successfully updated!',
      error_delete_default_contact: 'You cannot delete the default contact',
      error_create_new_zoning:
        'Pickup location is not updated by the new zoning. Please update the pickup location first.',
      form_labels: {
        business: 'Business',
        pickup_location: 'Pickup Location',
        pickup_date: 'Pickup Date',
        pickup_repeat: ' Do you want to repeat the pickup?',
        notes: 'Notes',
        package_type: 'I have a bulky item in this pickup.',
        package_type_heavy_light:
          'If you have bulky items, make sure to mention this.',
        package_type_heavy: 'I have heavy bulky items in this pickup.',
        package_type_light: 'I have light bulky items in this pickup.'
      },
      form_placeholders: {
        select_business: 'Select Business',
        select_location: 'Select Location',
        select_date: 'Select Date',
        name: 'Write your full name',
        first_name: 'Your first name',
        last_name: 'Your second name',
        phone: 'Add phone number',
        second_phone: 'Add second phone number',
        email: 'Add Email address',
        notes: 'write your notes here',
        light: 'Light Bulky',
        heavy: 'Heavy Bulky'
      },
      form_labels_tooltips: {
        pickup_location:
          'Select the pickup location that you want the star to arrive at for picking up deliveries.',
        select_date:
          'Select the day on which the star should come to pickup deliveries',
        select_contact:
          'Select your employee who is responsible for meeting the star and handing off the deliveries.'
      }
    },
    delete_pickup_modal: {
      title: 'Delete pickup',
      delete_current_pickup: 'Delete this pickup only',
      delete_future_pickup: 'Delete this pickup and all future ones',
      delete_parent_pickup:
        'Delete this pickup and every other future pickup ?',
      delete_content:
        'The pickup cannot be delete as it is already in progress. Do you want to delete future pickups ?',
      deleted_pickup_success: 'Pickup is deleted successfully',
      form_type_required: 'Select one, please!'
    },
    repeated_modal: {
      title: 'Create Recurring Pickup',
      sub_title: 'This pickup will be repeated',
      every_day: 'Every Day',
      every_week: 'Every Week',
      form_labels: {
        start_date: 'Start Pickup Date',
        reptead_type: 'Repeat',
        end_date: 'End At'
      },
      form_placeholders: {
        date_placeholder: 'Select Date',
        reptead_type: 'Select'
      }
    },
    actions: {
      create: 'Create pickup',
      create_ticket: 'Create a ticket',
      back_button: 'Pickups',
      stop: 'Stop Reucrring Pickup',
      create_recurring: 'Create Recurrence',
      add_location: 'Add New Location',
      create_repeated: 'Repeat Pickup',
      edit_repeated: 'Edit Recurring',
      add_person: 'Add New Person',
      create_button: 'Create pickup',
      edit_button: 'Edit Location',
      set_default: 'Set as default',
      edit_recurring: 'Edit Recurrence'
    },
    pickup_request: {
      title: 'Pickup Requests',
      table_title: '<span>Total Pickup Requested:</span> {count} ',
      show_count: 'Show Count',
      export_success: 'Pickups loaded successfully, exporting in progress.',
      exception_validation_tooltip:
        'This exception was validated by the business through WhatsApp',
      search: {
        pickup_request_id: 'Pickup Request ID',
        business: 'Business',
        state: 'State',
        star: 'Star',
        pickup_request_type: 'Pickup Request Type',
        repetition_type: 'Repetition type',
        assign_type: 'Assign Type',
        created_by_star: 'Created By Star',
        first_business_pickup: 'First Business Pickup',
        fm_code: 'FM Code',
        exception_validation: 'Exception Validation',
        valid: 'Valid',
        fake: 'Fake'
      },
      table_columns: {
        pickup_request_id: 'Pickup Request ID',
        scheduled_date: 'Scheduled Date',
        pickup_address: 'Pickup Address',
        exception_reason: 'Exception Reason',
        old_pickup_id: 'Old Pickup ID',
        package_type: 'Package Type',
        star_info: 'Star Info',
        picked_up_date: 'Pickedup Date',
        business_first_pickup: 'Business First Pickup',
        first_pickup_date: 'First Pickup Date',
        number_of_packages: '# of Packages',
        type: 'Type',
        repetition: 'Repetition',
        attemptDate: 'Attempt Date',
        fm_district_code: 'FM District Code',
        assignDate: 'Assign Date'
      },
      cancel_pickup_modal: {
        title: 'Cancel Reason Pickups',
        reason_label: 'Choose a Reason',
        success_message: 'Pickup cancelled successfully!'
      },
      assign_to_hub_modal: {
        title: 'Add to hub',
        success_message: 'Assigned to hub successfully!'
      },
      assign_to_route_modal: {
        title: 'Add to route',
        select_route: 'Select Route',
        success_message: 'Added to route successfully!',
        assigned_routes: 'Assigned Routes',
        unassigned_routes: 'Unassigned Routes',
        route: 'Route'
      },
      error_msgs: {
        no_pickup_selected:
          'No pickups are selected. Please select pickups before assigning them to route.'
      },
      export_xlsx: 'Export Xlsx',
      assign_to_route: 'Assign to Route',
      pickup_actions: 'Pickup Actions',
      multi_pickups_route_confirm:
        'Are you sure you want to assign the selected pickup requests to route [{routeName}]'
    }
  },
  searchable_table: {
    input: { placeholder: 'Search for shipments...' },
    empty_view: {
      title: 'There is no data to view',
      search_title: 'No search results found'
    }
  },

  contact_form_labels: {
    first_name: 'First name',
    last_name: 'Last name',
    full_name: 'Full name',
    phone: 'Phone',
    email: 'Email',
    secondary_phone: 'Secondary Phone Number',
    name: 'Name',
    password: 'Password'
  },
  location_form_labels: {
    country: 'Country',
    city: 'City',
    area: 'Area',
    street: 'Street Name',
    building: 'Building No.',
    floor: 'Floor No.',
    apartment: 'Apartment No',
    name: 'Name',
    building_number: 'Building No.',
    district: 'District',
    location_name: 'Location name',
    address_line: 'Address Line',
    zone: 'Zone',
    landmarks: 'Landmarks'
  },
  pricing: {
    pricing_types: 'Types',
    transit_costs: 'Transit',
    pricing_tiers: 'Pricing Tiers',
    pricing_size: 'Sizes',
    pricing_configurations: {
      title: 'Configurations',
      description:
        'These configurations will be used as default values, updating the pricing tier for a business won’t affect his configurations',
      payment_methods: {
        bank_transfer: 'Bank transfer',
        fawry: 'Fawry'
      },
      payment_frequency_schedule: {
        weekly_sun: 'Weekly (Sun)',
        weekly_mon: 'Weekly (Mon)',
        weekly_wed: 'Weekly (Wed)',
        two_days: '2 days (Mon, Wed)',
        three_days: '3 Days (Sun, Tue, Thu)',
        daily: 'Daily (Sun to Thu)',
        next_day: 'Next Day'
      },
      weighting: {
        label: 'Weighting',
        fixed_sizes: 'Fixed sizes',
        extra_weight: 'Extra weight'
      },
      restrictions: {
        label: 'Restrictions',
        checkbox_label: 'Restricted to businesses',
        tier_label: '(Restricted)'
      },
      other_revenue: 'Other revenue',
      payment_gateway: 'Payment gateway',
      cashout_frequency: 'Cash out frequency',
      update_success: 'Pricing tier updated successfully!'
    },
    manage_packaging_material: {
      title: 'Manage Packaging Material',
      table: {
        title: 'Materials ({count})',
        price: 'Price',
        material: 'Material',
        free: 'Free',
        bulk_edit: 'Bulk Edit'
      },
      modal: {
        title: 'Update packaging material price',
        bulk_title: 'Bulk update packaging material price'
      }
    },
    table: {
      cost: 'Cost',
      multiplier: 'Multiplier',
      active: 'Active',
      sectorId: 'Sector id',
      name: 'Name',
      action: 'Action',
      edit: 'Edit',
      sector_name: 'Sector Name',
      destination_sector_id: 'Destination Sector ID',
      destination_sector_name: 'Destination Sector Name',
      origin_sector_id: 'Origin Sector ID',
      origin_sector_name: 'Origin Sector Name',
      is_default: 'Default',
      rate: 'Rate',
      max_weight: 'Max Weight',
      min_weight: 'Min Weight',
      type_cost: 'Type Cost',
      show_prices: 'Show prices'
    },
    mark_as_default: 'Mark As Default',
    edit_multiplier: 'Edit Multiplier',
    not_active_status: 'Not Active',
    cost_label: 'Cost',
    default_label: 'Default',
    status_label: 'Status',
    extra_cod_amount_label: 'Extra COD amount',
    extra_cod_fees_label: 'Extra COD fees',
    zero_cod_discount_label: 'Zero COD discount',
    insurance_fees: 'Insurance fees',
    next_day_cod_fees: 'Next Day COD fees',
    cod_fees_label: 'COD fees',
    weight_label: 'Extra Weight',
    multiplier_label: 'Multiplier',
    extraCodAmount_label: 'Extra COD amount',
    extraCodFees_label: 'Extra COD fees',
    zeroCodDiscount_label: 'Zero COD discount',
    pos_fee: 'CCOD fees',
    is_initial_label: 'Initial',
    tier_id_label: 'Tier ID',
    percentage: 'Percentage',
    amount: 'Amount',
    min_amount: 'Minimum amount',
    weight_threshold: 'Weight threshold',
    threshold_cost: 'Threshold cost',
    additional_weight_cost: 'Cost per additional KG',
    exclude_type_checkbox: 'Exclude from order types',
    exclude_type_placeholder: 'Select order types to exclude',
    opening_package_fees: 'Opening package fees',
    rates: {
      flat: 'Flat Rate',
      dynamic: 'Dynamic Rate'
    },
    modal: {
      title: 'Edit Pricing Tier',
      cost_title: 'Edit Cost',
      multiplier_title: 'Edit Multiplier',
      service_type_modal_title: 'Types cost'
    },
    clone_modal: {
      confirmation: {
        title: 'Copy the {name} plan',
        subtitle: 'Are you sure you want to create a copy from this plan?',
        confirm: 'Yes, Copy it'
      },
      title: 'Set the new plan name',
      input_label: 'Plan Name',
      input_placeholder: 'CUSTOM - Business Name',
      success: 'Plan has been added sucessfully.',
      error: 'This plan name already exists.'
    },
    copy_tooltip: 'Copy',
    success_message: 'Cost successfully updated!',
    size_success_message: 'Pricing sizes successfully updated!',
    tiers_success_message: 'Pricing tiers successfully updated!',
    edit_name: 'Edit name',
    pricing_plan_name_title: 'Pricing plan name',
    delete_plan: 'Delete plan',
    delete_plan_message: 'Are you sure you want to delete this plan',
    choose_other_plan:
      'Please choose other pricing plan to transfer all existing users to it',
    type_label: 'Type',
    bosta_material_fee: 'bosta materials shipping fees',
    copy_plan: 'Copy plan',
    small_size: 'Small',
    large_size: 'Large',
    x_large_size: 'X-Large',
    normal_size: 'Normal',
    bulky_size: 'Bulky',
    heavy_bulky_size: 'Heavy Bulky',
    light_bulky_size: 'Light Bulky',
    white_bag_size: 'XXL (White Bag)',
    customize_amount_per_package: 'Customized per packages size',
    fixed_amount: 'Fixed amount',
    price_plan_type: 'Price Plan Type'
  },
  orders: {
    header_title: 'Order #{trackingNumber}',
    order_created_from_sllr: 'Ordered via Sllr store',
    shipment_details: {
      tabs: {
        activity_log: 'Activity Log',
        order_details: 'Order Details'
      },
      title: 'Tracking No. {trackingNumber}',
      shipment_details_summary: {
        order_types: {
          send: 'Deliver',
          cash_collection: 'Cash Collection',
          return_to_origin: 'Rreturn to origin',
          customer_return_pickup: 'Return',
          exchange: 'Exchange'
        },
        tables_headers: {
          order_details: 'Order Details',
          deliver: 'Deliver',
          deliver_order_details: 'Deliver order details',
          return_order_details: 'Return order details',
          pickup_info: 'Pickup Info',
          bosta_performance: 'Bosta Performance',
          customer_info: 'Customer Info',
          customer_info_reciever: 'Customer Info (Reciever)',
          return_location: 'Return Location',
          exchange: 'Business Info',
          return: 'Return'
        },
        table_cells: {
          pickup_request_id: 'Pickup ID',
          pickup_date: 'Pickup date',
          area: 'Area',
          city: 'City',
          description: 'Package description',
          star_name: 'Star name',
          star_phone: 'Star phone',
          dropOff_Address: 'Dropoff address',
          type: 'Type',
          number_of_items: 'No. of items',
          location_name: 'Location name',
          street_name: 'Street name',
          last_update_date: 'Last update date',
          sign_and_return: 'Sign and return',
          same_day: 'Same day',
          other_day: 'Other day',
          shipment_info_table: {
            business_refrence: 'Order reference',
            cod: 'Cash on delivery',
            refund_amount: 'Refund Amount',
            cash_to_be_collected: 'Cash to be collected',
            delivery_notes: 'Delivery notes',
            return_notes: 'Return notes',
            pickedup_items: 'Items pickedup from customer',
            opening_package: 'Opening Package',
            package_type: 'Package Type'
          },
          customer_info_table: {
            reciever_name: 'Customer name',
            phone_number: 'Phone number',
            customer_address: 'Customer address',
            second_phone_number: 'Secondary Phone Number',
            building_number: 'Building',
            floor_apartment: 'Floor, Apartment',
            work_address: 'Work Address'
          },
          bosta_performance: {
            delievery_attempts: 'Delivery attempts',
            outbound_calls: 'Outbound calls',
            bosta_promise: 'Delivery promise',
            return_attempts: 'Return attempts',
            pickup_attempts: 'Pickup attempts'
          },
          parcels_table: {
            tracking_number: 'Tracking No.',
            state: 'State'
          }
        },
        table_cells_labels: {
          cod_egp_mount: '{cod} EGP',
          calls: 'Calls',
          delivery_attempts: 'out of 3 attempts',
          return_attempts: ' out of 2 attempts',
          promise_in_progress: 'In progress',
          promise_met: 'Met',
          promise_not_met: 'Not Met',
          promise_not_started: 'Not started yet',
          pickedup_Items_Image_alt:
            '*Image is going to be available at the pickup time',
          enlarge_image: 'Enlarge',
          cash_to_collect: 'Cash to collect',
          refund_cash_to_customer: 'Refund cash to customer',
          allowed_to_open_package: 'Allowed',
          not_allowed_to_open_package: 'Not Allowed',
          cash_on_delivery: 'Cash on delivery',
          paid_online: 'Paid online',
          cod: 'COD',
          collected_cash: 'Collected cash',
          refund: 'Refund'
        },
        table_cells_tooltips: {
          bosta_performace:
            'This section will help you track the efforts made by Bosta to deliver your order within the promised period of time.',
          delivery_attempts_number:
            'The number of times the Bosta tried to deliver the order to your customer.',
          return_attempts:
            'The number of times the Bosta tried to return the order to you',
          outbound_effort:
            'The number of calls made by the outbound team to verify the star actions and take corrective actions if needed to deliver the order on time',
          delivery_promise_tooltips: {
            send: 'Bosta promises next day delivery (calculated from the pickup date) for orders with Cairo as the pickup and drop city. The expected delivery period increases to two or three days depending on the distance between the pick up and the drop off cities i.e. Alexandria, Delta or Upper Egypt',
            cash_collection:
              'Bosta promises next day collection (from the order creation day). If the collection was made after the next day, Bosta promise is not met.” ',
            customer_return_pickup:
              'Bosta promises to 1) pickup the order on the next day from the creation date and to 2) return the order on the next day of picking up. Bosta promise is met if the order was returned to business on the expected date.',
            exchange:
              'Bosta promises next day delivery (calculated from the pickup date) for orders with Cairo as the pickup and drop city. The expected delivery period increases to two or three days depending on the distance between the pick up and the drop off cities i.e. Alexandria, Delta or Upper Egypt',
            return_to_origin:
              'Bosta promises next day delivery (calculated from the pickup date) for orders with Cairo as the pickup and drop city. The expected delivery period increases to two or three days depending on the distance between the pick up and the drop off cities i.e. Alexandria, Delta or Upper Egypt'
          }
        },
        wallet: {
          title: 'My Wallet',
          empty_state_update:
            'Once your order is completed, your wallet will be updated within 24 hours.',
          empty_state_processing:
            'We are processing your order. Your wallet will be updated within 24 hours.',
          footer: '* Check {balanceButton} page for more info.',
          cash_cycles: {
            title: 'Cash Cycle',
            title_tooltip:
              'The details of your order’s collected cash and fees, and the date of their deposit in your wallet.',
            deposited_at: 'Deposited At',
            collected_cash: 'Collected Cash',
            bosta_fees: 'Bosta Fees',
            flyer_size: 'Flyer Size',
            flyer_size_tooltip:
              'Order fees depend on the size. For more info, check your {pricingButton} in Settings.',
            pricing_plan: 'pricing plan'
          },
          cashout: {
            title: 'Cash-out',
            title_tooltip:
              'The details of transferring the deposited amount to your bank account.',
            next_cashout_date: 'Next Cash-out Date',
            next_cashout_date_tooltip:
              'Your deposited cash cycles will be transferred to your bank account at this date.',
            cashout_frequency: 'Cash-out Frequency',
            cashout_frequency_tooltip:
              'The details of transferring the deposited amount to your bank account.',
            transfered_at: 'Transfered At',
            transfer_id: 'Transfer ID',
            transfer_amount: 'Transfer Amount'
          },
          compensation: {
            title: 'Compensation',
            title_tooltip:
              'The details of the compensation for your damaged or lost orders.',
            compensated_at: 'Compensated At',
            compensation_id: 'Compensation ID',
            compensation_amount: 'Compensation Amount'
          }
        }
      },
      shipment_details_timeline: {
        date_titles: {
          expected_return_date: 'Expected return date:',
          delivery_date: 'Delivered at:',
          expected_delivery_date: 'Expected delivery date:',
          returened_date: 'Returned at:',
          exception_reason: 'Exception Reason: ',
          expected_exchange_date: 'Expected exchange date: '
        },
        footer: {
          next_action_label: 'Next Action:',
          shipment_age_label: 'Shipment age:',
          overdue_sla_label: 'Overdue SLA',
          shipment_age_tail_label: ' out of 10 days'
        },
        shipment_age_tooltips: {
          10: 'A order is expected to be delivered or returned to you within 10 days starting from the pickup date. If the order is not delivered for more than 10 days, it is an overdue order (late order).',
          15: 'A order is expected to be picked up from your customer within 10 days starting from the creation date. If the order is not picked up within 10 days, it is an overdue order (late order).',
          20: 'A order is expected to be picked up from your customer and returned to you within 10 days starting from the creation date. If the order is not returned to you within 10 days, it is an overdue order (late order).',
          25: 'A order is expected to be picked up from your customer and returned to you within 10 days starting from the creation date. If the order is not returned to you within 10 days, it is an overdue order (late order).',
          30: 'The process of delivering a order to your customer (forward), picking up another one from your customer and returning it to you (Customer return pickup) should take the maximum of 10 days, starting from the pick up date of the forward delivery. If the order age exceeded 10 days and its not yet complete, then it’s a late order. If your order is late, you can create a ticket and we’ll help you.'
        }
      },
      action_by: 'Action By : {name}',
      received_by: 'Received By: '
    },
    actions: {
      confirm_delete_message: 'Are you sure you want to delete order {orderId}',
      print_airwaybill: 'Print Airwaybill',
      print_all: 'Print all',
      ticket: 'Tickets',
      create_ticket: 'Create ticket',
      related_tickets: 'Related Tickets ({relatedTickets})',
      search: 'Search',
      new_shipment: 'New Order',
      edit_shipment: 'Edit order',
      make_default: 'Make it default'
    },
    mass_upload: {
      title: 'Upload Multiple Orders',
      fulfillment: {
        title: 'Upload Multiple Orders From Inventory',
        sub_title: 'Easy upload your orders from your inventory stock.',
        step_one: '1. {download} the Inventory Fulfillment upload template.'
      },
      sub_title: 'Easy upload your orders using the mass upload sheet.',
      steps_title: 'Follow these steps to upload your oders:',
      step_one: '1. {download} the mass upload template.',
      step_two: '2. {learn} how to fill-in the template with you orders.',
      step_three: '3. You can only upload files in excel format (.xlsx)',
      download: 'Download',
      learn: 'Learn',
      business: 'Business',
      business_placeholder: 'Select business',
      upload_dialog: {
        title: 'Upload your orders sheet',
        sub_title:
          'Drag and drop the file here or click on the “Upload File” button',
        hint: 'The max number of orders you can upload is 500',
        upload_file: 'Upload File'
      },
      error_old_template_title: 'New mass upload template!',
      error_old_template_sub_title:
        'Download the new sheet to upload your orders.',
      error_old_template_hint:
        'Use Microsoft Excel 2010 or later version to get the best experience.',
      error_old_template:
        'The uploaded file is not supported anymore. Please download the new template to upload your orders.',
      error_select_business: 'Please select business first',
      error_table: {
        title: 'Upload failed.',
        sub_title: '{issuesNumber} issues found in uploaded orders.',
        table_title: 'Please fix the below errors then re-upload the file.',
        file_name: 'File name: {fileName}',
        file_size: 'File size: {fileSize} MB',
        total_orders: 'Total Orders: {ordersNumber}',
        reupload: 'Re-upload',
        columns: {
          field_names: 'Field Names',
          issue: 'Issue',
          in_rows: 'In Rows',
          view_more: 'View more'
        },
        errors: {
          field_is_required: 'Field is required',
          full_name_less_than_50_characters:
            'Should be less than 50 characters',
          phone_not_valid:
            'Should be a mobile number that starts with either 010, 011, 012 or 015 (ex: 01233344556).',
          second_phone_not_valid:
            'Should be either a mobile number or a landline (ex: 01233344556 or 0223333333).',
          street_name_less_than_250_characters:
            'Should be less than 250 characters. (ex: Mosddak Street)',
          work_address_should_be_empty_yes_or_no: 'Should be empty, Yes or No.',
          delivery_notes_less_than_100_characters:
            'Should be less than 200 characters',
          order_type_should_either_be_empty_or_one_of_dropdown_values:
            'Should be one of the dropdown values i.e. Deliver, Return, Exchange or Cash collection.',
          deliver_negative_string:
            "Should be empty or positive currency values only. Bosta doesn't allow adding refund amounts on deliver orders.",
          exchange_allowed_refund_string:
            'Should be empty or currency values only, positive value for collection and negative value for refunds.',
          exchange_not_allowed_refund_negative_string:
            'Should be empty or positive currency values only. If you need to place refund orders, please contact support for more info.',
          return_allowed_refund_string:
            'Should be empty or currency values only.',
          return_not_allowed_refund_positive_negative_string:
            'The cash amount should be empty on return orders. If you need to place refund orders, please contact support for more info.',
          cash_collection_allowed_refund_empty_zero_string:
            'COD is required for the cash collection orders. It must be a positive value (greater than 0) for collection and negative value for refunds',
          cash_collection_not_allowed_refund_empty_zero_negative_string:
            'COD is required for the cash collection orders. It must be a positive value (greater than 0). ',
          package_type_should_either_be_empty_or_one_of_dropdown_values:
            'Should either be empty or one of the dropdown values i.e. Parcel, Document , Light Bulky or Heavy Bulky.',
          number_items_less_than_4: 'Should less than or equal 4 digits.',
          package_description_less_than_500:
            'Should be less than 500 characters.',
          order_reference_less_than_100: 'Should be less than 100 characters.',
          allow_open_packages_should_be_empty_yes_or_no:
            'Should be empty, Yes or No.',
          return_number_items_less_than_4:
            'Should be less than or equal 4 digits.',
          return_package_description_less_than_500:
            'Should be less than 500 characters.',
          // uncovered_city:
          //   'Bosta does not deliver to {cityName}. {clickHere} to find the list of uncovered cities and area. Select another delivery address or remove this order.',
          uncovered_city:
            'Bosta does not deliver to the selected city. {clickHere} to find the list of uncovered cities and area. Select another delivery address or remove this order',
          click_here: 'Click here',
          not_found_city:
            'The selected City is not supported by Bosta. Please contact support to update for more help',
          not_found_zone:
            'The selected Area is not supported by Bosta. Please contact support to update for more help.',
          not_found_district:
            'The selected Area is not supported by Bosta. Please contact support to update for more help.',
          cod_greater_than_30000:
            'The COD amount should be less than or equal 30,000 EGP',
          sadui_phone_not_valid:
            'Should be a mobile number with 10 digits (ex: 0503344556). or 12 digits (+966503344556)',
          sadui_second_phone_not_valid:
            'Should be either a mobile number or a landline',
          invalid_tracking_number:
            'Tracking Numbers should be a number from 9 to 13 digits',
          tracking_number_already_used: 'Tracking Numbers are already in use',
          invalid_sku: 'This Product SKU doesnt relate to this business',
          invalid_sku_quantity:
            "This Product doesn't have more available quantity",
          uncovered_zone: 'The selected area is uncovered.'
        }
      },
      success: {
        title: 'Your file is uploaded successfully',
        sub_title: 'You can find your orders in orders page.',
        print_all_airwaybills: 'Print All Air Waybills',
        upload_more: 'Upload More Orders',
        view_orders: 'View Orders',
        file_downloaded_successfully: 'File downloaded successfully.'
      }
    },
    order_new_statues: {
      new: 'New',
      pending_pickup: 'Pending pickup',
      picked_up: 'Picked up',
      in_transit: 'In transit',
      preparing_for_delivery: 'Preparing for delivery',
      preparing_for_collection: 'Preparing for collection',
      out_for_delivery: 'Out for delivery',
      preparing_for_return: 'Preparing for Return',
      out_for_return: 'Out for Return',
      preparing_for_pickup: 'Preparing for pickup',
      out_for_pickup: 'Out for pickup',
      preparing_for_exchange: 'Preparing for exchange',
      out_for_exchange: 'Out for exchange',
      exchanged: 'Exchanged',
      out_for_collection: 'Out for collection',
      rescheduled: 'Rescheduled',
      canceled: 'Canceled',
      on_hold: 'On hold',
      delivered: 'Delivered',
      returned_to_business: 'Returned to business',
      collected: 'Collected',
      deleted: 'Deleted',
      archived: 'Archived',
      lost: 'Lost',
      damaged: 'Damaged',
      trial: 'Trial',
      location: 'Location',
      returned: 'Returned',
      exchanged_returned: 'Exchanged & Returned',
      returned_to_origin: 'Returned to origin',
      waiting_for_business_action: 'Awaiting your action',
      awaiting_your_action: 'Awaiting your action',
      out_for_signature: 'Out for signature',
      signed_and_collected: 'Signed & Collected',
      pending_customer_signature: "Pending customer's signature",
      signed_and_returned: 'Signed & Returned',
      fulfilled: 'Fulfilled',
      returned_to_stock: 'Returned to Stock',
      terminated: 'Terminated',
      collection_failed: 'Collection Failed',
      ready_for_pickup: 'Ready for pickup',
      ready_for_collection: 'Ready for collection',
      ready_to_dispatch: 'Ready to Dispatch',
      air_transit: 'Air Transit',
      received_at_warehouse_rejected_return:
        'Received at warehouse - Rejected Return',
      rejected_return: 'Rejected Return'
    },
    states_logs: {
      investigation:
        'Order is in investigation and the team is working to solve it.',
      return_investigation:
        'Return is on hold - Our team is investigating the problem',
      market_place_log: 'Order is confirmed',
      business: 'business',
      consignee: 'consignee',
      trial: {
        first: 'First trial',
        second: 'Second trial',
        third: 'Third trial'
      },
      rejection_reasons: {
        lost: 'We apologize for the inconvenience. The shipment was not found',
        damaged: 'We apologize for what happened, the shipment was damaged',
        delivered: 'The order has already been delivered',
        rto: 'The order has been canceled and will be returned to you'
      },
      rescheduled: {
        order_rescheduled_by_cs:
          'Order is rescheduled - Based on your request, Customer care postponed delivery to',
        ticket_ref_number: 'Ticket Ref. number',
        whatsapp_order_rescheduled:
          'Order is rescheduled - customer postponed delivery to',
        order_rescheduled_by_business:
          'Order has been rescheduled as per customer’s request to'
      },
      whatsapp: {
        confirm_exception: 'Consignee confirmed the exception',
        confirm_fake_exception: 'Consignee confirmed a fake exception',
        exception_message_sending:
          'Bosta sent a WhatsApp message link with exception details to consignee',
        confirm_init_message: 'Consignee confirmed delivery details',
        get_delivery_info:
          'Consignee saw WhatsApp message with delivery details',
        message_sending:
          'Bosta sent a WhatsApp message link with delivery details to consignee'
      },
      order_details_updates: {
        order_allow_open_update:
          'Customer care updated package info to allow consignee opening the package as per your request',
        order_allow_open_business_update:
          'Consignee is now allowed to open package as per customer’s request.',
        order_disallow_open_update:
          'Customer care updated package info to disallow consignee opening the package as per your request',
        order_disallow_open_business_update:
          'Consignee is now not allowed to open package as per customer’s request.',
        order_phone_update:
          'Consignee number updated - Based on your request, customer care updated consignee number to',
        order_phone_business_update:
          'Consignee phone number was updated as per customer’s request to',
        order_address_updated:
          'Consignee address updated - Based on your request, customer care updated delivery address to',
        order_address_updated_by_business:
          'Consignee’s address changed to {address} as per customer’s request.',
        address_updates: {
          city: 'City',
          street: 'Street name',
          building: 'Building #',
          floor: 'Floor #',
          apartment: 'Apt. #',
          district: 'District',
          zone: 'Zone',
          landmarks: 'Landmarks'
        }
      },
      logs: {
        order_created: 'Order is created',
        pickup_scheduled: 'Pickup is scheduled',
        picked_up: 'Our courier picked up the order',
        picked_up_fulfillment:
          "Order is picked up from your inventory stock at Bosta's warehouse.",
        received_at_warehouse:
          'Order is received at Our warehouses and being processed.',
        in_transit: 'Order is being transferred to our hubs.',
        uncovered_address:
          'Order is on hold due to delivery address validation',
        order_archived:
          'Order is archived- 2 weeks have passed over trying to return the order and you were not reachable.',
        order_lost: 'Order is lost.',
        order_damaged: 'Order is damaged.',
        cancellation_request_by_cs:
          'Cancelation request submitted - based on your request customer care requested canceling the order',
        cancellation_request_by_business:
          'Order cancellation request has been sent based on your request.',
        order_cancelled_by_cs:
          'Order has been cancelled - Based on your request, Customer care cancelled the order.',
        work_address: 'Work Address',
        call_duration: 'Call Duration',
        phone_number: 'Phone Number: {number}',
        sms_sent: 'Star sent SMS to consignee',
        missed_call: 'Star missed a call from consignee',
        incoming_call: 'Star received a call from consignee',
        outgoing_call: 'Star made a call to consignee',
        rejection_ticket: 'You opened a ticket with the rejection reason.',
        cod_amount_updated:
          'Customer care updated package COD as per customer’s request.',
        cod_amount_updated_by_business: 'Package COD was updated to: {amount}',
        ticket_created_by_business:
          'A ticket is created with ID: [{ticketId}] based on your request.',
        ticket_created_by_consignee:
          'A ticket is created with ID: [{ticketId}] based on consginee request.',
        cod_change_request_rejected:
          'A request to change the package COD was rejected. [Reason: {rejectionReason}]',
        cod_change_request_created:
          'A request to change package COD was submitted by customer service upon your request'
      },
      10: {
        20: 'Order is being prepared for delivery.',
        23: 'Order is picked up',
        22: 'Order is out for pickup',
        15: "Order is picked up from your inventory stock at Bosta's warehouse.",
        41: 'Order is out for delivery',
        45: 'Order is delivered',
        48: 'Order is deleted',
        '48_1': 'Order has been deleted due to 3 failed pickup attempts.',
        '48_2': 'Order has been deleted automatically by the system',
        pudo_at_locker: 'Order is Ready in locker.'
      },
      15: {
        8: 'Order is being prepared for collection.',
        20: 'Order is being prepared for delivery.',
        22: 'Order is out for pickup',
        23: 'Order is picked up',
        40: 'Order is out for collection',
        41: 'Order is out for delivery',
        45: 'Order is collected',
        48: 'Order is deleted',
        '48_1':
          'Order is deleted - it has been deleted by the system due to 3 failed collection attempts',
        '48_2': 'Order has been deleted automatically by the system'
      },
      20: {
        1: 'Order is going to be returned back to you after 3 failed delivery attempts',
        12: 'Order is cancelled and is going to be returned back to you.',
        13: 'Order is successfully returned back to your inventory stock',
        20: 'Order is being prepared for return.',
        105: 'You created a ticket to try returning the order',
        103: 'Order moved to on hold due to the inability to return the order to business',
        22: 'Order is out for pickup',
        23: 'Order is picked up',
        41: 'Order is out for return',
        45: 'Order is delivered',
        48: 'Order is deleted',
        '48_1': 'Order has been deleted due to 3 failed pickup attempts.',
        '48_2': 'Order has been deleted automatically by the system'
      },
      25: {
        20: 'Order is being prepared for pickup.',
        22: 'Order is out for pickup',
        23: 'Order is picked up',
        41: 'Order is out for return',
        46: 'Order is returned to you',
        48: 'Order is deleted',
        '48_1': 'Order has been deleted due to 3 failed pickup attempts.',
        '48_2': 'Order has been deleted automatically by the system'
      },
      30: {
        20: 'Order is getting prepared for exchange.',
        22: 'Order is out for exchange',
        23: 'Order is exchanged',
        41: 'Order is out for return',
        48: 'Order is deleted',
        '48_1':
          'Order is deleted by the system due to 3 failed pickup attempts',
        '48_2': 'Order has been deleted automatically by the system'
      },
      35: {
        20: 'Order is getting prepared for signature.',
        22: 'Order is out for signature',
        23: 'Order is signed and picked up',
        41: 'Order is out for return',
        48: 'Order is deleted',
        '48_1':
          'Order is deleted - it has been deleted by the system due to 3 failed collection attempts',
        '48_2': 'Order has been deleted automatically by the system'
      },
      exceptions: {
        10: {
          1: 'Order is rescheduled- customer was not in the address at the delivery time.',
          2: 'Order is rescheduled- customer requested a change in the address.',
          3: 'Order is rescheduled-  customer postponed delivery to {date}.',
          4: 'Order is canceled- customer wanted to open shipment before receiving it.',
          new4: 'Courier was unable to deliver the order due to [Customer wants to open the package].',
          accept4: 'You approved for customer to open the package.',
          5: 'Order is rescheduled- customer was unreachable due to incorrect phone.',
          address5:
            'Courier was unable to deliver the order due to [Unclear address]',
          phone5:
            'Courier was unable to deliver the order due to [Wrong customer phone number]',
          address_change_5: 'You updated the order address',
          address_not_changes_5:
            'Order is rescheduled- customer was unreachable and address is not clear',
          phone_change_5: 'You updated the order info',
          phone_not_changes_5:
            'Order is rescheduled- customer was unreachable due to incorrect phone',
          6: 'Order is canceled as per your request.',
          7: 'Order is rescheduled- customer was not answering the phone.',
          8: 'Order is canceled- customer refused to receive the order.',
          12: 'Order is canceled due to uncovered delivery address.',
          16: 'Failed Attempt',
          100: 'Order is rescheduled due to bad weather conditions',
          101: 'Order is rescheduled due to unexpected behavior from the customer',
          103: 'Order is rescheduled - Customer has requested POS machine',
          default: 'Order received an exception: {reason}'
        },
        15: {
          1: 'Order is rescheduled- customer was not in the address at the cash collection time.',
          2: 'Order is rescheduled- customer requested a change in the address.',
          3: 'Order is rescheduled-  customer postponed cash collection to {date}.',
          5: "Order is rescheduled-  Our courier couldn't reach the customer due to incorrect phone.",
          address5:
            'Courier was unable to collect the cash due to [Unclear address]',
          phone5:
            'Courier was unable to collect the cash due to [Wrong customer phone number]',
          address_change_5: 'You updated the order address',
          address_not_changes_5:
            'Order is rescheduled- customer was unreachable and address is not clear',
          phone_change_5: 'You updated the order info',
          phone_not_changes_5:
            'Order is rescheduled- customer was unreachable due to incorrect phone',
          6: 'Order is canceled as per your request.',
          7: 'Order is rescheduled- customer was not answering the phone.',
          10: 'Order is canceled - customer refused to give the cash to Our courier.',
          12: 'Order is canceled due to uncovered delivery address.',
          16: 'Failed Attempt',
          100: 'Order is rescheduled due to bad weather conditions',
          101: 'Order is rescheduled due to unexpected behavior from the customer',
          31: 'Order is rescheduled-  Your customer doesn’t have the exact COD amount.',
          default: 'Order received an exception: {reason}'
        },
        20: {
          20: 'Return is rescheduled- You request address change.',
          21: 'Return is rescheduled- You postponed the return to {date}.',
          22: "Return is rescheduled - Our courier couldn't reach you as the phone number was incorrect.",
          23: "Return is rescheduled - Our courier couldn't reach you on your phone.",
          24: 'Return is on hold - You refused to receive the returned order due to a problem with it.',
          25: 'Return is rescheduled- You were not in the address at the return time.',
          26: 'Return is on hold - You refused to receive the returned order. The order is damaged.',
          27: 'Return is on hold - You refused to receive the returned order. Empty order.',
          28: 'Return is on hold - You refused to receive the returned order. The order is incomplete.',
          29: 'Return is on hold - You refused to receive the returned order. The order does not belong the business.',
          30: 'Return is on hold - You refused to receive the returned order. The order was opened while it should not.',
          12: 'Order is canceled due to uncovered delivery address.',
          16: 'Failed Attempt',
          return4: 'You requested returning the order back to you.',
          100: 'Order is rescheduled due to bad weather conditions',
          101: 'Order is rescheduled due to unexpected behavior from the customer',
          default: 'Order received an exception: {reason}'
        },
        25: {
          1: 'Order is rescheduled- customer was not in the address at the pickup time.',
          2: 'Order is rescheduled- customer requested a change in the address.',
          3: 'Order is rescheduled-  customer postponed pickup to {date}.',
          5: "Order is rescheduled-  Our courier couldn't reach the customer due to incorrect phone.",
          address5:
            'Courier was unable to pickup the order due to [Unclear address]',
          phone5:
            'Courier was unable to pickup the order due to [Wrong customer phone number]',
          address_change_5: 'You updated the order address',
          address_not_changes_5:
            'Order is rescheduled- customer was unreachable and address is not clear',
          phone_change_5: 'You updated the order info',
          phone_not_changes_5:
            'Order is rescheduled- customer was unreachable due to incorrect phone',
          6: 'Order is canceled as per your request.',
          7: "Order is rescheduled-  Our courier couldn't reach the customer.",
          9: 'Order is canceled - customer refused to give the shipment to Our courier.',
          16: 'Failed Attempt',
          100: 'Order is rescheduled due to bad weather conditions',
          101: 'Order is rescheduled due to unexpected behavior from the customer'
        },
        30: {
          1: 'Order is rescheduled- customer was not in the address at the exchange time.',
          2: 'Order is rescheduled- customer requested a change in the address.',
          3: 'Order is rescheduled-  customer postponed delivery to {date}.',
          4: 'Order is canceled- customer wanted to open shipment before receiving it.',
          new4: 'Courier was unable to exchange the order due to [Customer wants to open the package].',
          accept4: 'You approved for customer to open the package.',
          5: 'Order is rescheduled- customer was unreachable due to incorrect phone.',
          address5:
            'Courier was unable to exchange the order due to [Unclear address]',
          phone5:
            'Courier was unable to exchange the order due to [Wrong customer phone number]',
          address_change_5: 'You updated the order address',
          address_not_changes_5:
            'Order is rescheduled- customer was unreachable and address is not clear',
          phone_change_5: 'You updated the order info',
          phone_not_changes_5:
            'Order is rescheduled- customer was unreachable due to incorrect phone',
          6: 'Order is canceled as per your request.',
          7: 'Order is rescheduled- customer was not answering the phone.',
          8: 'Order is canceled- customer refused to receive the order.',
          16: 'Failed Attempt',
          100: 'Order is rescheduled due to bad weather conditions',
          101: 'Order is rescheduled due to unexpected behavior from the customer'
        },
        35: {
          1: 'Order is rescheduled- customer was not in the address at the signature time.',
          2: 'Order is rescheduled- customer requested a change in the address.',
          3: 'Order is rescheduled-  customer postponed signature to {date}.',
          4: 'Order is canceled- customer wanted to open shipment before receiving it.',
          new4: 'Courier was unable to exchange the order due to [Customer wants to open the package].',
          accept4: 'You approved for customer to open the package.',
          5: 'Order is rescheduled- customer was unreachable due to incorrect phone.',
          address5:
            'Courier was unable to deliver the document due to [Unclear address]',
          phone5:
            'Courier was unable to deliver the document due to [Wrong customer phone number]',
          address_change_5: 'You requested to update the order address.',
          address_not_changes_5:
            'Order is rescheduled- customer was unreachable and address is not clear',
          phone_change_5: 'You requested to updated the customer phone.',
          phone_not_changes_5:
            'Order is rescheduled- customer was unreachable due to incorrect phone',
          6: 'Order is canceled as per your request.',
          7: 'Order is rescheduled- customer was not answering the phone.',
          8: 'Order is canceled- customer refused to sign the document.',
          9: 'Order is canceled since you refused to hand the document to Our courier.',
          16: 'Failed Attempt',
          100: 'Order is rescheduled due to bad weather conditions',
          101: 'Order is rescheduled due to unexpected behavior from the customer'
        }
      },
      outbound_actions: {
        10: {
          return_to_shipper:
            'Our call center confirmed with you that your order has been deleted as per your request.',
          uncovered_address:
            'Order is canceled due to uncovered delivery address.',
          customer_not_answering:
            'Unreachable customer- Our call center tried to reach your customer on their phone but there was no answer.',
          reschdule_to_tomm_or_new_date:
            'Order is rescheduled- Our call center confirmed rescheduling the delivery date with your customer to be on [{newDate}].',
          phone_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the delivery date with your customer to be on [{newDate}].',
          phone_update:
            'Data update- Our call center updated the customer phone to be: [{newPhone}].',
          address_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the delivery with your customer to be on [{newDate}].',
          address_update:
            'Data update- Our call center updated the customer address to be: [{newAddress}].',
          restart_order_when_picked_up_from_business:
            'Your order is rescheduled for delivery.\nIt is expected to be delivered within {day} days.',
          reset_order:
            'Your order is rescheduled for delivery.\nIt is expected to be delivered within {day} days.'
        },
        20: {
          return_to_shipper:
            'Order is canceled- Our call center confirmed the deletion of this order.',
          uncovered_address:
            'Order is canceled due to uncovered delivery address.',
          customer_not_answering:
            'Unreachable customer- Our call center tried to reach your customer on their phone but there was no answer.',
          reschdule_to_tomm_or_new_date:
            'Order is rescheduled- Our call center confirmed rescheduling the delivery date with your customer to be on [{newDate}].',
          phone_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the delivery date with your customer to be on [{newDate}].',
          phone_update:
            'Data update- Our call center updated the customer phone to be: [{newPhone}].',
          address_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the delivery with your customer to be on [{newDate}].',
          address_update:
            'Data update- Our call center updated the customer address to be: [{newAddress}].',
          restart_order_when_picked_up_from_business:
            'Your order is rescheduled for delivery.\nIt is expected to be delivered within {day} days.',
          reset_order:
            'Your order is rescheduled for delivery.\nIt is expected to be delivered within {day} days.'
        },
        25: {
          return_to_shipper:
            'Our call center confirmed the deletion of this order.',
          uncovered_address:
            'Order is canceled due to uncovered pickup address.',
          customer_not_answering:
            'Unreachable customer- Our call center tried to reach your customer on their phone but there was no answer.',
          reschdule_to_tomm_or_new_date:
            'Order is rescheduled- Our call center confirmed rescheduling the pickup date with your customer to be on [{newDate}].',
          phone_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the pickup date with your customer to be on [{newDate}].',
          phone_update:
            'Data update- Our call center updated the customer phone to be: [{newPhone}].',
          address_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the pickup with your customer to be on [{newDate}].',
          address_update:
            'Data update- Our call center updated the customer address to be: [{newAddress}].',
          restart_order_when_picked_up_from_business:
            'Your order is rescheduled for delivery.\nIt is expected to be delivered within {day} days.',
          reset_order:
            'Your order is rescheduled for delivery.\nIt is expected to be delivered within {day} days.'
        },
        30: {
          return_to_shipper:
            'Our call center confirmed the cancelation of this order.',
          uncovered_address:
            'Order is canceled due to uncovered delivery address.',
          customer_not_answering:
            'Unreachable customer- Our call center tried to reach your customer on their phone but there was no answer.',
          reschdule_to_tomm_or_new_date:
            'Order is rescheduled- Our call center confirmed rescheduling the exchange date with your customer to be on [{newDate}].',
          phone_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the exchange date with your customer to be on [{newDate}]',
          phone_update:
            'Data update- Our call center updated the customer phone to be: [{newPhone}].',
          address_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the exchange  with your customer to be on [{newDate}].',
          address_update:
            'Data update- Our call center updated the customer address to be: [{newAddress}].'
        },
        35: {
          return_to_shipper:
            'Our call center confirmed the cancelation of this order.',
          uncovered_address:
            'Order is canceled due to uncovered delivery address.',
          customer_not_answering:
            'Unreachable customer- Our call center tried to reach your customer on their phone but there was no answer.',
          reschdule_to_tomm_or_new_date:
            'Order is rescheduled- Our call center confirmed rescheduling the sign date with your customer to be on [{newDate}].',
          phone_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the sign date with your customer to be on [{newDate}]',
          phone_update:
            'Data update- Our call center updated the customer phone to be: [{newPhone}].',
          address_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the sign date  with your customer to be on [{newDate}].',
          address_update:
            'Data update- Our call center updated the customer address to be: [{newAddress}].'
        },
        15: {
          return_to_shipper:
            'Our call center confirmed the deletion of this order.',
          uncovered_address:
            'Order is canceled due to uncovered delivery address.',
          customer_not_answering:
            'Unreachable customer- Our call center tried to reach your customer on their phone but there was no answer.',
          reschdule_to_tomm_or_new_date:
            'Order is rescheduled- Our call center confirmed rescheduling the cash collection date with your customer to be on [{newDate}].',
          phone_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the cash collection date with your customer to be on [{newDate}]',
          phone_update:
            'Data update- Our call center updated the customer phone to be: [{newPhone}].',
          address_update_and_reschdule:
            'Order is rescheduled- Our call center confirmed rescheduling the cash collection  with your customer to be on [{newDate}].',
          address_update:
            'Data update- Our call center updated the customer address to be: [{newAddress}].',
          cancelled:
            'Our call center confirmed with you that your order has been deleted as per your request.',
          restart_order_when_exception:
            'Your order is rescheduled for cash collection.\nIt is expected to be collected {day}.'
        }
      }
    },
    states_description: {
      check_covered_zones: 'Please check our covered zones.',
      packing_guidelines: 'Packing guidelines',
      schedule_pickup: 'Schedule a pickup',
      days: 'days',
      tomorrow: 'tomorrow',
      within: 'within',
      today: 'today',
      few_days: 'within a few days',
      update_address: 'Update Address',
      update_phone: 'Update Phone',
      update_phone_number: 'Update phone number',
      success_message: 'Your order {tn} has been updated successfully.',
      return_ticket_title: 'Please return my order',
      rejection_exception:
        'The return has been rejected from your side, Please wait for the final order update to fill the required data to start the investigation',
      send: {
        10: 'Your order has been created. Schedule a pickup date and Bosta courier will pickup the order.',
        new10:
          'Your order has been created. Request a pickup date and Bosta courier will pickup the order.',
        101: 'Your order is rescheduled for delivery.\nIt is expected to be delivered {day}.',
        102: 'Your order is rescheduled for delivery after it was about to be returned to you.It is expected to be delivered {day}.',
        103: 'You requested a pickup, please print the Air waybill and pack your order before {pickupDate}. {pack}.',
        20: 'Your order is currently getting prepared.\nIt is expected to be out for delivery today.',
        21: 'Bosta courier picked up your order.\nIt is expected to be received at Bosta warehouses by the end of day.',
        24: 'Your order is received at Bosta warehouses and is being processed.\nIt is expected to be out for delivery {day}.',
        41: 'Your order is out for delivery!\nIt is expected to be delivered today.',
        45: 'Your order is successfully delivered to your customer.',
        49: 'Your customer delivery address is uncovered by Bosta.\nYour order is expected to be returned back to you {day}.\n{c}',
        491: 'Your customer delivery address is uncovered by Bosta.\n{checkCoveredZones}',
        47: {
          1: 'Your customer was not in the address at the delivery time.\nIt is expected to be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, the order will be returned back to you.',
          2: 'Your customer requested a change in the address.\nIt is expected to be out for delivery again tomorrow at the new address.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.',
          3: 'Your customer postponed the delivery date of your order.\nIt is expected to be out for delivery again at {newDate}.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.',
          4: 'Your customer refused to receive your order before opening it first.\nIt is expected to be returned back to you within {day} days.',
          awaiting_4:
            'Your customer asked to open the package before receiving it.\nDo you want Bosta to allow opening package or return order?',
          accept_4:
            'Since you allowed your customer to open the package, your order is expected to be out for delivery again tomorrow.',
          5: "Bosta courier couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.",
          address5:
            'Bosta is trying to deliver this order, but the address is not clear.\nPlease update the address, so it can be out for delivery again tomorrow.',
          phone5:
            'Bosta is trying to reach your customer to deliver this order, but the phone number is incorrect.\nPlease update the phone number, so it can be out for delivery again tomorrow.',
          address_change_5:
            'Your order address will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.',
          address_not_changes_5:
            'Bosta is trying to deliver this order, but the address is not clear.\nThe courier will be out for delivery again tomorrow. and try to reach your customer.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.',
          phone_change_5:
            'Your order info will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.',
          phone_not_changes_5:
            "Bosta courier couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.",
          6: 'Your order has been canceled as per your request.\nIt is expected to be returned back to you within {day} days.',
          7: "Bosta courier couldn't reach your customer on their phone to deliver the order.\nIt is expected to be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.",
          8: 'Your customer refused to receive your order and canceled it.\nIt is expected to be returned back to you {day}.',
          100: "Bosta courier couldn't reach your customer due to the bad weather conditions.\nIt is expected to be out for delivery again tomorrow.",
          101: "Bosta courier couldn't reach your customer due to unexpected behavior from the customer.\nIt is expected to be out for delivery again tomorrow."
        },
        10.1: 'You requested a pickup, please print the Air waybill and pack your order before the pickup date. Packing guidelines. It is expected to be pickup your order at pickup date.',
        fxf_send: {
          new10:
            'Your order has been created and will be fulfilled from your inventory stock soon.',
          47: {
            phone_change_5:
              'Your order info will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to your Inventory stock.',
            phone_not_changes_5:
              "Bosta courier couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to your Inventory stock.",
            address_change_5:
              'Your order address will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to your Inventory stock.',
            address_not_changes_5:
              'Bosta is trying to deliver this order, but the address is not clear.\nThe courier will be out for delivery again tomorrow. and try to reach your customer.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to your Inventory stock.',
            1: 'Your customer was not in the address at the delivery time.\nIt is expected to be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, the order will be returned back to your Inventory stock.',
            2: 'Your customer requested a change in the address.\nIt is expected to be out for delivery again tomorrow at the new address.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to your Inventory stock.',
            3: 'Your customer postponed the delivery date of your order.\nIt is expected to be out for delivery again at {newDate}.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to your Inventory stock.',
            6: 'Your order has been canceled as per your request.\nIt is expected to be returned back to your Inventory stock within {day} days.',
            7: "Bosta courier couldn't reach your customer on their phone to deliver the order.\nIt is expected to be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to your Inventory stock.",
            8: 'Your customer refused to receive your order and canceled it.\nIt is expected to be returned back to your Inventory stock {day}.'
          }
        },
        pudo_received:
          'Your order is received at PUDO warehouse and is being processed.',
        pudo_waiting_pickup:
          'Your order is in circle k station waiting for you customer to pick it.',
        pudo_delivered: 'Your order is successfully picked by your customer.'
      },
      sign_and_return: {
        new10:
          'Your order has been created. Request a pickup date and Bosta courier will pickup the order.',
        101: 'Your order is rescheduled for delivery.\nIt is expected to be delivered {day}.',
        102: 'Your order is rescheduled for delivery after it was about to be returned to you.It is expected to be delivered {day}.',
        103: 'You requested a pickup, please print the Air waybill and pack your order before {pickupDate}. {pack}.\nIt is expected to be pickup your order at pickup date.',
        20: 'Your order is currently getting prepared.\nIt is expected to be out for signature today.',
        202: 'Your document is being prepared for pickup.\nIt is expected to be signed and picked up today.',
        21: 'Bosta courier picked up your order.\nIt is expected to be received at Bosta warehouses by the end of day.',
        24: 'Your order is received at Bosta warehouses and is being processed.\nIt is expected to be out for signature {day}.',
        22: 'Your order is out for signature!\nIt is expected to be signed today.',
        221: 'Bosta courier is is out to sign and pickup your document.\nIt is expected to be signed and picked up from your customer today.',
        23: 'Your document is successfully signed by your customer, and the courier picked it up.',
        231: 'Bosta courier just picked up your signed document from your customer.\nYour document is about to be received at Bosta warehouses.',
        43: 'Your document is successfully handed to your customer and they kept it for review.\nIt is expected to be picked up from your customer at {pickupDate}.',
        1: 'Your document is being prepared for pickup.\nIt is expected to be signed and picked up today.',
        2: 'Bosta courier is is out to sign and pickup your document.\nIt is expected to be signed and picked up from your customer today.',
        3: 'Bosta courier just picked up your signed document from your customer.\nYour document is about to be received at Bosta warehouses {day}.',
        49: 'Your customer delivery address is uncovered by Bosta.\nYour order is expected to be returned back to you {day}.\n{c}',
        491: 'Your customer delivery address is uncovered by Bosta.\n{checkCoveredZones}',
        46: 'Your document is successfully returned back to you.',
        47: {
          1: 'Your customer was not in the address at the signature time.\nIt is expected to be out for signature again tomorrow.\nBeware that Bosta tries to  sign your document  3 times, after then, the order will be returned back to you.',
          2: 'Your customer requested a change in the address.\nIt is expected to be out for signature again tomorrow at the new address.\nBeware that Bosta tries to  sign your document 3 times, after then, your order will be returned back to you.',
          3: 'Your customer postponed the signature date of your order.\nIt is expected to be out for signature again at {newDate}.\nBeware that Bosta tries to sign your document 3 times, after then, your order will be returned back to you.',
          4: 'Your customer refused to receive your order before opening it first.\nIt is expected to be returned back to you within {day} days.',
          awaiting_4:
            'Your customer asked to open the package before receiving it.\nDo you want Bosta to allow opening package or return order?',
          accept_4:
            'Since you allowed your customer to open the package, your order is expected to be out for delivery again tomorrow.',
          5: "Bosta courier couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.",
          address5:
            'Bosta is trying to deliver your document, but the address is not clear.\nPlease update the address, so it can be out for signature again tomorrow.',
          phone5:
            'Bosta is trying to reach your customer to deliver your document, but the phone number is incorrect.\nPlease update the phone number, so it can be out for signature again tomorrow.',
          address_change_5:
            'Your order address will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for signature again tomorrow.\nBeware that Bosta tries to sign your document 3 times, after then, your order will be returned back to you.',
          address_not_changes_5:
            'Bosta is trying to deliver your document, but the address is not clear.\nThe courier will be out for signature again tomorrow. and try to reach your customer.\nBeware that Bosta tries to sign your document 3 times, after then, your order will be returned back to you.',
          phone_change_5:
            'Your order info will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for signature again tomorrow.\nBeware that Bosta tries to sign your document 3 times, after then, your order will be returned back to you.',
          phone_not_changes_5:
            "Bosta courier couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for signature again tomorrow.\nBeware that Bosta tries to sign your document 3 times, after then, your order will be returned back to you.",
          6: 'Your order has been canceled as per your request.\nIt is expected to be returned back to you within {day} days.',
          7: "Bosta courier couldn't reach your customer on their phone to deliver the order.\nIt is expected to be out for signature again tomorrow.\nBeware that Bosta tries to sign your document 3 times, after then, your order will be returned back to you.",
          8: 'Your customer refused to sign the order and canceled it.\nIt is expected to be returned back to you {day}.',
          100: "Bosta courier couldn't reach your customer due to the bad weather conditions.\nIt is expected to be out for signature again tomorrow.",
          101: "Bosta courier couldn't reach your customer due to unexpected behavior from the customer.\nIt is expected to be out for signature again tomorrow."
        },
        471: {
          1: 'Your customer was not in the address at the document pickup time.\nIt is expected to be out for pickup again tomorrow.\nBeware that Bosta tries to  pickup your document 3 times, after then, your order will be automatically canceled.',
          2: 'Your customer requested a change in the address.\nIt is expected to be out for pickup again tomorrow at the new address.\nBeware that Bosta tries to pickup your document 3 times, after then, your order will be be automatically canceled.',
          3: 'Your customer postponed the pickup date of your order.\nIt is expected to be out for pickup again at {newDate}.\nBeware that Bosta tries to pickup your document 3 times, after then, your order will be automatically canceled.',
          5: "Bosta courier tried to pickup your order but couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for pickup again tomorrow.\nBeware that Bosta tries to pickup your document 3 times, after then, your order will be automatically canceled.",
          address5:
            'Bosta is trying to pickup your document, but the address is not clear.\nPlease update the address, so it can be out for pickup again tomorrow.',
          phone5:
            'Bosta is trying to reach your customer to pickup your document, but the phone number is incorrect.\nPlease update the phone number, so it can be out for pickup again tomorrow.',
          address_change_5:
            'Your order address will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for pickup again tomorrow.\nBeware that Bosta tries to pickup your document 3 times, after then, your order will be returned back to you.',
          address_not_changes_5:
            'Bosta is trying to pickup this order, but the address is not clear.\nThe courier will be out for pickup again tomorrow. and try to reach your customer.\nBeware that Bosta tries to pickup your document 3 times, after then, your order will be returned back to you.',
          phone_change_5:
            'Your order info will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for pickup again tomorrow.\nBeware that Bosta tries to pickup your document 3 times, after then, your order will be returned back to you.',
          phone_not_changes_5:
            "Bosta courier couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for pickup again tomorrow.\nBeware that Bosta tries to pickup your document 3 times, after then, your order will be returned back to you.",
          6: 'Your order has been canceled as per your request.',
          7: "Bosta courier couldn't reach your customer on their phone to pickup the order.\nIt is expected to be out for pickup again tomorrow.\nBeware that Bosta tries to pickup your document 3 times, after then, your order will be be automatically canceled.",
          9: 'Your customer refused to hand the document to Bosta courier.\nThat caused your order to be canceled. Feel free to create a new order.',
          100: "Bosta courier couldn't reach your customer due to the bad weather conditions.\nIt is expected to be out for pickup again tomorrow.",
          101: "Bosta courier couldn't reach your customer due to unexpected behavior from the customer.\nIt is expected to be out for pickup again tomorrow."
        }
      },
      rto: {
        1: 'Bosta tried unsuccessfully to deliver your order for 3 times.It is expected to be returned back to you {day}.',
        2: 'Your order is turned into a return order as per your request.It is expected to be returned back to you {day}.',
        3: 'Your order is rescheduled for delivery after it was about to be returned to you.It is expected to be delivered {day}.',
        4: 'Bosta call center confirmed your order cancellation.This order is expected to be returned back to you {day}.',
        20: 'Your order is being prepared for return.\nIt is expected to be returned back to you {day}.',
        201: 'Bosta confirms your order cancelation as per your request.\nThis order is expected to be returned back to you {day}.',
        24: 'Your order is returned back at Bosta warehouses.\nIt is expected to be returned back to you {day}.',
        30: 'Your order is returned back at Bosta hubs.\nIt is expected to be returned back to you {day}.',
        41: 'Your order is out for return.\nIt is expected to be returned today.',
        46: 'Your order is successfully returned back to you.',
        47: {
          20: 'You requested a change in the address of your returned order.\nIt is expected to be out for return again tomorrow in the new address.\nBeware that Bosta tries to return back your order 3 times, after then, your order will be on hold at Bosta premise for 2 weeks until you schedule a new return date.',
          21: 'You postponed the return date of your order.\nIt is expected to be out for return again on {newDate}.\nBeware that Bosta tries to return back your order 3 times, after then, your order will be on hold at Bosta premise for 2 weeks until you schedule a new return date.',
          22: "Bosta tried to return back your order, but couldn't reach you as the phone number was incorrect.\nIt is expected to be out for return again tomorrow.\nBeware that Bosta tries to return back your order 3 times, after then, your order will be on hold at Bosta premise for 2 weeks until you schedule a new return date.",
          23: "Bosta couldn't reach you on your phone to return back the order.\nIt order is expected to be out for return again tomorrow.\nBeware that Bosta tries to return back your order 3 times, after then, your order will be on hold at Bosta premise for 2 weeks until you schedule a new return date.",
          24: 'You refused to receive the order due to a problem with it.\nBosta is reviewing the order and will contact you soon.',
          25: "Your were not in the address at the order's return time.\nIt is expected to be out for return again tomorrow.\nBeware that Bosta tries to return back your order 3 times, after then, your order will be on hold at Bosta premise for 2 weeks until you schedule a new return date.",
          26: 'You refused to receive the order due to a problem with it. Bosta is reviewing the order and will contact you soon.',
          100: "Bosta courier couldn't reach your customer due to the bad weather conditions.\nIt is expected to be out for return again tomorrow.",
          101: "Bosta courier couldn't reach your customer due to unexpected behavior from the customer.\nIt is expected to be out for return again tomorrow."
        },
        fxf_rto: {
          1: 'Bosta tried unsuccessfully to deliver your order for 3 times.It is expected to be returned back to your Inventory stock {day}.',
          2: 'Your order is turned into a return order as per your request.It is expected to be returned back to your Inventory stock {day}.',
          20: 'Your order is being prepared for return.\nIt is expected to be returned back to your Inventory stock {day}.',
          24: 'Your order is returned back at Bosta warehouses.\nIt is expected to be returned back to your Inventory stock {day}.',
          30: 'Your order is returned back at Bosta hubs.\nIt is expected to be returned back to your Inventory stock {day}.',
          41: 'Your order is out for return.\nIt is expected to be returned to your Inventory stock today.',
          46: 'Your order is successfully returned back to to your Inventory stock.',
          201: 'Bosta confirms your order cancelation as per your request.\nThis order is expected to be returned back to your Inventory stock {day}.'
        },
        pudo_cancelled: 'Your order is successfully returned back to you.'
      },
      destructive: {
        48: 'Your order has been automatically cancelled as Bosta tried to pickup your order 3 times before.\nIf you need, feel free to create a new order.',
        481: 'Your order has been canceled as per your request.',
        482: 'Your order has been automatically canceled.',
        103: 'Bosta tried unsuccessfully to return your order for 3 times. Please create a ticket to schedule a new return date.\nBeware that Bosta gives you 2 weeks to schedule a return date for the order, after then, you will not be able to claim the order.',
        103.1:
          "We're very sorry for the inconvenience, You refused to receive the order due to a problem with it. Please clarify the rejection reason and create a ticket.",
        105: "Bosta tried to return your order 3 times and couldn't reach you. You created a ticket #{ticketNumber} to reschedule the return.\nFollow on your ticket and once it is closed, your order will return.",
        105.1:
          'The order is under investigation now, Please follow up on the ticket that has been raised before.',
        on_hold:
          'Bosta tried to return your order 3 times. Please create a ticket to schedule a new return date.\nBeware that Bosta gives you 2 weeks to schedule a return date for the order, after then, you will not be able to claim the order.',
        archive:
          'Your order cannot be claimed anymore.\n2 weeks have passed over trying to return the order and you were not reachable.',
        100: 'Something went wrong with your order that caused it to be lost, Bosta takes full ownership for that, and you will be compensated for this as per the terms and conditions.',
        101: 'Something went wrong with your order that caused it to be damaged, Bosta takes full ownership for that, and you will be compensated for this as per the terms and conditions.',
        49: 'Your order has been automatically canceled as Bosta tried to pickup your order 3 times before.\nIf you need, feel free to create a new order.',
        102: 'The order is not delivered yet and our team is investigating to solve the issue.'
      },
      crp: {
        c1: 'Bosta call center confirmed your order cancelation.',
        10: 'Your order has been created.\nIt is expected to be picked up from your customer {day}.',
        101: 'Your order is rescheduled for pickup.\nIt is expected to be picked up {day}.',
        20: 'Your order is being prepared for a pickup.\nIt is expected to be picked up today.',
        22: 'Bosta courier is is out to pickup your order.\nIt is expected to be picked from your customer today.',
        23: 'Bosta courier just picked up your order from your customer.\nYour order is about to be received at Bosta warehouses today.',
        47: {
          1: 'Your customer was not in the address at the order pickup time.\nIt is expected to be out for pickup again tomorrow.\nBeware that Bosta tries to deliver your pickup 3 times, after then, your order will be automatically canceled.',
          2: 'Your customer requested a change in the address.\nIt is expected to be out for pickup again tomorrow at the new address.\nBeware that Bosta tries to deliver your pickup 3 times, after then, your order will be be automatically canceled.',
          3: 'Your customer postponed the pickup date of your order.\nIt is expected to be out for pickup again at {newDate}.\nBeware that Bosta tries to deliver your pickup 3 times, after then, your order will be automatically canceled.',
          5: "Bosta courier tried to pickup your order but couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for pickup again tomorrow.\nBeware that Bosta tries to deliver your pickup 3 times, after then, your order will be automatically canceled.",
          address5:
            'Bosta is trying to pickup this order, but the address is not clear.\nPlease update the address, so it can be out for pickup again tomorrow.',
          phone5:
            'Bosta is trying to reach your customer to pickup this order, but the phone number is incorrect.\nPlease update the phone number, so it can be out for pickup again tomorrow.',
          address_change_5:
            'Your order address will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for pickup again tomorrow.\nBeware that Bosta tries to pickup your order 3 times, after then, your order will be returned back to you.',
          address_not_changes_5:
            'Bosta is trying to pickup this order, but the address is not clear.\nThe courier will be out for pickup again tomorrow. and try to reach your customer.\nBeware that Bosta tries to pickup your order 3 times, after then, your order will be returned back to you.',
          phone_change_5:
            'Your order info will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for pickup again tomorrow.\nBeware that Bosta tries to pickup your order 3 times, after then, your order will be returned back to you.',
          phone_not_changes_5:
            "Bosta courier couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for pickup again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.",
          6: 'Your order has been canceled as per your request.',
          7: "Bosta courier couldn't reach your customer on their phone to pickup the order.\nIt is expected to be out for pickup again tomorrow.\nBeware that Bosta tries to deliver your pickup 3 times, after then, your order will be be automatically canceled.",
          9: 'Your customer refused to give the package to Bosta courier.\nThat caused your order to be canceled. Feel free to create a new order.',
          100: "Bosta courier couldn't reach your customer due to the bad weather conditions.\nIt is expected to be out for pickup again tomorrow.",
          101: "Bosta courier couldn't reach your customer due to unexpected behavior from the customer.\nIt is expected to be out for pickup again tomorrow."
        },
        49: 'The customer pickup address is uncovered by Bosta.\nWe are investigating the address again.\n{c}'
      },
      exchange: {
        10: 'Your order has been created.\nSchedule a pickup date and Bosta courier will pickup the order.',
        new10:
          'Your order has been created. Request a pickup date and Bosta courier will pickup the order.',
        103: 'You requested a pickup, please print the Air waybill and pack your order before the pickup date.{pack}.\nIt is expected to be pickup your order at pickup date.',
        101: 'Your order is rescheduled for exchange.\nIt is expected to be exchanged within {day} days.',
        102: 'Your order is rescheduled for delivery after it was about to be returned to you.\nIt is expected to be delivered within {day} days.',
        20: 'Your order is currently getting prepared.\nIt is expected to be out for exchange today.',
        21: 'Bosta courier picked up your order.\nIt is expected to be received at Bosta warehouses by the end of day.',
        23: 'Your order is successfully delivered to your customer, and the return package has been picked up.',
        24: 'Your order is received at Bosta warehouses and is being processed.\nIt is expected to be out for exchange {day}.',
        30: "Your order is being transferred to Bosta 's hubs.\nIt is expected to be out for exchange {day}.",
        41: 'Your order is out for exchange!\nIt is expected to be exchanged today.',
        49: "Your customer's exchange address is uncovered by Bosta.\nYour order is expected to be returned back to you {day}.\n{checkCoveredZones}",
        491: "Your customer's exchange address is uncovered by Bosta.\n{checkCoveredZones}",
        47: {
          1: 'Your customer was not in the address at the exchange time.\nIt is expected to be out for exchange again tomorrow.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.',
          2: 'Your customer requested a change in the address.\nIt is expected to be out for exchange again tomorrow at the new address.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.',
          3: 'Your customer postponed the exchange date of your order.\nIt is expected to be out for exchange again at {newDate}.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.',
          4: 'Your customer refused to receive your order before opening it first.\nIt is expected to be returned back to you within {day} days.',
          awaiting_4:
            'Your customer asked to open the package before receiving it.\nDo you want Bosta to allow opening package or return order ?',
          accept_4:
            'Since you allowed your customer to open the package, your order is expected to be out for exchange again tomorrow.',
          5: "Bosta tried to exchange your order, but couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for exchange again tomorrow.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.",
          address5:
            'Bosta is trying to exchange this order, but the address is not clear.\nPlease update the address, so it can be out for exchange again tomorrow.',
          phone5:
            'Bosta is trying to reach your customer to exchange this order, but the phone number is incorrect.\nPlease update the phone number, so it can be out for exchange again tomorrow.',
          address_change_5:
            'Your order address will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for exchange again tomorrow.\nBeware that Bosta tries to exchange  your order 3 times, after then, your order will be returned back to you.',
          address_not_changes_5:
            'Bosta is trying to exchange this order, but the address is not clear.\nThe courier will be out for exchange again tomorrow. and try to reach your customer.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.',
          phone_change_5:
            'Your order info will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for exchange again tomorrow.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.',
          phone_not_changes_5:
            "Bosta courier couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for exchange again tomorrow.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.",
          6: 'Your order has been canceled as per your request.\nIt is expected to be returned back to you within {day} days.',
          7: "Bosta couldn't reach your customer on their phone to exchange the order.\nIt is expected to be out for exchange again tomorrow.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.",
          8: 'Your customer refused to receive your order and canceled it.\nIt is expected to be returned back to you {day}.',
          100: "Bosta courier couldn't reach your customer due to the bad weather conditions.\nIt is expected to be out for exchange again tomorrow.",
          101: "Bosta courier couldn't reach your customer due to unexpected behavior from the customer.\nIt is expected to be out for exchange again tomorrow."
        },
        10.1: 'You requested a pickup, please print the Air waybill and pack your order before the pickup date. Packing guidelines. It is expected to be pickup your order at pickup date.',
        fxf_exchange: {
          102: 'Your order is rescheduled for delivery after it was about to be returned to your Inventory stock.\nIt is expected to be delivered {day}.',
          47: {
            1: 'Your customer was not in the address at the exchange time.\nIt is expected to be out for exchange again tomorrow.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to your Inventory stock.',
            2: 'Your customer requested a change in the address.\nIt is expected to be out for exchange again tomorrow at the new address.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to your Inventory stock.',
            3: 'Your customer postponed the exchange date of your order.\nIt is expected to be out for exchange again at {newDate}.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to your Inventory stock.',
            4: 'Your customer refused to receive your order before opening it first.\nIt is expected to be returned back to your Inventory stock within {day} days.',
            8: 'Your customer refused to receive your order and canceled it.\nIt is expected to be returned back to your Inventory stock {day}.'
          }
        }
      },
      cash_collection: {
        101: 'Your order is rescheduled for cash collection,It is expected to be collected {day}.',
        11: 'Your order has been created.\nIt is expected to be collected from your customer {day}.',
        20: 'Your order is being prepared for collection.\nIt is expected to be out for collection today.',
        40: 'Bosta courier is out to collect your cash.\nIt is expected to be collected from your customer today.',
        45: "Bosta courier just collected the cash from your customer.\nYou're going to collect your cash in the next payment transfer.",
        49: 'The customer cash collection address is uncovered by Bosta.\nWe are investigating the address again.\n{checkCoveredZones}',
        47: {
          1: 'Your customer was not in the address at the cash collection time.\nIt is expected to be out for collection again tomorrow.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be be automatically canceled. ',
          2: 'Your customer requested a change in the address.\nIt is expected to be out for cash collection again tomorrow at newAddress.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be be automatically canceled.',
          3: 'Your customer postponed the cash collection date of your order.\nIt is expected to be out for collected again at the new pickup date.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be be automatically canceled.',
          5: "Bosta courier tried to collect your cash, but couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for collection again tomorrow.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be be automatically canceled.",
          address5:
            'Bosta is trying to collect your cash, but the address is not clear.\nPlease update the address, so it can be out for collection again tomorrow.',
          phone5:
            'Bosta is trying to reach your customer to collect your cash, but the phone number is incorrect.\nPlease update the phone number, so it can be out for collection again tomorrow.',
          address_change_5:
            'Your order address will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for collection again tomorrow.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be returned back to you.',
          address_not_changes_5:
            'Bosta is trying to deliver this order, but the address is not clear.\nThe courier will be out for collection again tomorrow. and try to reach your customer.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be returned back to you.',
          phone_change_5:
            'Your order info will be updated. Bosta will reach out again for your customer.\nIt is expected will be out for collection again tomorrow.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be returned back to you.',
          phone_not_changes_5:
            "Bosta courier couldn't reach your customer as the phone number was incorrect.\nIt is expected will be out for collection again tomorrow.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be returned back to you.",
          6: 'Your order has been canceled as per your request.',
          7: "Bosta courier couldn't reach your customer on their phone to collect the cash.\nIt is expected to be out for collection again tomorrow.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be be automatically canceled.",
          10: 'Your customer refused to give the cash to Bosta courier which caused your order to be canceled.\n Feel free to create a new order.',
          100: "Bosta courier couldn't reach your customer due to the bad weather conditions.\nIt is expected to be out for collection again tomorrow.",
          101: "Bosta courier couldn't reach your customer due to unexpected behavior from the customer.\nIt is expected to be out for collection again tomorrow."
        }
      },
      outbound_actions: {
        10: {
          return_to_shipper:
            'Bosta call center confirmed with you that your order has been deleted as per your request.',
          uncovered_address:
            "Your customer's delivery address is uncovered by Bosta.\nYour order is expected to be returned back to you {day} days.\n{checkCoveredZones}",
          fxf_uncovered_address:
            "Your customer's delivery address is uncovered by Bosta.\nYour order is expected to be returned back to your Inventory stock {day} days.\n{checkCoveredZones}",
          customer_not_answering:
            "Bosta call center couldn't reach your customer on their phone to verify the (rescheduling or cancelation) reason.\nIt is expected to be out for delivery again tomorrow.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.",
          reschdule_to_tomm_or_new_date:
            'Bosta call center confirmed with your customer to reschedule the delivery date.\nIt is expected to be out for delivery again at {newDate}.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.',
          phone_update_and_reschdule:
            'Bosta call center updated your customer phone number and confirmed to reschedule the delivery date.\nIt is expected to be delivered at {newDate}.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.',
          address_update_and_reschdule:
            'Bosta call center updated your customer address and confirmed to reschedule the delivery date.\nIt is expected to be delivered at {newDate}.\nBeware that Bosta tries to deliver your order 3 times, after then, your order will be returned back to you.',
          restart_order_when_picked_up_from_business:
            'Your order is rescheduled for delivery.\nIt is expected to be delivered {day} days.',
          reset_order:
            'Your order is rescheduled for delivery.\nIt is expected to be delivered {day} days.'
        },
        20: {
          return_to_shipper:
            'Bosta call center confirmed your order cancelation.\nThis order is expected to be returned back to you {day}.',
          fxf_return_to_shipper:
            'Bosta call center confirmed your order cancelation.\nTThis order is expected to be returned back to your Inventory Stock within {day}.'
        },
        25: {
          return_to_shipper:
            'Bosta call center confirmed with you that your order has been deleted as per your request.',
          uncovered_address:
            "Your customer's pickup address is uncovered by Bosta.\nThat caused your order to be canceled.\n {checkCoveredZones}",
          customer_not_answering:
            "Bosta call center couldn't reach your customer to verify the (rescheduling or cancelation) reason.\nIt is expected to be out for pickup again tomorrow.\nBeware that Bosta tries to pickup your order 3 times, after then, your order will be be automatically canceled. ",
          reschdule_to_tomm_or_new_date:
            'Bosta call center confirmed with your customer to reschedule the pickup date.\nIt is expected to be out for pickup again at the {newDate}.\nBeware that Bosta tries to pickup your order 3 times, after then, your order will be be automatically canceled. ',
          phone_update_and_reschdule:
            'Bosta call center updated your customer phone number and confirmed to reschedule the pickup date.\nIt is expected to be picked up at {newDate}.\nBeware that Bosta tries to pickup your order 3 times, after then, your order will be be automatically canceled.',
          address_update_and_reschdule:
            'Bosta call center updated your customer address and confirmed to reschedule the pickup date.\nIt is expected to be picked up at {newDate}.\nBeware that Bosta tries to pickup your order 3 times, after then, your order will be be automatically canceled. ',
          cancelled:
            'Bosta call center confirmed with you that your order has been canceled as per your request.',
          restart_order_when_picking_up_from_consignee:
            'Your order is rescheduled for pickup.\nIt is expected to be picked up {day}.',
          reset_order:
            'Your order is rescheduled for pickup.\nIt is expected to be picked up {day}.'
        },
        30: {
          return_to_shipper:
            'Bosta call center confirmed with you that your order has been canceled as per your request.',
          uncovered_address:
            "Your customer's exchange address is uncovered by Bosta.\nYour order is expected to be returned back to you {day}.\n{checkCoveredZones}",
          customer_not_answering:
            "Bosta call center couldn't reach your customer to verify (the rescheduling or the cancelation) of the order.\nIt is expected to be out for exchange again tomorrow.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.",
          reschdule_to_tomm_or_new_date:
            'Bosta call center confirmed with your customer to reschedule the exchange date.\nIt is expected to be out for exchange again at {newDate}.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.',
          phone_update_and_reschdule:
            'Bosta call center updated your customer phone number and confirmed to reschedule the exchange date.\n It is expected to be out for exchange at {newDate}.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.',
          address_update_and_reschdule:
            'Bosta call center updated your customer address and confirmed to reschedule the exchange date.\nIt is expected to be out for exchange at {newDate}.\nBeware that Bosta tries to exchange your order 3 times, after then, your order will be returned back to you.',
          restart_order_when_picked_up_from_business:
            'Your order is rescheduled for exchange.\nIt is expected to be exchanged {day} days.'
        },
        15: {
          return_to_shipper:
            'Bosta call center confirmed with you that your order has been canceled as per your request.',
          uncovered_address:
            "Your customer's cash collection address is uncovered by Bosta.\nThat caused your order to be canceled.\n{checkCoveredZones}",
          customer_not_answering:
            "Bosta call center couldn't reach your customer to verify the (rescheduling or cancelation) of the order.\nIt is expected to be out for collection again tomorrow.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be be automatically canceled.",
          reschdule_to_tomm_or_new_date:
            'Bosta call center confirmed with your customer to reschedule the cash collection date.\nIt is expected to be out for collected again at {newDate}.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be be automatically canceled.',
          phone_update_and_reschdule:
            'Bosta call center updated your customer phone number and confirmed to reschedule the cash collection date.\nIt is expected to be collected at {newDate}.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be be automatically canceled. ',
          address_update_and_reschdule:
            'Bosta call center updated your customer address and confirmed to reschedule the cash collection date.\nIt is expected to be collected at {newDate}.\nBeware that Bosta tries to collect your cash 3 times, after then, your order will be be automatically canceled.',
          cancelled:
            'Bosta call center confirmed with you that your order has been canceled as per your request.',
          restart_order_when_exception:
            'Your order is rescheduled for cash collection.\nIt is expected to be collected {day}.'
        },
        35: {
          return_to_shipper:
            'Bosta call center confirmed with you that your order has been canceled as per your request.',
          uncovered_address:
            "Your customer's address is uncovered by Bosta.\nYour order is expected to be returned back to you {day}.\n{checkCoveredZones}",
          customer_not_answering:
            "Bosta call center couldn't reach your customer to verify (the rescheduling or the cancelation) of the order.\nIt is expected to be out for signature again tomorrow.\nBeware that Bosta tries to sign your document 3 times, after then, your order will be returned back to you.",
          reschdule_to_tomm_or_new_date:
            'Bosta call center confirmed with your customer to reschedule the sign date.\nIt is expected to be out for signature again at {newDate}.\nBeware that Bosta tries to sign your document 3 times, after then, your order will be returned back to you.',
          phone_update_and_reschdule:
            'Bosta call center updated your customer phone number and confirmed to reschedule the sign date.\n It is expected to be out for signature at {newDate}.\nBeware that Bosta tries to sign your order 3 times, after then, your order will be returned back to you.',
          address_update_and_reschdule:
            'Bosta call center updated your customer address and confirmed to reschedule the sign date.\nIt is expected to be out for signature at {newDate}.\nBeware that Bosta tries to sign your order 3 times, after then, your order will be returned back to you.',
          restart_order_when_picked_up_from_business:
            'Your order is rescheduled for signature.\nIt is expected to be signed {day} days.'
        }
      },
      deliver_from_inventory: {
        fulfilled:
          'Your order is picked up from your inventory stock at Bosta’s warehouse.',
        returned_to_stock:
          'Your order is successfully returned back to your inventory stock.'
      }
    },
    order_status: {
      awaiting_for_action: 'Awaiting for Action',
      on_hold: 'On hold',
      terminated: 'Terminated',
      processing: 'Processing',
      created: 'Created',
      delivered: 'Delivered',
      returned: 'Returned',
      "can't_be_delivered": 'Can’t be delivered',
      returned_to_business: 'Returned to business',
      delivery_confirmed: 'Delivery Confirmed',
      lost_or_damaged: 'Can’t be delivered',
      picked_up: 'Picked Up',
      completed: 'Completed'
    }
  },
  outbound: {
    tabs: { deliver: 'Deliveries', pickup: 'Pickups' },
    pickups: {
      form: {
        pickup_id: 'Pickup ID',
        trials: 'Trials numbers',
        business_phone: 'Business phone',
        business_name: 'Business name',
        holdAt: 'On Hold at',
        state: 'State'
      },
      table: {
        business: 'Business Name',
        pickup_id: 'Pickup Id',
        customer: 'Contact Phone',
        agent: 'Agent Name',
        on_hold: 'On Hold At',
        trials: 'No. Of Trials',
        exception_reason: 'Exception Reason',
        action: 'Action'
      }
    }
  },
  stars: {
    stars_listing: {
      create_star: 'Create New Star',
      export: 'Export Xlsx',
      table_title: 'Total Stars: {count}',
      export_success: 'Stars loaded successfully, exporting in progress.',
      search_filters: {
        title: 'Search Stars',
        star_id: 'Star ID',
        name: 'Name',
        fawry_id: 'Fawry ID',
        email: 'Email',
        phone: 'Phone',
        vehicle: 'Vehicle',
        state: 'State',
        status: 'Status',
        date: 'Date',
        shortage: 'Shortage'
      },
      table_columns: {
        star_name: 'Star Name',
        star_wallet: 'Star Wallet',
        availability_state: 'Availability State',
        status: 'Status',
        star_id: 'Star ID',
        contractor: 'Contractor'
      }
    },
    create_edit_star: {
      titles: {
        stars: 'Stars',
        basic_info: 'Basic Info',
        address: 'Star Address',
        vechile_info: 'Vehicle Info',
        salary: 'Salary Configuration'
      },
      form_labels: {
        vehicle_type: 'Vehicle Type',
        make: 'Make',
        license: 'License',
        color: 'Color',
        year: 'Year',
        model: 'Model',
        hub: 'Hub',
        fleet_type: 'Fleet Type',
        salary_type: 'Salary Type',
        fawry_id: 'Fawry ID',
        date: 'Date',
        pos_id: 'POS ID',
        valu_payment_link: 'Valu Payment Link',
        national_number: 'National Number',
        national_id: 'National ID',
        update_national_id: 'Upload new images'
      },
      form_placeholders: {
        star_first_name: 'Write star first name',
        star_last_name: 'Write star last name',
        star_email: 'example@email.com',
        star_fawry_id: 'Add Fawry ID',
        star_vehicle_type: 'Add Vehicle Type',
        vehicle_make: 'Make',
        vehicle_license: 'Add Vehicle License',
        vehicle_color: 'Add Vehicle Color',
        vehicle_year: 'Year',
        vehicle_model: 'Add Vehicle Model ',
        pos_id: 'Add POS ID',
        valu_payment_link: 'Add Valu Payment Link',
        national_number_placeholder: 'Add 14 digits national ID number'
      },
      update_success: 'Images Updated Successfully',
      form_errors: {
        national_length: 'National ID number should be {length} digits.',
        national_number: "National ID can't include letters.",
        national_id: 'You need to upload at least 2 images (front/back sides).'
      },
      salary_type: {
        fixed_per_day: {
          title: 'Fixed Per Day',
          subtitle: 'Fixed amount per working day.',
          help_sentense:
            '{amount} {currency} is the fixed amount in {zone} and vehicle type: {vehicleType}'
        },
        package_variable: {
          title: 'Package Variable',
          subtitle: 'Variable amount based on the zone and the vehicle type.',
          help_sentense: `{amount} {currency} is the variable amount in {zone} and vehicle type: {vehicleType}`
        },
        guaranteed_salary: {
          title: 'Guaranteed Salary',
          subtitle:
            'A guaranteed salary regardless of the number of packages handled.',
          help_sentense:
            '{amount} {currency} is the amount guaranteed per day in {zone} and vehicle type: {vehicleType}'
        },
        default_help_sentense:
          ' amount based on the star zone and the vehicle type.'
      },
      fleet_types: {
        first_mile: 'First Mile',
        middle_mile: 'Middle Mile',
        last_mile: 'Last Mile'
      },
      vehicle_types: {
        walking: 'Walking',
        sedan: 'Sedan',
        bike: 'Bike',
        bicycle: 'Bicycle',
        motorcycle: 'Motorcycle',
        van: 'Van',
        tricycle: 'Tricycle',
        car: 'Car',
        truck: 'Truck'
      },
      star_state: {
        online: 'Online',
        offline: 'Offline',
        busy: 'Busy'
      },
      star_status: {
        verified: 'Verified',
        pending: 'Pending'
      },
      star_shortage: {
        has_shortage: 'Has shortage',
        no_shortage: 'No shortage'
      },
      update_star_warning_message:
        'Updating this field will affect the star salary calculations. The updates will be effective starting from the next cycle. Are you sure you want to update the star [{updatedParams}]?',
      create_star_account: 'Create Star Account',
      update_star_account: 'Update Star Account',
      create_star_salary_zone_modal: {
        add_new_zone: 'Add New Zone',
        label: {
          new_zone_name: 'New Zone Name',
          similarZone: 'Similar Zone'
        },
        placeholders: {
          zone_name: 'Zone Name'
        }
      }
    },
    star_details: {
      cash_records: 'Cash Records',
      data_log: 'Data Log',
      star_name: 'Star Name',
      star_Wallet: 'Star Wallet',
      star_id: 'Star ID',
      phone: 'Phone',
      fawry_id: 'Fawry ID',
      hub: 'Hub',
      zone: 'Zone',
      email: 'Email',
      fleet_type: 'Fleet Type',
      vehicle_type: 'Vehicle Type',
      vehicle_description: 'Vehicle Description',
      contractor: 'Contractor',
      address: 'Address',
      description: 'Description',
      notes: 'Notes',
      reset_liability: 'Reset Liability',
      unlink_phone: 'Unlink Phone number',
      salary_config: 'Salary Configurations',
      table_columns: {
        amount: 'Amount',
        business: 'Business',
        tracking_no: 'Tracking  No.',
        money_source: 'Money Source',
        collected_at: 'Collected At',
        notes: 'Notes',
        date_time: 'Date Time',
        event_description: 'Event Description',
        taken_by: 'Taken By',
        receipt_number: 'Receipt Number',
        amount: 'Amount',
        receipt_date: 'Receipt Date'
      },
      notification_messages: {
        empty_export_date_range: 'Please select date range to export.',
        star_transaction_loading_success:
          'Stars transactions loaded successfully.',
        collect_cash_success: 'All cash collected successfully.'
      },
      actions: {
        title: 'Actions',
        delivery_details: 'Delivery Details',
        collect: 'Collect',
        activate: 'Activate',
        deactivate: 'Deactivate',
        edit: 'Edit',
        offline: 'Offline',
        online: 'Online',
        exclude_from_liability: 'Exclude from Liability',
        collect_all: 'Collect All',
        export: 'Export',
        add_settlement: 'Add Settlement',
        cancel: 'Cancel',
        confirm: 'Are you sure to collect all?',
        activate_confirmation: 'Are you sure you want to activate this star?',
        activate_star: 'Activate Star',
        deactivation_reason_label: 'Please select a deactivation reason',
        updated_successfully: 'Star updated successfully!'
      },
      deactivation_reasons: {
        harassment: 'Harassment',
        deposit_issue: 'Deposit Issue',
        thieft: 'Theft',
        personal_reasons: 'Personal Reasons',
        route_sortation: 'Route sortation issue',
        deducation_without_knowledge: 'Deduction without knowledge',
        hub_attiude: 'Hub attitude',
        lack_of_training: 'Lack of training',
        long_waiting_time: 'Long waiting time for dispatch',
        high_ofd: 'High OFD',
        vehicle_issues: 'vehicle issues',
        no_insurance: 'No insurance',
        total_income: 'Total income',
        open_packages: 'Open packages',
        over_promising: 'Over promising',
        open_package_by_force: 'Open packages by force'
      }
    }
  },
  signin: {
    verify_user: {
      title: 'A one-time password (OTP) Login',
      subtitle_OTP_app_type:
        "Enter the code from the two-factor app on your mobile device. If you've lost your device, you may enter one of your recovery codes",
      subtitle_OTP_email_type:
        'This account requires email verification. A verification code has been sent to your email, please paste it here.',
      subtitle_OTP_sms_type:
        'This account requires phone verification. A verification code has been sent to your phone number, please paste it here.',
      PIN_code_placeholder: 'Enter your PIN code',
      otp_validation_message: 'Enter valid OTP'
    }
  },
  star_log_action: {
    CALL: 'Phone Call to:',
    SMS: 'SMS to phone: ',
    REVERSE_EXCEPTION: 'Reverse exception',
    REVERSE_DELIVERED_DELIVERY: 'Reverse delivered delivery',
    CRP_PICKUP: 'Crp pickup',
    FM_PICKUP: 'Fm pickup',
    PICKED_UP_FROM_CONSIGNEE: 'Pickup up from consignee',
    DELIVER: 'Order is delivered ',
    COLLECT: 'Collect',
    RETURNED_TO_BUSINESS: 'Returned to business',
    EXCEPTION: 'Order recieved an exception ',
    between_call: ' {number} between calls ',
    length: 'Duration {length} secs',
    logs_table_title: 'Star Action Logs'
  },
  hubs: {
    title: 'Hub',
    tabs: {
      hub_notification: 'Hub Notification',
      receive_pickups: 'Receive Pickups',
      barcode_to_awb: 'Barcode to AWB',
      generate_barcodes: 'Generate Barcodes',
      add_to_shelf: 'Add to Shelf',
      receive: 'Receive',
      transfer: 'Transfer',
      dispatch_returns: 'Dispatch Returns',
      debrief_stars: 'Debrief Stars',
      debrief_cash: 'Debrief Cash',
      transfer_to_sorting: 'Transfer To Sorting',
      seals_logs: 'Seals Logs',
      manifests: 'Manifests',
      wrong_scan: 'Wrong Scan'
    },
    packages_debrief: {
      current_picture: 'Picture {current, number} out of {totalSlides, number}',
      title: 'Choose the hub and the star to begin the debrief.',
      hub_label: 'Hub',
      star_label: 'Star',
      select: 'Select',
      total_tasks: 'Total Tasks',
      pending_orders: 'Pending Orders',
      pending_pickups: 'Pending Pickups',
      success_rate: 'Success Rate',
      tomorrow: 'Tomorrow',
      tabs: {
        tasks: 'Tasks ({length})',
        exceptions: 'Exceptions ({length})',
        pickups: 'Pickups ({length})',
        soho_pickups: 'SOHO Pickups ({length})',
        first_mile: 'First Mile ({length})',
        last_mile: 'Last Mile ({length})',
        delivered: 'Delivered ({length})',
        rto: 'Returns ({length})',
        cod: 'COD ({totalCOD})',
        ccod: 'CCOD ({totalCOD})'
      },
      columns: {
        pickup_id: 'Pickup ID',
        tracking_number: 'TN',
        last_update_date: 'Last update date',
        type: 'Type',
        state: 'State',
        rescheduled_to: 'Rescheduled to: ',
        retry_delivery: 'Retry Delivery: ',
        cod: 'COD',
        record_action: 'Record Action',
        image_thumbnail: 'Image Thumbnail',
        pickup: 'Pickup',
        size: 'Size',
        business: 'Business Name',
        ofd: 'OFD',
        pending_orders: 'Pending Orders',
        returned_orders: 'Returned Orders',
        rejected_orders: 'Rejected Orders',
        national_id: 'National ID',
        actions: 'Actions',
        image: 'Image',
        reason: 'Reason',
        ticket_id: 'Ticket ID',
        attempts: 'No. of attempts',
        order_age: 'Order age (days)',
        action_type: 'Action Type',
        otp: 'OTP',
        signature: 'Signature',
        no_of_packages: '# of Packages'
      },
      tasks_tab: {
        pending_orders: 'Pending Orders ({pendingOrdersLength})',
        completed_orders: 'Completed Orders ({completedOrdersLength})',
        pending_pickup: 'Pending Pickup Requests ({pendingPickupsLength})',
        completed_pickup: 'Completed Pickup  ({completedPickupsLength})',
        filters: {
          completed: 'Completed ({completedLength})',
          pending: 'Pending ({pendingLength})'
        }
      },
      exceptions_tab: {
        tracking_number: 'Tracking Number',
        order_type: 'Order Type',
        states: {
          return_rejected: 'Return Rejected',
          on_hold: 'On Hold',
          rescheduled: 'Rescheduled',
          tomorrow: 'Tomorrow',
          scheduled_to: 'Scheduled to',
          assign_to: 'Assign to',
          rto: 'RTO',
          return_issues: 'Return Issues'
        },
        order_age_tooltips: {
          starting_from_pickup_date:
            'No. of days starting from the order pickup date.',
          changed_to_rto_date:
            'No. of days starting from turning the order into RTO.',
          received_at_warehouse_date_for_return:
            'No. of days starting from receiving the order into the warehouse to be returned.',
          creation_date: 'No. of days starting from the order creation date.'
        },
        attempts_tooltips: {
          delivery: 'No. of delivery attempts.',
          pickup: 'No. of pickup attempts.',
          return: 'No. of return attempts.',
          collection: 'No. of collection attempts.'
        },
        exceptions: {
          10: {
            1: 'Order is rescheduled- customer was not in the address at the delivery time.',
            2: 'Order is rescheduled- customer requested a change in the address.',
            3: `Order is rescheduled-  customer postponed delivery to {date}.`,
            4: 'Order is canceled- customer wanted to open shipment before receiving it.',
            5: 'Order is rescheduled- customer was unreachable due to incorrect phone.',
            6: 'Order is canceled as per your request.',
            7: 'Order is rescheduled- customer was not answering the phone.',
            8: 'Order is canceled- customer refused to receive the order.',
            12: 'Order is canceled due to uncovered delivery address.',
            13: 'Waiting for data modification - address not clear.'
          },
          15: {
            1: 'Order is rescheduled- customer was not in the address at the cash collection time.',
            2: 'Order is rescheduled- customer requested a change in the address.',
            3: `Order is rescheduled-  customer postponed cash collection to {date}.`,
            5: "Order is rescheduled-  Bosta courier couldn't reach the customer due to incorrect phone.",
            6: 'Order is canceled as per your request.',
            7: 'Order is rescheduled- customer was not answering the phone.',
            10: 'Order is canceled - customer refused to give the cash to Bosta courier.',
            12: 'Order is canceled due to uncovered delivery address.'
          },
          20: {
            20: 'Return is rescheduled- You request address change.',
            21: `Return is rescheduled- You postponed the return to {date}.`,
            22: "Return is rescheduled - Bosta courier couldn't reach you as the phone number was incorrect.",
            23: "Return is rescheduled - Bosta courier couldn't reach you on your phone.",
            24: 'Return is on hold - You refused to receive the returned order due to a problem with it.',
            25: 'Return is rescheduled- You were not in the address at the return time.',
            12: 'Order is canceled due to uncovered delivery address.'
          },
          25: {
            1: 'Order is rescheduled- customer was not in the address at the pickup time.',
            2: 'Order is rescheduled- customer requested a change in the address.',
            3: `Order is rescheduled-  customer postponed pickup to {date}.`,
            5: "Order is rescheduled-  Bosta courier couldn't reach the customer due to incorrect phone.",
            6: 'Order is canceled as per your request.',
            7: "Order is rescheduled-  Bosta courier couldn't reach the customer.",
            9: 'Order is canceled - customer refused to give the shipment to Bosta courier.'
          },
          30: {
            1: 'Order is rescheduled- customer was not in the address at the exchange time.',
            2: 'Order is rescheduled- customer requested a change in the address.',
            3: `Order is rescheduled-  customer postponed delivery to {date}.`,
            4: 'Order is canceled- customer wanted to open shipment before receiving it.',
            5: 'Order is rescheduled- customer was unreachable due to incorrect phone.',
            6: 'Order is canceled as per your request.',
            7: 'Order is rescheduled- customer was not answering the phone.',
            8: 'Order is canceled- customer refused to receive the order.'
          }
        },
        default_exception: `{reason}`,
        modals: {
          ticket_modal: {
            order_lost: 'Order Lost',
            order_damage: 'Order Damage',
            order_opened_by_force: 'Order Opened by Force',
            order_picked_by_mistake: 'Order Picked by mistake'
          },
          remove_order_modal: {
            confirmation_message: 'Are you sure you want to remove this order?'
          }
        }
      },
      rto_tab: {
        view_details: {
          title: 'Business Name',
          tabs: {
            common_columns: {
              tracking_number: 'Tracking Number',
              order_type: 'Original Order Type',
              last_update: 'Last Update',
              cod: 'COD'
            },
            cards: {
              pending_orders: 'Pending Orders',
              returned_orders: 'Returned Orders',
              rejected_orders: 'Rejected Orders',
              signature: 'Signature',
              national_id: 'National ID',
              contact_info: 'Contact Info'
            },
            pending_orders: {
              title: 'Pending Orders'
            },
            returned_orders: {
              title: 'Returned Orders',
              columns: {
                actions: 'Actions'
              },
              actions: {
                confirm: 'Confirm',
                create_ticket: 'Create Ticket'
              },
              extra_content: {
                selected: '{count} Selected',
                confirm_all_btn: 'Confrim All'
              }
            },
            rejected_orders: {
              title: 'Rejected Orders',
              columns: {
                exception_image: 'Exception Image',
                exception_reason: 'Exception Reason',
                ticket_id: 'Ticket ID'
              }
            }
          }
        }
      },
      soho_pickups_tab: {
        soho_pickups: 'SOHO Pickups',
        security_seal: 'Security Seal.',
        scan_security_seal: 'Scan Security Seal',
        print_all_awb: 'Print All AWBs',
        replace_new_security_seal: 'Replace New Security Seal',
        security_seal_not_found: 'Security seal number doesn’t exist',
        create_new_security_seal: 'Create New Security Seal',
        write_or_scan_new_security_seal: 'Write or Scan new security seal',
        received: 'Received',
        pickups: 'Pickups',
        seal_number: 'Seal No.',
        action: 'Action',
        deliveries_received_successfully: 'Deliveries received successfully!',
        delivery_receiving_failed:
          'Delivery with TN: {TN} failed to be received.',
        deliveries_receiving_failed:
          'Deliveries with TNs: {TN} failed to be received.'
      },
      actions: {
        start_debriefing: 'Start Debriefing',
        end_debriefing: 'End Debriefing',
        refresh: 'Refresh',
        remove: 'Remove',
        create_ticket: 'Create Ticket',
        confirm_receiving: 'Confirm Receiving',
        confirm_receiving_orders: 'Confirm Receiving Orders',
        confirm_receiving_pickups: 'Confirm Receiving Pickups',
        print_awb: 'Print AWB',
        view_details: 'View Details'
      },
      print_awb_modal: {
        header: ' Did you re-pack this item in bosta flyer?',
        confirm: 'Confirm & Print',
        cancel: 'cancel'
      },
      new_fm_pickup_table: {
        missed_pickup: '({count}) Missed Pickups',
        fm_missed_end_debrief_error:
          'pickups are missing, are you sure you want to close debriefing without scanning them?'
      }
    },
    receive_from_hub: {
      seal_numbers_search: {
        input_placeholder: 'Search by seal number',
        submit_btn: 'Search',
        clear_btn: 'Clear',
        error_msgs: {
          not_numeric: 'Please, Enter numbers only for seal numbers.',
          no_results: 'No search results'
        }
      },
      receive_seals: {
        header: 'You have {count} packages to recieving from Sorting',
        master_seals_count: '{count} Master Seal',
        seals_count: '{count} Seals',
        packages_count: '{count} Packages',
        master_seal_scan: {
          label: 'Master Seal',
          placeholder: 'Scan Master Number'
        },
        seal_scan: {
          label: 'Scanning Seal',
          placeholder: 'Scan Code Here...'
        },
        tracking_number: {
          label: 'Tracking Number',
          placeholder: 'Scann Tracking Number',
          seal: 'Seal',
          tables: {
            headers: {
              tracking_numbers_count: 'Tracking Numbers ({count})',
              scanned_tracking_numbers_count:
                'Scanned Tracking Numbers ({count})'
            },
            columns: {
              tracking_number: 'Tracking No.'
            }
          }
        },
        success_msgs: {
          all_seals_scanned:
            'All tracking numbers for all seals are scanned successfully. Please Confirm Receiving.'
        },
        error_msgs: {
          scanned_before: 'Scanned before.',
          not_found: 'Not found.',
          not_scanned:
            'You did not scan [{trackingNumbers}], are you sure you want to confirm?',
          failed_to_receive:
            'Although the success of receiving the packages there are some package failed to be received on the system, please review the following packages status: {trackingNumbers}',
          bulky_seal_doesnt_exist:
            'The scanned tracking number does not exist in this master seal'
        },
        start_receiving: 'Start Receiving',
        cancel_receiving: 'Cancel Receiving',
        confirm_receiving: 'Confirm Receiving',
        count_from_total: '{count} From {total}'
      }
    },
    receive_new_pickup: {
      rto_assigned_to_route:
        'This RTO order is assigned to route, remove it from route [{routeId}] before receiving it in the hub.',
      order_is_ofd:
        'This order is assigned to route, remove it from route [{routeId}] before receiving it in the hub.',
      modal: {
        size_confirm: 'Are you sure you entered the correct size(s)?',
        weight_confirm: 'Are you sure you entered the correct weight(s)?'
      },
      weight_required: 'Weight is required!',
      pickups_tab_actions: {
        package_size: 'Package Size',
        scanning_type: 'Scanning Type',
        weight_placeholder: 'Weight in KG',
        size_tracking_placeholder: 'Package Size / Tracking Number',
        tooltip: {
          sizes: 'Scan flyer barcode first then scan Tracking Number',
          weights:
            'Enter the package weight first and then scan Tracking Number'
        }
      },
      star_no_of_pickups: 'This star has {count} packages to receive',
      group_by_business: 'Group pickups by business'
    },
    rto_modal: {
      title: 'This order is turned to RTO',
      subtitle: 'Stick the RTO sticker on the AWB',
      cancel_btn_text: 'Cancel',
      confirm_btn_text: 'Confirm and add to route',
      assign_to: 'Assign to',
      or: 'or',
      select_hub_modal: {
        title: 'This order is turned to RTO',
        dropdown_placeholder: 'Select Hub'
      },
      assignee_modal: {
        title: 'This order is turned to RTO',
        subtitle: 'Stick the RTO sticker on the AWB',
        cancel_btn_text: 'Cancel',
        confirm_btn_text: 'Save',
        shelf: {
          name: 'shelf',
          placeholder: 'Select Shelf or Scan',
          btn_text: 'Shelf'
        },
        route: {
          name: 'route',
          placeholder: 'Select Route',
          btn_text: 'Select another route'
        }
      }
    },
    dispatch_rto: {
      title: 'Manage Return Orders',
      hub_dropdown_placeholder: 'Select Hub',
      search_input_placeholder: 'Search for orders by Tracking Number',
      group_by: {
        dropdown_placeholder: 'Group by',
        business: 'Business',
        shelves: 'Bin'
      },
      export_btn: 'Export',
      rto_table: {
        title: 'Total Orders ({rtoCount})',
        header: {
          tracking_number: 'Tracking Number',
          order_type: 'Order Type',
          age: {
            title: 'Age',
            day: '(Day)',
            tooltip: 'The number of days the order has been turned to RTO.'
          },
          actions: 'Actions'
        },
        unknown: {
          word: 'Unknown',
          tooltip: 'Bin is not detected'
        },
        transfer_to_sorting_facility: 'Sorting Facility',
        actions: {
          assign_to_route: 'Assign to Route',
          assign_to_shelf: 'Assign to Bin',
          assign_to_sorting_facility: 'Transfer to Sorting Facility'
        },
        empty_content: {
          title: 'Manage Hub RTO Orders',
          subtitle: 'Select hub first to start manage all RTO orders.'
        },
        no_search_results: 'No search results found.',
        error_msgs: {
          select_right_group:
            'Select the right {group} before you take an action.'
        },
        success_msgs: {
          single_order_assigned_to_route:
            'Order is successfully added to route {name}.',
          single_order_assigned_to_shelf:
            'Order is successfully added to bin {name}.',
          group_orders_assigned_to_route:
            '{groupName} orders are successfully added to route {name}.',
          group_orders_assigned_to_shelf:
            '{groupName} orders are successfully added to bin {name}.',
          not_all_selected_sorting:
            'Not all orders should be sent to sorting, please select the correct orders only.'
        }
      },
      scan: {
        select_assignee_dropdown: {
          label: 'Select Action',
          placeholder: 'Select',
          assignees: {
            route: 'Assign to Route',
            bin: 'Assign to Bin',
            transfer_to_hub: 'Transfer'
          },
          hint: 'Select Route, Bins or transfer to hub.'
        },
        assignee: {
          route: {
            dropdown_placeholder: 'Select Route',
            extra: 'Create New Route',
            modal_create_new: {
              title: 'Create New Route',
              placeholder: 'Route Name',
              errorMsg: 'Please enter the route name',
              successMsg: 'Route is created successfully.'
            },
            modal: {
              title: 'Select Route'
            }
          },
          bin: {
            dropdown_placeholder: 'Scan or select bin',
            modal: {
              title: 'Select Bin'
            }
          }
        },
        input_label: 'Scan Tracking Number',
        input_placeholder: 'Scan or type tracking number',
        table: {
          title: 'Total Orders ({count})',
          header: {
            tracking_number: 'Tracking No.',
            business: 'Business',
            action: 'Action'
          },
          item_modal: {
            title: 'Confirm',
            content:
              'Are you sure you want to undo scanning of TN {trackingNumber}?',
            cancel_btn: 'Cancel',
            submit_btn: 'Yes'
          },
          items_list_modal: {
            title: 'Confirm',
            content: 'Are you sure you want to cancel scanning the orders?',
            cancel_btn: 'Cancel',
            submit_btn: 'Yes'
          }
        },
        cancel_btn: 'Cancel',
        submit_btn: {
          save: 'Save',
          next: 'Next'
        },
        error_msgs: {
          not_numeric: 'Please, Enter numbers only for tracking number.',
          scanned_before:
            'This tracking number is already in the list, please, scan another one.',
          not_rto: 'No search results found. This order is not a return order.',
          not_in_this_hub:
            'This order is assigned to ({hubName}) hub, please receive this order into your hub and send it to the sorting facility.',
          final_state_order:
            'This order is ({orderState}). No further actions can be taken on this order.',
          other_states:
            'This order is ({orderState}). It must be received at your hub.',
          fulfillment_orders:
            'Fulfilment return orders cannot be assigned to route. Please remove them before saving.'
        }
      }
    },
    hub_transfer: {
      wrong_destnation_hub_error:
        'You cannot transfer this order to the selected hub, it should be transferred to {hubname}',
      title: 'Choose destination hub & click on start scanning',
      tabs: {
        transfer_seals: 'Transfer Seals/Bins',
        dispatch: 'Dispatch'
      },
      transfer_seals: {
        hub_star_selectors: {
          source_hub: 'Source Hub',
          destination_hub: 'Destination Hub',
          deliveries_count_banner:
            'You have {count} packages to transfer to “{hubName}” Hub.',
          view_packages: 'View Packages'
        },
        not_at_warehouse_validation: {
          success: 'Deliveries received successfully!',
          title: 'Confirm transfer',
          message: 'This order is not received at warehouse',
          confirm: 'Receive & Add to seal'
        },
        scanning_type: {
          seals: 'Seals',
          bulky: 'Bulky',
          bins: 'Bins'
        },
        scanning: {
          scanning_type: 'Scannig Type',
          scanning_seal: 'Scanning Seal',
          scanning_seal_placeholder: 'Scan Seal',
          scan_bin: 'Scan Bin',
          scanning_seal_tooltip: 'You should scan seal to complete transfer',
          tracking_number: 'Tracking Number',
          tracking_number_placeholder: 'Scan Tracking Number',
          and: 'and',
          empty_table_title: 'No Scanned Packages',
          close_seal: 'Close Seal',
          confirm_bin: 'Confirm Bin',
          start_scanning: 'Start Scanning'
        },
        scanning_errors: {
          already_scanned: 'you already scanned {trackingNumber} before',
          seal_limit: 'You can not scan more than {limit} deliveries per seal',
          already_intransit:
            'The delivery with tracking number {trackingNumber}, is already in transit.',
          wrong_source_hub:
            'The delivery with tracking number {trackingNumber} is not located in the specified source hub.',
          wrong_assigned_hub:
            'Can’t transfer this package “{trackingNumber}” as it has a different destination hub “{hubName}”.',
          uncovered: 'This parcel is in uncovered area',
          final_state: 'This Parcel is {deliveryState}',
          exceeded_sla: 'This Parcel Exceeded SLA',
          seal_already_used: 'This seal number is already used',
          scan_new_seal:
            'You have to scan new seal to save changes of this seal’s packages.',
          select_destination_hub: 'Please select Destination Hub first.',
          scan_seal_first: 'You have to scan seal first',
          scan_new_seal_validation: 'You have to scan new seal',
          wrong_international_scan:
            'You can only transfer international orders to this destination hub',
          forward_order_error: "You can't transfer forward order to this hub",
          return_order_error: "You can't transfer return order to this hub"
        },
        table: {
          title: 'Scanned Packages ({count})',
          edit_title: 'Packages ({count})'
        },
        table_columns: {
          tracking_number: 'Tracking Number',
          size: 'Size',
          type: 'Type',
          state: 'State',
          business: 'Business'
        },
        change_assigned_hub: 'Change Assigned Hub',
        confirm_changes: 'Confirm Changes',
        assign_hub_change_success:
          'You changed the assigned hub form “{oldHub}” to hub “{newHub}”.',
        print_awb: 'Print Air Waybill',
        edit_seal_modal: {
          title: 'Edit Seal',
          message: 'Are you sure you want to edit [{sealNumber}]?'
        },
        tracking_number_on_seal_modal: {
          title: 'Tracking Number is already on a seal',
          message:
            'This [TN: {trackingNumber}] is added on [Seal: {sealNumber}]. Are you sure you want to Scan Again?'
        },
        wrong_scan_table_title: 'Wrong Scanned Deliveries'
      },
      dispatch_seals: {
        seal_state: {
          title: 'Seal State',
          in_transit: 'In Transit',
          pending: 'Pending',
          completed: 'Completed',
          deleted: 'Deleted',
          investigation: 'Investigation'
        },
        search_type: {
          title: 'Search Type',
          seals: 'Seals',
          bins: 'Bins'
        },
        scanning: {
          scan_seals: 'Scan Seals',
          master_seal: 'Master Seal',
          master_seal_placeholder: 'Scan Master Seal',
          seal_not_exist: 'Invalid Seal Number',
          bulky_seal_empty:
            'This seal cannot be scanned because it does not contain any packages.'
        },
        table_columns: {
          seal_code: 'Seal Code',
          no_of_packages: 'No. of Packages',
          transfer_to: 'Transfer To',
          state: 'State',
          master_seal: 'Master Seal',
          last_update: 'Last Update'
        },
        scanning_table: {
          seals_count: 'Seals ({count})',
          scanned_seals: 'Scanned Seals ({count})'
        },
        tracking_number: 'Tracking Number',
        search_placeholder: 'Search for seals numbers or tracking numbers',
        tracking_search_placeholder: 'Enter a tracking number',
        table_title: 'Total ({count})',
        confirm_transfer: 'Confirm Transfer',
        confirm_transfer_error:
          'There are still remaining transferred seals that’s not dispatched',
        seals_count: '{count} Seals',
        packages_count: '{count} Packages',
        bins_count: '{count} Bins'
      },
      generate_bulky_seals_modal: {
        title: 'Generate Bulky Seals',
        seals_number: 'Bulky Seals Number',
        generate_and_print: 'Generate & Print'
      }
    },
    receive: {
      tabs: {
        pickups: 'Pickups',
        sorting: 'Sorting',
        in_transit: 'In Transit'
      },
      receive_bins: {
        deliveries_count: 'You have {count} packages to recieving from Sorting',
        end_receiving: 'End Receiving',
        packages_received: 'Packages received successfully',
        invalid_tracking_number:
          'This tracking number is not located in this bin.',
        end_receiving_error:
          'You have to scan all the deliveries to end receiving'
      }
    },
    seals_logs: {
      table_title: 'Seals  Logs',
      print_seal: 'Print Seal Barcode',
      headers: {
        seal_code: 'Seal Code',
        no_of_packages: 'No. Of Packages',
        seal_state: 'Seal State',
        closed_at: `Closed At`
      },
      log_timeline: 'Logs Timeline',
      delete_seal_modal: {
        title: 'Delete Seal?',
        content: 'Are you sure you want to delete this seal?',
        success_message: 'Seal deleted successfully!'
      }
    },
    receiving_manifest: {
      no_star: 'No Star',
      general_receiving: 'General Receiving',
      table_title: '{count, plural, one {# manifest} other {# manifests}}',
      columns: {
        manifest_name: 'Manifest Name',
        star_name: 'Star Name',
        deliveries: 'Deliveries'
      }
    },
    wrong_scan: {
      subtitle: 'List of deliveries that needs action from your hub.',
      table_columns: {
        tracking_number: 'Tracking No.',
        business: 'Business',
        source_hub: 'Source Hub',
        destination_hub: 'Destination Hub',
        last_user_hub: 'Last User Hub',
        last_action_user: 'Last Action User',
        last_action: 'Last Action',
        scanning_entry_point: 'Scanning Entry Point'
      },
      quick_filters: {
        wrong_scan_logs: 'Wrong Scan Logs',
        wrong_scan_not_receiveed: 'Wrong Scan - Not Received',
        removed_scans: 'Removed Logs'
      },
      filter_labels: {
        scanned_at: 'Scanned At',
        sourceHubId: 'Source Hub',
        destinationHubId: 'Destination Hub',
        scannedInHubId: 'Wrong Action Hub'
      },
      scan_types: {
        receive_new_pickup: 'Receiving “New pickups”',
        receive_in_transit: 'Receiving “In transit”',
        transfer: 'Transfer',
        routes: 'Routes',
        vendor: '3PL Vendor',
        dispatch_rto: 'Dispatch RTO',
        debrief: 'Debrief',
        first_mile: 'First Mile'
      }
    }
  },
  sidebar: {
    ops_control: 'Ops Control Room',
    businesses: 'Businesses',
    stars: 'Stars',
    deliveries: 'Deliveries',
    routes: 'Routes',
    pickup_request: 'Pickup Request',
    hub: 'Hub',
    hubs: 'Hubs',
    hub_operations: 'Hub Operations',
    hub_management: 'Hub Management',
    reimburs_reports: 'Reimburs Reports',
    outbound: 'Outbound',
    pricing: 'Pricing',
    inventory: 'Inventory',
    settings: 'Settings',
    live_ops: 'Live Ops',
    logout: 'Logout',
    international_shipping: 'Bosta Box',
    international_orders: 'International Shipping',
    international_deliveries: 'International Deliveries',
    borrow: 'Borrow',
    geofences: 'Geofences',
    stars_performance: 'Star Performance',
    vendor: 'Vendor',
    quality: 'Quality',
    star_cycles: 'Star Cycles',
    bonuses_and_deductions: 'Bonus & Deduction',
    fleet: 'Fleet',
    wallet: 'Wallet',
    international_wallet: 'International Wallet',
    total_balance: 'Total Balance',
    cash_cycles: 'Cash Cycles',
    wallet_adjustment: 'Wallet Adjustments',
    action_center: 'Action Center',
    star_salary: 'Star Salary',
    stars_map: 'Star Map',
    audit_actions: 'Audit Actions',
    cashier: 'Cashier',
    ofd_edits: 'OFD Edits',
    hub_performance: 'Hub Performance',
    hubs_performance: 'Hubs Performance',
    hub_analytics: 'Hub Analytics',
    monitor_hub_operations: 'Monitor hub operations',
    three_pl_integrations: '3PL Integrations',
    zoning: 'Zoning',
    wallet_compensation: 'Compensations',
    user_management: 'User Management',
    create_role: 'New Admin Group',
    edit_role: 'Update Access',
    permissions: 'Permissions',
    capital: 'Bosta Capital',
    capital_settings: 'Capital Settings',
    support: 'Support',
    bosta_coding: 'Bosta Coding',
    fulfillment_pricing: 'Fulfillment Pricing',
    international_pricing: 'International Pricing',
    international: {
      table_columns: {
        weight: 'Weight (KG)',
        price: 'Price'
      },
      transit: {
        from: 'From',
        to: 'To'
      },
      pricing_plan: 'Pricing Plans',
      exchange_rate: 'Exchange Rate',
      edit_exchange_rate: 'Edit Exchange Rate'
    },
    create_pickup: 'Create Pickup',
    create_international_pickup: 'Create International Pickup',
    fulfillment: {
      main: 'Fulfillment',
      inbound: 'Inbound',
      outbound: 'Outbound',
      products: 'Products',
      businesses: 'Businesses',
      returns: 'Returns',
      locations: 'Locations',
      inventory: 'Inventory',
      problematic: 'Problematic',
      return_to_vendor: 'Requests'
    },
    packaging: {
      main: 'Packaging',
      balance: 'Packaging Balance',
      manage_materials: 'Manage Materials',
      packaging_orders: 'Packaging Orders'
    },
    subscription: 'Subscriptions',
    sub_plans: 'Subscription Plans',
    sub_users: 'Subscription Users',
    escalation: 'Escalations',
    tickets_aging: 'Tickets Aging',
    transfers_aging: 'Transfer Aging'
  },
  international_shipping: {
    tabs: {
      orders: 'Orders',
      items: 'Items',
      insights: 'Insights'
    },
    title: 'Box',
    item_count: 'Items: {count}',
    summary: {
      table_columns: {
        status: 'Status',
        count: 'Count',
        international_discount: 'International discount',
        international_fees: 'International Fees',
        promotion_discount: 'Promotion discount'
      }
    },
    orders: {
      table_columns: {
        order_id: 'Order ID',
        created_at: 'Created At',
        updated_at: 'Updated At',
        no_of_items: 'No. Items',
        amount: 'Amount',
        visa: 'Paind',
        action: 'Action',
        order_provider_id: 'Paymob ID',
        shipping_provider_order_id: 'Exohub Order ID',
        provider: 'Provider',
        total: 'Total',
        status: 'Status'
      }
    },
    items: {
      table_columns: {
        product_image: 'Product Image',
        item_id: 'Exohub ID',
        order_id: 'Paymob ID',
        provider_order_id: 'ExoHub Order ID',
        package_id: 'Package ID',
        category: 'Category',
        weight: 'Weight',
        price: 'Price',
        status: 'Status',
        tracking_number: 'Tracking Number',
        tracking_url: 'Tracking Url',
        business: 'Business'
      }
    },
    international_shipping_service: 'International Shipping',
    activate: 'Activate',
    deactivate: 'Deactivate',
    active: 'Active',
    inactive: 'Inactive',
    international_shipping_activation:
      'Are you sure you want to {value} international shipping?',
    modal_activate_title: 'Activate International Shipping',
    modal_deactivate_title: 'Deactivate International Shipping',
    modal_activate: 'activate',
    modal_deactivate: 'deactivate',
    enable: 'Enable',
    disable: 'Disable'
  },
  borrow: {
    title: 'Borrow',
    table: {
      business: 'Business',
      registeration_date: 'Registeration date',
      funding_amount: 'Funding Amount',
      funding_reasons: 'Funding Reasons',
      commercial_card: 'Commercial card',
      total: 'Total profiles:'
    },
    form: {
      business: { label: 'Business', placeholder: 'Select business' },
      funding_reasons: {
        label: 'Funding Reasons',
        placeholder: 'Select reasons'
      },
      registeration_date: {
        label: 'Registered At'
      },
      commercial_card: {
        label: 'Has commercial card',
        placeholder: 'Select'
      },
      funding_amount: {
        label: 'Funding Amount Range'
      }
    },
    button: {
      search: 'Search',
      reset: 'Reset',
      export: 'Export'
    },
    funding_reasons: {
      inventory: 'Inventory',
      marketing: 'Marketing',
      salaries: 'Pay salaries',
      debt: 'Pay debt'
    }
  },
  star_cycles: {
    title: 'Star Cycles',
    symmary_title: 'Cycles Summary',
    summary_subtitle:
      'Total Salary is subject to change due to last minute guarantee calculation, bonus and deduction approval.',
    accumulated_total_salary: 'Total Salary',
    accumulated_total_salary_cc: 'Total Salary with CC',
    accumulated_total_deliveries: 'Total Deliveries',
    accumulated_total_pickup_stops: 'Total Pickup Stops',
    accumulated_total_rto_stops: 'Total RTO Stops',
    start_date: 'Start Date',
    end_date: 'End Date',
    state: 'State',
    tables: {
      cycles_title: '{count} Cycles',
      cycle_details_title: '{count} Stars',
      columns: {
        id: 'ID',
        cycle_id: 'Cycle ID',
        deliveries: '#Deliveries',
        pickup_stops: '#Pickup Stops',
        rto_stops: '#RTO Stops',
        total_bonuses: 'Total Bonuses',
        total_deductions: 'Total Deductions',
        total_gaurantee: 'Total Gaurantee',
        star_name: 'Star Name',
        contractor: 'Contractor',
        hub: 'Hub',
        zone: 'Zone',
        vehicle_type: 'Vehicle type',
        working_days: '#Working',
        fixed_per_day: 'Fixed Per Day',
        package_variable: 'Package Variable',
        guarantee_per_day: 'Guarantee Per Day',
        total_fixed: 'Total Fixed',
        total_package_variable: 'Total Package Variable',
        total_pickups_variable: 'Total Pickups Variable',
        total_variable: 'Total Variable',
        total_guarantee: 'Total Guarantee',
        fake_exceptions_deductions: 'Fake Exceptions Deduction',
        ops_deductions: 'Ops Deductions',
        fleet_deductions: 'Fleet Deductions',
        total_deductions: 'Total Deductions',
        ops_bonus: 'Ops Bonus',
        fleet_bonus: 'Fleet Bonus',
        total_bonus: 'Total Bonus',
        phone_lines: 'Phone Lines',
        other_allowances: 'Other Allowances',
        total_with_contractor_com: 'Total with Contractor Com',
        total_with_vat: 'Total with VAT (14%)',
        config_change: 'Config. Change'
      }
    },
    cycle_details: {
      title: 'Cycle Details',
      form_labels: {
        contractor: 'Contractor Name',
        star: 'Star Name'
      },
      actions: {
        clear: 'Clear',
        submit: 'Submit'
      }
    }
  },
  bonuses_deductions: {
    view_title: 'Bonuses & Deductions',
    columns: {
      title: '{count} Bonuses and Deductions',
      star_name: 'Star Name',
      hub: 'Hub',
      amount: 'Amount',
      type: 'Type',
      reason: 'Reason',
      tracking_number: 'Tracking Number',
      pickup_request_id: 'Pickup Request ID',
      added_by: 'Added By',
      added_at: 'Added At',
      approved_by: 'Approved By',
      approved_at: 'Approved At',
      rejected_by: 'Rejected By',
      rejected_at: 'Rejected At',
      state: 'State',
      notes: 'Notes',
      actions: {
        view_more: 'View More Info',
        edit: 'Edit',
        remove: 'Remove'
      }
    },
    create_edit_modal: {
      create_title: 'Add Bonus or Deduction',
      edit_title: 'Edit Bonus or Deduction',
      placeholders: {
        add_notes: 'Add Notes',
        select: 'Select',
        add_amount: 'Add Amount',
        add_tracking_number: 'Add TN',
        add_pickup_request_id: 'Add Pickup Request ID'
      }
    },
    remove: {
      title: 'Remove {type}',
      error_message:
        'You can’t remove this {type}, you can only remove bonuses or deductions that you added.',
      confirmation_message: 'Are you sure you want to remove this {type}?',
      ok: 'Ok'
    },
    details_modal: {
      title: '{type} Details',
      labels: {
        status: 'Status',
        images: 'Images',
        rejection_reason: 'Rejection Reason'
      },
      approve_confirmation_title: 'Approve {type}',
      approve_confirmation_message:
        'Are you sure you want to approve this {type} ?'
    },
    rejection_reasons_modal: {
      title: 'Enter Rejection Reason',
      error_message: 'rejection reason is required.'
    },
    actions: { search: 'Search', reject: 'Reject', approve: 'Approve' }
  },
  live_ops: {
    heading: 'Live Ops',
    title: 'Exceptions',
    sub_title: 'Validate star exceptions, calls and SMSes.',
    empty_view_table_title: 'No data to view',
    empty_view_table_sub_title: 'No exception orders to view here.',
    summary_card: {
      total_exceptions: 'Total Exceptions',
      pending_exceptions: 'Pending Exceptions',
      cannot_reach_consignee: 'Can’t Reach Consignee',
      fake_exceptions: 'Fake Exceptions',
      valid_exceptions: 'Validated Exceptions'
    },
    delivery_details: {
      reach: 'No. of reach outs',
      validated_by: 'Validated By',
      exception_validation: 'Invalid exception reason',
      agent: 'Agent Notes',
      feedback: 'Customer Feedback',
      validate: 'Validations',
      call: 'Call',
      sms: 'SMS',
      whatsapp: 'WhatsApp',
      exception: 'Exception'
    },
    call_modal: {
      title: 'Enter Consignee Feedback',
      checkbox_reach: 'Can’t reach consignee',
      checkbox_feedback: ' Consignee feedback',
      placeholder: 'Write customer feedback about this order...',
      error: 'field'
    },
    validate_modal_call_sms: {
      title: 'Select a Field to Validate',
      validate_exception_title: 'Validate Exception',
      sub_title: 'Please confirm if this exception are valid or fake.',
      sms: 'Validate SMS',
      call: 'Validate Call',
      exception: 'Validate Exception',
      validate_SMS_title: 'Validate SMS',
      validate_SMS_sub_title: 'Please confirm if this SMS are found or not.',
      validate_call_title: 'Validate Call',
      validate_call_sub_title: 'Please confirm if this Call are found or not.',
      invalid: 'Invalid',
      valid: 'Valid',
      fake: 'Fake',
      valid_exception: 'Valid Exception',
      placeholder: 'Add note about this exception',
      select_placeholder: 'Select Invalid Exception Reason',
      select_exception_error: 'Invalid exception reason is required.'
    },
    filter_modal: {
      title: 'Add Filter',
      whatsapp: 'WhatsApp'
    },
    form: {
      hub: 'hub',
      star: 'Star',
      placeholder: 'Select',
      button: 'Search',
      search_input_placeholder:
        'Search by: tracking number, customers name or phone',
      fake_attempts: 'Fake Exceptions',
      business: 'Business',
      exception_at: 'Exception At'
    },
    table: {
      title: '{count} Exceptions',
      columns: {
        tracking: 'Tracking No.',
        buisness: 'Business',
        exception: 'Exception',
        customer: 'Consignee',
        star: 'Star Name',
        hub: 'Hub',
        whatsapp: 'WhatsApp',
        sms: 'SMS',
        call: 'Calls',
        reach_outs: 'Reach Outs',
        actions: 'Actions'
      },
      actions: {
        details: 'View Order Details',
        validate: 'Validate',
        call: 'Call'
      }
    },
    exceptionReasonsStrings: {
      exception_reason1: "Star didn't call the consignee",
      exception_reason2: 'Star who cancelled the parcel',
      exception_reason3: 'Star who postponed the parcel',
      exception_reason4: "Star didn't send SMS",
      exception_reason5:
        "Star promised the consignee to deliver the parcel but didn't",
      exception_reason6: "Consignee didn't change the address",
      exception_reason7: "Consignee's phone number is correct",
      exception_reason8: 'Consignee in the address',
      exception_reason9: "It's allowed to open the parcel",
      exception_reason10: "Consignee didn't exchange the parcel"
    }
  },
  quality: {
    title: 'Quality',
    investigation: {
      title: 'Investigation'
    },
    undebriefied_routes: {
      title: 'Un-debriefed Routes'
    },
    rejected_returns_tab: {
      title: 'Rejected Returns'
    }
  },
  wallet: {
    view_breakdown: 'View Breakdown',
    view_receipt: 'View Receipt',
    delayed: 'Delayed',
    delayed_tooltip:
      "Sorry, your cash-out is delayed. It's expected within the next few days.",
    download_file: 'Download File',
    download_sheet: 'Download Sheet',
    export_success: 'Your file exported successfully',
    export_error: 'Error while exporting the file please try again',
    un_paid_tooltip: 'Your order cash-out is expected to be on {date}.',
    paid_tooltip: 'Your cash-out was paid successfully on {date}.',
    paid: 'Paid',
    un_paid: 'Unpaid',
    cycles: {
      title: 'Cash Cycles',
      sub_title:
        'Track the cash collected and fees for the confirmed completed orders.',
      table_title: '{orders} Orders',
      empty_title: 'No results found.',
      empty_title_today: 'No orders are deposited at this date yet.',
      empty_sub_title: 'Try to select a different date.',
      order_id_filter_placeholder: 'Search by Order ID',
      cashout_id_filter_placeholder: 'Search by  Cash-out ID',
      table_header_tooltip:
        'You might have more completed orders, but they will show in the wallet after being confirmed.',
      net_value_footer: 'Cash Collection - Bosta Fees',
      net_value_footer_international: 'Cash Collection - Total Fees',
      cash_collection_popover: {
        cash_collection: 'Collected Amount',
        cash_collection_tooltip:
          "This is the total sum of your orders' COD.\nFor more details, click on 'View breakdown'",
        cash_collected: 'Cash Collected',
        cash_refunded: 'Cash Refunded'
      },
      customs: {
        title: 'Customs Fees',
        tool_tip_text:
          "This is the total sum of your orders customs  clearance fees, For more details, click on 'View breakdown'",
        ddu: 'Collected from customer (DDU)',
        ddu_tooltip:
          'Delivered Duty unpaid: Customer will pay for duties, import clearance, and any taxes. (we’ll add & collect it with the entered cash collection amount once confirmed).',
        ddp: 'Collected from You (DDP)',
        ddp_tooltip:
          'Delivered Duty Paid (DDP): You’ll pay charged for duties, import clearance, and any taxes',
        from_customer: 'From Customer (DDU)'
      },
      bosta_fees_popover: {
        bosta_fees: 'Bosta Fees',
        total_fees: 'Total Fees',
        customs_fees: 'Customs Fees',
        bosta_fees_tooltip:
          "This is the total sum of your orders' fees.\nFor more details, click on 'View breakdown'",
        shipping_fees: 'Shipping Fees',
        shipping_fees_tooltip:
          'Your basic orders’ fees are based on their type and package size.',
        insurance_fees: 'Insurance Fees',
        insurance_fees_tooltip:
          'Since you are applying for insurance, {fee}% fees will be added to the order price.',
        collection_fees: 'Collection Fees',
        collection_fees_tooltip:
          'If COD is higher than {amount}, {fee}% fees from the extra amount above the {amount} will be added to the order price.',
        discount: '0 COD Discounts',
        discount_tooltip:
          'If COD is 0, a {amount} EGP discount will be applied to the order price.',
        extra_fees: 'Extra Fees',
        extra_fees_tooltip:
          'Since you are applying for the expedite service, {fee}% fees will be added to the order price.',
        vat: 'VAT {vatValue}%',
        vat_tooltip: 'All prices are subjected to {vatValue}% VAT.',
        net_value: 'Net Value',
        cod_fees: 'COD fees',
        cod_fees_tooltip_egp:
          'If the shipment has COD, a {amount} EGP fee will be added to the order price.',
        cod_fees_tooltip_sar:
          'If the shipment has COD, a {amount} SAR fee will be added to the order price.',
        next_day_transfer_fees: 'Next Day Transfer Fees',
        pos_fees: 'CCOD fees',
        pos_fees_tooltip:
          'Since you used CCOD service, {fee}% fees is added to the order price.',
        promotion: 'Promotion Discount',
        promotion_tooltip_percentage:
          '{value}% discount on your shipping fees.',
        promotion_tooltip_egp: '{value} EGP discount on your shipping fees.',
        fulfillment_fees: 'Fulfillment fees',
        open_package_fees: 'Open package Fees',
        open_package_fees_tooltip:
          'Open package Fees added when you allow the customer to Open the package'
      },
      table_columns: {
        order_id: 'Order ID',
        order_type: 'Order Type',
        status: 'Delivery Status',
        pickup_city: 'Pickup City',
        dropoff_city: 'Dropoff City',
        cash_collection: 'Collected Cash',
        bosta_fees: 'Bosta Fees',
        total_fees: 'Total Fees',
        deposited_amount: 'Deposited Amount',
        deposited_amount_tooltip:
          'This is the amount that gets deposited to your wallet daily for the orders delivered.',
        deposited_date: 'Deposited Date',
        deposited_date_tooltip:
          'This is the date of depositing the collected cash and Bosta fees to your wallet.',
        cashout_date: 'Cash-Out Date',
        cashout_date_tooltip:
          'This is the date of transferring the amount to your bank account.',
        payment_status: 'Payment Status',
        transit: 'Transit',
        customs_fees: 'Customs Fees'
      },
      order_states: {
        delivered: 'Delivered',
        collected: 'Collected',
        returned_to_business: 'Returned to origin'
      },
      flyer_size: {
        normal: 'Normal',
        large: 'Large',
        x_large: 'X-Large',
        xx_large: 'XXL (White Bag)',
        bulky: 'Bulky',
        light_bulky: 'Light Bulky',
        heavy_bulky: 'Heavy Bulky'
      }
    },
    balance: {
      title: 'My Wallet',
      sub_title: 'Track the cash amounts coming in and out of your wallet.',
      table_title: '{transactionsCount} Transactions',
      empty_title: 'No orders yet',
      empty_state: {
        header:
          'With the all-new Wallet, access your daily collected cash, fees, total balance, and more.',
        watch_video: 'Watch Video',
        learn_more: 'Learn More',
        financial_visibility_title: 'Have full financial visibility',
        financial_visibility_sub_title:
          'Get real-time and detailed updates for every transaction that adds to or deducts from your balance.',
        track_cash_title: 'Track your daily cash',
        track_cash_sub_title:
          'Instantly see how much you made from today’s orders and their shipping fees.',
        cashout_date_title: 'Know your cash-out date',
        cashout_date_sub_title:
          "Expect when to receive your next cash-out transfer and the frequency in which you'll be receiving it."
      },
      header: {
        total_balance_card: {
          title: 'Total Balance',
          tooltip:
            'This is the total balance of your wallet.\nFor more details, click on "View breakdown"'
        },
        total_balance_popover: {
          credit_title: 'Credit',
          credit_sub_title: 'The cash amounts that are added to your wallet.',
          debit_title: 'Debit',
          debit_sub_title:
            'The cash amounts that are deducted from your wallet.',
          collected_cash: 'Collected Cash',
          cash_collected: 'Cash Collected',
          cash_refunded: 'Cash Refunded',
          online_payments: 'Online Payments',
          collected_amount: 'Collected Amount'
        },
        cashout: {
          cashout_date: 'Next Cash-Out Date',
          cashout_date_add_bank: 'Add Bank Details',
          cashout_date_with_bank_tooltip:
            'Your deposited cash cycles will be transferred to your bank account at this date.',
          cashout_date_no_bank_tooltip:
            'Add your bank details to set your cash-out transfer date.',
          cashout_date_no_bank_frequency_tooltip:
            'Add your bank details to set your cash-out frequency.',
          cashout_frequency: 'Cash-Out Frequency',
          cashout_frequency_weekly: 'Weekly',
          cashout_frequency_weekly_sunday_tooltip:
            'Your deposited cash cycles will be transferred every 7 days on Sunday.',
          cashout_frequency_weekly_monday_tooltip:
            'Your deposited cash cycles will be transferred every 7 days on Monday.',
          cashout_frequency_weekly_wednesday_tooltip:
            'Your deposited cash cycles will be transferred every 7 days on Wednesday.',
          cashout_frequency_daily: 'Daily',
          cashout_frequency_daily_tooltip:
            'The details of transferring the deposited amount to your bank account.',
          cashout_frequency_two_days_week: '2 Days/Week',
          cashout_frequency_two_days_week_tooltip:
            'Your deposited cash cycles will be transferred every Monday and Wednesday.',
          cashout_frequency_three_days_week: '3 Days/Week',
          cashout_frequency_three_days_week_tooltip:
            'Your deposited cash cycles will be transferred every Sunday, Tuesday, and Thursday.',
          today: 'Today',
          cashout_today:
            "Today is your cash-out date! It's expected to be transferred before the end of day.",
          delayed: 'Delayed',
          cashout_delayed:
            "Sorry, Your cash-out is delayed. It's expected within the next few days.",
          partial_cashout:
            'Your cash-out was transferred partially. The remaining amount will be transferred within a few days.',
          partial: 'Partial Cash-Out',
          cashout_frequency_next_day: 'Next Day',
          all_paid: 'All paid'
        }
      },
      table: {
        empty_title: 'No transactions are added yet to your wallet.',
        empty_sub_title:
          'Once we process your first delivered order, the transactions will start to show here.',
        empty_filter_title: 'No transactions are found on this date.',
        empty_filter_sub_title: 'Try to select a different date.',
        table_columns: {
          transaction_id: 'Transaction ID',
          date: 'Date',
          category: 'Category',
          amount: 'Amount',
          balance: 'Balance',
          actions: 'Actions'
        },
        balance_categories: {
          collected_cash_title: 'Collected Cash Cycle',
          collected_cash_sub_title:
            'For {numberOfOrders} orders deposited at {date}',
          bosta_fees_title: 'Bosta Fees',
          total_fees_title: 'Total Fees',
          total_fees_subtitle:
            'Total Fees is including Bosta fees + Customs Fees in case of DDP',
          bosta_fees_sub_title:
            'For {numberOfOrders} orders deposited at {date}',
          bosta_fees_post_paid_sub_title:
            'For {numberOfOrders} orders deposited from {firstDateInMonth} to {lastDateInMonth} in {date}',
          cashout_title: 'Cash-out',
          compensation_title: 'Compensation',
          compensation_sub_title: 'For {numberOfOrders} disputed orders',
          packing_material_title: 'Packing Material Fees',
          topup_title: 'Recharge balance',
          bank_transfer: 'Bank Transfer',
          promotion_title: 'Free Credit',
          promotion_sub_title: 'For promotions granted to your account.',
          fawry_cashout_title: 'Fawry Cash-out Fees',
          fawry_cashout_sub_title:
            '1.5% from your cash-out is deducted, added to it 14% VAT fees.',
          fawry_cashout_sub_balance_table:
            '1.5% from your cash-out is deducted.',
          fawry_transfer: 'Fawry',
          in_accurate_bank_details:
            'Bank rejected transfer due to inaccurate bank details. Please {updateText}.',
          update_it: 'update it',
          rejected_cashout_title: 'Rejected Cash-Out',
          balance_adjustment: 'Balance Adjustment',
          balance_adjustment_positive:
            'Adding this amount to fix your wallet balance.',
          balance_adjustment_negative:
            'Deducting this amount to fix your wallet balance.',
          borrow: 'Borrow Repayment',
          borrow_sub_title: 'Your loan remit amount.',
          credit_card: 'Card',
          compensation_variance_negative:
            'Compensation Variance: Deducted amount to fix your wallet balance.',
          fulfillment_settlement_positive:
            'Fulfillment Settlement: Added amount to fix your wallet balance.',
          overdue_settlement_negative:
            'Overdue Settlement: Deducted amount to fix your wallet balance.',
          cod_settlement_negative:
            'COD Settlement: Deducted amount to fix your wallet balance.',
          cod_settlement_positive:
            'COD Settlement: Added amount to fix your wallet balance.',
          overdue_settlement_positive:
            'Overdue Settlement: Added amount to fix your wallet balance.',
          fulfillment_settlement_negative:
            'Fulfillment Settlement: Deducted amount to fix your wallet balance.',
          compensation_variance_positive:
            'Compensation Variance: Added amount to fix your wallet balance.',
          borrow_adjustment_positive:
            'Borrow Adjustment: Added amount to fix your wallet balance.',
          borrow_adjustment_negative:
            'Borrow Adjustment: Deducted amount to fix your wallet balance.',
          extra_fees_positive:
            'Extra Fees: Added amount to fix your wallet balance.',
          extra_fees_negative:
            'Extra Fees: Deducted amount to fix your wallet balance.',
          international_transfer_fees_positive:
            'International Transfer Fees: Added amount to fix your wallet balance.',
          international_transfer_fees_negative:
            'International Transfer Fees: Deducted amount to fix your wallet balance.',
          fulfillment_pallet_fees_positive:
            'Fulfillment Pallet Fees: Added amount to fix your wallet balance.',
          fulfillment_pallet_fees_negative:
            'Fulfillment Pallet Fees: Deducted amount to fix your wallet balance.',
          fulfillment_storage_fees_positive: 'Fulfillment Storage Fees',
          fulfillment_storage_fees_negative: 'Fulfillment Storage Fees',
          pickup_Fees_adjustment_positive: 'Pick-up Fees Adjustment',
          pickup_Fees_adjustment_negative: 'Pick-up Fees Adjustment',
          donation_positive:
            'Donation Fees: Added amount to fix your wallet balance.',
          donation_negative:
            'Donation Fees: Deducted amount to fix your wallet balance.',
          pickup_fees: 'Pickup Fees',
          pickup_fees_tooltip:
            'Pickup fees apply to pickups with fewer than 3 orders.',
          pickup_fees_subtitle:
            'For {numberOfOrders, plural, one {# order} other {# orders}} picked up',
          transfer_fees_positive: 'Transfer Fees',
          transfer_fees_negative: 'Transfer Fees'
        },
        receipt: {
          title: '{category} Receipt',
          receipt_id: 'Receipt ID',
          date: 'Receipt Date',
          bank_account: 'Bank Account',
          transfer_amount: 'Transfer Amount',
          opening_balance: 'Opening Balance',
          closing_balance: 'Closing Balance',
          topup_text:
            'This is to confirm that Bosta has received the above transfer amount and it has been added to your wallet.',
          collected_cash_sub_title: 'For {numberOfOrders} deposited orders',
          compensation_sub_title: 'For {numberOfOrders} disputed orders',
          packing_material_fees_sub_title: 'For packing material fees ordered',
          paid_to: 'Paid to',
          paid_from: 'Paid from',
          rejected_transfer:
            'Previous rejected cash-outs that got transferred again.',
          pickup_fees: 'Pickup Fees',
          pickup_fees_subtitle: 'For {numberOfOrders} picked up orders'
        },
        empty_title_filter: 'No transactions are found on this date.',
        empty_sub_title_filter: 'No transactions are found on this date.'
      },
      bank_details_permission:
        'You don\'t have access permission to the "Banking Details"'
    },
    otp_screen: {
      title: 'A one time password (OTP) is required to access your wallet.',
      sub_title:
        'To secure your sensitive financial data, this page is locked.',
      button_text: 'Unlock Wallet'
    },
    balance_adjustment: {
      export_cashouts: 'Export Cash-outs',
      export_balances: 'Export Balances',
      page_header: 'Upload Balance Adjustments',
      title: 'Balance Adjustment template!',
      sub_title:
        'Please make sure to download this sheet to upload your adjustments.',
      upload_hint: '{clickToUpload} or drag and drop your orders sheet',
      click_to_upload: 'Click to upload',
      support_text: 'Supports xlsx only.',
      success_screen: {
        title: 'Your file is uploaded sucessfully!',
        sub_title: ' All adjustments are added to the wallet.',
        file_name: 'File name {fileName}',
        total_records: 'Total Records: {totalRecords}',
        upload_more_orders: 'Upload More Orders'
      }
    },
    business_configuration: {
      header: 'Bulk Upload Business Configurations',
      success_screen: {
        title: 'Your file is uploaded sucessfully!',
        sub_title:
          ' All configurations are updated to the wallet and it will take around an hour to be updated on Oracle.',
        file_name: 'File name {fileName}',
        total_records: 'Total Businesses: {totalRecords}',
        export_button: 'Export All Businesses'
      },
      confirm_modal: {
        title: 'You will update {businessCount} business.',
        sub_title: 'Are you sure?'
      }
    },
    compensation: {
      page_header: 'Compensation Requests',
      search_placeholder: 'Search by Request ID or Order ID.',
      empty_search_title: 'No results found.',
      empty_search_sub_title:
        'Your search or filters did not match any orders.',
      empty_state_title: 'No requests.',
      empty_state_sub_title: 'There are no requests here yet.',
      table_header: '{count} Requests',
      rejection_text_area_placeholder: 'Please add the rejection reason',
      unpaid_wallet_tooltip:
        'The compensation was synced with Oracle at {date} at {time}.',
      paid_wallet_tooltip: 'The compensation has been paid at {date}.',
      pending_approval_wallet_tooltip:
        'The compensation will sync with Oracle after the approvals.',
      rejected_approval_wallet_tooltip:
        'The compensation is rejected and won’t be paid.',
      approved: 'Approved',
      undo: ' Undo action',
      rejected: 'Rejected',
      reject: 'Reject',
      approve: 'Approve',
      approve_reject_by: '{action} by {takenBy}',
      request_success: 'Requests with IDs ({ids}) {action} successfully',
      request_unauth:
        'User {user} is not authorized to approve compensation request with ids ({ids})',
      request_oracle_fail: 'Requests with IDs ({ids}) failed art Oracle',
      tabs: {
        new: 'New',
        completed: 'Completed',
        all: 'All Requests'
      },
      columns_title: {
        order_id: 'ORDER ID',
        business: 'BUSINESS',
        request_id: 'REQUEST ID',
        sla: 'SLA',
        ticket_url: 'TICKET URL',
        reason: 'REASON',
        type: 'TYPE',
        amount: 'AMOUNT',
        department: 'DEPARTMENT',
        approved_by: 'APPROVED BY',
        submitted_by: 'SUBMITTED BY',
        fleet_exp_approval: 'FLEET/EXPERIENCE APPROVAL',
        finance_approval: 'FINANCE APPROVAL',
        wallet_status: 'WALLET STATUS',
        liability: 'Liability'
      },
      amount_tooltip: {
        order_type: 'Order Type',
        status: 'Status',
        package_size: 'Package Size',
        deliverd_at: 'Deliverd At',
        confirmed_at: 'Confirmed At',
        cod: 'COD',
        basic_fees: 'Basic Fees',
        package_size_fees: 'Package Size Fees',
        insurance_fees: 'Insurance Fees',
        collection_fees: 'Collection  Fees',
        next_day_fees: 'Next Day Transfer Fees',
        zero_cod_discount: '0 COD Discount',
        promotion_discount: 'Promotion Discount',
        total_before_vat: 'Total before VAT',
        vat: 'VAT {amount}%'
      },
      filters: {
        submitted_date: 'Submitted Date',
        approval_date: 'Finance Approval Date',
        business_id: 'Business ID',
        business_id_placeholder: 'Search with business id',
        request_id_or_order_id: 'Request/Order ID',
        order_id: 'Order ID',
        order_id_placeholder: 'Search by order ID',
        request_id: 'Request ID',
        request_id_placeholder: 'Search by Request ID',
        multi_id_placeholder:
          'Multiple Order IDs shall be separated by a comma.',
        multi_request_placeholder:
          'Multiple Request IDs shall be separated by a comma.'
      }
    },
    cycles_new: {
      collected_amount_popover: {
        title: 'Collected Amount',
        cash_collected: 'Cash Collected',
        online_payments: 'Online Payments'
      }
    },
    business_id_error: 'Please select business first',
    no_date_error: 'Please select date to export',
    not_same_date_error: 'Please select a day not a date range'
  },
  stars_performance: {
    title: 'Stars Performance',
    star_performance_tab: {
      table_title: '({count}) Stars',
      table_empty_view_subtitle: 'No performance to view here.',
      attendance: 'Attendance',
      attendance_tooltip:
        'The percentage of working stars out of the hub stars total number.',
      delivery_sr: 'Delivery SR',
      delivery_sr_tooltip:
        'The percentage of successfully delivered and returned orders out of the total number of OFD orders.',
      pickup_sr: 'Pickup SR',
      pickup_sr_tooltip:
        'The percentage of successfully done pickup requests out of the total number of pickup requests dispatched to the star.',
      total_route_progress: 'Route Progress',
      total_route_progress_tooltip:
        'The percentage of orders and pickup requests that were handled by the star (either by completion or giving exceptions) out of the total number of OFD and pickup requests.',
      total_fake_exceptions: 'Fake Exceptions',
      total_fake_exceptions_tooltip:
        'The number of fake exceptions confirmed by the live ops team.',
      total_app_usage: 'App Usage',
      total_app_usage_tooltip:
        'The average rating of an hourly score that is given to the stars to indicate how frequently they take actions on the app (i.e. deliver orders or give exceptions) through the day.',
      total_shortage: 'Shortage',
      total_shortage_tooltip:
        'The percentage of stars with pending COD>300 out of the total number of stars in the selected hub.',
      total_rating: 'Rating',
      total_score: 'Total score',
      total_rating_tooltip:
        'The average rating given to the stars (by the consignees) of the selected hub.',
      ofd: 'OFD',
      total_perfomance: 'Total Performance',
      cell_subtitle: '{day} out of {allDays} days',
      star_performance_filter: {
        hub_filter_default_value: 'All Hubs',
        date_filter_default_value: 'Today',
        stars_filter_placeholder: 'All Stars'
      }
    },
    score_card_tab: {
      title: 'Score Card',
      column_headers: {
        hubs: 'Hubs',
        stars: 'Stars',
        total_score: 'Total Score',
        assigned_template: 'Assigned Template ID'
      }
    }
  },
  promotions: {
    promotion_types: {
      sales_promotion: 'Sales Promotion',
      direct_marketing_promotion: 'Direct Marketing Promotion',
      public_relation_promotion: 'Public Relation Promotion',
      advertising_promotion: 'Advertising Promotion'
    },
    promotion_subtypes: {
      percentage_sales: 'Percentage Sales',
      seasonal: 'Seasonal',
      referral: 'Referral',
      loyalty: 'Loyalty',
      capped_volume: 'Capped Volume',
      free_shipping: 'Free Shipping',
      duration: 'Duration'
    },
    target_types: {
      domestic: 'Domestic',
      international: 'International'
    },
    modal: {
      edit_title: 'Edit promotion',
      add_title: 'Add promotion',
      promotion_type: 'Promotion type',
      promotion_value_type: 'Promotion value type',
      fixed: 'Fixed',
      activation_date: 'Activation Date',
      expiry_date: 'Expiry Date',
      min_volume_cap: 'Volume cap min',
      max_volume_cap: 'Volume cap max',
      interval: 'Volume cap interval',
      duration: 'Duration',
      percentage: 'Percentage',
      target: 'Target',
      placeholder: {
        enter_date: 'Enter Date',
        percentage: 'Pecentage',
        volume_cap: 'Ex: 150 Shipments',
        interval: 'Interval in days',
        duration: 'Duration in days'
      },
      button: {
        update: 'Update',
        add: 'Add'
      }
    },
    bulk: {
      header: 'Bulk Upload Promotions',
      title: 'Bulk Promotions Template!',
      subtitle:
        'Please make sure to download this sheet to upload bulk promotions.',
      upload_more: 'Upload more promotions',
      confirmation_message:
        'You will update {count} businesses. Are you sure? ',
      success_subtitle: 'All promotions are updated to the businesses.',
      records_number: 'Total Updated Records: {count}',
      file_name: 'File name ',
      error_text:
        '{errorCount} errors are found. Please fix the below errors and re-upload the file.'
    }
  },
  dropdown_modal: {
    title: 'Select',
    placeholder: 'Select',
    confirm: 'Save',
    cancel: 'Cancel'
  },
  action_center: {
    title: 'Action Center',
    actions_needed: 'Actions Needed',
    manage_hub_actions: 'Manage Hub Actions',
    select_hub_placeholder: 'Please select a hub',
    order_details_updated: 'Order Details Updated',
    update_order_details: 'Update Order Details',
    address_updated: 'Address Updated',
    cancelled: 'Cancelled',
    cancelled_by_business: 'This order has been cancelled by business',
    cancelled_orders:
      'Order with TNs {trackingNumbers} has been cancelled by business, please remove it from the route',
    customer_request_to_cancel: 'Customer requested to cancel',
    return_issue_resolved: 'Return Issue resolved',
    transfer_to_hub: 'Transfer To Hub',
    delivery_insctructions: 'Delivery insctructions',
    change_cod: 'Change COD',
    view_logs: 'View Logs',
    deleted_order: 'Deleted Order',
    make_it_rto: 'Make it RTO',
    empty_orders: {
      no_orders_selected: 'No Orders Selected',
      select_order_to_view: 'Select any order to view'
    },
    order_details: {
      order_details_updated:
        'Order details updated - please print new AWB to be able to assign to route',
      order_cod_updated:
        'COD amount changed for this order, print AWB to be able to dispatch this order',
      assigned_hub_changed:
        'Assigned hub updated based on address changes - please print new AWB and then transfer to proper hub',
      address_updated:
        'Assigned hub updated based on address changes - please print new AWB to be able to transfer to another hub',
      order_cod_updated_transfer:
        'COD amount changed for this order, print AWB to be able to transfer this order',
      changes_log: 'Changes Log',
      reschedule_order_to: 'Customer request reschedule orders to',
      order_rescheduled: 'Order Rescheduled',
      order_rescheduled_to: 'This order has been rescheduled to',
      address_updated_to: 'Address was updated to',
      phone_updated_to: 'Phone number was updated to',
      second_phone_updated_to: 'Second phone number was updated to',
      open_package_updated_to: 'Allow open package updated to',
      cod_was_updated_to: 'COD was updated to {amount}',
      order_cancelled: 'Order has been cancelled.',
      action_notes: 'Action Notes',
      business_name: 'Business Name',
      type: 'Type',
      state: 'State',
      package_type: 'Package Type',
      number_of_items: 'No. of Items',
      opening_package: 'Opening Package',
      creation_date: 'Creation Date',
      fm_pickup_date: 'FM Pickup Date',
      customer_name: 'Customer Name',
      customer_address: 'Customer Address',
      customer_phone: 'Customer Phone',
      delivery_instructions: 'Delivery instructions added',
      action_notes: {
        title: 'Action Notes',
        action_note_rescheduled: 'Move this package to the correct Bin',
        print_awb: 'Print AWB',
        action_note_update_transfer_to_sorting: 'Transfer To Sorting',
        add_rto_sticker: 'Add “RTO” sticker',
        move_to_rto_bin: 'Move order to “RTO” bin',
        move_to_order_to_dispatch:
          'Move this order to dispatch shelf to be dispatched next working day'
      }
    },
    order_actions: {
      tracking_no: 'Tracking No.',
      bin: 'Bin',
      updates: 'Updates',
      date_and_time: 'Date & Time',
      scheduled_date: 'Scheduled Date',
      action: 'Action',
      move_to_bin: 'Move to Bin',
      select_bin: 'Select Bin',
      select_shelf: 'Select Shelf',
      search_for_tracking_number: 'Search for tracking number',
      confirm_printing_awb: 'Please confirm printing new AWB',
      move_to_bin_add_rto_stricker: 'Move to Bin & Add RTO Sticker'
    },
    requests: {
      title: 'Requests',
      star_at: 'Star at {hubName}',
      star_name: 'Star Name',
      whatsapp: 'WhatsApp',
      ticket_no: 'Ticket No.',
      actions: {
        reject: 'Reject',
        approve: 'Approve'
      },
      types: {
        change_cod: 'COD change request'
      },
      table_columns: {
        star: 'Star'
      },
      request_details: {
        cod_change:
          'Customer requested to change COD from {codBefore} to {codAfter}'
      },
      reject_request_modal: {
        title: 'Reject Request',
        subtitle: 'Please add the rejection reasons.',
        rejection_reason_label: 'Rejection Reason',
        rejection_reason_placeholder: 'Please select the rejection reason',
        notes_error: "Rejection Reason can't exceed 30 charachter",
        reasons: {
          already_deliveried: 'The order is already delivered',
          couldnt_reach_courier: 'We couldn’t reach the courier'
        }
      }
    }
  },
  run_sheet: {
    total_deliveries: 'Total No. Deliveries:',
    route_name: 'Route Name:',
    business_name: 'Business Name:',
    rto_orders_count: 'No. Return Orders:',
    orders_count: 'No. of Orders:'
  },
  star_map: {
    table: {
      star_action: 'Star Action',
      timestamp: 'Timestamp',
      view_on_map: 'Map',
      location: 'Location',
      tracking_no_pickup_id: 'TN/Pickup',
      star: 'Star name',
      consignee: 'Consignee',
      title: '{count} Star'
    },
    title: 'Manage action logs. for stars.',
    call_duration: 'Call Duration:',
    select_star: 'Select Star',
    search_tracking_number: 'Search by TN',
    search_pickup_id: 'Search by pickup id',
    search_by_title: 'Search by:',
    tracking_number: 'Tracking No.',
    pickup_id: 'Pickup ID',
    call_type_placeholder: 'All',
    call_type_title: 'CALL TYPE',
    action_type_title: 'ACTION TYPE',
    star_actions: {
      sms_title: 'SMS',
      call_title: 'Outgoing Call',
      missed_call_title: 'Missed Call',
      incoming_call_title: 'Incoming Call',
      deliver_title: 'Deliver',
      exception_title: 'Exception',
      collect_title: 'Collect',
      picked_up_from_consignee_title: 'Picked Up From Consignee',
      fm_pickup_title: 'First Mile Pickup',
      crp_pickup_title: 'CRP Pickup',
      returned_to_business_title: 'Returned To Business',
      pending_customer_signature_title: 'Pending Customer Signature',
      call_title_no_answer: 'Outgoing- No Answer',
      incoming_call_no_answer_title: 'Incoming - No Answer'
    }
  },
  audit_actions: {
    title: 'Audit Actions',
    manage_audit_action: 'Manage all audit actions for all hubs',
    search_for_hub: 'Search for hub',
    all_hubs: 'All hubs',
    table_columns: {
      hub_name: 'Hub Name',
      hub_leader: 'Hub Leader',
      one_day: '1 Day',
      two_days: '2 Days',
      three_days: '3 Days',
      four_days: '4 Days',
      five_or_more_days: '+5 Days',
      total: 'Total'
    },
    order_summary_tooltip: {
      rescheduled: 'Rescheduled',
      details_updated: 'Details Updated'
    }
  },
  create_edit_vendors: {
    label: {
      company_name: 'Company Name',
      vendor_email: 'Vendor Email',
      hub_leader: 'Hub Leader',
      hub_clerks: 'Hub Clerk(s)',
      serving_hubs: 'Serving Hubs',
      serving_cities: 'Serving Cities'
    },
    placeholders: {
      select_hub_leader: 'Select Hub Leader',
      select_hub_clerks: 'Select Hub Clerks',
      enter_bosta_email: 'Enter bosta email'
    }
  },
  create_edit_hubs: {
    fulfillment_hub: 'Fulfillment Hub',
    first_mile_hub_type: 'First Mile Hub',
    last_mile_hub_type: 'Last Mile Hub',
    sorting_hub_type: 'Sorting Hub',
    managerial_hub_type: 'Managerial Hub',
    edit_success: 'Hub edited successfully!',
    add_geofence: 'Click where you want to add hub geofences!',
    latitude: 'Latitude',
    longitude: 'Longitude',
    plot: 'Plot',
    package_sizes: 'Package Sizes (Manual Selection Only)',
    label: {
      hub_name: 'Hub Name',
      hub_name_ar: 'Hub Arabic Name',
      hub_leader: 'Hub Leader',
      hub_clerks: 'Hub Clerk(s)',
      hub_stars: 'Hub Star(s)',
      shortage_amount: 'Shortage Amount',
      hub_type: 'Type',
      communication_email: 'Communication Email',
      serving_districts: 'Serving Districts',
      serving_zones: 'Serving Zones'
    },
    placeholders: {
      enter_hub_name: 'enter hub name',
      enter_hub_name_ar: 'enter hub arabic name',
      select_hub_leader: 'Select Hub Leader',
      select_hub_clerks: 'Select Hub Clerks',
      select_hub_stars: 'Select Stars',
      enter_shortage_amount: 'Enter Shortage Amount',
      select_hub_type: 'Select Hub Type',
      enter_bosta_email: 'Enter bosta email'
    }
  },
  ofd_edits: {
    star_update: 'Star Update',
    seen: 'Seen',
    not_seen: 'Not Seen'
  },
  analytics: {
    header: {
      title: 'Analytics',
      subtitle:
        'The delivery performance of completed orders over a period of time'
    },
    tooltips_title: 'How is it calculated?',
    analytics_filter: {
      apply: 'Apply',
      clear: 'Clear',
      select_city: 'Select City',
      select_hub: 'Select Hub',
      cities: 'Cities',
      hubs: 'Hubs',
      city: 'City',
      hub: 'Hub',
      search_cities_placeholder: 'Search for city',
      search_hubs_placeholder: 'Search for hub',
      select_type: 'Select Type',
      select_the_pickup_date: 'Select the pickup date',
      order_type: 'Order Type'
    },
    deliveries_view: {
      total_orders: 'total orders',
      successful_orders: 'successful orders',
      unsuccessful_orders: 'unsuccessful orders',
      processing_orders: 'processing orders',
      total_orders_tooltip:
        'The total picked up orders in the selected period of time.',
      successful_orders_tooltip:
        'The picked up orders that are completed successfully, like delivered, cash collected, exchanged & returned, picked & returned, or signed & returned.',
      unsuccessful_orders_tooltip:
        'The picked up orders that are completed unsuccessfully, like returned to origin, archived, lost, damaged, or collection failed (for Signed & Return orders).',
      processing_orders_tooltip:
        'The picked up orders that are currently in progress and not completed yet.',
      not_active: {
        get_started: 'Get started with Bosta Analytics!',
        subtitle: {
          start:
            'Start creating orders with Bosta to be able to measure the performance analytics.',
          activation: 'It will start to be active',
          five_orders: 'after completing 5 orders.'
        },
        create_order: 'Create Order',
        learn_more: 'Learn More ↗'
      },
      no_orders: {
        title:
          'No picked up orders to show analytics for, you can select a different filter to show data.'
      }
    },
    delivery_performance: {
      title: 'Delivery Performance',
      delivery_success_rate: {
        title: 'Delivery Success Rate',
        subtitle: 'The percentage of successful orders.',
        tooltip_subtitle:
          'Showing the percentage of successful from the total completed orders (successful + unsuccessful).',
        include_processing_switch: 'Include processing orders',
        pie_chart_hint:
          ' This success rate will change again after completing your current processing orders.',
        completed_orders: 'completed orders',
        total: 'Total'
      },
      unsuccessful_reasons: {
        title: 'Unsuccessful Delivery Reasons',
        subtitle: 'The top reasons of unsuccessful deliveries.',
        tootlip:
          'Showing the count of the last attempt reasons for unsuccessful orders.',
        empty_table_title: 'No unsuccessful orders to show.',
        empty_table_subtitle:
          ' You can select a different filter to show the unsuccessful delivery reasons.',
        exceptions_reasons: {
          customer_is_not_in_address: 'The customer is not in the address.',
          customer_changed_the_address: 'The customer changed the address.',
          customer_postponed:
            'The customer requested to postpone for another day.',
          customer_wants_to_open_order: 'The customer wants to open the order.',
          shipper_cancel_order: 'The shipper requested to cancel the order.',
          customer_not_answer: 'The customer is not answering the phone.',
          customer_refused_to_receive:
            'The customer refused to receive the order.',
          customer_refused_to_give_the_order:
            'The customer refused to give the order to our Star.',
          customer_refused_to_pay: 'The customer refused to pay the cash.',
          address_is_not_clear: 'The customer address is not clear.',
          phone_is_wrong: 'The customer phone number is wrong.',
          order_lost: 'Something went wrong that caused your order to be lost.',
          order_damaged:
            'Something went wrong that caused your order to be damaged.',
          unknown_reason: 'Unknown reason',
          wrong_phone_or_address:
            'The customer phone number or address is wrong.'
        },
        table_headers: {
          count: 'count',
          reason: 'reason',
          percentage: 'percentage'
        },
        total_unsuccessful_orders: 'TOTAL UNSUCCESSFUL ORDERS'
      },
      average_delivery_time: {
        title: 'Average Delivery Time',
        subtitle: 'The average time to end a successful delivery cycle.',
        tooltip:
          'Showing the average lead time (from pickup date to completion date) for the successful orders.',
        days: 'Days',
        day: 'Day',
        hours: 'H',
        same_day: 'Same Day'
      },
      delivery_attempts_analysis: {
        title: 'Delivery Attempts Analysis',
        subtitle: 'The percentage of successful orders per each attempt.',
        tooltip: {
          title: 'How is it calculated?',
          message:
            'Showing the percentage of each attempt for the successful orders.'
        },
        attempt_order: {
          '1st': '1st',
          '2nd': '2nd',
          '3rd': '3rd',
          '4th': '4th+'
        },
        attempt_tooltip: {
          '1st_attempts': '1st attempts',
          '2nd_attempts': '2nd attempts',
          '3rd_attempts': '3rd attempts',
          '4th+_attempts': '4th+ attempts',
          percentage: 'Percentage',
          total: 'Total'
        },
        delivery_attempts_avg_industry:
          '{firstAttempt} for 1st attempt, {secondAttempt} for 2nd attempt, and {thirdAttempt} for 3rd attempt.'
      },
      avg_industry: {
        title: 'In the {industryName} industry,',
        subtitle: 'The average success rate is ',
        learn_more: 'Learn more'
      }
    },
    geographical_analysis: {
      title: 'Geographical Analysis',
      subtitle: {
        cities: 'The top performing cities and their share.',
        hubs: 'The top performing hubs and their share.'
      },
      city: 'City',
      hub: 'Hub',
      success_rate: 'SUCCESS RATE',
      share: 'SHARE',
      empty_table: {
        no_orders: 'No completed orders to show.',
        different_filter:
          'You can select a different filter to show the geographical analysis.'
      },
      tooltip: {
        calculated: 'How is it calculated?',
        message:
          '<TextDiv>Share: Showing the count of completed orders per each city or hub. </TextDiv> Performance: Showing the percentage of successful orders from the total completed orders.'
      },
      table: {
        total_orders: 'TOTAL COMPLETED ORDERS'
      },
      buttons: {
        cities: 'Cities',
        hubs: 'Hubs'
      }
    },
    financial_summary: {
      title: 'Financial Summary',
      cash_cycles: {
        title: 'Cash Cycles',
        subtitle: 'The total Cash collected and fees for completed orders.',
        tooltip:
          ' Showing the total collected cash, fees, and the net value for the completed orders in the selected period of time.',
        bosta_fees: 'Bosta fees',
        net_value: 'Net value',
        collected_cash: 'Collected Cash'
      },
      cash_outs: {
        title: 'Cash-outs',
        subtitle: 'The total revenue that has been cashed-out to you.',
        tooltip:
          'Showing the list of cash-out receipts that in the selected period of time.',
        table: {
          transaction_id: 'transaction ID',
          date: 'Date',
          amount: 'Amount'
        },
        empty_table: {
          title: 'No cash-outs took place in this date.',
          subtitle:
            'You can select a different date to show the cash-out receipts.'
        }
      }
    }
  },
  geofences: {
    route_geofences: {
      title: 'Dynamic Routing',
      tabs: {
        tomorrow_routes: {
          title: 'Tomorrow Routes',
          header: 'Route Creation',
          confirm_create_routes_btn: 'Confirm Routes Creation',
          default_num_of_stars: 'Use hub stars number',
          num_of_stars_placeholder: 'Enter number of stars',
          select_star: 'Select a star',
          star_label: 'Star',
          is_second_day_route: 'Is Second Day Route?',
          create_route: 'Create Route',
          merge_routes: 'Merge Routes',
          split_routes: 'Split Routes',
          merge_deliveries: 'Merge Deliveries',
          start_merging: 'Start Merging',
          start_splitting: 'Start Splitting',
          force_update: 'Force Update',
          routes_to_merge: 'Routes to merge:',
          confirm_merging: 'Confirm Merging',
          confirm_splitting: 'Confirm Splitting',
          routes_merge_success: 'Routes merged successfully',
          routes_split_success: 'Routes splitted successfully',
          route_already_selected: 'Route Already Selected',
          route_assigned_to_another_hub: 'Route is assigned to another hub',
          actions: 'Actions',
          select_action: 'Select Action',
          route_belong_to_different_hub:
            'Cannot merge this route because it is assigned to a different hub',
          routes_to_be_merged: 'The following routes will be merged.',
          routes_to_be_splitted: 'The following routes will be splitted.',
          deliveries_to_be_merged:
            'The following deliveries will be merged to the mentioned route',
          select_route: 'Select Route',
          select_different_route:
            'You cannot select the same route the delivery is assigned to.',
          delivery_merge:
            '- Tracking Number: {trackingNumber} will be merged to route: {routeId}',
          delivery_already_selected:
            'This delivery is already selected to merge to another route',
          cannot_select_route:
            'Cannot select this route because it is assigned to a differnet hub',
          cannot_select_delivery:
            'Cannot select this delivery because it is assigned to a differnet hub'
        },
        yesterday_routes: {
          title: 'Yesterday Routes',
          routes: 'Routes',
          select_routes: 'Select Routes',
          hub: 'Hub',
          select_hub: 'Select a hub',
          selected_tracking_number: 'Selected Tracking Number: {trackingNumber}'
        }
      },
      route_data: {
        title: 'Route data',
        tracking_numbers: 'Tracking Numbers ({counts})',
        route_id: 'Route ID: {routeId}',
        deliveries_without_geolocation: 'Deliveries without geolocation',
        summary: 'Summary'
      },
      success_msgs: {
        routes_created: 'Routes created successfully'
      },
      error_msgs: {
        routes_failed: 'Some routes were not created because: {reasons}',
        select_star: 'Please select a star'
      }
    }
  },
  cashier: {
    cashier_tab_title: 'Cashier',
    log_tab_title: 'Cashier Logs',
    title: 'Cashier System',
    sub_title: 'Debrief star financials',
    recepits: 'Receipts',
    rejected: 'Rejected',
    accepted: 'Accepted',
    pending: 'Pending',
    collected: 'Collected',
    intransit: 'Intransit',
    deposted: 'Deposited',
    investigation: 'Investigation',
    active_route: 'Active routes',
    table_title: '{count} Stars',
    search_placeholder: 'Search for receipt number',
    empty_search_title: 'No results found.',
    empty_search_sub_title: 'Your search or filters did not match any records.',
    print_receipt: 'Print Receipt',
    settled: 'Settled',
    finance_placeholder: 'Search by hub name or receipt id',
    signature: 'Signature',
    states: {
      title: 'Status',
      active_route: 'Active Route',
      pending: 'Pending',
      rejected: 'Rejected',
      collected: 'Collected',
      in_transit: 'In-transit',
      deposited: 'Deposited',
      investigation: 'Investigation',
      intransit: 'Intransit',
      settled: 'Settled'
    },
    rejection_modal: {
      title: 'Reject Receipt',
      sub_title: 'Reject a star’s receipt.',
      rejection_reason_label: 'Rejection Reason',
      rejection_reason_placeholder: 'write the rejection reason here...',
      collected_cod_amount_label: 'Collected COD Amount',
      collected_ccod_amount_label: 'Collected CCOD Amount',
      receipt_image_label: 'Receipt Image',
      collected_amount_placeholder: '0.00'
    },
    columns: {
      receipt_number: 'Receipt Number',
      star_name: 'Star Name',
      star_info: 'Star Info',
      date: 'Date',
      cod: 'COD',
      ccod: 'CCOD',
      amount: 'Total Amount',
      status: 'Status',
      view_pod: 'View POD',
      settle: 'Settle',
      hub: 'Hub',
      pos: 'POS',
      bank_transfer: 'BT',
      star_wallet: 'Star wallet',
      cod_collected: 'COD collected'
    },
    bulk_modal: {
      title: 'Confirm Amount',
      sub_title: 'Please upload amount receipt to confirm.',
      upload_title: '{click} or drag and drop the receipt photo',
      click_to_upload: 'Click to upload',
      title_accountant: 'Settle Amount',
      sub_title_accountant: 'Are you sure that you want to settle this amount?',
      title_signature: 'Signature',
      signature_upload_titke: '{click} or drag and drop the signature photo'
    },
    logs: {
      table_title: 'Logs'
    },
    receipt_modal: {
      title: 'Receipt Details',
      receipt_number: 'Receipt Number',
      proof_of_deposit: 'Proof of deposit',
      rejection_reason: 'Rejection Reason',
      star_name: 'Star Name',
      star_id: 'Star ID',
      pos_id: 'POS ID',
      amount_details: 'Amounts Details',
      total_amount: 'Total Amount',
      cod_amount: 'COD Amount',
      pos_amount: 'POS Amount',
      bt_amount: 'BT Amount',
      bt_proof: 'BT Proofs ({count} orders)',
      bt_proof_sub:
        'The proofs of bank transfer customer deposits, taken by the star.'
    },
    finance_table_columns: {
      safe_box: 'Safe Box',
      remaining: 'Remaining',
      deposited_claim: 'Deposited Claim',
      received_amount: 'Received Amount',
      pod: 'POD',
      deposited_date: 'Deposit date'
    },
    settle_amount_modal: {
      title: 'Settle Amount',
      subtitle: 'confirm the deposited amounts vs hub’s deposit claim.',
      deposit_details: 'Deposit Details',
      hubs_deposit: 'Hub’s Deposit',
      actual_deposit: 'Actual Deposit'
    }
  },
  bib: {
    this_month: 'This month',
    hub_performance: {
      hub_filter_title: 'Hub',
      hub_filter_placeholder: 'Select Hub',
      cards: {
        previous_hub_title: 'Pervious Hub',
        rank: 'Rank #{rank}',
        next_hub_title: 'Next Hub',
        assigned_order_title: 'for {assignedOrders} Assgined orders'
      },
      highlight: {
        title: 'Highlight Analytics',
        missed_deliveries: 'MISSED DELIVERIES',
        zero_attempts: '0 Attempts',
        sla: 'SLA',
        cost_per_shipment: 'COST PER SHIPMENT'
      },
      score_card: {
        title: 'Score Card',
        daily_sr: 'Daily SR',
        sla: 'SLA',
        fda: 'FDA',
        zero_attempts: '0 Attempts',
        route_dispatch_time: 'Route Dispatch Time',
        pending_in_transit: 'Pending in Transit',
        total_score: 'Total Score',
        attempt_sr: 'Attempt SR',
        daily_volume: 'Daily Volume',
        dsr: 'DSR',
        asr: 'ASR',
        business_sla: 'Business promise SLA',
        rank: 'Rank'
      },
      star_performance_table: {
        header: 'Total Hubs Analysis',
        title: '({count}) Total Stars',
        star_name: 'STAR NAME',
        attendance: 'ATTENDANCE',
        ofd: 'OFD',
        delivery_sr: 'DELIVERY SR',
        fake_exception: 'FAKE EXCEPTION',
        shortage: 'SHORTAGE',
        total: 'TOTAL',
        view_logs: 'View Logs',
        search_star_placeholder: 'Search for star by star id',
        out_of: '{value} out of {outOfDays} days',
        route_id: 'ROUTE ID'
      }
    },
    hubs_performance: {
      total_hubs: 'Total Hubs',
      top_performance: 'Top Performance',
      good: 'Good',
      low: 'Low',
      delivery_sr: 'DELIVERY SR',
      attempt_sr: 'ATTEMPT SR',
      fda_performance: 'FDA PERFORMANCE',
      sla: 'SLA',
      highlight_label: 'Highlight Analytics',
      table: {
        title: '{count} Hub',
        rank: 'Rank',
        hub_name: 'Hub Name',
        assigned_volume: 'assigned volume',
        total_score: 'total score',
        cost_per_shipment: 'cost per shipment',
        search_hub_placeholder: 'Search for hub by hub name',
        performance_standards: 'Performance Standards'
      }
    }
  },
  monitor_hub_operations: {
    title: 'Monitor hub operations',
    common: {
      unknown: 'Unknown',
      issues_title: 'issues',
      orders_count: '{count} Orders',
      fulfillment: 'Fulfillment',
      exchange_forward: 'Exchange-Forward',
      exchange_return: 'Exchange-Return',
      crp_forward: 'CRP-Forward',
      crp_return: 'CRP-Return',
      export_successful:
        "Export successful. You'll receive an email with the excel file.",
      table_columns: {
        tracking_no: 'Tracking No.',
        comments: 'Comments',
        shelf: 'Shelf',
        order_type: 'Order Type',
        age: 'Age (Days)',
        attempts: 'Attempts'
      },
      quick_filters: {
        all: 'All ({count})',
        delayed: 'Delayed ({count})',
        rescheduled: 'Rescheduled ({count})',
        due_today: 'Due today ({count})',
        rto: 'Return ({count})'
      },
      issues: {
        zero_attempts: 'Order with 0 attempts',
        exceeded_sla: 'Exceeded SLA',
        moving_between_hubs: 'Moving between hubs',
        reschedule_date_missed: 'Missed rescheduled date'
      }
    },
    dispatching: {
      tab_title: 'Dispatching',
      title: 'Manage orders to be dispatched',
      cards_title: {
        to_be: 'To be dispatched',
        action: 'Added to route',
        done: 'Dispatched',
        late: 'Late dispatch',
        missed: 'Missed dispatch'
      },
      overview: {
        title: 'All Hubs Overview',
        summary_title: 'To be {count} dispatched'
      }
    },
    transferring: {
      tab_title: 'Transferring',
      title: 'Monitor orders to be transferred',
      cards_title: {
        to_be: 'To be transferred',
        action: 'Ready for transfer',
        done: 'Transferred',
        late: 'Late transfer',
        missed: 'Missed transfer'
      },
      overview: {
        title: 'All Hubs Overview',
        summary_title: 'To be {count} transferred'
      }
    },
    ofd_search: {
      tab_title: 'OFD Search',
      asr: 'ASR',
      asr_tooltip:
        "It's the attempt success rate of all the attempts in the applied filter.",
      total_attempts: 'Total Attempts',
      total_attempts_tooltip:
        "It's the total number of attempts in the applied filter",
      empty_state_title: 'Please select filter first',
      validated_by_whatsapp:
        'This exception was validated by the consignee through WhatsApp',
      valid: 'Valid',
      invalid: 'Invalid',
      filters: {
        ofd_at_label: 'OFD At',
        tracking_number: 'Tracking Number',
        tracking_number_placeholder: 'Enter tracking numbers',
        hub_label: 'Hub',
        business_label: 'Business',
        star_label: 'Star',
        attempt_state_label: 'Attempt State',
        attempt_type: 'Attempt Type',
        exception_validation: 'Exception Validation'
      },
      table_columns: {
        tracking_number: 'Tracking No.',
        business: 'Business',
        attempt_type: 'Attempt Type',
        attempt_date: 'Attempt Date',
        route: 'Route ID',
        hub: 'Hub',
        attempt_state: 'Attempt State',
        exception: 'Exception',
        international_order: 'International',
        exception_validation: 'Exception Validation'
      },
      attempts_types: {
        delivery: 'Delivery',
        pickup: 'Pickup',
        return: 'Return'
      },
      attempts_states: {
        in_progress: 'In progress',
        failed: 'Failed',
        succeeded: 'Succeeded'
      },
      attempts_type: {
        forward: 'Forward',
        return: 'Return'
      },
      ofd_banner: {
        subtitle:
          'Want to fasten your search on OFD, and easy show the ASR without calculation, use this page',
        action_button: 'Go to OFD Search'
      }
    }
  },
  money_debriefing: {
    columns: {
      amount: 'Amount',
      business_name: 'Business name',
      tracking_number: 'Tracking number',
      money_resource: 'Money source',
      transaction_number: 'Transaction ID',
      collected_at: 'Collected at',
      notes: 'Notes'
    },
    change_to_cod_success_msg:
      'Order #{trackingNumber} changed to COD successfully',
    change_to_ccod_success_msg:
      'Order #{trackingNumber} changed to CCOD successfully',
    change_to_axis_success_msg:
      'Order #{trackingNumber} changed to Axis Wallet successfully',
    change_to_valu_success_msg:
      'Order #{trackingNumber} changed to Valu successfully',
    change_to_success_msg:
      'Order #{trackingNumber} changed to {newMoneySource} successfully',
    change_to_modal_title:
      'Change order #{trackingNumber} source from COD to {selectedMoneySource}'
  },
  date_picker: {
    select_date: 'Select Date',
    filter_option: {
      today: 'Today',
      yesterday: 'Yesterday',
      this_week: 'This week',
      last_week: 'Last week',
      last_seven_days: 'Last 7 days',
      this_month: 'This month',
      this_quarter: 'This quarter',
      this_year: 'This year',
      last_month: 'Last month',
      last_quarter: 'Last quarter',
      last_year: 'Last year',
      today: 'Today',
      tomorrow: 'Tomorrow'
    }
  },
  return_manifest: {
    title: 'Return Manifest Details',
    return_manifest_id: 'Return Manifest ID',
    returns: 'Returns: {count}',
    total_returns: 'Total Returns',
    out_for_return_date: 'Out for Return Date',
    return_date: 'Return Date',
    return_info: {
      title: 'Return Info',
      total_returns: 'Total Returns',
      out_for_return_date: 'Out for Return Date',
      return_date: 'Return Date',
      business_address: 'Business Address'
    },
    route_info: {
      title: 'Route Info',
      star_id: 'Star ID',
      star_name: 'Star Name',
      star_phone: 'Star Phone',
      route_id: 'Route ID'
    },
    proof_of_return: {
      title: 'Proof of Return',
      proof_of_return_type: 'Proof of Return Type',
      contact_name: 'Contact Name',
      contact_phone: 'Contact Phone',
      otp: 'OTP'
    },
    return_group_states: {
      rejected: 'Rejected',
      partially_returned: 'Partially Returned',
      signed_and_returned: 'Signed & Returned'
    }
  },
  three_pl_integrations: {
    title: '3PL Integrations',
    send_to_provider: 'Send to {provider}',
    send: 'Send',
    tracking_numbers: 'Tracking Numbers',
    tracking_numbers_placeholder: 'Enter tracking numbers sepreated by a comma',
    print_provider_awb: 'Print {provider} Airway Bills',
    sent_successfully: 'Sent to {provider} successfully',
    provider: 'Provider',
    select_provider: 'Select a provider',
    error_message: 'Error Message',
    failed_tracking_numbers_error: 'The following tracking numbers failed',
    print: 'Print',
    invalid_tracking_number: 'Invalid tracking numbers',
    providers: {
      jnt: 'JNT',
      thabit: 'Thabit',
      saee: 'Saee',
      torod: 'Torod',
      imile: 'Imile',
      logestechs: 'LogesTechs',
      flow: 'Flow'
    }
  },
  zoning: {
    title: 'Zoning',
    subtitle: 'Control of all zones that a city covers.',
    add_new: 'Add New',
    hub_placeholder: 'Please select a hub',
    coverage_filter_placeholder: 'Filter By',
    hub: 'Hub',
    city: 'City',
    search_placeholder: 'search by Arabic or English names',
    table_title: '{count} Districts',
    covered: 'Covered',
    uncovered: 'Uncovered',
    system_admin: 'SYSTEM ADMIN',
    coverage: 'Coverage',
    show_districts: 'Show Districts',
    filters: 'Filters',
    district: 'District',
    table_columns: {
      zone_name_en: 'Zone Name (EN)',
      zone_name_ar: 'Zone Name (AR)',
      pickup: 'Pickup',
      delivery: 'Delivery',
      added_by: 'Added By',
      last_update: 'Last Update',
      fm_hub: 'FM Hub'
    },
    create_edit_zone_modal: {
      title: 'Add New Zone',
      add_zone: 'Add Zone',
      edit_zone: 'Edit Zone',
      zone_name_en_placeholder: 'Write zone name in english',
      zone_name_ar_placeholder: 'Write zone name in arabic',
      city: 'City',
      city_placeholder: 'Please select city',
      pickup_coverage: 'Pickup Coverage',
      delivery_coverage: 'Delivery Coverage',
      update_success: 'Zone updated successfully',
      create_success: 'Zone created successfully',
      coverage_warning:
        "Editing Zone's Coverage will also be applied to the districts of this zone."
    },
    create_edit_district_modal: {
      title: 'Add New District',
      add_district: 'Add District',
      edit_district: 'Edit District',
      zone: 'Zone',
      zone_placeholder: 'Please select zone',
      update_success: 'District updated successfully',
      create_success: 'District created successfully',
      district_name_en: 'District Name (EN)',
      district_name_ar: 'District Name (AR)',
      district_name_en_placeholder: 'Write district name in english',
      district_name_ar_placeholder: 'Write district name in arabic'
    },
    delete_zone_confirmation: {
      title: 'Delete Zone',
      confirmation_message: 'Are you sure you want to delete this zone?',
      has_districts_confirmation_message:
        'Are you sure you want to delete this zone, it has districts under it?',
      success_message: 'Zone deleted successfully'
    },
    delete_district_confirmation: {
      title: 'Delete District',
      confirmation_message: 'Are you sure you want to delete this district?',
      success_message: 'District deleted successfully'
    }
  },
  request_compensation_modal: {
    title: 'Request compensation',
    business_info_title: 'BUSINESS INFO',
    business_name: 'Business Name',
    business_id: 'Business ID',
    submitter_info_title: 'SUBMITTER INFO',
    submitted_by: 'Submited by',
    email: 'Email',
    total_amount: 'TOTAL AMOUNT',
    compensation_details_title: 'COMPENSATION DETAILS',
    compensation_liability_title: 'COMPENSATION LIABILITY',
    hub: 'Hub',
    md: 'MD',
    cfo: 'CFO',
    success_message:
      'Your compensation request has been added successfully.{trackRequest}',
    track_request: 'Track request',
    form_title: {
      ticket_url: 'Ticket URL',
      ticket_url_subtitle: 'Please copy the ticket URL and paste it here.',
      compensation_reason: 'Compensation Reason',
      order_id: 'Order ID',
      multiple_order_title: 'Order IDs',
      multiple_orders_subtitle: 'Multiple orders shall be separated by comma.',
      single_order: 'Single order',
      multiple_orders: 'Multiple orders',
      compensation_type_title: 'Compensation Type',
      free_orders_title: 'Free Orders',
      credit_title: 'Credit',
      assigned_departments: 'Assigned Department',
      size_confirmation: 'Size Confirmation',
      approved_by: 'Approved by'
    },
    form_placeholders: {
      ticket_url: 'https://bosta.freshdesk.com/a/tickets/672067',
      compensation_reason: 'Please choose one..',
      order_id: '2345678',
      multiple_order_id: '399228, 829829',
      approved_by: 'Approved by',
      investigation_reason: "Investigation's Reason",
      compensation_amount: '1,000',
      size_confirmation: 'Please choose one..'
    },
    validation_messages: {
      invalid_url: 'Invalid URL. Please copy the ticket URL and paste it here.',
      compensation_reason: 'Please select a reason.',
      order_ids:
        'Please add order IDs. Multiple orders shall be separated by comma.',
      invalid_tracking_numbers:
        'Some of these orders are invalid. Please review them one by one.',
      tracking_numbers_dont_belong_to_business:
        'Some of these orders don’t belong to this business. Please create separately.',
      invalid_input_id: 'Invalid input',
      compensation_type: 'Please select one of these types.',
      size_confirmation: 'Please select the confirmed size.',
      approved_by: 'Please select one.',
      investigation_reason: 'Please select a reason',
      compensation_amount: 'Please add the amount.',
      department: 'Please select at least one department.',
      md_error:
        'For this compensation amount you should get an approval from MD.',
      cfo_error:
        'For this Compensation amount you should get an approval from CFO.',
      more_than_50_id: 'Maximum number orders per request is 50.',
      ticket_url_required: 'Please copy the ticket URL and paste it here.',
      amount_negative:
        'It is not possible to request this compensation, as it has to be more than 0.',
      hub: 'Hub is required'
    }
  },
  roles_permissions: {
    title: 'Roles & Permissions',
    admin_group: 'Admin Group',
    admin_group_placeholder: 'Select admin group',
    resource: 'Resource',
    resource_placeholder: 'Select Resource',
    permissions: 'Permissions',
    scopes: 'Scopes',
    update_success: 'Updated Successfully!',
    access_levels: 'Access Levels',
    ADVANCED: 'Advanced',
    CONFIDENTIAL: 'Confidential',
    RESTRICTED: 'Restricted',
    SIMPLE: 'Simple',
    name: 'Role Name',
    new_name: 'New Role Name',
    clone: 'Clone an existing admin group',
    create_success: 'Admin Group created successfully!',
    or: 'OR',
    module: 'Module',
    module_placeholder: 'Select Module',
    special_permissions: 'Special Permissions',
    edit_special_permissions: 'Edit Special Permissions',
    create_new_admin_group: 'Create New Admin Group',
    act_as_super_admin: 'Act as Super Admin'
  },
  sllr_analytics: {
    page_title: 'Sllr Metrics Page',
    sllr_kpis: 'SLLR KPIs',
    sllr_features: 'FEATURES',
    sllr_top_sellers: 'TOP SELLERS',
    sllr_activation: 'SLLR ACTIVATION',
    sllr_adoption: 'SLLR ADOPTION',
    order_creation_sources: 'ORDER CREATION SOURCES',
    top_sellrs: '{count} TOP SELLERS',
    activation_title: '{count} ACCOUNTS',
    shipping_fees_adoption: 'SHIPPING FEES ADOPTION',
    shipping_fees_types: 'SHIPPING FEES TYPES',
    shipping_fees_impact: 'SHIPPING FEES IMPACT',
    discount_price_adoption: 'DISCOUNTED PRICE ADOPTION',
    discount_price_impact: 'DISCOUNTED PRICE IMPACT',
    pudo_adoption: 'PUDO ADOPTION',
    product_variance_adoption: 'PRODUCT VARIANCE ADOPTION',
    average_product_variances: 'AVG PRODUCT VARIANCES',
    all_countries: 'All Countries',
    country: 'Country',
    order_creation_srcs_section_note: 'Month to date',
    published: 'Published',
    unpublished: 'Unpublished',
    export_domains_list: "Export Domains' list",
    headers: {
      name: 'Name',
      value: 'Value',
      business_name: 'Business Name',
      created_sllrs: 'Sllr',
      orders: 'Orders ',
      products: '#products',
      discounted_products: '#active_discounted_products',
      store_views: '#store views',
      completed: '#completed',
      confirmed: '#confirmed',
      shipped: '#shipped',
      total_orders: '#total',
      store_link: 'Sllr URL',
      settings_store_url: 'Settings URL',
      last_confirmed_order_date: 'Last confirmed order',
      account_type: 'Account type'
    },
    sllr_kpis_rows: {
      stores_published: '#Stores_Published',
      products: '#Products',
      created_sllr_orders: '#Created Sllr Orders',
      confirmed_sllr_orders: '#Confirmed Sllr Orders',
      created_orders_with_multiple_products: '#Created Orders With 1+ Product',
      product_inventory: '#Product_Inventory',
      active_product_inventory: '#Active_Product_Inventory',
      avg_product_inventory_store: '#AVG product_Inventory / Store',
      shipped_orders: '#Shipped orders',
      active_product_sllr: '#Active_Product_Sllr',
      active_product_sllr_catalog: '#Active_Product_Sllr_Catalog',
      average_products_per_store: '#AVG Products/Store',
      average_basket_size: '#AVG Basket Size',
      confirmed_sales: '#Confirmed Sales',
      stores_created: '#Stores_Created',
      stores_active: '#Stores_Active',
      products_active: '#Products_Active'
    },
    order_creation_sources_rows: {
      orders: '#orders',
      orders_apis: '#orders_APIs',
      orders_massupload: '#orders_Massupload',
      orders_plugins: '#orders_Plugins',
      orders_create_order: '#orders_Create_Order',
      orders_sllr_confirm_orders: '#orders_Sllr_Confirm_Orders',
      orders_business_app: '#Orders_Business_APP_Create',
      orders_sllr_app: '#Orders_Business_APP_Sllr',
      others: 'Others'
    },
    sllr_adoption_rows: {
      kpi_name: 'KPI Name',
      bosta_account: 'Bosta Account',
      sllr_account: 'Sllr Account'
    },
    shipping_fees_adoption_rows: {
      published_stores: '#published_stores',
      disabled_shipping_fees: '#disabled_shipping_fees',
      enabled_shipping_fees: '#enabled_shipping_fees'
    },
    shipping_fees_types_rows: {
      free_shipping: '#free_shipping',
      conditional_free_shipping: '#conditional_free_shipping',
      flat_rate: '#flat_rate',
      custom_rate: '#custom_rate'
    },
    shipping_fees_impact_rows: {
      completed_orders: '#completed_orders',
      completed_orders_disabled_shipping_fees:
        '#completed_orders_disabled_shipping_fees',
      completed_orders_free_shipping: '#completed_orders_free_shipping',
      completed_orders_conditional_free_shipping:
        '#completed_orders_conditional_free_shipping',
      completed_orders_flat_rate: '#completed_orders_flat_rate',
      completed_orders_custom_rate: '#completed_orders_custom_rate'
    },
    discount_products_analytics_rows: {
      active_products_with_discounts:
        '#active_products_on_sllr_dicounted_price',
      completed_orders_discounted_products:
        '#completed_orders_discounted_products',
      completed_orders_total_products: '#completed_orders_total_products',
      completed_orders_with_discounted_products:
        '#completed_orders_with_discounted_products',
      total_active_products: '#active_products_on_sllr'
    },
    pudo_adoption_rows: {
      eg_published_stores: '#EG_published_stores',
      eg_disabled_pudo: '#EG_disabled_pudo',
      eg_enabled_pudo: '#EG_enabled_pudo',
      pudo_shipped_orders: '#pudo_shipped_orders',
      pudo_delivered_orders: '#pudo_delivered_orders'
    },
    product_variance_adoption_rows: {
      products_inventory: '#products_inventory',
      products_catalog: '#products_catalog',
      products_disabled_options: '#products_disabled_options',
      products_enabled_options: '#products_enabled_options'
    },
    avg_product_variances: {
      avg_product_options: '#avg_product_options',
      avg_product_values: '#avg_product_values',
      products_used_size_options: '#products_used_size_option',
      products_used_color_options: '#products_used_color_option',
      products_used_material_option: '#products_used_material_option',
      products_used_condition_option: '#products_used_condition_option',
      products_used_brand_option: '#products_used_brand_option',
      products_used_storage_option: '#products_used_storage_option',
      products_used_custom_option: '#products_used_custom_option'
    },
    sllr_activation_filters: {
      all: 'All',
      bosta: 'Bosta',
      sllr: 'Sllr'
    },
    activated: 'Activated',
    not_yet: 'Not yet'
  },
  support_tickets: {
    page_title: 'Support',
    title: 'Create Ticket',
    header_title: 'Create New Ticket',
    header_subtitle: 'Tell us more about your issue',
    re_assign: 'Re-assign',
    tickets_list: {
      title: 'Your tickets list',
      search_placeholder: 'Search for ticket...',
      tickets_count: 'Tickets ({ticketsCount})',
      labels: {
        ticket_id: 'Ticket ID',
        created_by: 'Created By',
        group_name: 'Group Name'
      },
      no_tickets: {
        title: "You don't have any tickets yet",
        subtitle: 'create a ticket to start',
        button: 'Create New Ticket'
      },
      show_all_tickets: 'Show all tickets'
    },
    ticket_status: {
      open: 'Open',
      closed: 'Closed',
      pending: 'Pending',
      resolved: 'Resolved',
      pending_customer_response: 'Pending Customer Response'
    },
    ticket_conversation: {
      start_chatting: 'Start Chatting',
      type_your_message: 'Type your message…',
      tracking_number: 'Tracking Number',
      description: 'Description',
      attachments: 'Attachments'
    },
    reassign_ticket_modal: {
      title: 'Re-assign Ticket',
      reassign_label: 'Re-assign To',
      reassign: 'Re-assign',
      choose_team: 'Choose Team',
      success_message: 'Ticket re-assigned successfully!',
      agent_name_label: 'Agent name',
      agent_placeholder: 'Choose agent'
    },
    tags: {
      filters: 'Filters'
    },
    ticket_category: {
      lost_order: 'Lost Order',
      damaged_package: 'Damaged Package',
      opened_package: 'Opened Package',
      missing_item: 'Missing item',
      mixed_order: 'Mixed Order',
      empty_package: 'Empty package',
      awb: 'AWB',
      orders_creation: 'Orders Creation',
      rejected_package: 'Rejected package',
      taken_by_force: 'Taken by force',
      ecommerce_cases: 'E-commerce cases',
      uncovered_zone_parcel: 'Uncovered zone parcel',
      uncovered_zone_bulky: 'Uncovered zone bulky',
      updated_delivered: 'Updated delivered',
      investigation_cases: 'Investigation cases',
      tech_issues: 'Tech issues',
      amazon_poor_packing: 'Amazon Poor Packing',
      fleet_team_validation: 'Fleet team validation',
      package_size: 'Package size',
      unclear_address_tel: 'Unclear address or Tel',
      cod_editing_ofd: 'COD Editing OFD',
      cod_editing_ops: 'COD Editing OPS',
      specific_time_business: 'Specific time Business',
      specific_time_consingee: 'Specific time Consignee',
      terminate_tn: 'Terminate TN',
      convert_to_rto_business: 'Convert to RTO Business',
      convert_to_rto_consignee: 'Convert to RTO Consignee',
      fake_order: 'Fake Order'
    },
    ticket_subcategory: {
      delayed_return: 'Delayed Return',
      wrong_update: 'Wrong update',
      damaged_order: 'Damaged order',
      mixed_order: 'Mixed order',
      missing_item: 'Missing Item',
      empty_package: 'Empty Package',
      opened_shipment: 'Opened shipment',
      fake_update: 'Fake Update'
    },
    unclear_address_subcategory: {
      change_address_business: 'Change Address (Business)',
      change_address_consignee: 'Change Address (Consignee)',
      change_phone_business: 'Change Phone number (Business)',
      change_phone_consingee: 'Change Phone number (Consignee)',
      change_phone_address_business: 'Phone number and address (Business)',
      change_phone_address_consignee: 'Phone number and address (Consignee)',
      add_another_phone_business: 'Add another number (Business)',
      add_another_phone_consignee: 'Add another number (Consignee)'
    },
    tech_issues_subcategory: {
      pickup_requets: 'Pickup Requests',
      integration: 'Integration',
      printing_awb: 'Printing AWB',
      reset_password: 'Reset Password',
      change_address: 'Change Address',
      change_phone_number: 'Change Phone Number',
      material_request: 'Material Request',
      fulfillment: 'Fulfillment',
      mass_upload: 'Mass Upload',
      bosta_box: 'Bosta Box',
      bosta_app: 'Bosta App'
    },
    awb_subcategory: {
      terminated_awb: 'Terminated AWB',
      duplicated_awb: 'Duplicated AWB'
    },
    ticket_assignee: {
      investigation_team: 'Investigation Team',
      tickets_support: 'Tickets Support'
    },
    create_ticket: {
      place_holder: {
        category: 'Select issue',
        sub_category: 'Select sub category',
        assignee: 'Select assignee',
        description:
          'Add a description to help support team understand your issue...'
      },
      labels: {
        create_new_ticket: 'Create New Ticket',
        tracking_no: 'Tracking Number',
        subtitle: 'Tell us more about your issue',
        category: "I've got a problem with",
        sub_category: 'Sub Category',
        ticket_subject: 'Ticket subject',
        description: 'Description',
        assignee: 'Assignee'
      },
      create_ticket: 'Create a Ticket',
      notify_messages: {
        ticket_created_successfuly: 'Your ticket has beed created successfuly'
      },
      tooltips: {
        tracking_number:
          'If the issue is related to more than one order, please add them separated a comma. (231321, 34234, 232322)'
      }
    },
    upload_images: {
      header: 'Upload File',
      title: 'or drag and drop',
      sub_title: 'Supports JPG, PNG, XLSX - Max (15 MB)',
      upload: 'Upload',
      click_to_upload: 'Click to upload '
    },
    forward_ticket_modal: {
      title: 'Forward Ticket',
      label: 'Email',
      submit_button: 'Forward',
      place_holder: 'Enter emails , enter multiple emails by adding a comma',
      notify_agents_title: 'Notify Agents'
    },
    change_ticket_status_modal: {
      title: 'Change Ticket Status',
      label: 'Select Status'
    },
    add_tracking_number_modal: {
      title: 'Add Tracking Number',
      label: 'Tracking Number',
      place_holder: 'Type tracking number'
    }
  },
  change_password_form: {
    title: 'Your password has expired, please set a new password.',
    labels: {
      old_password_label: 'Current Password',
      new_password_label: 'New Password',
      new_password_confirm_label: 'Confirm New Password'
    },
    placeholders: {
      old_password_placeholder: 'Please enter your current password',
      new_password_placeholder: 'Please enter your new password',
      new_password_confirm_placeholder: 'Please re-enter your new password'
    },
    validation_messages: {
      contains_number: 'Contains a number',
      at_least_8_char: 'At least 8 characters.',
      at_least_uppercase: 'At least one uppercase character',
      at_least_special_char:
        'At least one special character (!@#$%^&*()-_=+{};:,<.>)',
      confirm_password_error: 'Confirm New Password must match New Password',
      validation_success: 'Nice work. This is an excellent password.',
      password_changed_successfully:
        'Password changed successfully. Please login again.'
    },
    set_new_password: 'Set new password'
  },
  forgot_password: {
    title: 'Reset Password',
    send_email: {
      title: 'Enter your email to reset your password',
      reset: 'Reset',
      back: 'Back',
      success_message:
        'We have send an email with your password reset link. Please check your email.'
    },
    set_new_password: {
      title: 'Set a new password'
    },
    labels: {
      email: 'Email',
      new_password: 'New Password',
      confirm_new_password: 'Confirm'
    },
    placeholders: {
      email: 'example@bosta.co'
    }
  },
  sign_in: {
    title: 'Sign in to your account',
    confirm_action: 'Sign in',
    sign_in_with: 'Sign in with',
    labels: {
      email: 'Email',
      password: 'Password',
      forgot_password: 'Forgot your password?'
    },
    placeholder: {
      email: 'example@bosta.co'
    },
    domain_selector_title: 'Select your Email domain',
    password_expired:
      'You password has expired. Check your inbox, a link to reset your password has been emailed to {email}'
  },
  create_route: {
    first_mile_validation: {
      content:
        'That’s a deliver order, are you sure you want to add it to route?',
      confirm: 'Add to route',
      cancel: 'back to scanning '
    },
    order_cod_updated_modal: {
      title: 'COD amount changed for the below orders',
      remove_orders: 'Remove orders',
      print_awb: 'Print AWBs'
    },
    rto_last_mile_confirmation_modal: {
      content:
        'that’s RTO order, are you sure you want to add it to the route?',
      confirm: 'Add to route',
      cancel: 'Back to scanning'
    },
    delete_confirmation_modal: {
      title: 'Confirm Removing Deliveries',
      content:
        'The following deliveries will be removed from the route: {trackingNumbers}'
    },
    commercial_order_confirmation: {
      title:
        'This is a Commerical order. Are you sure you want to dispatch it today?'
    },
    pos_modal: {
      title: 'POS requested for this order',
      sub_title:
        'A POS machine was requested for this order. Make sure to assign it to a courier with a POS machine.',
      add: 'Add Order to Route'
    }
  },
  barcode_to_awb: {
    title: 'Today’s Received Barcodes',
    generate_barcodes_title: 'Barcodes Overview',
    not_authorized: 'You are not authorized to take action at your current hub',
    cards: {
      printed: 'Printed',
      not_printed_yet: 'Not Printed Yet'
    },
    barcodes_list: {
      title: 'Not Printed Yet Barcodes',
      actions: {
        generate_barcodes: 'Generate Barcode Sheets',
        start_scanning: 'Start Scanning'
      },
      empty_state: {
        title: 'No barcodes received yet'
      }
    },
    generate_barcodes_modal: {
      title: 'Generate Barcode Sheets',
      send_to_email: 'Send File to E-mail',
      number_of_sheets_label: 'Number of Sheets',
      number_of_sheets_help_text: 'The maximum is {count} sheets per process.',
      number_of_sheets_banner:
        '{count, plural, one {# A5 sheet has} other {# A5 sheets have}} {stickersCount} stickers',
      type_label: 'Type',
      csv_zebra_help_text: 'The maximum is 1000 barcodes per process.',
      number_of_barcodes_label: 'Number of barcodes'
    },
    success_modal: {
      title:
        '{count, plural, one {# sheet} other {# sheets}} sent successfully to your email!',
      generate_more_sheets: 'Generate More Sheets'
    },
    barcode_scanning: {
      form_label: 'Scan or enter barcode numbers',
      list_title: '{count} Printed',
      empty_state_title: 'Nothing printed yet',
      already_scanned: 'This barcode is already scanned'
    },
    types: {
      a5_sheets: 'A5 Sheets',
      zebra_sheets: 'Zebra Sheets',
      barcodes_csv: 'Barcodes CSV'
    }
  },
  capital: {
    title: 'Bosta Capital',
    table: {
      title: '{count} Total profiles',
      business: 'Business Data',
      revenue_data: 'Revenue amount',
      total_orders: 'Total Orders',
      couriers: 'Couriers',
      industry: 'Industry',
      form_filled_date: 'Form Filled Date',
      apply_date: 'Apply Date',
      first_plan: 'First Plan',
      second_plan: 'Second Plan',
      third_plan: 'Third Plan',
      amount: 'Amount',
      orders: 'Orders',
      capital_id: 'Capital ID',
      eligability: 'Eligibility',
      fund_amount: 'Fund Amount',
      avg_orders: 'Avg Orders/month',
      avg_cod: 'Avg Cod/order',
      status: 'Status',
      edit_status: 'Edit Status',
      down_legal_docs: 'Download legal docs'
    },
    filters: {
      funding_amount: 'Funding Amount',
      applied_at: 'Applied at',
      eligability: 'Eligibility',
      business_id: 'Business ID',
      business_email: 'Business Email',
      business_phone: 'Business Phone'
    },
    tabs: {
      tab_1: 'Applied Businesses',
      tab_2: "Eligable didn't apply"
    }
  },
  capital_settings: {
    title: 'Capital Settings',
    max_loan: 'Max Loan amount',
    min_orders: 'Minimum order numbers per month',
    min_cod: 'Minimum COD collected per month',
    success_rate: 'Delivery Success Rate %'
  },
  subscription: {
    title: 'Subscriptions',
    create: 'Create New Subscription',
    time_preview: {
      month: 'Month',
      year: 'Year'
    },
    monthly: 'Monthly',
    yearly: 'Yearly',
    egp_amount: 'EGP {amount}',
    usd_amount: 'USD {amount}',
    plan_price: '{currency} {amount} per {interval}',
    columns: {
      subscription: 'Subscription',
      pricing_tier: 'Pricing Tier',
      date: 'Date',
      p_month: 'Price/Month',
      p_year: 'Price/Year',
      status: 'status',
      empty_heading: 'Start Creating Subscriptions',
      empty_body: 'Start creating subscriptions for different pricing tiers',
      business_name: 'Business Name',
      status: 'Status',
      plan: 'Plan',
      pricing: 'Pricing'
    },
    form: {
      label: {
        sub_details: 'Subscription Details',
        features: 'Features',
        payment_details: 'Payment Details',
        name: 'Name',
        description: 'Description',
        pricing_tier: 'Pricing Tier',
        product: 'Product',
        feature: 'Feature',
        currency: 'Currency',
        m_price: 'Monthly Price',
        y_price: 'Yearly Price'
      },
      placeholder: {
        name: 'Add subscription name',
        description: 'Add description for subscription',
        pricing_tier: 'Select pricing tier',
        product: 'Select a product for this subscription',
        feature: 'Add your feature',
        currency: 'Select pricing currency',
        m_price: '000',
        y_price: '000'
      },
      error: {
        name: 'Please enter a name',
        description: 'err',
        pricing_tier: 'err',
        product: 'err',
        feature: 'feature error !',
        currency: 'err',
        price: 'Price cannot be less than EGP 30'
      },
      add_feat: 'Add feature'
    },
    users: {
      table_header: '{count} Users',
      store: '{name} Store'
    },
    activate: 'Activate',
    deactivate: 'Deactivate',
    create: 'Create New Subscription',
    create_btn: 'Create Subscription',
    update: 'Update',
    preview: 'Preview',
    includes: 'This includes',
    per_month: ' per Month',
    per_year: 'per Year',
    update: 'Update',
    clone: 'Clone',
    clone_sub: 'Clone Subscription',
    cancel_sub: 'Cancel Subscription',
    canceled_success: 'Subscription was canceled',
    subscribe: 'Subscribe',
    renew_at: 'Your plan renews at {date}',
    end_at: 'Your plan ends at {date}',
    ended_at: 'Your plan ended at {date}',
    cancels_at: 'Cancels at {date}',
    details: {
      sub_details: 'Subscription details',
      started: 'STARTED',
      next_invoice: 'NEXT INVOICE',
      egp_next_invoice_date: 'EGP {amount} on {date}',
      usd_next_invoice_date: 'USD {amount} on {date}',
      business: 'BUSINESS',
      created: 'CREATED',
      id: 'ID',
      billing_history: 'BILLING HISTORY'
    }
  },
  order_details: {
    delete_subtitle:
      'Your order #{order_id} will be removed from your orders, are you sure you want to delete it?',
    create_ticket: 'Create ticket',
    wallet: 'Wallet',
    check_wallet: 'Check {balanceButton} page for more info.',
    customer: 'Customer',
    building_number: 'Building# {value}',
    floor_number: 'Floor# {value}',
    apartment_number: 'Apartment# {value}',
    performance: 'Delivery Performance',
    order_type: 'Order Type',
    cash_collection: 'Cash Collection',
    pickup_details: 'Pickup Details',
    expand: 'Expand Timeline',
    collapse: 'Collapse Timeline',
    activity_log: 'Activity Log',
    at: 'at',
    package: 'Package',
    delivery_package: 'Delivery Package',
    return_package: 'Return Package',
    package_details: 'Package description',
    number_of_items: 'No. of items',
    product: 'Product',
    quantity: 'Quantity',
    price: 'Price',
    landmark: 'Landmark: {value}',
    work_address: 'Work address',
    return_location: 'Return Location'
  },
  hub_management: {
    received_by_mistake: {
      title: 'Received by Mistake',
      action: 'Mark as Received By Mistake',
      input_label: 'Tracking Numbers',
      input_placeholder:
        'Enter tracking numbers seperated by a comma or a space',
      success_message: 'Tracking numbers terminated successfully'
    },
    money_debrief_report: {
      columns: {
        debrief_id: 'Debrief ID',
        debrief_date: 'Deprief Date',
        star: 'star',
        receip_type: 'Receipt type',
        tn: 'Tracking Number',
        receipt_number: 'Receipt Number',
        fawry_id: 'Fawry ID',
        amount: 'Amount',
        receipt_date: 'Receipt Date'
      }
    }
  },
  sllr_pay: {
    saudi_services: {
      mada: 'Mada',
      visa: 'Visa',
      master_card: 'Mastercard',
      maestro: 'Maestro',
      american_express: 'American Express',
      mada_card: 'Mada Card',
      apple_pay: 'Apple Pay'
    }
  },
  packaging: {
    header: 'Packaging',
    balance_title: 'Packaging Balance',
    flyers: 'FLYERS',
    paid_items: 'PAID ITEMS',
    orders: 'Orders',
    table_title: '{count} Orders',
    search_placeholder: 'Search by Order ID',
    successful: 'Successful',
    all: 'All',
    return: 'Return',
    table: {
      order_id: 'Order Id',
      customer_info: 'Customer Info',
      amount: 'Amount',
      status: 'Status'
    },
    manage_materials: {
      title: 'Manage Packaging Materials',
      table: {
        header: 'Materials ({count})',
        material: 'Material',
        quantity: 'Balance',
        price: 'Price',
        status: 'Status'
      },
      logs: {
        quantity_action: 'Balance updated',
        price_action: 'Price updated',
        status_action: 'Package Material Status updated',
        quantity_log_text:
          'Updated the balance of {itemName} from {oldValue} to {newValue}',
        status_log_text: '{status} {itemName}',
        price_log_text:
          'Updated the price of {itemName} from {oldValue} to {newValue}',
        load_more: 'Load more logs'
      },
      description: 'Description',
      description_placeholder: 'Type description for these updates...'
    },
    orders_tab: {
      tabs: {
        shipping_orders: 'Shipping Orders',
        sales_orders: 'Sales Orders',
        cancelled_orders: 'Cancelled Orders'
      },
      draft_orders: {
        table: {
          order_id: 'Reference ID',
          customer_info: 'Customer Info',
          location: 'Location',
          items: 'Items',
          amount: 'Amount',
          creation_date: 'Creation Date',
          cancelation_date: 'Cancelation Date',
          confirmation_date: 'Confirmation Date',
          delete: 'Delete Order',
          cancellation_date: 'Cancellation Date',
          cancellation_reason: 'Cancellation Reason',
          items_count: '{count} items',
          selected: '{count} Selected',
          cancelled_count: '{count} Cancelled Orders',
          draft_count: '{count} Draft Orders',
          search_placeholder: 'Search by Customer or Reference ID'
        },
        delete_modal: {
          modal_title: 'Delete and Cancel Order',
          delete_confirmation:
            'Are you sure you want to delete this order? customer will receive an SMS to inform him/her that the order is cancelled. ',
          confirm_selected: 'Confirm Selected ({count})',
          delete: 'Yes, Delete',
          keep: 'No, Keep it',
          rejection_reason_title: 'Cancellation Reason',
          rejection_reason_placeholder: 'Please enter a cancellation reason'
        },
        confirm_not_all_success_message:
          'Some flyers orders are confirmed successfully and others failed.{backendMessage}',
        delete_not_all_success_message:
          'Some flyers orders are cancelled successfully and others failed.{backendMessage}'
      },
      shipping_orders: {
        table: {
          title: '<span>Orders:</span> {count}',
          order_id: 'Order ID',
          type: 'Type',
          customer_info: 'Customer Info',
          location: 'Location',
          amount: 'Amount',
          status: 'Status',
          attempts: 'Attempts',
          last_update: 'Last Update'
        },
        filters: {
          order_id: 'Order ID',
          phone_number: 'Customer Phone Number',
          status: 'Status',
          ref_num: 'Order Reference',
          updated_at: 'Updated At',
          confirmed_at: 'Confirmed At'
        }
      }
    }
  },
  bosta_coding: {
    zones_geofencing: {
      title: 'Zones Geofencing',
      updated_successfully: 'Zone updated successfully!',
      selectors: {
        hub_label: 'Hub',
        hub_placeholder: 'Select a Hub',
        zone_label: 'Zones',
        zone_placeholder: 'Select Zone',
        show_others: 'Show Other Zones',
        clear_zone_geofence: 'Clear Zone Geofencing'
      },
      add_zone_geofence: 'Click where you want to add zone geofence',
      errors: {
        intersecting_polygons: '* Two or more Zones Geofencing are intersecting'
      }
    },
    codes_geofencing: {
      title: 'Codes Geofencing',
      update_success: 'Street Code updated successfully!',
      creation_success: 'Street Code created successfully!',
      delete_success: 'Street Code deleted successfully!',
      add_street_code_geofence:
        'Click where you want to add Street Code Geofencing',
      selectors: {
        street_codes_label: 'Street Codes',
        street_codes_placeholder: 'Select Street Code',
        create_new_street_code: 'Create New Street Code',
        clear_street_code_geofencing: 'Clear Street Code Geofencing',
        show_all_codes: 'Show all street codes geofencing',
        delete_street_code: 'Delete Street Code'
      },
      errors: {
        invalid_points:
          '* Street code geofencing includes points outside the zone geofencing.',
        intersecting_polygons:
          '* Two or more Street Codes Geofencing are intersecting'
      },
      create_edit_modal: {
        title: 'Create Street Code',
        edit_title: 'Edit Street Code',
        name_label: 'Name',
        name_placeholder: 'Enter Street Code Name',
        name_ar_label: 'Name (AR)',
        name_ar_placeholder: 'Enter Street Code Arabic Name',
        star_label: 'Assign Star',
        star_placeholder: 'Please select a star'
      },
      delete_modal: {
        title: 'Confirm Deleting Street Code',
        content: 'Are you sure you want to delete this street code?'
      }
    }
  },
  fulfillment_inbound: {
    title: 'Inbound',
    create_po: {
      title: 'Create New PO',
      actions: {
        confirm: 'Create PO'
      },
      business_details: {
        title: 'Business Details',
        business_name: {
          label: 'Business Name',
          placeholder: 'Choose or search businesses'
        },
        business_id: {
          label: 'Business ID'
        },
        view_business: 'View Business'
      },
      products: {
        title: 'Products',
        columns: {
          product_name: 'Product Name',
          quantity: 'Quantity',
          bo_sku: 'Bo-SKU',
          original_quantity: 'Original Qty',
          actual_quantity: 'Actual Qty',
          confirmed_quantity: 'Confirmed Qty',
          qualified_quantity: 'Qualified Qty',
          serial_numbers: 'Serial Numbers'
        },
        empty_state: {
          title: 'Add Products',
          select_products: 'Select Products',
          upload_sheet: 'Upload Sheet',
          or: 'or'
        },
        select_products_modal: {
          title: 'Select Products',
          search_field: {
            placeholder: 'Search by Product Name, SKU, or IRN',
            search_options: {
              product_name: 'Product Name',
              sku: 'SKU',
              reference_id: 'IRN'
            }
          },
          add_product: 'Add Product',
          products_count: 'Products: {count}',
          quantity_count: 'Qty: {count}',
          confirm_btn: 'Add Products',
          empty_state: {
            title: 'No products for {businessName}',
            subtitle: 'This business doesn’t have any products yet.'
          },
          selected_products: 'Selected products',
          other_products: 'All products'
        },
        import_multiple_products_modal: {
          title: 'Import Sheet',
          subtitle: 'Upload all PO’s SKUs using the FC template.',
          confirm: 'Upload file',
          info: 'We recommend using this <span>sample template</span> to see an example of the required format.',
          replace_file: 'Replace file',
          file_name: 'File name: {fileName}',
          total_skus: 'Total SKUs: ',
          total_qty: 'Total Qty: ',
          file_size: 'File size: <span>{fileSize} MB</span>',
          upload: {
            title: 'or drag and drop your PO’s SKUs sheet',
            subtitle:
              'Supports CSV or XLSX - Max number of products per sheet is ({maxNumOfProducts})',
            error_msgs: {
              type: 'Only supports CSV or XLSX - Max number of products per sheet is ({maxNumOfProducts})',
              max_count:
                'Please make sure that the file has a maximum limit of {maxNumOfProducts} SKUs.',
              empty_file:
                'The file is empty. Use this <span>sample template</span> to see an example of the required format.',
              invalid_file:
                'Invalid file. Use this <span>sample template</span> to see an example of the required format.',
              validations:
                '{count} errors are found. Please fix the below errors and re-upload the file.'
            }
          },
          uploading: {
            title: 'Uploading ',
            subtitle: 'Please wait while your file is uploading..',
            created_products_successfully:
              '{count} products are imported successfully.',
            table: {
              header: {
                column_name: 'Column Name',
                issue: 'Issue',
                in_rows: 'In Rows'
              },
              rows: {
                productName: {
                  column_name: 'Product Name',
                  issue_required: 'Product Name is required.'
                },
                quantity: {
                  column_name: 'Quantity',
                  issue_required: 'Quantity is required.',
                  issue_numbers_only_with_min:
                    'Quantity should only be in numbers and at least 1 for each SKU.'
                },
                sku: {
                  column_name: 'SKU',
                  issue_required: 'SKU is required.',
                  not_belong_to_business:
                    'Some SKUs are not in business’s current products list.',
                  duplicated: 'Some SKUs are duplicated.'
                }
              }
            }
          }
        },
        success_modal: {
          title: '{poId} is created and ready for confirmation!',
          confirm_btn: 'Go to PO Confirmation'
        }
      }
    },
    confirm_po: {
      actions: {
        confirm: 'Confirm PO',
        cancel: 'Cancel PO',
        edit: 'Edit PO'
      },
      actual_quantity_tooltip: 'The actual counted items.',
      confirmation_modal: {
        title: 'Confirm that you received a total of <span>items</span>'
      },
      success_modal: {
        title: '{poId} is confirmed and ready for quality check!',
        confirm_btn: 'Quality Check',
        cancel_btn: 'Go to Purchase Orders'
      }
    },
    quality_check: {
      actions: {
        confirm: 'Check PO',
        cancel: 'Cancel PO'
      },
      qualified_quantity_tooltip: 'The items that you’ll receive only.',
      confirmation_modal: {
        title: 'Pass Quality Check?',
        content:
          'Clicking “QC is Done” means:\n1. You checked the quantities.\n2. The items passes the packaging guidelines.',
        confirm_btn: 'QC is Done'
      },
      success_modal: {
        title: '{poId} is ready!',
        confirm_btn: 'Print Labels',
        cancel_btn: 'I’ll do it later'
      },
      serials_generate_success_msg:
        '{count} serials were generated successfully.'
    },
    print_PO_labels: {
      actions: {
        confirm: 'Print Labels'
      },
      tooltip_while_generating_serials:
        'Please wait until serials are generated to start printing.',
      serials_count: '{count} serials',
      generating_serials: 'Generating...',
      confirmation_modal: {
        title: 'Proceed to print <span>labels</span>?',
        confirm_btn: 'Print Labels'
      },
      success_modal: {
        title: 'Printing {count} labels...'
      }
    },
    edit_po: {
      actions: {
        confirm: 'Save Changes',
        cancel: 'Discard'
      },
      can_not_edit: 'This PO is in a state you can not edit.',
      success_msg: 'Edits saved successfully.',
      discard_changes_confirmation_modal: {
        title: 'Discard Changes?',
        content:
          'Any changes you did to this PO will be lost if you discarded your changes.',
        confirm_btn: 'Discard Changes',
        cancel_btn: 'Stay on Page'
      }
    },
    cancel_po_modal: {
      title: 'Cancel PO?',
      content:
        'Once you cancel this PO you won’t be able to do any processes on it again.',
      confirm_btn: 'Cancel PO',
      cancel_btn: 'Keep PO'
    },
    po_states: {
      created: 'Created',
      confirmed: 'Confirmed',
      putaway: 'Ready',
      completed: 'Completed',
      canceled: 'Canceled'
    },
    create_new_po_btn: 'Create New PO',
    po_statistics: {
      titles: {
        total: 'All',
        confirm: 'Confirm',
        quality_check: 'Quality Check',
        putaway: 'Putaway',
        completed: 'Completed',
        canceled: 'Canceled'
      }
    },
    po_filters: {
      created_on: {
        label: 'Created On'
      },
      created_by: {
        label: 'Created By',
        placeholder: 'Filter by creator'
      },
      business: {
        label: 'Business',
        placeholder: 'Filter by business'
      },
      putaway: {
        label: 'Putaway',
        options: {
          all: 'All',
          not_stated: 'Didn’t Start',
          in_progress: 'In Progress'
        }
      }
    },
    po_table: {
      title: '{count} Purchase Orders',
      search_placeholder: 'Search by PO number, SKU, serial, or IRN',
      search_options: {
        po: 'PO',
        sku: 'SKU',
        serial: 'Serial',
        reference_id: 'IRN'
      },
      columns: {
        reference: 'Reference',
        business_name: 'Business Name',
        order_type: 'Order Type',
        no_of_sku: '# of SKU',
        quantity: 'Qty',
        created_on: 'Created On',
        created_by: 'Created By',
        status: 'Status',
        total_price: 'Total Price',
        last_update: 'Last Update',
        tracking_number: 'TN#'
      },
      before_cutoff: 'Before cut-off time',
      after_cutoff: 'After cut-off time',
      putaway_tooltip: 'Putaway in progress.'
    }
  },
  fulfillment_businesses: {
    title: 'Businesses',
    businesses_table: {
      title: '{count} Businesses',
      search_placeholder: 'Search by business id or business name...',
      search_options: {
        business_name: 'Business name',
        business_id: 'Business ID'
      },
      columns: {
        id: 'ID',
        business_name: 'Business Name',
        contact_number: 'Contact Number',
        contact_email: 'Contact Email',
        city: 'City'
      }
    },
    business_details: {
      business_info: 'Business Info',
      contact_info: 'Contact Info',
      email_copied_successfully: 'Contact email is copied successfully'
    },
    business_tabs: {
      business_info: 'Business Info',
      products: 'Products',
      inventory: 'Inventory',
      analytics: 'Analytics'
    }
  },
  fulfillment_products: {
    title: 'Products',
    products_table: {
      title: '{count} Products',
      search_placeholder: 'Search by {searchBy}...',
      columns: {
        product_name: 'Product Name',
        sku: 'SKU',
        reference_id: 'IRN',
        price: 'Price',
        total_on_hand: 'Total On Hand',
        total_forecasted: 'Total Forecasted',
        business: 'Business'
      },
      varies: 'Varies',
      master: 'Master',
      variants: '{count} variants',
      one_variant: '1 variant',
      variants_tooltip: 'This product has {count}.'
    },
    add_product_modal: {
      title: 'Add Product to Business',
      search_placeholder: 'Search by Business Name'
    },
    select_products_modal: {
      confirm: 'Add Products',
      selected_products_count: 'Selected Products: {count}',
      empty_state: {
        title: 'No products'
      },
      success_msg: 'Products were added successfully'
    }
  },
  fulfillment_outbound: {
    title: 'Outbound',
    statistics: {
      titles: {
        total: 'All',
        pick: 'Pick',
        pack: 'Pack',
        transfer: 'Transfer',
        transferred: 'Transferred',
        canceled: 'Canceled'
      }
    },
    order_types: {
      deliver: 'Deliver',
      exchange_forward: 'Exchange Forward'
    },
    sales_orders_table: {
      title: '{count} Sales Orders',
      search_placeholder: 'Search by {searchBy}',
      search_options: {
        sku: 'SKU',
        tracking_number: 'TN',
        reference_id: 'IRN',
        serial: 'Serial'
      },
      selected: '{count} Selected'
    },
    state_name: {
      created: 'Created',
      picked: 'Picked',
      packed: 'Packed',
      transferred: 'Transferred',
      canceled: 'Canceled'
    },
    business_info: {
      title: 'Business Info'
    },
    customer_info: {
      title: 'Customer Info'
    },
    order_logs: {
      title: 'Order Logs'
    },
    cod_from_business: 'COD from business',
    system_price: 'System’s price',
    columns: {
      required_quantity: 'Required Qty',
      on_hand: 'On Hand',
      unit_price: 'Unit Price',
      subtotal: 'Subtotal',
      serials_progress: 'Serials Progress',
      quantity: 'Quantity'
    },
    scan_serials: {
      label: 'Scan or enter serial numbers',
      placeholder: 'Scan or enter serial numbers...',
      errors: {
        wrong: {
          message:
            'You scanned a wrong item. Please make sure to pack the right SKU and serial.',
          btn_text: 'Try Again'
        },
        scanned_before: {
          message: 'This item is already scanned!',
          btn_text: 'Close'
        }
      }
    },
    serials_count: '{count} Serials',
    left_items: '{count} items left to pack',
    confirmation_modal: {
      title: 'Exit Order Packing?',
      message:
        'All your work will be lost and orders will remain waiting for packing.',
      confirm: 'Exit Order Packing',
      cancel: 'Keep Working'
    },
    actions: {
      print_airwaybill: 'Print Airwaybill',
      receive_transfer: 'Receive & Transfer',
      pack: 'Pack'
    },
    filters: {
      picked_on: 'Picked On',
      packed_on: 'Packed On',
      transferred_on: 'Transferred On'
    },
    packed_success_modal: {
      title: '{trackingNumber} is packed and ready for transfer!',
      cancel_btn: 'Go to Sales Orders'
    }
  },
  fulfillment_returns: {
    title: 'Returns',
    statistics: {
      titles: {
        total: 'All',
        return: 'Return',
        quality_check: 'Quality Check',
        putaway: 'Putaway',
        completed: 'Completed'
      }
    },
    order_types: {
      all: 'All',
      rto: 'RTO',
      picked_internal: 'Picked - Internal',
      packed_internal: 'Packed - Internal',
      received_internal: 'Received - Internal',
      transferred_internal: 'Transferred - Internal',
      crp: 'CRP',
      exchange_return: 'Exchange Return'
    },
    type_filter: {
      label: 'Type'
    },
    issues_filter: {
      label: 'Issues',
      lost: 'Lost only',
      damaged: 'Damaged only'
    },
    return_orders_table: {
      title: '{count} Returns',
      search_placeholder: 'Search by {searchBy}',
      search_options: {
        sku: 'SKU',
        tracking_number: 'TN',
        reference_id: 'IRN'
      },
      columns: {
        tracking_number: 'TN#',
        business_name: 'Business Name',
        no_of_sku: '# of SKU',
        qty: 'Qty',
        type: 'Type',
        status: 'Status',
        updated_at: 'Updated At',
        created_at: 'Created At',
        lost: 'Lost',
        damaged: 'Damaged'
      },
      contains_lost: 'Contains lost',
      contains_damaged: 'Contains damaged'
    },
    state_name: {
      on_hold: 'On Hold',
      confirmed: 'Confirmed',
      ready: 'Ready',
      completed: 'Completed'
    },
    actions: {
      confirm_return: 'Confirm Return',
      check_ro: 'Check RO',
      print_labels: 'Print Labels',
      scan_serials: 'Scan Serials',
      add_to_stock: 'Add to stock',
      delete_serial: 'Delete serial',
      print_crp_awb: 'Print CRP Airway Bill'
    },
    confirm_return_order: {
      confirmation_modal: {
        title: 'Confirm that you’ll return the order of TN# {trackingNumber}'
      },
      success_modal: {
        so_title: '{soId} is confirmed to return and ready for quality check!',
        tracking_number_title:
          'TN#{trackingNumber} is confirmed to return and ready for quality check!',
        confirm_btn: 'Quality Check',
        cancel_btn: 'Go to Returns'
      }
    },
    print_serials_modal: {
      title: 'Print labels',
      select_all: 'Select all {count} Labels',
      confirm_btn: 'Print {count} Labels'
    },
    scan_serials_modal: {
      title: 'Scan Serials',
      scan_serials_field: {
        label: 'Scan or enter serial numbers',
        placeholder: 'Scan or enter serial numbers...'
      },
      scanned_count: 'Scanned: {count}/{total}',
      error_msgs: {
        scanned_before: 'This serial is scanned before.',
        not_belong_to_order: 'This serial is not in the return order.',
        max_num_of_scan: 'Scanned serials can’t exceed the existing serials.',
        can_not_added: 'This serial can not be add to this RTV.'
      },
      confirmation_modal: {
        content:
          'You missed <span>serials</span> in this order Mark it as lost?',
        confirm_btn: 'Mark {count} as lost'
      }
    },
    linked_orders_card: {
      title: 'Linked Orders',
      original_so: 'Original SO: ',
      original_ro: 'RO: ',
      problematic_job: 'Problematic Job: ',
      exchange_forward: 'Exchange Forward: '
    },
    scan_damaged_serials_modal: {
      title: 'Damages Quality Check',
      counts: {
        damaged: 'Damaged: {count}'
      },
      confirm_btn: 'Confirm QC',
      confirmation_modal: {
        title: '{count} damages',
        content: 'Confirm QC for TN#{trackingNumber}'
      },
      success_modal: {
        title: 'TN#{trackingNumber} is checked and ready for putaway!',
        cancel_btn: 'Go to Returns'
      }
    },
    serials_view: {
      title: 'Units Center',
      view_serial_btn: 'View serials',
      print_all_serials: 'Print All Serials',
      active: {
        title: 'Active',
        header_count: 'Active Serials ({count})',
        print_btn: 'Print Active Serials',
        serial_type: 'Active Serial'
      },
      lost: {
        title: 'Lost',
        header_count: 'Lost Serials ({count})',
        print_btn: 'Print Lost Serials',
        serial_type: 'Lost Serial'
      },
      damaged: {
        title: 'Damaged',
        header_count: 'Damaged Serials ({count})',
        print_btn: 'Print Damaged Serials',
        serial_type: 'Damaged Serial'
      },
      generated: {
        title: 'Generated',
        header_count: 'Generated Serials ({count})',
        print_btn: 'Print Generated Serials',
        serial_type: 'Generated Serial'
      },
      packed: {
        title: 'Packed',
        header_count: 'Packed Serials ({count})',
        print_btn: 'Print Packed Serials',
        serial_type: 'Packed Serial'
      },
      transferred: {
        title: 'Transferred',
        header_count: 'Transferred Serials ({count})',
        print_btn: 'Print Transferred Serials',
        serial_type: 'Transferred Serial'
      },
      completed: {
        title: 'Completed',
        header_count: 'Completed Serials ({count})',
        print_btn: 'Print Completed Serials',
        serial_type: 'Completed Serial'
      },
      on_shelf: {
        title: 'On-Shelf ',
        header_count: 'On-Shelf Serials ({count})',
        print_btn: 'Print On-Shelf Serials',
        serial_type: 'On-Shelf Serial'
      }
    }
  },
  fulfillment_locations: {
    title: 'Locations',
    actions: {
      create_new_location: 'Create New Location',
      print_labels: 'Print Labels'
    },
    location_levels: {
      zone: 'Zone',
      aisle: 'Aisle',
      rack: 'Rack',
      shelf: 'Shelf',
      tote: 'Tote'
    },
    create_location_modal: {
      title: 'Create New Location',
      location_address: 'Location Address',
      dropdown_placeholder: 'Choose',
      location_level: {
        label: 'Location Level'
      },
      location_name: {
        label: 'Location Name',
        placeholder: 'Enter name here'
      },
      create: 'Create {level}',
      you_have: 'You have {count} {level}s:',
      not_have: 'You have 0 {level}s in this {parentLevel}',
      success_modal: {
        title: '{name} {level} location is created successfully!',
        go_to_location: 'Go to location',
        print_label: 'Print Label'
      },
      parents: {
        zone: 'Parent Zone',
        aisle: 'Parent Aisle',
        rack: 'Parent Rack',
        shelf: 'Parent Shelf'
      }
    },
    zone: {
      empty_state: {
        title: 'No zones added yet.'
      }
    },
    aisle: {
      empty_state: {
        title: 'No aisles added here yet.'
      }
    },
    rack: {
      empty_state: {
        title: 'No racks added here yet.'
      }
    },
    shelf: {
      empty_state: {
        title: 'No shelfs added here yet.'
      }
    },
    tote: {
      empty_state: {
        title: 'No totes added here yet.'
      }
    }
  },
  fulfillment_inventory_serials: {
    title: 'Inventory',
    type_filter: {
      label: 'Return Type'
    },
    serial_states: {
      in_stock: 'In Stock',
      processing: 'Processing',
      transferred: 'Transferred',
      damaged: 'Damaged',
      lost: 'Lost',
      rtv: 'RTV'
    },
    table: {
      title: '{count} Serials',
      contains_lost: 'Contains lost',
      contains_damaged: 'Contains damaged'
    },
    delete_serial_modal: {
      title: 'Delete (1) serial'
    }
  },
  fulfillment_common: {
    sku: 'SKU',
    serial: 'Serial',
    tracking_number: 'TN',
    business_name: 'Business Name',
    product_name: 'Product Name',
    status: 'Status',
    location: 'Location',
    qty: 'Qty',
    lost: 'Lost',
    damaged: 'Damaged',
    po: 'PO',
    po_id: 'PO-{poId}',
    so: 'SO',
    so_id: 'SO-{soId}',
    ro: 'RO',
    ro_id: 'RO-{roId}',
    pj: 'PJ',
    pj_id: 'PJ-{pjId}',
    rtv: 'RTV',
    rtv_id: 'RTV-{rtvId}',
    problematic: 'Problematic',
    vendor_serial: 'Vendor Serial',
    dates: {
      created_at: 'Created at',
      updated_at: 'Updated at',
      transferred_at: 'Transferred at',
      received_at: 'Received at',
      ro_created_at: 'RO created at',
      so_created_at: 'SO created at',
      damaged_at: 'Damaged at',
      lost_at: 'Lost at',
      rtv_at: 'RTV at',
      completed_at: 'Completed at'
    },
    filter_by_date_drawer: {
      title: 'Dates Filters',
      open_drawer_btn: 'Filter by dates',
      apply_filters: 'Apply filters'
    },
    print_label: 'Print Label',
    print_labels: 'Print Labels',
    created_by: 'Created by',
    completed_by: 'Completed by',
    return: 'Return',
    on_shelf: 'On-Shelf'
  },
  fulfillment_problematic_jobs: {
    title: 'Problematic',
    problematic_jobs_table: {
      title: '{count} Problematic Jobs',
      columns: {
        job_id: 'Job #',
        serials: 'Serials'
      }
    },
    state_name: {
      created: 'Created',
      completed: 'Completed'
    },
    add_to_stock: {
      confirmation_modal: {
        content:
          'Confirm creating {jobsCount} problematic jobs for {serialsCount} serials?',
        confirm_btn: 'Create problematic jobs'
      },
      success_modal: {
        title:
          '{count} problematic jobs were created and ready for putaway cycle!',
        cancel_btn: 'Go to Problematic Jobs'
      }
    }
  },
  fulfillment_return_to_vendor: {
    title: 'Requests',
    create_new_rtv_btn: 'Create New RTV',
    state_name: {
      completed: 'Completed'
    },
    return_to_vendor_table: {
      title: '{count} RTV Requests'
    },
    line_types: {
      partial: 'Partial',
      damaged: 'Damaged',
      all: 'All stock'
    },
    create_rtv: {
      title: 'Create New RTV',
      actions: {
        confirm: 'Complete RTV'
      },
      remove_sku_confirmation_modal: {
        message:
          'When removed, all serials will not be marked as RTV. You can always add it again.',
        confirm: 'Remove SKU',
        cancel: 'Keep SKU',
        success_msg: 'SKU removed from RTV successfully'
      },
      exit_rtv_confirmation_modal: {
        title: 'Exit RTV?',
        message: 'All your work will be lost and serials will remain in FC.',
        confirm: 'Exit RTV',
        cancel: 'Keep Working'
      },
      complete_rtv: {
        error_msg: {
          skus_with_no_serials:
            '{count} SKU failed to add because there is no stock or damaged serials in it',
          skus_with_no_serials_scanned:
            'One or more SKUs don’t have scanned serials'
        },
        confirmation_modal: {
          title: 'Complete RTV?',
          message:
            'Completing RTV means:\n1.({serialsCount}) serials in ({skuCounts}) SKUs will be marked as RTV.\n2.The serials will not be available for ordering.',
          confirm: 'Complete RTV'
        },
        success_modal: {
          title: 'RTV-{rtvId} request is completed',
          confirm: 'Go to RTV',
          cancel: 'All Requests'
        }
      }
    }
  },
  impersonation: {
    remote_access: 'Remote Access',
    modal_title: 'Request Remote Access',
    title: 'Access Customer’s Dashboard',
    subtitle:
      'Choose a business and we’ll send them SMS to grant you a permission for 48 hours.',
    send_request: 'Send Request',
    resend_request: 'Re-send Request',
    unlock_waiting: 'Unlock waiting ({timeLeft})',
    revoke_access: 'Revoke Access',
    access_dashboard: 'Access Dashboard',
    business_placeholder: 'Search by business name or id',
    business_details_banner:
      'This store belongs to <span>{ownerName}</span> Click “Send Request” and they will receive an SMS at <span>{phoneNumber}</span>. Ask them to open the link in the SMS that will direct them to the dashboard to approve.',
    status: {
      pending: {
        title: 'Your request was already sent to <span>{businessName}</span>',
        subtitle:
          'Ask your customer to check for the SMS <span>{phoneNumber}</span> and click the link to approve.'
      },
      approved: {
        title: 'Access granted to {businessName}',
        subtitle: 'Use your access for {timeLeft} before it ends automatically.'
      }
    }
  },
  international_orders: {
    tabs: {
      deliveries: 'Deliveries',
      pickups: 'Pickups',
      transfer: 'Transfer',
      receive: 'Receive'
    },
    pickups: {
      table_title: '{count, plural, one {# Pickup} other {# Pickups}}',
      selected: '{count} Selected',
      assign_to_courier: 'Assign to courier',
      unassign_from_courier: 'Un-Assign from Courier',
      choose_courier: 'Choose courier',
      assign_courier_title:
        'Assign {count, plural, one {# pickup} other {# pickups}} to courier',
      export_success: 'Pickups exported successfully!',
      pickup_assign_success: 'Pickups assigned successfully!',
      state: 'State',
      unassign: 'Un-Assign Courier',
      unassign_success:
        '{count, plural, one {# pickup} other {# pickups}} were un-assigned and moved back to Not assigned successfully!',
      unassign_confirmation_modal: {
        title: 'Un-Assign Courier?',
        content:
          'The selected pickup(s) will then be available for re-assigning.',
        confirm_action: 'Un-Assign Courier',
        cancel_action: 'Keep Courier'
      },
      search: {
        pickup_id_label: 'Pickup ID',
        pickup_id_placeholder: 'Search by pickup id',
        business_placeholder: 'Choose Business',
        courier_label: 'Courier',
        courier_placeholder: 'Filter by courier',
        createdAt: 'Created At',
        assignedAt: 'Assigned At',
        cancelledAt: 'Cancelled At',
        pickedUpAt: 'Completed At',
        apply_filters: 'Apply Filters'
      },
      states: {
        not_assigned: 'Not Assigned',
        assigned: 'Assigned',
        completed: 'Completed',
        canceled: 'Canceled'
      },
      pickup_details: {
        title: 'Pickup Details',
        contact_person: 'Contact Person',
        pickup_info: {
          pickup_date: 'Pickup Date',
          pickup_location_name: 'Pickup Location Name',
          pickup_hub: 'Pickup Hub',
          pickup_address: 'Pickup Address',
          phone_number: 'Phone Number',
          additional_phone_number: 'Additional Phone Number'
        }
      }
    },
    deliveries: {
      table_title: '{count, plural, one {# Delivery} other {# Deliveries}}',
      export_error: 'You need to filter or select some orders to export',
      export_success: 'Deliveries exported successfully!',
      states: {
        created: 'Created',
        picked_up: 'Picked up',
        received_at_warehouse_origin: 'Received at warehouse “Origin”',
        received_at_warehouse_destination:
          'Received at warehouse “Destination”',
        received_at_warehouse: 'Received at Warehouse',
        in_transit: 'In transit',
        in_transit_destination: 'In Transit “Destination”',
        ready_for_air_transit: 'Ready to Air transit',
        air_transit: 'Air Transit',
        completed: 'Completed',
        canceled: 'Canceled',
        transferred: 'Transferred',
        custom_cleared: 'Customs Cleared',
        out_for_delivery: 'Out for Delivery',
        delivered: 'Delivered',
        picked_up_from_business: 'Picked up from Business',
        exception: 'Exception',
        returned: 'Returned',
        awaiting_for_action: 'Awaiting for business action'
      },
      search: {
        tracking_number_label: 'TN#',
        tracking_number_placeholder: 'Search by tracking number',
        customer_phone_label: 'Customer phone',
        customer_phone_placeholder: 'Search by customer phone'
      },
      table_columns: {
        dropoff_address: 'Drop-off Address',
        customer_details: 'Customer Details',
        picked_up_at: 'Picked up at',
        in_transit_at: 'In Transit At',
        international_tn: 'International TN#',
        from_to: 'From > To',
        destination_tn: 'Destination TN#',
        weight: 'Weight',
        customs_vat: 'Customs VAT',
        updated_at: 'Updated at'
      },
      table_actions: {
        print_awb: 'Print AWB',
        bulk_print: 'Print AWBs ({count})',
        received_at_destination: 'Received at Destination Country',
        print_invoice: 'Print Invoice',
        bulk_print_invoice: 'Print Invoices ({count})',
        view: 'View',
        print_vat_receipt: 'Print VAT Receipt',
        bulk_print_vat_receipt: 'Print VAT Receipt ({count})'
      },
      delivery_details: {
        weight: '{weight} KG',
        edit_weight: 'Edit Weight',
        edit_customs_vat: 'Edit Customs Vat',
        updated_successfully: 'Order updated successfully!',
        edit_customer_address: 'Edit Customer Address',
        tabs: {
          logs: 'Logs'
        },
        sections: {
          delivery_details: 'Delivery Details',
          customer_details: 'Customer Details',
          pickup_details: 'Pickup Details',
          flight_details: 'FLIGHT DETAILS',
          pricing_details: 'International Pricing'
        },
        fields: {
          weight: 'Weight',
          invoice_value: 'Invoice Value',
          work_address: 'Working Address',
          second_phone: 'Second Phone Number',
          address: 'Address',
          courier_name: 'Courier Name',
          courier_phone: 'Courier Phone Number',
          pickup_location_name: 'Pickup Location Name',
          vat: 'VAT',
          customs_vat: 'Customs VAT Value',
          doemstic_tracking_number: 'Doemstic Tracking no.',
          flight_no: 'Flight No.',
          flight_date: 'Flight Date',
          master_airway_bill: 'Master Airwaybill No.',
          pricing_tier: 'Pricing Tier',
          pricing_with_vat: 'Pricing With VAT',
          pricing_without_vat: 'Pricing without VAT',
          package_weight: 'Package Weight',
          pickup_country: 'Pickup Country',
          dropoff_country: 'Drop-off Country'
        },
        logs: {
          created: 'Order is created',
          pickup_scheduled: 'Pickup Scheduled',
          picked_up: 'Order pickup up',
          received_at_warehouse: 'Order is received at warehouse',
          ready_for_transfer: 'Order is ready for transfer',
          in_transit_source: 'Order is on Airplane',
          in_transit: 'Order set to in transit',
          completed: 'Order customs is cleared',
          address_updated: 'Address was updated',
          custom_vat_updated: 'Customs VAT Type was updated',
          custom_vat_updated_to: 'Custom VAT type was updated to {value}',
          return_location_updated: 'Return Location was updated',
          received_exception: 'Order received an exception',
          custom_vat_value_updated: 'Custom VAT value was updated',
          custom_vat_value_updated_to:
            'Custom VAT value was updated to {value}',
          international_completed: 'Order is delivered',
          international_out_for_delivery: 'Order is out for delivery',
          international_termianted: 'Order is terminated'
        },
        return_options: {
          return_to_address: 'Return to address in the destination country.',
          return_to_fulfillment:
            'Bosta fulfilment center in the destination country',
          terminate: 'Don’t return (Terminate)',
          return_to_origin: 'Return to Origin'
        },
        edit_return_location: {
          title: 'Edit Return Location',
          return_option: 'Return Option',
          return_locations: 'Return Locations',
          options: {
            return_to_fulfillment_label:
              'Return to Bosta fulfillment center in the destination country',
            return_to_origin_label: 'Return to Origin ({address})',
            terminate_label: "Don't return (Terminate)"
          }
        }
      },
      mark_as_received_at_destination_modal: {
        title: 'Mark as received at destination country?',
        content:
          'Are you sure you want to mark this delivery as recieved at destination country. this action can’t be undone.',
        content_bulk:
          'This means all selected orders were received at destination country. This action can’t be undone.',
        success_message:
          '{count, plural, one {# delivery was} other {# deliveries were}} marked as received at destination country successfully!'
      }
    },
    transfer: {
      setup: {
        title: 'Setup',
        start_scanning: 'Start Scanning',
        scanning_started: 'Scanning Started'
      },
      scan: {
        title: 'Scan',
        scan_tracking_number: 'Scan tracking number',
        scan_or_enter_tracking_number: 'Scan or enter tracking number...',
        incorrect_tracking: 'This tracking number does not exist!',
        already_scanned: 'This delivery is already scanned!',
        trasnfer_scan_error:
          'You can only scan deliveries with state “Received at Warehouse”',
        receive_scan_error:
          'You can only scan deliveries with state “Created” or “Picked up”.'
      },
      table: {
        title: '{count, plural, one {# Package} other {# Packages}}',
        transfer: 'Transfer',
        receive_at_warehouse: 'Confirm Receiving',
        transfer_success:
          '{count, plural, one {# delivery} other {# deliveries}} transferred successfully “in transit” and you will receive manifest shortly on email',
        receive_confirmation_title:
          'Confirm receiving {count, plural, one {# package} other {# packages}}',
        receive_confirmation_subtitle:
          'Are you sure that you want to receive ({count}) package?',
        receive_success:
          '{count, plural, one {# package was } other {# packages were}} marked as received!'
      }
    },
    create_edit_flight_modal: {
      title:
        'Confirm transferring {count, plural, one {# package} other {# packages}}',
      subtitle: 'Are you sure that you want to transfer ({count}) package?',
      content_title: 'Flight Details',
      required_message: 'This field is mandatory',
      master_awb_validation: 'The Master AWB should contain only numbers.',
      form_labels: {
        flight_no: 'Flight No.',
        flight_date: 'Flight Date',
        master_awb: 'Master Airwaybill No.'
      },
      placeholder: {
        flight_no: '598127642',
        master_awb: 'Enter master airwaybill number.'
      }
    },
    e2e_listing: {
      filters: {
        tracking_number_label: 'International or domestic TN#',
        from_origin: 'From “Origin”',
        to_destination: 'To “Destination”',
        vat_type: 'VAT type',
        createdAt: 'Created at',
        sourcePickedUpAt: 'Picked up “Origin” at',
        sourceTransferredAt: 'Transferred  “Origin” at',
        clearedFromCustomsAt: 'Cleared from customs at',
        completedAt: 'Delivered at'
      }
    }
  },
  bulk_update_pricing_tier: {
    header: 'Bulk Update Pricing Tiers',
    download_banner: {
      title: 'Bulk Update Pricing Tiers Template!',
      sub: 'Please make sure to download this sheet to upload business pricing tiers',
      action: 'Export First'
    },
    success_state: {
      title: 'Your file is uploaded sucessfully!',
      action: 'Upload Again'
    },
    failed_state: {
      title: 'Errors Found',
      sub: 'Please fix errors below and then re-upload the sheet',
      action: 'Re-upload',
      table_columns: { business_id: 'Business ID', message: 'Error Details' }
    },
    failed_and_success_message:
      '{noOfSuccessBusinesses} Businesses updated successfully and {noOfFailedBusinesses} Businesses are not updated',
    failed_message: '{noOfFailedBusinesses} Businesses are not updated'
  },
  vendors: {
    title: 'Vendors',
    table_title: 'Total Vendors: {count}',
    new_vendor: 'Create New Vendor',
    vendor_id_label: 'Vendor ID',
    company_name_label: 'Company Name',
    country_label: 'Country',
    city_label: 'City',
    table_columns: {
      company_name: 'Company Name',
      phone: 'Phone',
      address: 'Address'
    },
    delete_confirmation_modal: {
      title: 'Delete Vendor',
      content: 'Are you sure you want to delete this vendor?'
    }
  },
  missing_multi_package_modal: {
    title:
      '<span>{missingPackages, plural, one {# package missing} other {# packages missing}}</span> from {multiPackages, plural, one {# Multi-Package order} other {# Multi-Package orders}}',
    multi_package_orders: 'Multi-Package Orders',
    scanned: 'Scanned',
    back_to_scanning: 'Back to scanning',
    missing_package_list_item:
      'Order #{trackingNumber} <span>missing {missingPackages, plural, one {# package} other {# packages}}</span>'
  },
  routes: {
    routes_listing: {
      title: 'Routes',
      table_title: 'Total Routes: {count}',
      route_deleted_successfully: 'Route Deleted Successfully!',
      route_delete_confirmation: 'Are you sure you want to delete this route?',
      route_states: {
        drafted: 'Drafted',
        dispatched: 'Dispatched',
        debriefed: 'Debriefed',
        done: 'Done',
        archived: 'Archived',
        unassigned: 'UnAssigned',
        assigned: 'Assigned',
        accepted: 'Accepted',
        rejected: 'Rejected'
      },
      table_columns: {
        created_at: 'Created At',
        route_age: 'Route Age',
        last_updated: 'Last Updated',
        name: 'Name',
        number_of_deliveries: '# of Deliveries',
        number_of_pickups: '# of Pickups',
        state: 'State'
      },
      filters: {
        route_state: 'Route State'
      },
      actions: {
        print_run_sheet: 'Print Run Sheet',
        print_manifests: 'Print Manifests',
        edit_route: 'Edit Route',
        assign_star: 'Assign Star',
        delete_route: 'Delete Route'
      },
      assign_star_modal: {
        title: 'Assign Star',
        subtitle: 'Please select a star to assign to this route',
        success_message: 'Star assigned to route successfully!'
      }
    }
  },
  cod_reports: {
    amazon: {
      invoice_report_template: 'Invoice Report Template',
      upload_invoice_report: 'Upload Invoice Report'
    }
  },
  activate_international_shipping: {
    title: 'Activate international shipping',
    banner_title:
      'Make sure to review the below business info and documents before activating international shipping.',
    label: 'Contract',
    pickup_location: 'Pickup Location',
    international_pricing_plan: 'International Pricing Plan',
    international_bank_details: 'International Bank Account details',
    identity_number: 'Identity number',
    birth_date: 'Birth date',
    id_copy: 'ID Copy',
    commercial_registration: 'Commercial Registration',
    tax_id: 'Tax ID',
    upload_subtitle: 'Supports JPG, JPEG, PNG, PDF, DOC, DOCX - Max (5 MB)',
    success_activated_message: 'International shipping activated successfully',
    success_deactivated_message:
      'International shipping deactivated successfully '
  }
};
