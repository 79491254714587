import { useState, useEffect } from 'react';

import { checkFeatureToggle } from 'services/utils';

const useFeatureToggle = (toggleName) => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFeatureToggle = async () => {
      setIsLoading(true);
      try {
        const {
          data: { isToggleEnabled }
        } =
          (await checkFeatureToggle({
            toggleName
          })) || {};
        setIsFeatureEnabled(isToggleEnabled);
      } catch (error) {
        //
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeatureToggle();
  }, [toggleName]);

  return { isFeatureEnabled, isLoading };
};

export default useFeatureToggle;
