import React from 'react';
import { Modal } from 'antd';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';

import './PreviewImage.less';

class PreviewImage extends React.Component {
  render() {
    const { close, previewImage, previewImageTitle, isSvg } = this.props;

    return (
      <Modal
        {...this.props}
        footer={null}
        title={
          <>
            <span>{previewImageTitle}</span>
            <CloseIcon onClick={close} />
          </>
        }
        onCancel={close}
        wrapClassName="br-preview-image-modal"
      >
        {isSvg ? (
          previewImage
        ) : (
          <img
            alt=""
            className="br-support-tickets__preview-image"
            src={previewImage}
          />
        )}
      </Modal>
    );
  }
}

export default PreviewImage;
