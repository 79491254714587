import React from 'react';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import { getFormattedNumber } from 'utils/number';
import { COUNTRY_CURRENCY, IS_SAUDI } from 'constants/helper';
import {
  currentUserCountry,
  getInternationalVatPercentage,
  getVAT
} from 'common/countries/countries-mapping';
import {
  CUSTOMS_VAT_TYPE,
  INTERNATIONAL_COUNTRY_CURRENCY_ID,
  PERCENTAGE
} from 'constants/wallet';

import BRHeaderCardContent from 'components/BRHeaderCardContent/BRHeaderCardContent';

import './BostaFeesContent.less';

const isPercentageLabel = (type) => {
  return type === PERCENTAGE ? 'percentage' : 'egp';
};

const isPercentageValue = (type, value) => {
  return type === PERCENTAGE ? parseInt(value * 100) : value;
};

const BostaFeesContent = ({
  bostaFees,
  showTotal,
  tierInfo,
  promotion,
  isShipment = false,
  international = false
}) => {
  const {
    total_bosta_fees,
    totalBostaFeesAmount,
    totalShippingFeesAmount,
    totalInsuranceFeesAmount,
    totalCodFeesAmount,
    totalCustomVatAmount,
    cod_fees,
    insurance_fees,
    shipping_fees,
    vat_amount,
    totalVatAmount,
    custom_vat_type,
    custom_vat_value,
    vat,
    insurance_fees_percentage
  } = bostaFees || {};

  const f_total = totalBostaFeesAmount || total_bosta_fees || 0;
  const f_shipping = totalShippingFeesAmount || shipping_fees || 0;
  const f_insurance = totalInsuranceFeesAmount || insurance_fees || 0;
  const f_cod = totalCodFeesAmount || cod_fees || 0;
  const f_vat = totalVatAmount || vat_amount || vat || 0;
  const f_customs =
    custom_vat_type === CUSTOMS_VAT_TYPE.DDP ? custom_vat_value : 0;

  const vatValue = international ? getInternationalVatPercentage() : getVAT();

  return (
    <div className="br-bosta-fees-content">
      {showTotal && (
        <>
          <p className="br-bosta-fees-content__title">
            {fmt({
              id: `wallet.cycles.bosta_fees_popover.${
                international ? 'total_fees' : 'bosta_fees'
              }`
            })}
          </p>
          <p className="br-bosta-fees-content__sub-title br-bosta-fees-content__sub-title-negative">
            {fmt(
              {
                id: international
                  ? INTERNATIONAL_COUNTRY_CURRENCY_ID[
                      currentUserCountry.codeName
                    ]
                  : COUNTRY_CURRENCY
              },
              { cod: getFormattedNumber(f_total) }
            )}
          </p>
        </>
      )}
      <BRHeaderCardContent
        inline={!showTotal}
        currencyId={
          international &&
          INTERNATIONAL_COUNTRY_CURRENCY_ID[currentUserCountry.codeName]
        }
        dataSource={[
          {
            label: fmt({
              id: `wallet.cycles.bosta_fees_popover.shipping_fees`
            }),
            tooltip: fmt({
              id: `wallet.cycles.bosta_fees_popover.shipping_fees_tooltip`
            }),
            value: f_shipping
          },
          {
            label: fmt({
              id: `wallet.cycles.bosta_fees_popover.insurance_fees`
            }),
            tooltip: fmt(
              {
                id: `wallet.cycles.bosta_fees_popover.insurance_fees_tooltip`
              },
              {
                fee: parseFloat(insurance_fees_percentage) * 100
              }
            ),
            value: f_insurance,
            isVisible: () => f_insurance > 0
          },
          {
            label: fmt({
              id: `wallet.cycles.bosta_fees_popover.collection_fees`
            }),
            tooltip: fmt(
              {
                id: `wallet.cycles.bosta_fees_popover.collection_fees_tooltip`
              },
              {
                amount: tierInfo?.extraCodFee?.codAmount,
                fee: tierInfo?.extraCodFee?.percentage * 100
              }
            ),
            value: bostaFees?.collection_fees,
            isVisible: () => bostaFees?.collection_fees > 0
          },
          {
            label: fmt({
              id: 'wallet.cycles.bosta_fees_popover.cod_fees'
            }),
            tooltip: fmt(
              {
                id: `wallet.cycles.bosta_fees_popover.cod_fees_tooltip_${
                  IS_SAUDI ? 'sar' : 'egp'
                }`
              },
              {
                amount: tierInfo?.codFee?.amount
              }
            ),
            value: f_cod,
            isVisible: () => f_cod && parseInt(f_cod)
          },
          {
            label: fmt({
              id: `wallet.cycles.bosta_fees_popover.next_day_transfer_fees`
            }),
            tooltip: fmt(
              {
                id: `wallet.cycles.bosta_fees_popover.extra_fees_tooltip`
              },
              {
                fee: tierInfo?.expediteFee?.percentage * 100
              }
            ),
            value: bostaFees?.expedite_fees,
            isVisible: () => bostaFees?.expedite_fees > 0
          },
          {
            label: fmt({
              id: `wallet.cycles.bosta_fees_popover.pos_fees`
            }),
            tooltip: fmt(
              {
                id: `wallet.cycles.bosta_fees_popover.pos_fees_tooltip`
              },
              {
                fee: tierInfo?.posFee?.percentage * 100
              }
            ),
            value: bostaFees?.pos_fees,
            isVisible: () => bostaFees?.pos_fees > 0
          },
          {
            label: fmt({
              id: `wallet.cycles.bosta_fees_popover.fulfillment_fees`
            }),
            // tooltip: fmt(
            //   {
            //     id: `bosta_fees_popover.fulfillment_fees_tooltip`
            //   },
            // ),
            value: bostaFees?.fulfillment_fees,
            isVisible: () =>
              bostaFees?.fulfillment_fees &&
              parseInt(bostaFees?.fulfillment_fees)
          },
          {
            label: fmt({
              id: `wallet.cycles.bosta_fees_popover.discount`
            }),
            tooltip: fmt(
              {
                id: `wallet.cycles.bosta_fees_popover.discount_tooltip`
              },
              {
                amount: tierInfo?.zeroCodDiscount?.amount
              }
            ),
            value: bostaFees?.zero_discount_fees * -1,
            isVisible: () => bostaFees?.zero_discount_fees > 0
          },
          {
            label: fmt({
              id: `wallet.cycles.bosta_fees_popover.promotion`
            }),
            tooltip: fmt(
              {
                id: `wallet.cycles.bosta_fees_popover.promotion_tooltip_${
                  isShipment
                    ? isPercentageLabel(
                        bostaFees?.promotion_discount_value_type
                      )
                    : isPercentageLabel(promotion?.valueType)
                }`
              },
              {
                value: isShipment
                  ? isPercentageValue(
                      bostaFees?.promotion_discount_value_type,
                      bostaFees?.promotion_discount_value
                    )
                  : isPercentageValue(promotion?.valueType, promotion?.value)
              }
            ),
            value: bostaFees?.promotion_discount_amount * -1,
            isVisible: () => bostaFees?.promotion_discount_amount > 0
          },
          {
            label: fmt(
              {
                id: `wallet.cycles.bosta_fees_popover.vat`
              },
              {
                vatValue
              }
            ),
            tooltip: fmt(
              {
                id: 'wallet.cycles.bosta_fees_popover.vat_tooltip'
              },
              {
                vatValue
              }
            ),
            value: f_vat,
            isVisible: () => f_vat
          },
          {
            label: fmt({
              id: `wallet.cycles.bosta_fees_popover.open_package_fees`
            }),
            tooltip: fmt({
              id: 'wallet.cycles.bosta_fees_popover.open_package_fees_tooltip'
            }),
            value: bostaFees?.opening_package_fees,
            isVisible: () => !!parseInt(bostaFees?.opening_package_fees)
          },
          {
            label: fmt({
              id: `wallet.cycles.bosta_fees_popover.customs_fees`
            }),
            value: totalCustomVatAmount || f_customs,
            isVisible: () => parseInt(totalCustomVatAmount || f_customs)
          }
        ]}
      />
    </div>
  );
};

export default BostaFeesContent;
