import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import FxFDateFilter from '../FxFDateFilter/FxFDateFilter';
import SelectBusiness from 'components/NewFulfillment/FulfillmentInbound/components/SelectBusiness/SelectBusiness';

import './FxFFilters.less';

const FxFFilters = ({
  intl,
  updateFilters,
  dateFilter = null,
  renderExtraFilters = () => null,
  rightContent = null,
  hideDateFilter = false
}) => {
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  useEffect(() => {
    updateFilters({ businessId: selectedBusiness?._id });
  }, [selectedBusiness]);

  return (
    <div className="br-fxf-filters">
      <div className="br-fxf-filters__content">
        <SelectBusiness
          label={intl.formatMessage({
            id: 'fulfillment_inbound.po_filters.business.label'
          })}
          placeholder={intl.formatMessage({
            id: 'fulfillment_inbound.po_filters.business.placeholder'
          })}
          selectedBusiness={selectedBusiness}
          setSelectedBusiness={setSelectedBusiness}
        />
        {dateFilter ||
          (!hideDateFilter && (
            <FxFDateFilter
              label={intl.formatMessage({
                id: 'fulfillment_inbound.po_filters.created_on.label'
              })}
              updateFilters={updateFilters}
            />
          ))}
        {renderExtraFilters()}
        {rightContent}
      </div>
    </div>
  );
};

export default injectIntl(FxFFilters);
