import { Form, DatePicker } from 'antd';
import dayjs from 'dayjs';

import './FxFDateFilter.less';

const FxFDateFilter = ({
  label,
  updateFilters = () => {},
  updateStatisticsFilters = () => {}
}) => {
  const onDateChange = (date) => {
    const params = {
      from: date && dayjs.utc(date[0]).startOf('day').format(),
      to: date && dayjs.utc(date[1]).endOf('day').format()
    };

    updateFilters(params);
    updateStatisticsFilters(params);
  };

  return (
    <Form.Item label={label} className="br-fxf-date-filter-form-item">
      <DatePicker.RangePicker
        className="br-fxf-date-filter"
        onChange={onDateChange}
      />
    </Form.Item>
  );
};

export default FxFDateFilter;
